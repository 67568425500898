import { useCallback, useEffect, useState } from "react";
import { Card, Typography } from "@mui/material";
import { Bar, Tooltip } from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import VerticalBarChartWidget from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import {
  dateToExactDateTimeStringNoTz,
  getEndOfDay,
  getStartOfDay,
} from "../../../../../utils";
import { formatNumber } from "../../../../../utils/formatters";
import {
  ChartTooltip,
  ChartType,
} from "../../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";
import {
  DEFAULT_DASHBOARD_END_DATE,
  DEFAULT_DASHBOARD_START_DATE,
} from "../../shared";
import {
  formatDateIntoLabel,
  formatDateIntoLabelForTooltip,
  hasNoPermission,
} from "../../shared/utils";

export interface AlertsPerDay {
  date: string;
  value: number;
}
export interface DashboardWidgetAlertsPerDayData {
  alertsPerDay: AlertsPerDay[];
}

export interface DashboardWidgetAlertsPerDay
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAlertsPerDayData;
}

export interface AlertsPerDayWidgetProps {
  widget: DashboardWidgetAlertsPerDay;
}

export const AlertsPerDayWidget: React.FC<AlertsPerDayWidgetProps> = ({
  widget,
}) => {
  const isDarkMode = useCurrentTheme().palette.mode === "dark";
  const [startDate, setStartDate] = useState<Date>(
    DEFAULT_DASHBOARD_START_DATE
  );
  const [endDate, setEndDate] = useState<Date>(DEFAULT_DASHBOARD_END_DATE);
  const [data, setData] = useState<
    AlertsPerDayWidgetProps["widget"]["data"]["alertsPerDay"]
  >(widget?.data?.alertsPerDay || []);

  const handleDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        // They come in as dates and we need exact datetime strings
        setStartDate(getStartOfDay(startDate));
        setEndDate(getEndOfDay(endDate));
      }
    },
    [setStartDate, setEndDate]
  );
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetAlertsPerDayQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        orgId: currentOrg.value,
        widgetId: widget?.id,
        // Note: prevents the AWSDateTime input type resolver from casting to Date again and altering the desired date
        fromDate: dateToExactDateTimeStringNoTz(startDate),
        toDate: dateToExactDateTimeStringNoTz(endDate),
      },
    },
    {
      enabled: Boolean(widget?.id) && Boolean(currentOrg.value),
    }
  );

  useEffect(() => {
    setData(
      JSON.parse(getDashboardWidgetData?.getDashboardWidget?.data || "[]")
        ?.alertsPerDay ?? []
    );
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetAlertsPerDayQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
    >
      {data && !dashboardWidgetAlertsPerDayQueryError && (
        <div data-testid="dashboard-widget--alerts-per-day">
          <VerticalBarChartWidget
            /*
              startDate and endDate need to be converted to dates again
              to match the requirements of the DateRangePicker
            */
            startDate={startDate}
            endDate={endDate}
            isLoading={isLoading}
            xAxisTickFormatter={formatDateIntoLabel}
            onDateFilterChange={handleDateChange}
            title={widget.name ?? ""}
            yAxisLabel="Number of Alerts"
            xAsisKey="date"
            barKey="value"
            data={data}
            isDateFilterEnabled
            yAxisLabelColor={isDarkMode ? ColorsPalette.BrightWhite : undefined}
            noPermission={hasNoPermission(getDashboardWidgetData)}
            widgetId={widget.id}
          >
            <Bar
              fill={ColorsPalette.PrimaryBlue}
              dataKey="value"
              maxBarSize={70}
            ></Bar>
            <Tooltip
              formatter={(value) => [
                formatNumber(value as number),
                "Number of Alerts:",
              ]}
              labelFormatter={formatDateIntoLabelForTooltip}
              labelStyle={{
                color: "black",
              }}
              cursor={false}
              content={<ChartTooltip type={ChartType.Common} />}
            />
          </VerticalBarChartWidget>
        </div>
      )}
      {Boolean(dashboardWidgetAlertsPerDayQueryError) && (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the alerts per day
          chart! Please try again later.
        </Typography>
      )}
    </Card>
  );
};
