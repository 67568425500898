import { DwellSensorState } from "../../views/AssetsView/MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";

export const getDwellType = (dwellNumber: number): DwellSensorState => {
  if (dwellNumber > 29) {
    return DwellSensorState.Mega;
  } else if (dwellNumber > 7) {
    return DwellSensorState.Large;
  } else if (dwellNumber > 3) {
    return DwellSensorState.Medium;
  } else if (dwellNumber >= 0) {
    return DwellSensorState.Low;
  }

  return DwellSensorState.None;
};
