import { FC, useEffect, useMemo, useState } from "react";
import { Circle } from "@mui/icons-material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Chip,
  Stack,
  TextField,
  MenuItem,
  ThemeProvider,
  Grid,
  IconButton,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { nanoid } from "nanoid";
import {
  DateRangeKey,
  DEFAULT_DATE_RANGE_OPTIONS,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { Asset } from "../../../../../graphql/operations";
import { GraphOverlay } from "../../../../../shared/components/LoadingOverlaySkeletons/GraphLoader/GraphOverlay";
import { useSearchAggregatedEventDoorData } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useSearchAggregatedEventDoorData";
import { themes } from "../../../../../shared/hooks/theme/utils";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import useChartExpand from "../../../../../shared/hooks/useChartExpand";
import {
  checkIsAfter,
  checkIsBefore,
  convertToUTCEndOfDay,
  convertToUTCStartOfDay,
  formatDateWithoutTimeZone,
  getDifferenceInDays,
  getStartOfDay,
  getSubMonths,
  isEqualDates,
} from "../../../../../utils/date";
import {
  getBoxColor,
  getBoxBorderColor,
} from "../../Assets/CargoTabPanel/CargoStatus/utils";
import DoorHeader from "../../Assets/CargoTabPanel/DoorStatus/DoorHeader";
import { DoorData } from "../../Assets/CargoTabPanel/DoorStatus/utils";
import { HOURS_MAP } from "../../Assets/CargoTabPanel/InternalCameraSpaceGraph/constants";
import { AggregatedEventHistoryReportTypes } from "../../Assets/SummaryTabPanel/Charts/chartUtils";
import { useBrakesTabTheme } from "../BrakesTab/useBrakesTabTheme";

export interface DoorSensorGraphProps {
  canResize?: boolean;
  selectedAsset: Asset | null;
}

const DOOR_LEGEND_LABELS = ["Open", "Closed", "Unknown"];
const DEFAULT_DATE_RANGE_OPTION = DEFAULT_DATE_RANGE_OPTIONS.find(
  (option) => option.key === DateRangeKey.Last30Days
);

const DoorSensorGraph: FC<DoorSensorGraphProps> = ({
  canResize = true,
  selectedAsset,
}) => {
  // Hooks
  const brakesTabTheme = useBrakesTabTheme();
  const { gridWidth, gridWidthIndicator, handleToggleGridWidth } =
    useChartExpand();
  const isDesktop = useBreakpoint("up", "lg");
  const {
    state: { theme },
  } = useAppContext();
  const doorLegendColors = useMemo(
    () =>
      theme.theme === themes.light
        ? ["var(--brand-light-blue)", "var(--off-white)", "var(--border-color)"]
        : ["var(--brand-light-blue)", "var(--border)", "var(--dark-grey)"],
    [theme]
  );

  // States
  const [defaultStartDate, defaultEndDate] = DEFAULT_DATE_RANGE_OPTION?.getRange
    ? DEFAULT_DATE_RANGE_OPTION.getRange()
    : [new Date(), new Date()];
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    DEFAULT_DATE_RANGE_OPTION?.value
  );
  const [dateRangeDaysCount, setDateRangeDaysCount] = useState<number>(
    getDifferenceInDays(endDate, startDate)
  );

  // Effects
  useEffect(() => {
    setDateRangeDaysCount(getDifferenceInDays(endDate, startDate));
  }, [startDate, endDate]);

  // Queries
  const { data: dataDoor, isLoading } = useSearchAggregatedEventDoorData(
    {
      input: {
        startDate: convertToUTCStartOfDay(startDate),
        endDate: convertToUTCEndOfDay(endDate),
        assetId: selectedAsset?.asset_id ?? "",
        reportType: AggregatedEventHistoryReportTypes.DailyReportDoor,
        includeEmptyDates: true,
      },
    },
    {
      enabled: Boolean(selectedAsset?.asset_id),
    }
  );

  // Handlers
  const handleDateRangeChange = (value: number) => {
    const newOption = DEFAULT_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    const [start, end] = newOption?.getRange
      ? newOption.getRange()
      : [new Date(), new Date()];

    if (newOption && start && end) {
      setSelectedDateRangeOption(value);
      setStartDate(start);
      setEndDate(end);
    } else {
      setSelectedDateRangeOption(value);
    }
  };

  const setCustomDateRangeOption = () => {
    setSelectedDateRangeOption(last(DEFAULT_DATE_RANGE_OPTIONS)?.value);
  };

  const filteredDoorData = useMemo(() => {
    const sortedEventHistoryData = [...dataDoor].sort(
      (a: DoorData, b: DoorData) => {
        const aDate = new Date(a?.eventHistoryData?.date ?? 0);
        const bDate = new Date(b?.eventHistoryData?.date ?? 0);
        return aDate.getTime() - bDate.getTime();
      }
    );

    return sortedEventHistoryData.filter((data: DoorData) => {
      if (!data?.eventHistoryData?.date || !startDate || !endDate) return false;

      const eventDate = new Date(data.eventHistoryData.date);
      const areDatesInRange =
        checkIsAfter(eventDate, startDate) && checkIsBefore(eventDate, endDate);
      const areDatesEqual =
        isEqualDates(eventDate, startDate) || isEqualDates(eventDate, endDate);

      return areDatesInRange || areDatesEqual;
    });
  }, [dataDoor, startDate, endDate]);

  return (
    <Grid item lg={gridWidth} className={isDesktop ? "block" : "hidden"}>
      <Box className="h-full w-full flex flex-col p-5 bg-dashboard_subheader__bg">
        <Stack direction="row" justifyContent="end">
          <DoorHeader title="Door Trending Graph" noBorder />
          {canResize && (
            <IconButton
              sx={{
                width: "2rem",
                height: "1rem",
                backgroundColor: "transparent!important",
              }}
              aria-label="toggleWidth"
              data-testid="toggleWidth-door-chart"
              onClick={() => handleToggleGridWidth()}
            >
              <OpenInFullIcon
                fontSize="medium"
                style={{
                  fill: "var(--cargo-trend-expand-icon)",
                  marginRight: "0.313rem",
                }}
              />
              <span
                className="font-bold text-sm leading-4"
                style={{ marginTop: "-0.5rem", color: ColorsPalette.LightBlue }}
              >
                {gridWidthIndicator}
              </span>
            </IconButton>
          )}
        </Stack>
        <ThemeProvider theme={brakesTabTheme}>
          <Box className="flex items-center justify-end gap-4 py-4">
            <MobileDatePicker
              label="Start date"
              inputFormat="MM/dd/yyyy"
              closeOnSelect
              value={startDate}
              shouldDisableDate={(day) =>
                day < getStartOfDay(getSubMonths(new Date(), 6))
              }
              disableFuture
              onChange={(date) => {
                if (date) {
                  setStartDate(date);
                  setCustomDateRangeOption();
                }
              }}
              DialogProps={{
                className: "door-trending-date-picker",
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="door-chart-start-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <span className="text-base font-normal text-primary">to</span>
            <MobileDatePicker
              DialogProps={{
                className: "trending-voltage-date-picker",
              }}
              label="End date"
              closeOnSelect
              inputFormat="MM/dd/yyyy"
              value={endDate}
              shouldDisableDate={(day) => !!startDate && day < startDate}
              disableFuture
              onChange={(date) => {
                if (date) {
                  setEndDate(date);
                  setCustomDateRangeOption();
                }
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="voltage-chart-end-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />

            <TextField
              data-testid="door-chart-date-range-select"
              select
              value={selectedDateRangeOption}
              variant="outlined"
              className="w-40"
              onChange={(e) => handleDateRangeChange(+e.target.value)}
            >
              {DEFAULT_DATE_RANGE_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid={`door-chart-date-range-select-option-${option.value}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </ThemeProvider>
        <Box
          className="flex font-medium text-[10px] overflow-auto"
          data-testid="door-trending-graph-wrapper"
        >
          <Box
            className="flex flex-col mr-2 pt-1"
            data-testid="door-trending-graph-hour-list"
          >
            {HOURS_MAP.map((hour) => (
              <Box
                key={nanoid()}
                className="h-[36px] mb-2 flex items-center justify-end text-card-sensors-text"
              >
                {hour}
              </Box>
            ))}
          </Box>

          <Box
            className="flex h-full w-full"
            data-testid="door-trending-graph-content"
          >
            {isLoading ? (
              <GraphOverlay daysCount={dateRangeDaysCount} />
            ) : (
              filteredDoorData.map((day) => (
                <Box className="w-full" key={nanoid()}>
                  {(day?.eventHistoryData?.door?.doorStatus ?? [])
                    .slice() // Copy the array to avoid mutating the original
                    .reverse() // Hourly data is displayed in reverse order - 23:00 to 00:00
                    .map((hour) => (
                      <Box
                        key={nanoid()}
                        className={`h-[36px] flex flex-1 items-center justify-center m-1 mb-2 rounded ${getBoxColor(
                          hour?.status as number
                        )}`}
                        sx={{
                          borderColor: getBoxBorderColor(
                            hour?.status as number
                          ),
                        }}
                      />
                    ))}

                  <Box
                    className="mt-4 text-center text-card-sensors-text"
                    data-testid="door-trending-graph-day"
                  >
                    {day?.eventHistoryData?.date &&
                      formatDateWithoutTimeZone(day.eventHistoryData.date)}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>

        <Box
          className="mt-8 flex justify-center items-center"
          data-testid="door-trending-graph-legend"
        >
          {DOOR_LEGEND_LABELS.map((key, index) => (
            <Chip
              key={key}
              label={DOOR_LEGEND_LABELS[index]}
              icon={
                <Circle
                  sx={{
                    width: "16px",
                    height: "16px",
                    fill: doorLegendColors[index].toLowerCase(),
                    borderRadius: "50%",
                  }}
                />
              }
              sx={{
                height: "1.5rem",
                color: ColorsPalette.BrightWhite,
                borderRadius: "9999px",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                backgroundColor: "var(--grayscale-feather-gray)",
                "& .MuiChip-label": {
                  color: "var(--mid-charcoal)",
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default DoorSensorGraph;
