import { FC, useState } from "react";
import { Box } from "@mui/material";
import { sortBy } from "lodash";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  MergedPsiAirSupplySensorConfig,
  SensorThresholdShort,
} from "../../../../../graphql/operations";
import { MaxValuesBySensorType } from "../../../../../shared/helpers/battery";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValue } from "../../../../../utils/convertPressure";
import {
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  formatDate,
  parseISODate,
} from "../../../../../utils/date";
import { State } from "../../Assets/TiresTabPanel/components/charts/components/tpms/TiresChartBody";
import { sensorConfigToReferenceAreaValues } from "../../Assets/TiresTabPanel/helpers";
import BatteryChartLegend from "../BatteryChart/BatteryChartLegend";
import { CustomBrakesChartTooltip } from "./CustomBrakesChartTooltip";

export interface IndividualAirTank {
  min?: number | null;
  max?: number | null;
  median?: number | null;
}
export interface AirTankData {
  date: string;
  airTank?: {
    // airTank1, airTank2 and so on (if needed)
    [key: string]: IndividualAirTank;
  } | null;
}

interface BrakesAirTankChartProps {
  data?: AirTankData[];
  sensorProfile: MergedPsiAirSupplySensorConfig | null;
}

const containerHeight = 630;
const darkColors = [ColorsPalette.PrimaryBlue, ColorsPalette.Alert];
const lightColors = [ColorsPalette.PrimaryBlue, ColorsPalette.Alert];

export const STATE_TO_COLOR = {
  [State.Critical]: ColorsPalette.ErrorOpacity20,
  [State.Warning]: ColorsPalette.CautionOpacity10,
  [State.Alert]: ColorsPalette.AlertOpacity20,
  [State.Healthy]: ColorsPalette.SuccessOpacity20,
};

export const BrakesAirTankChart: FC<BrakesAirTankChartProps> = ({
  data,
  sensorProfile,
}) => {
  const pressureUnit = useGetPressureUnitPreference();
  const [hoveredLineIndex, setHoveredLineIndex] = useState<number>();
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);
  const muiTheme = useCurrentTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";
  const chartColors = isDarkMode ? darkColors : lightColors;

  const referenceAreaValues = sensorConfigToReferenceAreaValues(
    (sensorProfile?.tankPressure?.match?.thresholds as SensorThresholdShort) ??
      []
  );

  const formatXAxis = (tickItem: number) => {
    return formatDate(new Date(tickItem), DAY_MONTH_FORMAT);
  };

  const lineKeys = ["airTank1"];

  const visibleLines = lineKeys.filter((key) => !hiddenLines.includes(key));

  const formattedData = data?.map((d) => {
    const date = new Date(d.date);
    return {
      ...d,
      airTank: {
        airTank1: {
          min: getConvertedPressureValue(
            d?.airTank?.airTank1?.min ?? 0,
            pressureUnit
          ),
          max: getConvertedPressureValue(
            d?.airTank?.airTank1?.max ?? 0,
            pressureUnit
          ),
          median: getConvertedPressureValue(
            d?.airTank?.airTank1?.median ?? 0,
            pressureUnit
          ),
        },
      },
      axisDate: new Date(date).getTime(),
      toolTipDate: formatDate(parseISODate(d.date), DATE_FORMAT),
    };
  });

  const sortedData = sortBy(formattedData, (d) => d?.date);
  const XAxisDomain = [
    (sortedData[0]?.axisDate as number) ?? "auto",
    (sortedData[sortedData.length - 1]?.axisDate as number) ?? "auto",
  ];
  const YAxisDomain = [
    0,
    getConvertedPressureValue(MaxValuesBySensorType.tankPressure, pressureUnit),
  ];

  return (
    <>
      <Box
        className="relative !text-xs !font-medium"
        style={{ height: containerHeight }}
        data-testid="brakes-airtank-chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={sortedData}
            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="axisDate"
              tickFormatter={formatXAxis}
              scale="time"
              type="number"
              domain={XAxisDomain}
            />
            <YAxis
              label={{ value: "Pressure", angle: -90, position: "insideLeft" }}
              type={"number"}
              yAxisId={"pressure"}
              domain={YAxisDomain}
            />
            <Tooltip
              content={
                <CustomBrakesChartTooltip
                  activeIndex={hoveredLineIndex}
                  pressureUnit={pressureUnit}
                />
              }
            />

            {referenceAreaValues?.map((value, index) => (
              <ReferenceArea
                yAxisId={"pressure"}
                y1={getConvertedPressureValue(value.min ?? 0, pressureUnit)}
                y2={getConvertedPressureValue(value.max ?? 0, pressureUnit)}
                fill={STATE_TO_COLOR[value.state as State]}
                fillOpacity={0.8}
                ifOverflow="extendDomain"
                data-testid="air-tank-trending-graph-chart-body-reference-area"
                key={`air-tank-trending-graph-chart-body-reference-area-${index}`}
              />
            ))}

            {lineKeys.map((key, index) =>
              hiddenLines.includes(key) ? null : (
                <Line
                  key={key}
                  strokeWidth={4}
                  type="linear"
                  yAxisId={"pressure"}
                  dataKey={`airTank.${key}.median`}
                  stroke={chartColors[index]}
                  connectNulls
                  dot={false}
                  activeDot={{
                    onMouseOver: () => {
                      setHoveredLineIndex(
                        visibleLines.findIndex((k) => k === key)
                      );
                    },
                    onMouseOut: () => setHoveredLineIndex(undefined),
                  }}
                />
              )
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Box className="mb-12 flex justify-center gap-8">
        {lineKeys.map((key, index) => (
          <BatteryChartLegend
            key={key}
            label={`Air Tank ${index + 1}`}
            checked={!hiddenLines.includes(key)}
            color={chartColors[index]}
            onClick={() =>
              setHiddenLines((prevState) =>
                prevState.includes(key)
                  ? prevState.filter((k) => k !== key)
                  : [...prevState, key]
              )
            }
          />
        ))}
      </Box>
    </>
  );
};
