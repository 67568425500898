import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { DataItem } from "../../../../../shared/components/DashboardWidgets/PieChartWidget";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export const reportingDevicesMap: Record<string, string> = {
  reporting: "Reporting",
  notReporting: "Not Reporting",
  notApplicable: "N/A",
};

export type DashboardWidgetReportingDevicesData = {
  reporting?: number;
  notReporting?: number;
  notApplicable?: number;
};

export interface DashboardWidgetReportingDevicesTypes
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetReportingDevicesData;
}

export interface ReportingDevicesWidgetProps {
  widget: DashboardWidgetReportingDevicesTypes;
}

export const mapWidgetData = (
  data: DashboardWidgetReportingDevicesData
): { dataSet: DataItem[]; total: number } => {
  let total = 0;
  if (!data) {
    return { dataSet: [], total };
  }

  const dataSet = Object.keys(data).map((key, index) => {
    const value = data[key as keyof DashboardWidgetReportingDevicesData];
    const isNumber = value && typeof value === "number";

    total += isNumber ? value : 0;

    return {
      name: reportingDevicesMap[key],
      color: mapChartColors(key, index),
      value: isNumber ? value : 0,
    };
  });
  return { dataSet, total };
};

const mapChartColors = (data: string, index: number): string => {
  const colors = [
    "rechartsTeal",
    "rechartsPurple",
    "rechartsViolet",
    "rechartsMarineBlue",
    "rechartsCoral",
  ];
  return `var(--${colors[index]})`;
};

export const ReportingDevicesWidget: React.FC<ReportingDevicesWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetReportingDevicesData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const { dataSet: mappedData, total } = mapWidgetData(parsedData);

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--reporting-devices"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the reporting devices
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          subtitle="Devices"
          showTotal={true}
          isLoading={isLoading}
          showPercentages={true}
          totalRecords={total}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
