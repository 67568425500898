import { FC, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DevicesPerOrgRecord = {
  _id: string;
  totalCount: number;
  percentage: number;
};

export type DashboardWidgetDevicesPerOrgData = {
  total: number;
  data: Array<DevicesPerOrgRecord>;
};

export interface DashboardWidgetDevicesPerOrgType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetDevicesPerOrgData;
}

export interface DevicesPerOrganizationsWidgetProps {
  widget: DashboardWidgetDevicesPerOrgType;
}

const mapWidgetData = (devicesPerOrg: DashboardWidgetDevicesPerOrgData) => {
  return (
    devicesPerOrg?.data?.reduce((acc: HorizontalBarChartData[], deviceStat) => {
      const resultObject: HorizontalBarChartData = {
        name: capitalize(deviceStat._id),
        color: ColorsPalette.PrimaryBlue,
        value: deviceStat.totalCount,
      };

      acc.push(resultObject);

      return acc;
    }, []) ?? []
  );
};

export const DevicesPerOrganizationsWidget: FC<
  DevicesPerOrganizationsWidgetProps
> = ({ widget }) => {
  const currentOrg = useSelectedOrg();
  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetDevicesPerOrgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );

    const mappedData = mapWidgetData(parsedData);

    const total = parsedData?.total ?? 0;
    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--devices-per-org"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the devices per
          organization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={widget.name ?? "Devices"}
          showXAxis={false}
          subtitle="Total number of Devices"
          showTotal={true}
          showPercentages={true}
          totalRecords={total}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
