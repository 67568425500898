import { FC, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  ASSETS_UPLOAD_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../../constants";
import { useGetAssetDoorTypes } from "../../../../constants/assets";
import { useAppContext } from "../../../../context/AppContext";
import { BatchTemplate } from "../../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../../shared/components/UploadFileDialog/UploadResult";
import { ParseFileContentError, useFeatureFlag } from "../../../../utils";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import {
  BatchUploadDialogProps,
  useBatchUploadForm,
} from "../../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import { parseAssetsFile, mapFileDataToCreateAssetInput } from "./helpers";
import { ValidatedAsset } from "./types";

export const UploadAssetsDialog: FC<BatchUploadDialogProps> = ({
  title,
  customerOrg,
  dialogFields,
  isOpen,
  onClose,
  onUpload,
}: BatchUploadDialogProps) => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useBatchUploadForm(title, customerOrg);

  const doorTypeOptions = useGetAssetDoorTypes();

  const resetState = () => {
    setIsAssetsUploading(false);
    setIsAssetsUploaded(false);
    setParseFileResult({ validatedAssets: [], validationErrors: [] });
  };

  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isAssetsUploading, setIsAssetsUploading] = useState(false);
  const [isAssetsUploaded, setIsAssetsUploaded] = useState(false);
  const [parseFileResult, setParseFileResult] = useState<{
    validatedAssets: ValidatedAsset[];
    validationErrors: ParseFileContentError[];
  }>({ validatedAssets: [], validationErrors: [] });

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const handleSubmit = async (file: File) => {
    setIsAssetsUploading(true);

    const {
      org_select: { id: orgId, label: org_name },
      batch_name,
    } = getValues();

    try {
      const { fileData, errors: parseErrors } = await parseAssetsFile(file);
      const { validatedAssets, validationErrors } =
        mapFileDataToCreateAssetInput(fileData, org_name, {
          doorTypes: doorTypeOptions,
        });

      const combinedErrors = [...validationErrors, ...parseErrors];
      setParseFileResult({
        validatedAssets,
        validationErrors: combinedErrors,
      });
      if (combinedErrors.length > 0) {
        throw new Error("Validation errors");
      }

      const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
      setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

      const assets = validatedAssets.map(({ asset }) => asset);
      const jsonFile = parseContentAsJsonBlobFile(assets, fileName);

      await uploadBatchFileToS3(
        jsonFile,
        BatchTemplate.CreateAssets,
        batch_name,
        orgId
      );
    } catch (error) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Devices Uploading Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
      setIsAssetsUploading(false);
      setIsAssetsUploaded(true);
    }

    setIsAssetsUploading(false);
    setIsAssetsUploaded(true);

    if (onUpload) {
      onUpload();
    }

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      queryClient.invalidateQueries(["getAssetsForClustersOS"]);
      queryClient.invalidateQueries(["getAssetsForTableOS"]);
      queryClient.invalidateQueries(["getAssetsForListOS"]);
    } else {
      queryClient.invalidateQueries(["getAssetsClusters"]);
      queryClient.invalidateQueries(["getAssetsForTable"]);
      queryClient.invalidateQueries(["getAssetsForList"]);
    }
  };

  const onFilesUploadDialogClose = () => {
    onClose();
    resetState();
  };

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      onClose={onFilesUploadDialogClose}
      handleSubmit={handleSubmit}
      isDataUploading={isAssetsUploading}
      isDataUploaded={isAssetsUploaded}
      downloadTemplateFilePath={ASSETS_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={parseFileResult.validationErrors}
          processedRecordsCount={parseFileResult.validatedAssets.length}
          itemName={"asset"}
        />
      }
    />
  );
};
