import { GridValueFormatterParams } from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import {
  AssetDetentionTableData,
  Maybe,
  TableColumnFormat as ReportColumnFormat,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { TableColumnProps } from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { unloadDurationValueFormatter } from "../../../../AssetsView/MapView/Geofences/GeofenceDwellHistory/helpers";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel: Record<
  keyof Pick<
    AssetDetentionTableData,
    | "assetId"
    | "geofenceName"
    | "orgName"
    | "freeDaysStart"
    | "freeDaysEnd"
    | "detentionStart"
    | "totalDaysInDetention"
    | "detentionCharges"
    | "detentionEnd"
    | "cargoStatusOnEnter"
    | "unloadDuration"
    | "cargoStatusOnExit"
    | "locationAddress"
  >,
  boolean
> = {
  assetId: false,
  geofenceName: false,
  orgName: false,
  freeDaysStart: false,
  freeDaysEnd: false,
  detentionStart: false,
  totalDaysInDetention: false,
  detentionCharges: false,
  detentionEnd: false,
  cargoStatusOnEnter: false,
  unloadDuration: false,
  cargoStatusOnExit: false,
  locationAddress: false,
};

const commonColumnConfig = {
  flex: 1,
  minWidth: 120,
};

const statusColumnOptions: TableColumnProps<AssetDetentionTableData>["options"] =
  {
    ...commonColumnConfig,
    minWidth: 130,
    valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
      if (value === "NA") return value;
      return value ? capitalize(value) : "";
    },
  };

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  return [
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("geofenceName", "Geofence", ReportColumnFormat.String),
    createColumn("orgName", "Organization Name", ReportColumnFormat.String),
    createColumn(
      "freeDaysStart",
      "Free Days Start",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn(
      "freeDaysEnd",
      "Free Days End",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn(
      "detentionStart",
      "Detention Start",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn(
      "totalDaysInDetention",
      "Days In Detention",
      ReportColumnFormat.String,
      {
        type: "number",
      }
    ),
    createColumn(
      "detentionCharges",
      "Detention Charges ($)",
      ReportColumnFormat.String,
      {
        type: "number",
      }
    ),
    createColumn(
      "detentionEnd",
      "Detention End",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn(
      "cargoStatusOnEnter",
      "Cargo Status On Enter",
      ReportColumnFormat.String,
      statusColumnOptions
    ),
    createColumn(
      "unloadDuration",
      "Unload Duration",
      ReportColumnFormat.String,
      {
        type: "number",
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<
          AssetDetentionTableData["unloadDuration"]
        >) => unloadDurationValueFormatter(value),
      }
    ),
    createColumn(
      "cargoStatusOnExit",
      "Cargo Status On Exit",
      ReportColumnFormat.String,
      statusColumnOptions
    ),
    createColumn(
      "locationAddress",
      "Location Address ",
      ReportColumnFormat.String,
      {
        filterable: false,
        sortable: false, // Consumes too much memory as the field is not optimized for sorting
      }
    ),
  ];
};

export const SEARCH_KEYS = [
  "assetId",
  "geofenceName",
  "orgName",
  "freeDaysStart",
  "freeDaysEnd",
  "detentionStart",
  "totalDaysInDetention",
  "detentionCharges",
  "detentionEnd",
  "cargoStatusOnEnter",
  "unloadDuration",
  "cargoStatusOnExit",
  "locationAddress",
];

export const ASSET_DETENTION_COLUMN_TYPE_MAP = {
  assetId: TableValueDataType.String,
  geofenceName: TableValueDataType.String,
  orgName: TableValueDataType.String,
  freeDaysStart: TableValueDataType.Date,
  freeDaysEnd: TableValueDataType.Date,
  detentionStart: TableValueDataType.Date,
  totalDaysInDetention: TableValueDataType.Number,
  detentionCharges: TableValueDataType.Number,
  detentionEnd: TableValueDataType.Date,
  cargoStatusOnEnter: TableValueDataType.String,
  unloadDuration: TableValueDataType.Number,
  cargoStatusOnExit: TableValueDataType.String,
  locationAddress: TableValueDataType.String,
};
