import { useCallback, useEffect, useState } from "react";
import { Card, CircularProgress, Typography } from "@mui/material";
import { Bar, Tooltip } from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import VerticalBarChartWidget from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import {
  dateToExactDateTimeStringNoTz,
  DAY_MONTH_FORMAT,
  DATE_FORMAT,
  formatDate,
  getEndOfDay,
  getStartOfDay,
} from "../../../../../utils";
import { formatNumber } from "../../../../../utils/formatters";
import {
  ChartTooltip,
  ChartType,
} from "../../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";
import {
  DEFAULT_DASHBOARD_END_DATE,
  DEFAULT_DASHBOARD_START_DATE,
} from "../../shared";
import { hasNoPermission } from "../../shared/utils";

export const formatDateIntoLabelForTooltip = (date: string) => {
  return `${formatDate(new Date(date), DATE_FORMAT)}`;
};
export const formatDateIntoLabel = (date: string) => {
  // If the date is a range -> return only start date for label
  if (date?.length > 10)
    return formatDate(new Date(date.slice(0, 10)), DAY_MONTH_FORMAT);
  return formatDate(new Date(date), DAY_MONTH_FORMAT);
};

export type NotReportingDeviceData = {
  orgId: string;
  notReporting: number;
  reporting: number;
  reportDate: string;
};

export type DashboardWidgetNotReportingDevicesData = {
  notReportingDevices: Array<NotReportingDeviceData>;
};

export interface DashboardWidgetNotReportingDevicesTypes
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetNotReportingDevicesData;
}

export interface NotReportingDevicesWidgetProps {
  widget: DashboardWidgetNotReportingDevicesTypes;
}

export const NotReportingDevicesWidget: React.FC<
  NotReportingDevicesWidgetProps
> = ({ widget }) => {
  const isDarkMode = useCurrentTheme().palette.mode === "dark";
  const customerOrganization = useSelectedOrg();

  const [startDate, setStartDate] = useState<Date>(
    DEFAULT_DASHBOARD_START_DATE
  );
  const [endDate, setEndDate] = useState<Date>(DEFAULT_DASHBOARD_END_DATE);
  const [data, setData] = useState<
    NotReportingDevicesWidgetProps["widget"]["data"]["notReportingDevices"]
  >([]);

  const handleDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        // They come in as dates and we need exact datetime strings
        setStartDate(getStartOfDay(startDate));
        setEndDate(getEndOfDay(endDate));
      }
    },
    [setStartDate, setEndDate]
  );

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetInstallationsPerDayQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: customerOrganization.value,
        // Note: prevents the AWSDateTime input type resolver from casting to Date again and altering the desired date
        fromDate: dateToExactDateTimeStringNoTz(startDate),
        toDate: dateToExactDateTimeStringNoTz(endDate),
      },
    },
    {
      enabled:
        Boolean(customerOrganization.value) &&
        Boolean(startDate) &&
        Boolean(endDate),
    }
  );

  useEffect(() => {
    if (getDashboardWidgetData?.getDashboardWidget?.data) {
      const data: Array<NotReportingDeviceData> = JSON.parse(
        getDashboardWidgetData.getDashboardWidget.data
      ).notReportingDevices;
      const dataToDisplay = data.filter((x) => x.notReporting > 0);
      setData(dataToDisplay.length > 0 ? data : []);
    }
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetInstallationsPerDayQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
    >
      {data && !dashboardWidgetInstallationsPerDayQueryError && (
        <div data-testid="dashboard-widget--not-reporting-devices">
          <VerticalBarChartWidget
            /*
              startDate and endDate need to be converted to dates again
              to match the requirements of the DateRangePicker
            */
            startDate={startDate}
            endDate={endDate}
            xAxisTickFormatter={formatDateIntoLabel}
            onDateFilterChange={handleDateChange}
            title={widget.name ?? ""}
            yAxisLabel="Number of Devices"
            xAsisKey="reportDate"
            barKey="notReporting"
            isLoading={isLoading}
            data={data}
            isDateFilterEnabled
            yAxisLabelColor={isDarkMode ? ColorsPalette.BrightWhite : undefined}
            yAxisLabelPosition="left"
            noPermission={hasNoPermission(getDashboardWidgetData)}
            widgetId={widget.id}
          >
            <Bar
              fill={ColorsPalette.PrimaryBlue}
              dataKey="notReporting"
              maxBarSize={70}
            ></Bar>
            <Tooltip
              formatter={(value) => [
                formatNumber(value as number),
                "Number of Devices:",
              ]}
              labelStyle={{
                color: "black",
              }}
              cursor={false}
              content={<ChartTooltip type={ChartType.Common} />}
            />
          </VerticalBarChartWidget>
        </div>
      )}
      {Boolean(dashboardWidgetInstallationsPerDayQueryError) && (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the Not Reporting
          Devices chart! Please try again later.
        </Typography>
      )}
    </Card>
  );
};
