import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  TableViewType,
  useGetAssetAlertsTableDataQuery,
  FileFormat,
  AssetAlertsTableDataResponse,
  GetAssetAlertsTableDataInput,
  SortOrder,
  AssetAlertsTableData,
  TableDomain,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel, getColumns } from "./columns";

export type AlertsListProps = {
  selectedAssetId: string;
};

export const AssetAlertsList: FC<AlertsListProps> = memo(
  ({ selectedAssetId }) => {
    const timezone = usePreferredTimezone();
    const apiRef = useGridApiRef();
    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isExporting, setIsExporting] = useState(false);
    const columns = useMemo(() => getColumns(timezone), [timezone]);

    const {
      state: { appConfig },
    } = useAppContext();

    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [queryInput, setQueryInput] = useState<GetAssetAlertsTableDataInput>({
      sorting: [{ field: "remindedAt", order: SortOrder.Desc }],
      assetId: selectedAssetId,
      pagination: {
        limit: appConfig.table.defaultRowsPerPage,
        skip: 0,
      },
    });

    const baseFilters = useMemo(
      () => ({ assetId: selectedAssetId }),
      [selectedAssetId]
    );

    useEffect(() => {
      setQueryInput((prev) => ({
        ...prev,
        assetId: selectedAssetId,
      }));
    }, [selectedAssetId]);

    const {
      data: queryData,
      isLoading,
      isSuccess,
    } = useGetAssetAlertsTableDataQuery<AssetAlertsTableDataResponse>(
      { input: queryInput },
      {
        staleTime: 5000,
        refetchOnMount: true,
        select: ({ getAssetAlertsTableData }) => getAssetAlertsTableData,
      }
    );

    useTableDataExporter<AssetAlertsTableData>({
      queryInput,
      columns,
      apiRef,
      domain: TableDomain.AssetAlerts,
      isExporting,
      fileFormat,
      setExporting: setIsExporting,
      totalCount: queryData?.pagination.total,
      baseFilters,
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    const updateQueryInput = useCallback(
      (data: Partial<GetAssetAlertsTableDataInput>) => {
        setQueryInput((prev) => ({ ...prev, ...data }));
      },
      [setQueryInput]
    );

    return (
      <Box className="h-full w-full bg-background pt-2">
        <Box
          data-testid="asset-alerts-table-container"
          className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16"
        >
          <BackEndProcessingTable
            apiRef={apiRef}
            domain={TableDomain.AssetAlerts}
            baseFilters={baseFilters}
            isSendingEmail={isSendingEmail}
            setSendingEmail={setIsSendingEmail}
            tableName={"asset-alerts-list"}
            queryInput={queryInput}
            tableType={TableViewType.Alerts}
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            onExport={handleExport}
            data={{
              rows: queryData?.data ?? [],
              pagination: queryData?.pagination,
            }}
            updateQueryInput={updateQueryInput}
            isLoading={isLoading}
            sorting={queryInput.sorting ?? undefined}
            isSuccess={isSuccess}
            totalCount={queryData?.pagination.total}
          />
        </Box>
        <Spinner counter={Number(isSendingEmail)} />
      </Box>
    );
  }
);
