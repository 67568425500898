import { TRIP_STATUS } from "../../../../../constants/map";
import { Maybe } from "../../../../../graphql/operations";
import { getDwellType } from "../../../../../shared/helpers/dwell";
import { DwellSensorState } from "../../../MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";

export enum IconType {
  EMPTY = "EMPTY",
  MOVING = "MOVING",
  LOW_DWELL = "LOW_DWELL",
  MEDIUM_DWELL = "MEDIUM_DWELL",
  HIGH_DWELL = "HIGH_DWELL",
  MEGA_DWELL = "MEGA_DWELL",
}

// for sorting purpose we need some literal value to represent a type of icon
export const getIconType = (
  trip_st?: Maybe<string>,
  dwellingDays?: Maybe<number>,
  signal?: Maybe<boolean>,
  cargo?: Maybe<string>
) => {
  let result = "";
  if (!trip_st) {
    result = IconType.EMPTY;
  }
  const tripStatus = trip_st?.toLocaleLowerCase();
  if (tripStatus === TRIP_STATUS.Moving) {
    result = IconType.MOVING;
  }
  if (tripStatus === TRIP_STATUS.Parked) {
    const dwellNumber = Number(dwellingDays);
    const dwellType = getDwellType(dwellNumber);

    switch (dwellType) {
      case DwellSensorState.Mega:
        result = IconType.MEGA_DWELL;
        break;
      case DwellSensorState.Large:
        result = IconType.HIGH_DWELL;
        break;
      case DwellSensorState.Medium:
        result = IconType.MEDIUM_DWELL;
        break;
      case DwellSensorState.Low:
        result = IconType.LOW_DWELL;
        break;
    }
  }

  if (signal) {
    result = `${result}_ONLINE`;
  }
  if (cargo && cargo !== "unknown") {
    result = `${result}_${cargo.toUpperCase()}`;
  }

  return result;
};
