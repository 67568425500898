import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CircleIcon from "@mui/icons-material/Circle";
import CompressIcon from "@mui/icons-material/Compress";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { QueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import {
  AssetWithSensors,
  AssignOrgProfileToAssetsResponse,
  SensorProfileConfigType,
  ProfileConfigProperty,
  SensorProfileType,
  SetAssetSensorProfilesResponse,
  SensorProfileConfig,
  SensorStatusUppercase,
  PressureUnit,
  SensorThresholdShort,
  TpmsPressureThresholds,
  AirTankPressureThresholds,
  TemperatureUnit,
  TemperatureInternalThresholds,
  TableColumnFormat,
  Pagination,
} from "../../../../graphql/operations";
import { getBatteryIcon } from "../../../../shared/components/BatteryIcon";
import type { TableGridColDef } from "../../../../shared/components/Table";
import { getTableColumn } from "../../../../shared/components/Table/utils";
import TableHeader from "../../../../shared/components/TableHeader/TableHeader";
import { createField } from "../../../../shared/helpers/utils";
import { getConvertedPressureValue } from "../../../../utils/convertPressure";
import { getConvertedTemperatureValue } from "../../../../utils/convertTemperature";
import {
  BatteryState,
  tableStyleMap,
  renderValueRanges,
  SensorsProperties,
  getLiteSentryGammaSensorTableColumns,
} from "../Profiles/profileUtils";

export enum TemperatureHealthState {
  Healthy = "Healthy Temperature Level",
  Warning = "Warning Temperature Level",
  Alert = "Alert Temperature Level",
  Critical = "Critical Temperature Level",
}

export enum PressureHealthState {
  Healthy = "Healthy Pressure Level",
  Warning = "Warning Pressure Level",
  Alert = "Alert Pressure Level",
  Critical = "Critical Pressure Level",
}

export enum ImbalanceHealthState {
  Healthy = "Healthy Dual Imbalance",
  Warning = "Warning Dual Imbalance",
  Alert = "Alert Dual Imbalance",
  Critical = "Critical Dual Imbalance",
}

export enum CameraState {
  Critical = "Critical",
  Alert = "Alert",
  Warning = "Warning",
  Healthy = "Healthy",
  Unknown = "Unknown",
}

export enum CameraSensorTypes {
  FloorUsagePercentage = "floorUsagePercentage",
  CubeUsagePercentage = "cubeUsagePercentage",
}

export enum RegulatorHealthState {
  HealthyMoving = "Healthy Regulator Pressure Moving",
  WarningMoving = "Warning Regulator Pressure Moving",
  AlertMoving = "Alert Regulator Pressure Moving,",
  CriticalMoving = "Critical Regulator Pressure Moving",
  HealthyParked = "Healthy Regulator Pressure Parked",
  WarningParked = "Warning Regulator Pressure Parked",
  AlertParked = "Alert Regulator Pressure Parked",
  CriticalParked = "Critical Regulator Pressure Parked",
}

export type SensorsData = {
  data: AssetWithSensors[]; // TODO: Fix any
  pagination: Pagination | undefined;
  isErrorSensor: boolean;
  isFetchingSensor: boolean;
};

export enum SensorsGatewayTypes {
  Voltage = SensorProfileConfigType.Voltage,
  TpmsBeta = SensorProfileConfigType.TpmsBeta,
  Airbag = SensorProfileConfigType.Airbag,
  airSupply = ProfileConfigProperty.AirSupply,
  CargoCamera = SensorProfileConfigType.CargoCamera,
  PsiWheelEnd = SensorProfileConfigType.PsiWheelEnd,
  AtisAlpha = SensorProfileConfigType.AtisAlpha,
  Internal = ProfileConfigProperty.Internal,
  LiteSentryGamma = SensorProfileConfigType.LiteSentryGamma,
  Liftgate = SensorProfileConfigType.Liftgate,
}

export const tableSensorStatusColorsMapping: Record<
  SensorStatusUppercase,
  ColorsPalette | "var(--sensor-unknown)"
> = {
  [SensorStatusUppercase.Healthy]: ColorsPalette.Success,
  [SensorStatusUppercase.Warning]: ColorsPalette.Caution,
  [SensorStatusUppercase.Alert]: ColorsPalette.Alert,
  [SensorStatusUppercase.Critical]: ColorsPalette.Error,
  [SensorStatusUppercase.Unknown]: "var(--sensor-unknown)",
};

export const getColumnValue = (
  params: GridValueGetterParams<any, AssetWithSensors>,
  path: string | number
) => {
  return get(params.row, path);
};

const getTableStyle = (state: string): string => {
  const styles: { [key: string]: string } = {
    healthy: tableStyleMap.healthy,
    warning: tableStyleMap.warning,
    alert: tableStyleMap.alert,
    critical: tableStyleMap.critical,
  };
  return styles[state] || "";
};

export const sensorsTableColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.voltage.statusVoltage", "Status", {
      minWidth: 64,
      renderCell: (params: GridRenderCellParams) =>
        getBatteryIcon(params.value, { width: "24px", height: "24px" }),
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("imei", "Device ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("prd_cde", "Product Name", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("sensorProfile.configuration.voltage", "Profile", {
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
    }),
  },
  {
    ...createField("batterySensors.healthy", "Healthy", {
      renderHeader: () => {
        return <TableHeader text={BatteryState.Healthy} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("batterySensors.warning", "Warning", {
      renderHeader: () => <TableHeader text={BatteryState.Warning} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("batterySensors.alert", "Alert", {
      renderHeader: () => <TableHeader text={BatteryState.Alert} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("batterySensors.critical", "Critical", {
      renderHeader: () => <TableHeader text={BatteryState.Critical} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

export const liftgateSensorTableColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.liftgateAdvertisement.statusLiftgate", "Status", {
      minWidth: 64,
      renderCell: (params: any) => (
        <CircleIcon
          sx={{
            color:
              tableSensorStatusColorsMapping[
                params.value as SensorStatusUppercase
              ],
          }}
        />
      ),
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("imei", "Device ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("prd_cde", "Product Name", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("sensorProfile.configuration.liftgate", "Profile", {
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
    }),
  },
  {
    ...createField("liftgate.healthy", "Healthy", {
      renderHeader: () => {
        return <TableHeader text={BatteryState.Healthy} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("liftgate.warning", "Warning", {
      renderHeader: () => <TableHeader text={BatteryState.Warning} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("liftgate.alert", "Alert", {
      renderHeader: () => <TableHeader text={BatteryState.Alert} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("liftgate.critical", "Critical", {
      renderHeader: () => <TableHeader text={BatteryState.Critical} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

const createFieldWithOptions = (
  field: string,
  label: string,
  options: Record<string, any> = {}
) => ({
  ...createField(field, label, { flex: 1, minWidth: 120, ...options }),
});

export const sensorsAirSupplyTableColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.psiAirSupply.statusPsiAirSupply", "Status", {
      minWidth: 64,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CircleIcon
            sx={{
              color:
                tableSensorStatusColorsMapping[
                  params.value as SensorStatusUppercase
                ],
            }}
          />
        );
      },
    }),
  },
  createFieldWithOptions("asset_id", "Asset ID"),
  createFieldWithOptions("imei", "Device ID"),
  createFieldWithOptions("prd_cde", "Product Name"),
  createFieldWithOptions(
    "sensorProfile.configuration.psiAirSupply",
    "Profile",
    {
      valueGetter: (params: GridValueGetterParams) => {
        return params?.value?.profileName;
      },
    }
  ),
  {
    field: "tankPressure.minSpeed",
    headerName: "Min Speed",
    flex: 1,
    minWidth: 120,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => params.value,
    sortable: false,
  },
  {
    ...createField("tankPressure.healthy", "Healthy Air Tank Pressure", {
      renderHeader: () => {
        return <TableHeader text="Healthy Air Tank Pressure" />;
      },
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.value?.healthyFrom} - ${params.value?.healthyTo}`;
      },
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("tankPressure.warning", "Warning Air Tank Pressure", {
      renderHeader: () => <TableHeader text="Warning Air Tank Pressure" />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("tankPressure.alert", "Alert Air Tank Pressure", {
      renderHeader: () => <TableHeader text="Alert Air Tank Pressure" />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("tankPressure.critical", "Critical Air Tank Pressure", {
      renderHeader: () => <TableHeader text="Critical Air Tank Pressure" />,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },

  // Regulator columns
  {
    ...createField(
      "regulator.moving.healthy",
      "Healthy Regulator Pressure Moving",
      {
        renderHeader: () => {
          return <TableHeader text={RegulatorHealthState.HealthyMoving} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.healthy,
        cellClassName: tableStyleMap.healthy,
        filterable: false,
        sortable: false,
      }
    ),
  },
  {
    ...createField("regulator.moving.warning", "Warning Level Moving", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.WarningMoving} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.moving.alert", "Alert Level Moving", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.AlertMoving} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.moving.critical", "Critical Level Moving", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.CriticalMoving} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.parked.healthy", "Healthy Level Parked", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.HealthyParked} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.parked.warning", "Warning Level Parked", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.WarningParked} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.parked.alert", "Alert Level Parked", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.AlertParked} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("regulator.parked.critical", "Critical Level Parked", {
      renderHeader: () => {
        return <TableHeader text={RegulatorHealthState.CriticalParked} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

export const tpmsSensorColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.tpmsBeta.statusTpmsBeta", "Status", {
      minWidth: 64,
      valueGetter: (params: GridValueGetterParams) =>
        getColumnValue(params, "sensors.tpmsBeta.statusTpmsBeta"),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CompressIcon
            sx={{
              color:
                tableSensorStatusColorsMapping[
                  params.value as SensorStatusUppercase
                ],
            }}
          />
        );
      },
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { minWidth: 64 }),
  },
  {
    ...createField("imei", "Device ID", { minWidth: 64 }),
  },
  {
    ...createField("prd_cde", "Product Name", { minWidth: 64 }),
  },
  {
    ...createField(
      "sensorProfile.configuration.tpmsBeta.pressure.profileName",
      "Profile",
      {
        minWidth: 64,
      }
    ),
  },
  {
    ...createField("temperature.healthy", "Healthy Temperature Level", {
      renderHeader: () => {
        return <TableHeader text={TemperatureHealthState.Healthy} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      minWidth: 90,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("temperature.warning", "Warning Temperature Level", {
      renderHeader: () => {
        return <TableHeader text={TemperatureHealthState.Warning} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      minWidth: 90,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("temperature.alert", "Alert Temperature Level", {
      renderHeader: () => {
        return <TableHeader text={TemperatureHealthState.Alert} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      minWidth: 90,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("temperature.critical", "Critical Temperature Level", {
      renderHeader: () => {
        return <TableHeader text={TemperatureHealthState.Critical} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      flex: 1,
      minWidth: 90,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("pressure.healthy", "Healthy Pressure Level", {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Healthy} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("pressure.warning", "Warning Pressure Level", {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Warning} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("pressure.alert", "Alert Pressure Level", {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Alert} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("pressure.critical", "Critical Pressure Level", {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Critical} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("imbalance.healthy", "Healthy Dual Imbalance Level", {
      renderHeader: () => {
        return <TableHeader text={ImbalanceHealthState.Healthy} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("imbalance.warning", "Warning Dual Imbalance Level", {
      renderHeader: () => {
        return <TableHeader text={ImbalanceHealthState.Warning} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("imbalance.alert", "Alert Dual Imbalance Level", {
      renderHeader: () => {
        return <TableHeader text={ImbalanceHealthState.Alert} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("imbalance.critical", "Critical Dual Imbalance Level", {
      renderHeader: () => {
        return <TableHeader text={ImbalanceHealthState.Critical} />;
      },
      renderCell: renderValueRanges,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

const createCameraSensorField = (
  fieldName: string,
  headerText: string,
  cameraState: CameraState,
  valueGetter: (params: GridValueGetterParams) => string | undefined,
  headerClassName: string,
  cellClassName: string
) => ({
  ...createField(fieldName, headerText, {
    renderHeader: () => <TableHeader text={headerText} />,
    valueGetter,
    minWidth: 64,
    flex: 1,
    headerClassName,
    cellClassName,
    filterable: false,
    sortable: false,
  }),
});

export const cargoCameraSensorColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("asset_id", "Asset ID", { minWidth: 64, flex: 1 }),
  },
  {
    ...createField("imei", "Device ID", { minWidth: 64, flex: 1 }),
  },
  {
    ...createField("prd_cde", "Product Name", { minWidth: 64, flex: 1 }),
  },
  {
    ...createField("sensorProfile.configuration.cargoCamera", "Profile", {
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
      minWidth: 64,
      flex: 1,
    }),
  },
  ...Object.keys(CameraSensorTypes)
    .map((sensorType) => {
      const columnPrefix =
        CameraSensorTypes[sensorType as keyof typeof CameraSensorTypes];

      const columnsForSensorType = Object.keys(CameraState)
        .filter((state) => state !== CameraState.Unknown)
        .map((state) => {
          const lowercaseState = state.toLowerCase();
          return createCameraSensorField(
            `cargoCameraSensors.${columnPrefix}.${lowercaseState}`,
            columnPrefix === CameraSensorTypes.FloorUsagePercentage
              ? `${state} Floor Space`
              : `${state} Cube Space`,
            state as CameraState,
            (params: GridValueGetterParams) =>
              getCameraStateText(
                params.value?.[`${lowercaseState}From`],
                params.value?.[`${lowercaseState}To`]
              ),
            getTableStyle(lowercaseState),
            getTableStyle(lowercaseState)
          );
        });

      return columnsForSensorType;
    })
    .flat(),
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

export const airbagSensorColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.airbag.statusAirbag", "Status", {
      minWidth: 64,
      valueGetter: (params: any) =>
        getColumnValue(params, "sensors.airbag.statusAirbag"),
      renderCell: (params: any) => (
        <CircleIcon
          sx={{
            color:
              tableSensorStatusColorsMapping[
                params.value as SensorStatusUppercase
              ],
          }}
        />
      ),
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { minWidth: 64, flex: 1 }),
  },
  {
    ...createField("imei", "Device ID", {
      minWidth: 64,
      flex: 1,
    }),
  },
  {
    ...createField("prd_cde", "Product Name", { minWidth: 64, flex: 1 }),
  },
  {
    ...createField("sensorProfile.configuration.airbag", "Profile", {
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
    }),
  },
  {
    ...createField("airbag.healthy", PressureHealthState.Healthy, {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Healthy} />;
      },
      valueGetter: (params: any) => {
        return `${params.value?.healthyFrom ?? 0} - ${
          params.value?.healthyTo ?? params.value
        }`;
      },
      minWidth: 90,
      flex: 1,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("airbag.warning", PressureHealthState.Warning, {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Warning} />;
      },
      valueGetter: (params: any) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      minWidth: 90,
      flex: 1,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("airbag.alert", PressureHealthState.Alert, {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Alert} />;
      },
      valueGetter: (params: any) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      minWidth: 90,
      flex: 1,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("airbag.critical", PressureHealthState.Critical, {
      renderHeader: () => {
        return <TableHeader text={PressureHealthState.Critical} />;
      },
      valueGetter: (params: any) =>
        params.value?.criticalFrom || params.value?.critical
          ? `${params.value?.criticalFrom} - ${params.value?.criticalTo}`
          : `${params.value} +`,
      flex: 1,
      minWidth: 90,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

export const temperatureSensorColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.temperature.statusTemperature", "Status", {
      minWidth: 45,
      valueGetter: (params: GridValueGetterParams) =>
        getColumnValue(params, "sensors.temperature.statusTemperature"),
      renderCell: (params: GridRenderCellParams) => (
        <CircleIcon
          sx={{
            color:
              tableSensorStatusColorsMapping[
                params.value as SensorStatusUppercase
              ],
          }}
        />
      ),
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { minWidth: 90, flex: 1 }),
  },
  {
    ...createField("imei", "Device ID", { minWidth: 90, flex: 1 }),
  },
  {
    ...createField("prd_cde", "Product Name", { minWidth: 90, flex: 1 }),
  },
  {
    ...createField("sensorProfile.configuration.temperature", "Profile", {
      valueGetter: ({ value }: GridValueGetterParams) => {
        return value?.type === SensorProfileType.Asset
          ? "Custom"
          : value?.profileName;
      },
      minWidth: 160,
      flex: 1,
    }),
  },
  {
    ...createField(
      "temperatureSensor.internal.healthy",
      TemperatureHealthState.Healthy,
      {
        renderHeader: () => (
          <TableHeader text={TemperatureHealthState.Healthy} />
        ),
        renderCell: renderValueRanges,
        minWidth: 200,
        flex: 1,
        headerClassName: tableStyleMap.healthy,
        cellClassName: tableStyleMap.healthy,
        filterable: false,
        sortable: false,
      }
    ),
  },
  {
    ...createField(
      "temperatureSensor.internal.warning",
      TemperatureHealthState.Warning,
      {
        renderHeader: () => {
          return <TableHeader text={TemperatureHealthState.Warning} />;
        },
        renderCell: renderValueRanges,
        minWidth: 200,
        flex: 1,
        headerClassName: tableStyleMap.warning,
        cellClassName: tableStyleMap.warning,
        filterable: false,
        sortable: false,
      }
    ),
  },
  {
    ...createField(
      "temperatureSensor.internal.alert",
      TemperatureHealthState.Alert,
      {
        renderHeader: () => {
          return <TableHeader text={TemperatureHealthState.Alert} />;
        },
        renderCell: renderValueRanges,
        minWidth: 200,
        flex: 1,
        headerClassName: tableStyleMap.alert,
        cellClassName: tableStyleMap.alert,
        filterable: false,
        sortable: false,
      }
    ),
  },
  {
    ...createField(
      "temperatureSensor.internal.critical",
      TemperatureHealthState.Critical,
      {
        renderHeader: () => {
          return <TableHeader text={TemperatureHealthState.Critical} />;
        },
        renderCell: renderValueRanges,
        minWidth: 200,
        flex: 1,
        headerClassName: tableStyleMap.critical,
        cellClassName: tableStyleMap.critical,
        filterable: false,
        sortable: false,
      }
    ),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});

export const liteSentryGammaTableColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.liteSentryGamma.statusLiteSentryGamma", "Status", {
      minWidth: 64,
      valueGetter: (params: any) =>
        getColumnValue(params, "sensors.liteSentryGamma.statusLiteSentryGamma"),
      renderCell: (params: any) => (
        <CircleIcon
          sx={{
            color:
              tableSensorStatusColorsMapping[
                params.value as SensorStatusUppercase
              ],
          }}
        />
      ),
    }),
  },
  {
    ...createField("asset_id", "Asset ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("imei", "Device ID", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("prd_cde", "Product Name", { flex: 1, minWidth: 120 }),
  },
  {
    ...createField("sensorProfile.configuration.liteSentryGamma", "Profile", {
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
    }),
  },
  ...(getLiteSentryGammaSensorTableColumns(
    SensorsProperties.LiteSentryGamma
  ) as GridColDef<AssetWithSensors>[]),
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
      filterable: field.includes("scenario") ? false : true,
      sortable: field.includes("scenario") ? false : true,
    },
  });
});

export const sensorsTableKeyList: string[] = sensorsTableColumns.map(
  (column) => column.field
);

export const columnVisibilityModel = {
  default: false,
};

export const getCameraStateIcon = (cameraState: CameraState) => {
  switch (cameraState) {
    case CameraState.Healthy:
      return (
        <CameraAltIcon
          sx={{
            color: ColorsPalette.Success,
            fontSize: "1.5rem",
          }}
        />
      );
    case CameraState.Warning:
      return (
        <CameraAltIcon
          sx={{
            color: ColorsPalette.Caution,
            fontSize: "1.5rem",
          }}
        />
      );
    case CameraState.Alert:
      return (
        <CameraAltIcon
          sx={{
            color: ColorsPalette.Alert,
            fontSize: "1.5rem",
          }}
        />
      );
    case CameraState.Critical:
      return (
        <CameraAltIcon
          sx={{
            color: ColorsPalette.Error,
            fontSize: "1.5rem",
          }}
        />
      );
    case CameraState.Unknown:
      return <CameraAltIcon />;
  }
};

export const getCameraStateText = (from: string, to: string) =>
  `${from}% - ${to}%`;

export const getSensorsCount = (data: any[]): number => {
  if (!data) {
    return 0;
  }
  return (
    data.filter(
      (item) => !!item?.sensorProfile?.configuration?.voltage?.battery
    ).length ?? 0
  );
};

//batteriesDrawer utils

export type Result = {
  allPassed: boolean;
  allFailed: boolean;
  partiallyPassed: {
    message: string;
    count: number;
  } | null;
};

const TYPE_OF_ASSIGNMENT_FIELD = {
  name: "typeOfAssignment",
  type: "autocomplete",
  label: "Type of Assignment",
  options: [
    {
      label: "Custom",
      value: "custom",
      id: "custom",
    },
    {
      label: "Profile",
      value: "profile",
      id: "profile",
    },
  ],
  required: true,
};

export const BATTERY_FORM_FIELDS = [
  TYPE_OF_ASSIGNMENT_FIELD,
  {
    name: "batterySensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const AIR_SUPPLY_FORM_FIELDS = [
  TYPE_OF_ASSIGNMENT_FIELD,
  {
    name: "airSupplySensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const AIR_BAG_SENSOR_FORM_FIELDS = [
  TYPE_OF_ASSIGNMENT_FIELD,
  {
    name: "airbagSensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const LITE_SENTRY_GAMMA_FORM_FIELDS = [
  TYPE_OF_ASSIGNMENT_FIELD,
  {
    name: "liteSentryGammaSensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const LIFTGATE_FORM_FIELDS = [
  TYPE_OF_ASSIGNMENT_FIELD,
  {
    name: "liftgateBatterySensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

/**
 * Analyzes the response items to determine the overall status.
 */
export const analyzeResponse = (
  response:
    | SetAssetSensorProfilesResponse[]
    | AssignOrgProfileToAssetsResponse[],
  targetStatus: string
): Result => {
  const totalCount = response.length;
  let passedCount = 0;
  let failedCount = 0;

  for (const item of response) {
    // Check if the item's status matches the target status
    if (item.status === targetStatus) {
      passedCount += 1;
    } else {
      failedCount += 1;
    }
  }

  // Determine overall status
  const allPassed = passedCount === totalCount;
  const allFailed = failedCount === totalCount;

  // Calculate partially passed information
  const partiallyPassed =
    allPassed || allFailed
      ? null
      : {
          message: `${passedCount} out of ${totalCount} passed`,
          count: passedCount,
        };

  return { allPassed, allFailed, partiallyPassed };
};

/**
 * Handles the analyzed response and triggers appropriate actions based on the overall status.
 */
export function handleAnalyzedResponse(
  response: Result,
  dispatchErrorMessage: (message: string) => void,
  dispatchSuccessMessage: () => void,
  queryClient: QueryClient
) {
  // If all items failed, dispatch an error message
  if (response.allFailed === true) {
    dispatchErrorMessage("Failed to update the selected assets.");
  }
  // If all items passed, dispatch a success message and invalidate the relevant query
  else if (response.allPassed === true) {
    queryClient.invalidateQueries(["getAssetsWithSensorProfiles"]);
    queryClient.invalidateQueries(["getAssetsWithSensorProfilesOS"]); // this is in separate query invalidator because otherwise it is not working ?!
    dispatchSuccessMessage();
  }
  // If partially passed, dispatch a partial error message and invalidate the relevant query
  else {
    dispatchErrorMessage(response?.partiallyPassed?.message ?? "");
    queryClient.invalidateQueries(["getAssetsWithSensorProfiles"]);
    queryClient.invalidateQueries(["getAssetsWithSensorProfilesOS"]); // this is in separate query invalidator because otherwise it is not working ?!
  }
}

export type SensorProfileDropdownItem = {
  label: string;
  value: string;
  id: string;
  type?: SensorProfileType;
  configuration?: SensorProfileConfig;
};

// Sensor profile dropdown item with `id` instead of `value` is used for the sensor profile dropdown that is using react-hook-form-mui components
export type SensorProfileDropdownItemWithId = {
  label: string;
  id: string;
  type?: SensorProfileType;
  configuration?: SensorProfileConfig;
};

// sort sensor profiles by placing the default PCT profile last
export const sortSensorProfileNameDropdownItems = (
  sensorProfiles: SensorProfileDropdownItem[]
): SensorProfileDropdownItem[] => {
  sensorProfiles.sort((a, b) => {
    if (a?.type === SensorProfileType.Pct) {
      return 1;
    } else if (b?.type === SensorProfileType.Pct) {
      return -1;
    } else {
      return 0;
    }
  });

  return sensorProfiles;
};

/**
 * Rework this function once https://phillips-connect.atlassian.net/browse/PRJIND-7916 is done.
 */
export const convertSensorThresholdsShort = <
  UnitType extends PressureUnit | TemperatureUnit
>(
  thresholds: SensorThresholdShort,
  unit: UnitType,
  converterCallback: (value: number, unit: UnitType) => number
): SensorThresholdShort => {
  const thresholdsClone = { ...thresholds };

  if (
    thresholdsClone.healthy?.healthyFrom !== undefined &&
    thresholdsClone.healthy?.healthyFrom !== null &&
    thresholdsClone.healthy?.healthyTo !== undefined &&
    thresholdsClone.healthy?.healthyTo !== null
  ) {
    thresholdsClone.healthy = {
      healthyFrom: converterCallback(thresholdsClone.healthy.healthyFrom, unit),
      healthyTo: converterCallback(thresholdsClone.healthy.healthyTo, unit),
    };
  }

  if (
    thresholdsClone.warning?.warningFrom &&
    thresholdsClone.warning?.warningTo
  ) {
    thresholdsClone.warning = {
      warningFrom: converterCallback(thresholdsClone.warning.warningFrom, unit),
      warningTo: converterCallback(thresholdsClone.warning.warningTo, unit),
    };
  }

  if (thresholdsClone.alert?.alertFrom && thresholdsClone.alert?.alertTo) {
    thresholdsClone.alert = {
      alertFrom: converterCallback(thresholdsClone.alert.alertFrom, unit),
      alertTo: converterCallback(thresholdsClone.alert.alertTo, unit),
    };
  }

  if (
    thresholdsClone.critical?.criticalFrom !== undefined &&
    thresholdsClone.critical?.criticalFrom !== null &&
    thresholdsClone.critical?.criticalTo !== undefined &&
    thresholdsClone.critical?.criticalTo !== null
  ) {
    thresholdsClone.critical = {
      criticalFrom: converterCallback(
        thresholdsClone.critical.criticalFrom,
        unit
      ),
      criticalTo: converterCallback(thresholdsClone.critical.criticalTo, unit),
    };
  }

  return thresholdsClone;
};

/**
 * Rework this function once https://phillips-connect.atlassian.net/browse/PRJIND-7916 is done.
 */
export const convertTpmsSensorThresholdsShort = (
  thresholds: TpmsPressureThresholds,
  pressureUnit: PressureUnit
) => {
  const thresholdsClone = { ...thresholds };
  const { healthy, warning, alert, critical } = thresholdsClone;

  if (
    healthy?.overFrom !== undefined &&
    healthy?.overFrom !== null &&
    healthy?.overTo !== undefined &&
    healthy?.overTo !== null &&
    healthy?.underFrom !== undefined &&
    healthy?.underFrom !== null &&
    healthy?.underTo !== undefined &&
    healthy?.underTo !== null
  ) {
    healthy.overFrom = getConvertedPressureValue(
      healthy.overFrom,
      pressureUnit
    );
    healthy.overTo = getConvertedPressureValue(healthy.overTo, pressureUnit);
    healthy.underFrom = getConvertedPressureValue(
      healthy.underFrom,
      pressureUnit
    );
    healthy.underTo = getConvertedPressureValue(healthy.underTo, pressureUnit);
  }

  if (
    warning?.overFrom &&
    warning?.overTo &&
    warning?.underFrom &&
    warning?.underTo
  ) {
    warning.overFrom = getConvertedPressureValue(
      warning.overFrom,
      pressureUnit
    );
    warning.overTo = getConvertedPressureValue(warning.overTo, pressureUnit);
    warning.underFrom = getConvertedPressureValue(
      warning.underFrom,
      pressureUnit
    );
    warning.underTo = getConvertedPressureValue(warning.underTo, pressureUnit);
  }

  if (alert?.overFrom && alert?.overTo && alert?.underFrom && alert?.underTo) {
    alert.overFrom = getConvertedPressureValue(alert.overFrom, pressureUnit);
    alert.overTo = getConvertedPressureValue(alert.overTo, pressureUnit);
    alert.underFrom = getConvertedPressureValue(alert.underFrom, pressureUnit);
    alert.underTo = getConvertedPressureValue(alert.underTo, pressureUnit);
  }

  if (
    critical?.overFrom !== undefined &&
    critical?.overFrom !== null &&
    critical?.overTo !== undefined &&
    critical?.overTo !== null &&
    critical?.underFrom !== undefined &&
    critical?.underFrom !== null &&
    critical?.underTo !== undefined &&
    critical?.underTo !== null
  ) {
    critical.overFrom = getConvertedPressureValue(
      critical.overFrom,
      pressureUnit
    );
    critical.overTo = getConvertedPressureValue(critical.overTo, pressureUnit);
    critical.underFrom = getConvertedPressureValue(
      critical.underFrom,
      pressureUnit
    );
    critical.underTo = getConvertedPressureValue(
      critical.underTo,
      pressureUnit
    );
  }

  return thresholdsClone;
};

export const convertTemperatureSensorThresholds = (
  thresholds: TemperatureInternalThresholds,
  temperatureUnit: TemperatureUnit
) => {
  const thresholdsClone = { ...thresholds };
  const { healthy, warning, alert, critical } = thresholdsClone;

  if (
    healthy?.coldSideFrom !== undefined &&
    healthy?.coldSideTo !== undefined &&
    healthy?.hotSideFrom !== undefined &&
    healthy?.hotSideTo !== undefined
  ) {
    healthy.coldSideFrom = getConvertedTemperatureValue(
      healthy.coldSideFrom ?? 0,
      temperatureUnit
    );
    healthy.coldSideTo = getConvertedTemperatureValue(
      healthy.coldSideTo ?? 0,
      temperatureUnit
    );
    healthy.hotSideFrom = getConvertedTemperatureValue(
      healthy.hotSideFrom ?? 0,
      temperatureUnit
    );
    healthy.hotSideTo = getConvertedTemperatureValue(
      healthy.hotSideTo ?? 0,
      temperatureUnit
    );
  }

  if (
    warning?.coldSideFrom !== undefined &&
    warning?.coldSideTo !== undefined &&
    warning?.hotSideFrom !== undefined &&
    warning?.hotSideTo !== undefined
  ) {
    warning.coldSideFrom = getConvertedTemperatureValue(
      warning.coldSideFrom ?? 0,
      temperatureUnit
    );
    warning.coldSideTo = getConvertedTemperatureValue(
      warning.coldSideTo ?? 0,
      temperatureUnit
    );
    warning.hotSideFrom = getConvertedTemperatureValue(
      warning.hotSideFrom ?? 0,
      temperatureUnit
    );
    warning.hotSideTo = getConvertedTemperatureValue(
      warning.hotSideTo ?? 0,
      temperatureUnit
    );
  }

  if (
    alert?.coldSideFrom !== undefined &&
    alert?.coldSideTo !== undefined &&
    alert?.hotSideFrom !== undefined &&
    alert?.hotSideTo !== undefined
  ) {
    alert.coldSideFrom = getConvertedTemperatureValue(
      alert.coldSideFrom ?? 0,
      temperatureUnit
    );
    alert.coldSideTo = getConvertedTemperatureValue(
      alert.coldSideTo ?? 0,
      temperatureUnit
    );
    alert.hotSideFrom = getConvertedTemperatureValue(
      alert.hotSideFrom ?? 0,
      temperatureUnit
    );
    alert.hotSideTo = getConvertedTemperatureValue(
      alert.hotSideTo ?? 0,
      temperatureUnit
    );
  }

  if (
    critical?.coldSideFrom !== undefined &&
    critical?.coldSideTo !== undefined &&
    critical?.hotSideFrom !== undefined &&
    critical?.hotSideTo !== undefined
  ) {
    critical.coldSideFrom = getConvertedTemperatureValue(
      critical.coldSideFrom ?? 0,
      temperatureUnit
    );
    critical.coldSideTo = getConvertedTemperatureValue(
      critical.coldSideTo ?? 0,
      temperatureUnit
    );
    critical.hotSideFrom = getConvertedTemperatureValue(
      critical.hotSideFrom ?? 0,
      temperatureUnit
    );
    critical.hotSideTo = getConvertedTemperatureValue(
      critical.hotSideTo ?? 0,
      temperatureUnit
    );
  }

  return thresholdsClone;
};

/**
 * Rework this function once https://phillips-connect.atlassian.net/browse/PRJIND-7916 is done.
 */
export const mapAssetsSensorsData = (
  assets: AssetWithSensors[],
  pressureUnit: PressureUnit,
  temperatureUnit: TemperatureUnit
) => {
  const assetsClone = [...assets];

  return assetsClone.map((asset: AssetWithSensors) => {
    if (asset.airbag) {
      asset.airbag = convertSensorThresholdsShort(
        asset.airbag,
        pressureUnit,
        getConvertedPressureValue
      );
    }
    if (asset.tankPressure) {
      asset.tankPressure = {
        minSpeed: asset.tankPressure.minSpeed,
        ...convertSensorThresholdsShort(
          asset.tankPressure as SensorThresholdShort,
          pressureUnit,
          getConvertedPressureValue
        ),
      } as AirTankPressureThresholds;
    }
    if (asset.pressure) {
      asset.pressure = convertTpmsSensorThresholdsShort(
        asset.pressure,
        pressureUnit
      );
    }
    if (asset.imbalance) {
      asset.imbalance = convertSensorThresholdsShort(
        asset.imbalance,
        pressureUnit,
        getConvertedPressureValue
      );
    }
    if (asset.regulator) {
      if (asset.regulator?.moving) {
        asset.regulator.moving = convertSensorThresholdsShort(
          asset.regulator.moving,
          pressureUnit,
          getConvertedPressureValue
        );
      }

      if (asset.regulator?.parked) {
        asset.regulator.parked = convertSensorThresholdsShort(
          asset.regulator.parked,
          pressureUnit,
          getConvertedPressureValue
        );
      }
    }

    if (asset.temperatureSensor) {
      asset.temperatureSensor = {
        internal: convertTemperatureSensorThresholds(
          asset.temperatureSensor.internal as TemperatureInternalThresholds,
          temperatureUnit
        ),
      };
    }

    if (asset.psiWheelEnd) {
      asset.psiWheelEnd = convertSensorThresholdsShort(
        asset.psiWheelEnd,
        temperatureUnit,
        getConvertedTemperatureValue
      );
    }

    if (asset.temperature) {
      asset.temperature = convertSensorThresholdsShort(
        asset.temperature,
        temperatureUnit,
        getConvertedTemperatureValue
      );
    }

    return asset;
  });
};
