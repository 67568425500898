import React, { createContext, useContext, useState, ReactNode } from "react";
import { GetDashboardWidgetResponse } from "../graphql/operations";

interface DashboardContextProps {
  dashboardId: number | null;
  setDashboardId: (id: number | null) => void;
  dashboardWidgets: GetDashboardWidgetResponse[];
  setDashboardWidgets: (widgets: GetDashboardWidgetResponse[]) => void;
  dashboardUserId: string | null;
  setDashboardUserId: (id: string | null) => void;
  initialDashboardWidgets: GetDashboardWidgetResponse[];
  setInitialDashboardWidgets: (widgets: GetDashboardWidgetResponse[]) => void;
}

export const DashboardContext = createContext<
  DashboardContextProps | undefined
>(undefined);

export const DashboardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dashboardId, setDashboardId] = useState<number | null>(null);
  // userID who created the dashboard
  const [dashboardUserId, setDashboardUserId] = useState<string | null>(null);
  // widgets that are currently showed on the dashboard
  const [dashboardWidgets, setDashboardWidgets] = useState<
    GetDashboardWidgetResponse[]
  >([]);
  // widgets that are initially showed on the dashboard - before any deletion
  const [initialDashboardWidgets, setInitialDashboardWidgets] = useState<
    GetDashboardWidgetResponse[]
  >([]);

  return (
    <DashboardContext.Provider
      value={{
        dashboardId,
        setDashboardId,
        dashboardWidgets,
        setDashboardWidgets,
        dashboardUserId,
        setDashboardUserId,
        initialDashboardWidgets,
        setInitialDashboardWidgets,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = (): DashboardContextProps => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
};
