import { FC, MutableRefObject, memo } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  SensorProfileConfigType,
  TableViewType,
  TableDomain,
  GetTableDataInput,
} from "../../../../../../graphql/operations";
import { BackEndProcessingTable } from "../../../../../../shared/components/Table/BackEndProcessingTable";
import type { QueryInput } from "../../../../../../shared/components/Table/BackEndProcessingTable";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { SensorsData, columnVisibilityModel } from "../../sensorsUtils";
import useSensorsTableLogic from "../../shared/hooks/useSensorsTableLogic";
import { sensorsAtisAlphaTableColumns } from "./constats";

export interface SensorsAtisAlphaTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void; // TODO: Fix any
  onRowSelect: (value: any) => void; // TODO: Fix any
  apiRef: MutableRefObject<GridApiPremium>;
  sensorsData: SensorsData;
  rowSelectionModel?: Array<number | string>;
  updateQueryInput: (data: Partial<QueryInput>) => void;
  queryInput: GetTableDataInput;
}

const SensorsAtisAlphaTable: FC<SensorsAtisAlphaTableProps> = ({
  tableType,
  onRowClick,
  onRowSelect,
  apiRef,
  sensorsData,
  rowSelectionModel,
  updateQueryInput,
  queryInput,
}) => {
  const { tableRows, canCheckTheRows, appConfig } = useSensorsTableLogic({
    apiRef,
    rowSelectionModel,
    sensorsData: sensorsData.data,
    sensorPropertyType: SensorProfileConfigType.AtisAlpha,
    sensorColumns: sensorsAtisAlphaTableColumns,
  });
  return (
    <Box className="flex-1 basis-[300px]">
      <BackEndProcessingTable
        apiRef={apiRef}
        queryInput={queryInput}
        updateQueryInput={updateQueryInput}
        domain={TableDomain.SensorsAtis}
        tableName="atis-sensors"
        baseFilters={{}}
        tableType={tableType}
        columns={sensorsAtisAlphaTableColumns}
        allowFilters
        columnVisibilityModel={columnVisibilityModel}
        sorting={queryInput.sorting ?? undefined}
        data={{
          pagination: sensorsData?.pagination,
          rows: tableRows,
        }}
        checkboxSelection={canCheckTheRows}
        isLoading={sensorsData.isFetchingSensor}
        isSuccess={!sensorsData.isErrorSensor}
        onExport={() => {}}
        rowsPerPage={appConfig.table.defaultRowsPerPage}
        updateSelectedRowsHandler={onRowSelect}
        searchMinLength={appConfig.searchMinLength}
        onRowClick={onRowClick}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(SensorsAtisAlphaTable);
