import { FC, memo } from "react";
import { Box } from "@mui/system";
import {
  ProfileConfigProperty,
  TableDomain,
} from "../../../../../../graphql/operations";
import { BackEndProcessingTable } from "../../../../../../shared/components/Table/BackEndProcessingTable";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import {
  columnVisibilityModel,
  sensorsAirSupplyTableColumns,
} from "../../sensorsUtils";
import useSensorsTableLogic from "../../shared/hooks/useSensorsTableLogic";
import type { SensorsBatteriesTableProps } from "../Batteries/SensorsBatteriesTable";

const SensorsAirSupplyTable: FC<SensorsBatteriesTableProps> = ({
  tableType,
  onRowClick,
  onRowSelect,
  apiRef,
  sensorsData,
  rowSelectionModel,
  updateQueryInput,
  queryInput,
}) => {
  const { tableRows, canCheckTheRows, appConfig } = useSensorsTableLogic({
    apiRef,
    rowSelectionModel,
    sensorsData: sensorsData.data,
    sensorPropertyType: ProfileConfigProperty.AirSupply,
    sensorColumns: sensorsAirSupplyTableColumns,
  });

  return (
    <Box className="flex-1 basis-[300px]">
      <BackEndProcessingTable
        apiRef={apiRef}
        queryInput={queryInput}
        updateQueryInput={updateQueryInput}
        domain={TableDomain.SensorAirSupply}
        tableName="air-supply-sensors"
        baseFilters={{}}
        tableType={tableType}
        columns={sensorsAirSupplyTableColumns}
        allowFilters
        columnVisibilityModel={columnVisibilityModel}
        sorting={queryInput.sorting ?? undefined}
        data={{
          pagination: sensorsData?.pagination,
          rows: tableRows,
        }}
        checkboxSelection={canCheckTheRows}
        isLoading={sensorsData.isFetchingSensor}
        isSuccess={!sensorsData.isErrorSensor}
        onExport={() => {}}
        rowsPerPage={appConfig.table.defaultRowsPerPage}
        updateSelectedRowsHandler={onRowSelect}
        searchMinLength={appConfig.searchMinLength}
        onRowClick={onRowClick}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(SensorsAirSupplyTable);
