import { FC, useState, useEffect } from "react";
import { useForm, FieldValues } from "react-hook-form";
import {
  AutocompleteElement,
  CheckboxElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Accordion,
  AccordionSummary,
  ThemeProvider,
  Grid,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cloneDeep, isEmpty } from "lodash";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import {
  ASSET_CONFIGURATION_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD,
  ASSET_CONFIGURATION_CREATE_FAILED_PAYLOAD,
  ASSET_CONFIGURATION_DELETE_FAILED_PAYLOAD,
  ASSET_CONFIGURATION_DELETE_SUCCESS_PAYLOAD,
  ASSET_CONFIGURATION_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD,
  ASSET_CONFIGURATION_UPDATE_FAILED_PAYLOAD,
  ASSET_CONFIGURATION_UPDATE_SUCCESS_PAYLOAD,
} from "../../../../../../constants/assetsConfigurationSet";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useFindConfigurationSetByIdQuery,
  ConfigurationSetType,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../../AssetsView/TableView/hooks/useNomenclatures";
import { useConfigurationSetsApi } from "../../../../hooks/useConfigurationSetsApi";
import {
  DrawerType,
  AssetDrawers,
  assetsConfigSchema,
  ERROR_CONFIGURATION_SET_NAME_DUPLICATE,
} from "../../configurationsUtils";

interface SensorType {
  cargo: string;
  door: string;
  internalCamera: string;
  smartLockGladhand: string;
  smartPair: string;
}

const sensorTypePositiveLabelsMapping: SensorType = {
  cargo: "Cargo - Loaded",
  door: "Door - Closed",
  internalCamera: "Internal Camera - Loaded",
  smartLockGladhand: "SmartLock Gladhand - Locked",
  smartPair: "T/T Pair - Paired",
};

const sensorTypeNegativeLabelsMapping: SensorType = {
  cargo: "Cargo - Empty",
  door: "Door - Open",
  internalCamera: "Internal Camera - Empty",
  smartLockGladhand: "SmartLock Gladhand - Unlocked",
  smartPair: "T/T Pair - Unpaired",
};

enum IconFormNames {
  name = "name",
  color = "color",
  colorReflectsAsset = "colorReflectsAsset",
  outerRingReflectsDwell = "outerRingReflectsDwell",
  preCheckEnabled = "preCheckEnabled",
  lostCommunicationTime = "lostCommunicationTime",
  centerSensorType = "centerSensor.type",
  centerSensorCenterTextDescriptionLabel = "centerSensor.centerTextDescriptionLabel",
  centerSensorPositiveReadingLabel = "centerSensor.positiveReadingLabel",
  centerSensorNegativeReadingLabel = "centerSensor.negativeReadingLabel",
  powerMain = "power.main",
  powerSolarAmperage = "power.solarAmperage",
  powerAuxiliary = "power.auxiliary",
  powerBattery = "power.battery",
  powerMinVoltageLevel = "power.minVoltageLevel",
  powerMaxVoltageLevel = "power.maxVoltageLevel",
}
const ICON_FORM_FIELDS_LABELS = [
  "Configuration Name",
  "Default Color",
  "Color Reflects Assets Health",
  "Outer Ring Reflects Dwell Status",
  "Precheck enabled",
  "Time for Lost Communication",
  "Sensor Type",
  "Center Text Description",
  "Positive Reading",
  "Negative Reading",
  "Primary Voltage",
  "Solar Amperage",
  "Secondary Voltage",
  "Battery",
  "Minimum battery level",
  "Maximum battery level",
];
export interface CreateUpdateAssetConfigDrawerProps {
  isOpen: boolean;
  type?: DrawerType;
  selectedConfigId?: string;
  selectedOrgId?: string;
  onClose: () => void;
  onRequestClose: () => void;
}

export type DrawerForm = {
  name: string;
  color: string;
};

type FormInputs = {
  name: string;
  color: string;
  colorReflectsAsset: boolean;
  outerRingReflectsDwell: boolean;
  preCheckEnabled: boolean;
  lostCommunicationTime: string;
  centerSensor: {
    type: string;
    centerTextDescriptionLabel: string;
    positiveReadingLabel: string;
    negativeReadingLabel: string;
  };
  power: {
    main: boolean;
    solarAmperage: boolean;
    auxiliary: boolean;
    battery: boolean;
    maxVoltageLevel?: string;
    minVoltageLevel?: string;
  };
};

const CreateUpdateAssetConfigDrawer: FC<CreateUpdateAssetConfigDrawerProps> = (
  props
) => {
  const {
    isOpen,
    type,
    selectedConfigId,
    selectedOrgId,
    onClose,
    onRequestClose,
  } = props;

  const formTheme = useFormTheme();
  const breakpoints = { xs: 12 };
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const sensorTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.sensorTypes);
  const isCreateDrawer = type === AssetDrawers.Create;
  const isEditDrawer = type === AssetDrawers.Edit;
  const {
    data: dataConfigurationSet,
    isFetching: isFetchingFindConfiguration,
    isSuccess: isSuccessFindConfiguration,
    refetch,
  } = useFindConfigurationSetByIdQuery(
    {
      input: {
        entityId: selectedConfigId ?? "",
      },
    },
    {
      enabled: Boolean(selectedConfigId) && isEditDrawer,
    }
  );

  const defaultValues = {
    name: "",
    color: "",
    colorReflectsAsset: false,
    lostCommunicationTime: "Never",
    outerRingReflectsDwell: true,
    preCheckEnabled: false,
    centerSensor: {
      type: "",
      centerTextDescriptionLabel: "",
      positiveReadingLabel: "",
      negativeReadingLabel: "",
    },
    power: {
      battery: true,
      main: true,
      solarAmperage: true,
      auxiliary: true,
      minVoltageLevel: "0.0",
      maxVoltageLevel: "4.2",
    },
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm<FormInputs>({
    resolver: yupResolver(yup.object().shape(assetsConfigSchema)),
    defaultValues,
  });

  useEffect(() => {
    if (isSuccessFindConfiguration && isEditDrawer) {
      const dataParsed =
        dataConfigurationSet?.findConfigurationSetById?.value &&
        JSON.parse(dataConfigurationSet?.findConfigurationSetById?.value);

      const defaultValuesApiData =
        cloneDeep({
          name: dataConfigurationSet?.findConfigurationSetById?.name,
          ...dataParsed,
        }) ?? {};
      defaultValuesApiData.centerSensor.negativeReadingLabel =
        sensorTypeNegativeLabelsMapping[
          defaultValuesApiData.centerSensor
            .negativeReadingLabel as keyof SensorType
        ] ?? "";
      defaultValuesApiData.centerSensor.positiveReadingLabel =
        sensorTypePositiveLabelsMapping[
          defaultValuesApiData.centerSensor
            .positiveReadingLabel as keyof SensorType
        ] ?? "";
      reset(defaultValuesApiData);
    }
  }, [
    isSuccessFindConfiguration,
    dataConfigurationSet?.findConfigurationSetById?.value,
    dataConfigurationSet?.findConfigurationSetById?.name,
    isEditDrawer,
    reset,
  ]);

  useEffect(() => {
    if (isOpen && isEditDrawer) {
      refetch();
    }
  }, [refetch, isOpen, isEditDrawer]);

  const createAssetConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: ASSET_CONFIGURATION_DELETE_SUCCESS_PAYLOAD,
    });

    onClose();
  };

  const createAssetConfigurationSetOnError = (error: Error) => {
    const errorPayload =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? ASSET_CONFIGURATION_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD
        : ASSET_CONFIGURATION_CREATE_FAILED_PAYLOAD;

    reset(defaultValues);
    dispatch({
      type: PAGE_SNACKBAR,
      payload: errorPayload,
    });
  };

  const updateAssetConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: ASSET_CONFIGURATION_UPDATE_SUCCESS_PAYLOAD,
    });

    onClose();
  };

  const updateAssetConfigurationSetOnError = (error: Error) => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    const errorPayload =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? ASSET_CONFIGURATION_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD
        : ASSET_CONFIGURATION_UPDATE_FAILED_PAYLOAD;

    dispatch({
      type: PAGE_SNACKBAR,
      payload: errorPayload,
    });
  };

  const deleteAssetConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: ASSET_CONFIGURATION_DELETE_SUCCESS_PAYLOAD,
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const deleteAssetConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: ASSET_CONFIGURATION_DELETE_FAILED_PAYLOAD,
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess: createAssetConfigurationSetOnSuccess,
    createConfigurationSetOnError: createAssetConfigurationSetOnError,

    updateConfigurationSetOnSuccess: updateAssetConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateAssetConfigurationSetOnError,

    deleteConfigurationSetOnSuccess: deleteAssetConfigurationSetOnSuccess,
    deleteConfigurationSetOnError: deleteAssetConfigurationSetOnError,
  });

  const [toggleDeleteDialogState, setToggleDeleteDialogState] =
    useState<boolean>(false);

  const colorWatchedValue = watch("color");
  const colorReflectsAssetWatchedValue = watch("colorReflectsAsset");
  const typeWatchedValue = watch("centerSensor.type");

  const onSubmit = (data: FieldValues) => {
    const formDataCopy = JSON.parse(JSON.stringify(data)) as typeof data;
    delete formDataCopy["name"];
    if (isEditDrawer && dataConfigurationSet?.findConfigurationSetById) {
      const { _id, orgId, parentId } =
        dataConfigurationSet.findConfigurationSetById;

      updateConfigurationSet({
        _id,
        orgId,
        parentId,
        name: data.name,
        value: JSON.stringify(formDataCopy),
      });
    }
    if (isCreateDrawer && selectedOrgId) {
      createConfigurationSet({
        type: ConfigurationSetType.AssetType,
        orgId: selectedOrgId,
        name: data.name,
        value: JSON.stringify(formDataCopy),
      });
    }
  };
  const defaultColorOptions = useNomenclatures(
    NOMENCLATURE_NAMES.defaultConfigurationColors
  );
  const isLoading =
    isFetchingFindConfiguration ||
    isLoadingUpdateConfigurationSet ||
    isLoadingCreateConfigurationSet ||
    isLoadingDeleteConfigurationSet;
  const isSubmitDisabled = isLoading || isEmpty(dirtyFields);

  const headerText = `${isCreateDrawer ? "Create" : "Edit"} Configuration`;
  return (
    <Drawer
      testId="icon-config-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={headerText} onClose={onClose} />
      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="bg-background noTopPaddingDrawerSection">
              <Grid item {...breakpoints} data-testid="name-control">
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={IconFormNames.name}
                  required
                  label={ICON_FORM_FIELDS_LABELS[0]}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item {...breakpoints} data-testid="color-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    onChange: (_, data) => {
                      if (data !== null && defaultColorOptions.includes(data)) {
                        setValue(
                          "colorReflectsAsset",
                          data.label !== "green"
                            ? false
                            : colorReflectsAssetWatchedValue
                        );
                      }
                    },
                  }}
                  rules={{ required: true }}
                  matchId={true}
                  label={ICON_FORM_FIELDS_LABELS[1]}
                  control={control}
                  name={IconFormNames.color}
                  options={defaultColorOptions}
                />
              </Grid>
              <Grid
                container
                className="justify-between !ml-0 !mt-0"
                spacing={2}
              >
                <Grid
                  item
                  className="w-1/2"
                  data-testid="colorReflectsAssets-control"
                >
                  <CheckboxElement
                    control={control}
                    disabled={colorWatchedValue !== "green"}
                    name={IconFormNames.colorReflectsAsset}
                    label={ICON_FORM_FIELDS_LABELS[2]}
                  />
                </Grid>

                <Grid item className="w-1/2">
                  <CheckboxElement
                    control={control}
                    name={IconFormNames.outerRingReflectsDwell}
                    label={ICON_FORM_FIELDS_LABELS[3]}
                  />
                </Grid>

                <Grid item className="w-1/2">
                  <CheckboxElement
                    control={control}
                    name={IconFormNames.preCheckEnabled}
                    label={ICON_FORM_FIELDS_LABELS[4]}
                  />
                </Grid>
              </Grid>
              <Grid item {...breakpoints}>
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={IconFormNames.lostCommunicationTime}
                  data-testid="lostCommunicationTime-control"
                  required
                  label={ICON_FORM_FIELDS_LABELS[5]}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item {...breakpoints}>
                <Accordion defaultExpanded>
                  <AccordionSummary className="!p-0">
                    Center Sensor
                  </AccordionSummary>

                  <Grid item {...breakpoints} data-testid="sensorType-control">
                    <AutocompleteElement
                      autocompleteProps={{
                        disabled: isLoading,
                        onChange: (_, data) => {
                          if (
                            data &&
                            sensorTypeOptions.map((x) => x.value).includes(data)
                          ) {
                            setValue("centerSensor.positiveReadingLabel", data);
                            setValue("centerSensor.negativeReadingLabel", data);
                          } else {
                            setValue("centerSensor.positiveReadingLabel", "");
                            setValue("centerSensor.negativeReadingLabel", "");
                          }
                        },
                      }}
                      matchId={true}
                      label={ICON_FORM_FIELDS_LABELS[6]}
                      control={control}
                      name={IconFormNames.centerSensorType}
                      options={sensorTypeOptions}
                    />
                  </Grid>

                  <Grid
                    item
                    {...breakpoints}
                    data-testid="centerTextDescription-control"
                  >
                    <TextFieldElement
                      fullWidth
                      control={control}
                      name={
                        IconFormNames.centerSensorCenterTextDescriptionLabel
                      }
                      label={ICON_FORM_FIELDS_LABELS[7]}
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item {...breakpoints}>
                    <TextFieldElement
                      fullWidth
                      control={control}
                      name={IconFormNames.centerSensorPositiveReadingLabel}
                      value={
                        sensorTypePositiveLabelsMapping[
                          typeWatchedValue as keyof SensorType
                        ] ?? ""
                      }
                      label={ICON_FORM_FIELDS_LABELS[8]}
                      disabled
                    />
                  </Grid>

                  <Grid item {...breakpoints}>
                    <TextFieldElement
                      fullWidth
                      control={control}
                      name={IconFormNames.centerSensorNegativeReadingLabel}
                      value={
                        sensorTypeNegativeLabelsMapping[
                          typeWatchedValue as keyof SensorType
                        ] ?? ""
                      }
                      label={ICON_FORM_FIELDS_LABELS[9]}
                      disabled
                    />
                  </Grid>
                </Accordion>
              </Grid>
              <Grid item {...breakpoints}>
                <Accordion defaultExpanded>
                  <AccordionSummary className="!p-0">
                    Power Settings
                  </AccordionSummary>

                  <Grid
                    container
                    className="justify-between !ml-0 !mt-0"
                    spacing={2}
                  >
                    <Grid item className="w-1/2">
                      <CheckboxElement
                        control={control}
                        disabled={colorWatchedValue !== "green"}
                        name={IconFormNames.powerMain}
                        label={ICON_FORM_FIELDS_LABELS[10]}
                      />
                    </Grid>

                    <Grid item className="w-1/2">
                      <CheckboxElement
                        control={control}
                        name={IconFormNames.powerSolarAmperage}
                        label={ICON_FORM_FIELDS_LABELS[11]}
                      />
                    </Grid>

                    <Grid item className="w-1/2">
                      <CheckboxElement
                        control={control}
                        name={IconFormNames.powerAuxiliary}
                        label={ICON_FORM_FIELDS_LABELS[12]}
                      />
                    </Grid>

                    <Grid item className="w-1/2">
                      <CheckboxElement
                        control={control}
                        name={IconFormNames.powerBattery}
                        label={ICON_FORM_FIELDS_LABELS[13]}
                      />
                    </Grid>
                    <Box className="flex justify-between gap-11">
                      <Grid item>
                        <TextFieldElement
                          fullWidth
                          control={control}
                          name={IconFormNames.powerMinVoltageLevel}
                          data-testid="battery-min-voltage-level"
                          required
                          label={ICON_FORM_FIELDS_LABELS[14]}
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item>
                        <TextFieldElement
                          fullWidth
                          control={control}
                          name={IconFormNames.powerMaxVoltageLevel}
                          data-testid="battery-max-voltage-level"
                          required
                          label={ICON_FORM_FIELDS_LABELS[15]}
                          disabled={isLoading}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>
        {isEditDrawer ? (
          <DrawerActions
            deleteBtnTestId="action-delete-config"
            disabled={
              isLoading ||
              Boolean(dataConfigurationSet?.findConfigurationSetById?.archived)
            }
            showDeleteBtn={true}
            onDelete={() => setToggleDeleteDialogState(true)}
            onCancel={onClose}
          />
        ) : (
          <DrawerActions disabled={isLoading} onCancel={onClose} />
        )}

        {isEditDrawer && toggleDeleteDialogState && (
          <ConfirmationDialog
            title="You are about to delete a Configuration"
            message="Are you sure?"
            open={toggleDeleteDialogState}
            isLoading={isLoadingDeleteConfigurationSet}
            confirmButtonText="Delete Configuration"
            cancelButtonText="Cancel"
            handleConfirmationResult={(value) => {
              if (value) {
                deleteConfigurationSet({
                  entityId: selectedConfigId ?? "",
                });
              } else {
                setToggleDeleteDialogState(false);
              }
            }}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingUpdateConfigurationSet ||
          isLoadingCreateConfigurationSet ||
          isLoadingDeleteConfigurationSet
            ? "Saving..."
            : "Save"
        }
        disabled={isSubmitDisabled}
        testId="btn-asset-form-submit"
        submit={handleSubmit(() => {
          onSubmit(getValues());
        })}
      />
    </Drawer>
  );
};

export default CreateUpdateAssetConfigDrawer;
