import { sortBy } from "lodash";
import { GeofenceEventInfo } from "../../../../../graphql/operations";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDate,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../utils";
import { detailItem } from "../../Shared/DashboardWidgets/DetailListWidget";

export enum GeofenceCollisionType {
  Entered = "entered",
  Stationary = "stationary",
  Exited = "exited",
}

export function getLocationInfo(
  addressData: string | undefined,
  locationData: number[] | undefined,
  lastReportedDate: Date | undefined | null,
  tripStatus: string | undefined | null,
  geofenceData: GeofenceEventInfo[] | null,
  userPreferredTimezone?: string | null
) {
  const detailList: Array<detailItem<string | number>> = [];
  let sortedGeofenceData: GeofenceEventInfo[] = [];
  let geofenceName = "";
  let geofenceDate = "";

  const timezoneDate =
    lastReportedDate &&
    formatDateInTimezone(
      lastReportedDate,
      DATE_TIME_FORMAT_SHORT,
      mapTimezoneToIANAValue(userPreferredTimezone)
    );

  if (geofenceData && geofenceData.length > 0) {
    // sort all geofence event records in descending order to get the latest event
    sortedGeofenceData = sortBy(
      geofenceData,
      (geofence) => new Date(geofence?.eventAt ?? null)
    ).reverse();

    let latestGeofenceEventData = sortedGeofenceData[0];
    geofenceName = latestGeofenceEventData.name ?? "";

    if (latestGeofenceEventData.enteredAt) {
      geofenceDate = `(${
        latestGeofenceEventData.collisionType ===
        GeofenceCollisionType.Stationary
          ? GeofenceCollisionType.Stationary
          : GeofenceCollisionType.Entered
      } on ${formatDate(
        new Date(latestGeofenceEventData.enteredAt),
        "MM/dd/yy hh:mm aa"
      )})`;
    }
    if (latestGeofenceEventData.exitedAt) {
      geofenceDate = `(exited ${formatDate(
        new Date(latestGeofenceEventData.exitedAt),
        "MM/dd/yy hh:mm aa"
      )})`;
    }
  }

  const geofenceFieldValue = `${geofenceName} ${geofenceDate}`;

  // load data for detailList
  detailList.push({
    label: "Address",
    detailItemvalue: addressData ?? "",
  });

  detailList.push({
    label: "Latitude,\nLongitude",
    detailItemvalue:
      locationData?.length === 2
        ? locationData[1] + ",\n" + locationData[0]
        : "",
  });
  detailList.push({
    label: "Last Reported",
    detailItemvalue: timezoneDate ?? "",
  });
  detailList.push({
    label: "Trip Status",
    detailItemvalue: tripStatus ?? "",
  });

  detailList.push({
    label: "Geofence",
    detailItemvalue: geofenceFieldValue,
  });

  return detailList;
}
