import { JsonLogicTree } from "@react-awesome-query-builder/core";
import {
  cloneDeep,
  isArray,
  isBoolean,
  isNil,
  isObject,
  isString,
  isUndefined,
  property,
  startCase,
  uniq,
} from "lodash";
import { ASSET_FILTERS_DISPLAY_NAME_MAP } from "../../../../../constants/map";
import {
  DistanceUnit,
  PressureUnit,
  TemperatureUnit,
} from "../../../../../graphql/operations";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  DATE_FORMAT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../utils";
import { convertKmToMiles } from "../../../../../utils/convertUnits";
import { formatNumber } from "../../../../../utils/formatters";
import { safeJsonParse } from "../../../../../utils/safeJsonParse";
import { pluralize } from "../../../../DashboardsView/DashboardView/components/widgets/AssetsMileageWidget";
import { AssetFilters, IGeofenceFilters } from "../../AssetsDataContext";
import { AssetSharedType } from "../Filters/AssetFilters/AssetFilters";

const isSet = (value: unknown) => !isNil(value);

const UNIT_SHORTHANDS_MAP = new Map<
  DistanceUnit | PressureUnit | TemperatureUnit,
  string
>([
  [DistanceUnit.Kilometers, "km"],
  [DistanceUnit.Miles, "mi"],
  [PressureUnit.Mbar, "mbar"],
  [PressureUnit.Psi, "psi"],
  [TemperatureUnit.Celsius, "°C"],
  [TemperatureUnit.Fahrenheit, "°F"],
  // TODO: PRJIND-9386 Implement usage of weight unit preference
]);

export interface IMapAssetFilterValueParams {
  filterKey: keyof AssetFilters | keyof IGeofenceFilters;
  filterValue: any;
  unitPreferences: {
    distance: DistanceUnit;
    pressure: PressureUnit;
    temperature: TemperatureUnit;
    // TODO: PRJIND-9386 Implement usage of weight unit preference
  };
  preferredTimezone: any;
}

const countQueryBuilderRules = (tree: JsonLogicTree): number => {
  let count = 0;

  const values = Object.values(tree);
  for (let value of values) {
    for (let tree of value) {
      if (isObject(tree)) {
        if ("var" in tree) {
          count += 1;
        } else {
          count += countQueryBuilderRules(tree);
        }
      }
    }
  }

  return count;
};

export const mapAssetFilterValue = ({
  filterKey,
  filterValue,
  unitPreferences,
  preferredTimezone,
}: IMapAssetFilterValueParams): string | undefined => {
  // Query Builder
  if (filterKey === "filters") {
    const filters = safeJsonParse(filterValue as string, {});
    const count = countQueryBuilderRules(filters);

    return `${count} ${pluralize(count, "rule")}`;
  } else if (isArray(filterValue)) {
    // Object arrays
    if (filterValue.some((entry) => !isString(entry))) {
      return filterValue.map((entry) => entry?.label).join(", ");
    }

    // Trip status
    if (filterKey === "status") {
      return filterValue
        .map((status) => {
          // If status is "dwell status" and not "trip status" -> change the label
          if (
            ["low", "medium", "high", "mega"].includes(status.toLowerCase())
          ) {
            status = `${status} Dwell`;
          }

          return startCase(status);
        })
        .join(", ");
    }

    // String arrays
    return filterValue.map(startCase).join(", ");
  } else if (isSet(filterValue?.startValue) || isSet(filterValue?.endValue)) {
    const distanceUnitShorthand = UNIT_SHORTHANDS_MAP.get(
      unitPreferences.distance
    );

    const formattedFilterStartValue =
      typeof filterValue?.startValue === "number" && filterKey !== "assetYear"
        ? formatNumber(filterValue?.startValue)
        : filterValue?.startValue;
    const formattedFilterEndValue =
      typeof filterValue?.endValue === "number" && filterKey !== "assetYear"
        ? formatNumber(filterValue?.endValue)
        : filterValue?.endValue;

    switch (filterKey) {
      case "weightStatus":
        // TODO: PRJIND-9386 Implement usage of weight unit preference
        return `${formattedFilterStartValue}kg - ${formattedFilterEndValue}kg`;
      case "totalMileage":
        const convertedStartValue =
          unitPreferences.distance !== DistanceUnit.Kilometers
            ? formatNumber(convertKmToMiles(filterValue?.startValue))
            : formattedFilterStartValue;
        const convertedEndValue =
          unitPreferences.distance !== DistanceUnit.Kilometers
            ? formatNumber(convertKmToMiles(filterValue?.endValue))
            : formattedFilterEndValue;
        return `${convertedStartValue}${distanceUnitShorthand} - ${convertedEndValue}${distanceUnitShorthand}`;
      default:
        return `${formattedFilterStartValue} - ${formattedFilterEndValue}`;
    }
  } else if (isSet(filterValue?.minVoltage) || isSet(filterValue?.maxVoltage)) {
    return `${filterValue?.minVoltage}V - ${filterValue?.maxVoltage}V`;
  } else if (
    isSet(filterValue?.minAmperage) ||
    isSet(filterValue?.maxAmperage)
  ) {
    return `${filterValue?.minAmperage}mA - ${filterValue?.maxAmperage}mA`;
  } else if (isSet(filterValue?.startDate) || isSet(filterValue?.endDate)) {
    let startDate, endDate;
    if (isSet(filterValue?.startDate))
      if (preferredTimezone) {
        startDate = formatDateInTimezone(
          filterValue.startDate,
          DATE_FORMAT,
          mapTimezoneToIANAValue(preferredTimezone)
        );
      } else {
        startDate = convertToIsoStringIgnoringTimezoneOffset(
          new Date(filterValue.startDate),
          "MM/dd/yyyy"
        );
      }

    if (isSet(filterValue?.endDate))
      if (preferredTimezone) {
        endDate = formatDateInTimezone(
          filterValue.endDate,
          DATE_FORMAT,
          mapTimezoneToIANAValue(preferredTimezone)
        );
      } else {
        endDate = convertToIsoStringIgnoringTimezoneOffset(
          new Date(filterValue.endDate),
          "MM/dd/yyyy"
        );
      }

    if (startDate && endDate) return `${startDate} - ${endDate}`;
    else if (startDate) return `Since ${startDate}`;
    else if (endDate) return `Up to ${startDate}`;
  } else if (isSet(filterValue?.minDays) || isSet(filterValue?.maxDays)) {
    let from, to;

    if (isSet(filterValue?.minDays)) from = filterValue.minDays;
    if (isSet(filterValue?.maxDays)) to = filterValue.maxDays;
    if (isSet(from) && isSet(to)) return `${from} - ${to}`;
    else if (isSet(from)) return `More than ${from}`;
    else return `Less than ${to}`;
  } else if (isSet(filterValue?.timeFrom) || isSet(filterValue?.timeTo)) {
    const from = filterValue?.timeFrom;
    const to = filterValue?.timeTo;
    if (from && to) {
      return `From ${from} to ${to}`;
    } else if (from) {
      return `From ${from}`;
    } else {
      return `Until ${to}`;
    }
  } else if (
    filterKey === "internalCameraFloorUsagePercentage" ||
    filterKey === "internalCameraCubeUsagePercentage"
  )
    return `${filterValue.minPercentage}% - ${filterValue.maxPercentage}%`;
  // handles regions which is a dropDown option with array as value
  // & we need to show the label of the selected region
  // This gets triggered only through geofences, assets regions filter is array
  else if (filterKey === "regions" || filterKey === "zones") {
    return filterValue?.label;
  } else if (isBoolean(filterValue)) {
    if (filterKey === "installStatus")
      return filterValue ? "Installed" : "Not Installed";
    else if (filterKey === "signals")
      return filterValue ? "Locked" : "Unlocked";
    else if (filterKey === "subOrganizations")
      return filterValue ? "Include" : "Exclude";
    else if (filterKey === "hasAssets")
      return filterValue ? "Has assets present" : "Has no assets present";
  } else if (isString(filterValue)) {
    // Return these as-is
    const shouldNotFormatFilter = ["lastReportedDateRange"].includes(filterKey);
    if (shouldNotFormatFilter) return filterValue;
    if (filterValue === AssetSharedType.Both) return "Shared By, Shared With";
    // Cargo is special case because it has to be renamed
    if (filterKey === "cargoUltrasonicState") {
      if (filterValue === "unloaded") return "Empty";
    }

    // Else format them
    return startCase(filterValue);
  } else if (filterValue?.label) {
    return filterValue?.label;
  } else {
    return "Failed to process value";
  }
};

export const mapFilterKeyToName = (
  filterName: keyof AssetFilters | keyof IGeofenceFilters,
  filterValue: unknown
) => {
  switch (filterName) {
    case "status":
      if (isArray(filterValue)) return "Trip Status";
      else return "Dwelling Days";
    case "filters":
      return "Query Builder";
    default:
      return ASSET_FILTERS_DISPLAY_NAME_MAP.get(filterName) as string;
  }
};

export const removeUntouchedFiltersAndQueryBuilder = (
  allFilters: Partial<AssetFilters | IGeofenceFilters>,
  initialFilterState: AssetFilters | IGeofenceFilters
): Partial<AssetFilters | IGeofenceFilters> => {
  const filters = cloneDeep(allFilters);
  for (const key in filters) {
    const filter = key as keyof typeof filters;

    // Remove Query Builder filters
    if (["complexFilters", "complexFiltersMongodb"].includes(filter)) {
      delete filters[filter];
    }

    // Check if filter value is different from the initial
    const isTouched =
      JSON.stringify(filters[filter]) !==
      JSON.stringify(initialFilterState[filter]);

    // If not -> remove it
    if (!isTouched) delete filters[filter];

    // Edge cases
    // "undefined" is also an acceptable default value for these
    if (isUndefined(filters[filter])) {
      if (
        [
          "cargoUltrasonicState",
          "doorState",
          "internalCameraStatus",
          "signals",
        ].includes(filter)
      )
        delete filters[filter];
    }
  }
  return filters;
};
