import { useEffect, useState } from "react";
import { ROOT_ORGANIZATION_NAME } from "../../../../../constants";
import {
  ConfigurationSetType,
  useFindOrgQuery,
  useGetConfigurationSetsQuery,
} from "../../../../../graphql/operations";
import { useGlobalOrganizationFilter } from "../../../../../shared/hooks/useGlobalOrganizationFilter";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { useRegionsContext } from "../context";
import { ParsedRegionsData, RegionCountry } from "../interfaces";
import {
  getTerritoriesInUseRegionData,
  initialRegionsData,
  parseRegionData,
} from "../utils";

interface UseGetRegionsData {
  regions: ParsedRegionsData;
  isLoading: boolean;
  getCountryHasOwnRegions: (country: RegionCountry) => boolean;
}

export const useGetRegionsData = (): UseGetRegionsData => {
  // Hooks
  const selectedOrg = useSelectedOrg();
  const isPhillipsConnectSelectedOrg =
    selectedOrg.label === ROOT_ORGANIZATION_NAME;
  const globalOrgSelected = useGlobalOrganizationFilter();
  const {
    setTerritoriesInUseUSA,
    setTerritoriesInUseCanada,
    setTerritoriesInUseMexico,
  } = useRegionsContext();

  // States
  const [regions, setRegions] = useState<ParsedRegionsData>(initialRegionsData);
  const [hasOwnRegionsUSA, setHasOwnRegionsUSA] = useState<boolean>(false);
  const [hasOwnRegionsCanada, setHasOwnRegionsCanada] =
    useState<boolean>(false);
  const [hasOwnRegionsMexico, setHasOwnRegionsMexico] =
    useState<boolean>(false);

  // Queries
  const { data: rootOrgData, isLoading: isLoadingRootOrgData } =
    useFindOrgQuery({
      input: {
        org_key: ROOT_ORGANIZATION_NAME,
      },
    });

  const { data: defaultRegionsData, isFetching: isLoadingDefaultRegionsData } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: rootOrgData?.findOrg?._id ?? "",
          type: isPhillipsConnectSelectedOrg
            ? ConfigurationSetType.RegionGroupPctDefault
            : ConfigurationSetType.RegionGroup,
          excludeDataFromParents: true,
        },
      },
      {
        enabled: Boolean(rootOrgData?.findOrg?._id),
      }
    );

  const { data: regionsData, isFetching: isLoadingRegionsData } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: globalOrgSelected?.value ?? "",
          type: ConfigurationSetType.RegionGroup,
          excludeDataFromParents: true,
          includeSuborgs: false,
        },
      },
      {
        enabled: Boolean(globalOrgSelected?.value),
      }
    );

  // Effects
  useEffect(() => {
    if (defaultRegionsData && regionsData) {
      const parsedRegions = parseRegionData(regionsData.getConfigurationSets);
      const parsedDefaultRegions = parseRegionData(
        defaultRegionsData.getConfigurationSets,
        true
      );
      const usaHasOwnRegions = parsedRegions.unitedStates.data.length > 0;
      const canadaHasOwnRegions = parsedRegions.canada.data.length > 0;
      const mexicoHasOwnRegions = parsedRegions.mexico.data.length > 0;

      const newRegions = {
        unitedStates: usaHasOwnRegions
          ? parsedRegions.unitedStates
          : parsedDefaultRegions.unitedStates,
        canada: canadaHasOwnRegions
          ? parsedRegions.canada
          : parsedDefaultRegions.canada,
        mexico: mexicoHasOwnRegions
          ? parsedRegions.mexico
          : parsedDefaultRegions.mexico,
      };

      setHasOwnRegionsUSA(usaHasOwnRegions);
      setHasOwnRegionsCanada(canadaHasOwnRegions);
      setHasOwnRegionsMexico(mexicoHasOwnRegions);
      setRegions(newRegions);

      // Update territories in use
      const territoriesInUseUSA = getTerritoriesInUseRegionData(
        newRegions.unitedStates.data
      );
      const territoriesInUseCanada = getTerritoriesInUseRegionData(
        newRegions.canada.data
      );
      const territoriesInUseMexico = getTerritoriesInUseRegionData(
        newRegions.mexico.data
      );

      setTerritoriesInUseUSA(territoriesInUseUSA);
      setTerritoriesInUseCanada(territoriesInUseCanada);
      setTerritoriesInUseMexico(territoriesInUseMexico);
    }
  }, [
    regionsData,
    defaultRegionsData,
    setTerritoriesInUseUSA,
    setTerritoriesInUseCanada,
    setTerritoriesInUseMexico,
  ]);

  const getCountryHasOwnRegions = (country: RegionCountry): boolean => {
    switch (country) {
      case RegionCountry.UnitedStates:
        return hasOwnRegionsUSA;
      case RegionCountry.Canada:
        return hasOwnRegionsCanada;
      case RegionCountry.Mexico:
        return hasOwnRegionsMexico;
    }
  };

  const isLoading =
    isLoadingRootOrgData || isLoadingRegionsData || isLoadingDefaultRegionsData;

  return { regions, isLoading, getCountryHasOwnRegions };
};
