import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetDevicesPerTagDeviceData = {
  _id: string;
  total_count: number;
  percentage: number;
};

export type DashboardWidgetDevicesPerTagData = {
  dashboardWidgetDevicesPerTag: DashboardWidgetDevicesPerTagDeviceData[];
  totalCount: number;
};

export interface DashboardWidgetDevicesPerTagWidget
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetDevicesPerTagData;
}

export interface DevicesPerTagProps {
  widget: DashboardWidgetDevicesPerTagWidget;
}

const mapChartColors = (data: string) => {
  if (data === "Other") {
    return "var(--concrete)";
  }
};

const mapWidgetData = (data: DashboardWidgetDevicesPerTagData) =>
  data?.dashboardWidgetDevicesPerTag?.map((device) => ({
    name: device._id,
    value: device.total_count ?? 0,
    percent: device.percentage ?? 0,
    color: mapChartColors(device._id),
  })) ?? [];

export const DevicesPerTagWidget: React.FC<DevicesPerTagProps> = ({
  widget,
}) => {
  const title = widget.name ?? "Devices Per Tag";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetDevicesPerTagData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.totalCount ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--devices-per-tag-type"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the devices per tag type
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={data}
          title={title}
          totalRecords={total}
          isLoading={isLoading}
          showPercentages
          showTotal={true}
          showXAxis={false}
          isTotalVisible={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
