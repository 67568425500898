import { CargoCameraStats } from "../../../../../../graphql/operations";

export const getGraphChipBoxColor = (item: CargoCameraStats): string => {
  if (item.floorUsageStatus === "loaded" && item?.floorUsagePercentage) {
    const value = parseInt(item.floorUsagePercentage.replace("%", ""));

    // Round value up to the nearest 10
    // Examples: 31 goes to 40, 54 goes to 60 ans 69 goes to 70
    const roundUp = Math.ceil(value / 10) * 10;

    if (roundUp === 100) {
      return "bg-brand-light-blue";
    } else {
      return `bg-primary-blue-transparent-${roundUp}`;
    }
  }

  if (item.floorUsageStatus === "empty") {
    return "border";
  }

  return "bg-box-shadow";
};
