import { useMemo } from "react";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MopedIcon from "@mui/icons-material/Moped";
import SchemaIcon from "@mui/icons-material/Schema";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { Card, Typography } from "@mui/material";
import {
  AssetTypeLowerCased,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import Highlights, {
  HighlightItem,
} from "../../../../../shared/components/DashboardWidgets/HighlightsWidget/Highlights";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetAssetsTypeData = {
  total: number;
  categories: Record<AssetTypeLowerCased, number>;
};

export interface DashboardWidgetAssetsType extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsTypeData;
}

export interface AssetsTypeWidgetProps {
  widget: DashboardWidgetAssetsType;
}

const mapWidgetData = (
  data: DashboardWidgetAssetsTypeData
): HighlightItem[] => {
  if (!data || !data.categories) {
    return [];
  }

  const categoryNames = Object.keys(data.categories);
  //keys are sorted alphabetically, so we need to reorder them as per design
  const boatIndex = categoryNames.indexOf(AssetTypeLowerCased.Boat);
  const containerIndex = categoryNames.indexOf(AssetTypeLowerCased.Container);
  const chassisIndex = categoryNames.indexOf(AssetTypeLowerCased.Chassis);
  const dollyIndex = categoryNames.indexOf(AssetTypeLowerCased.Dolly);
  const trailerIndex = categoryNames.indexOf(AssetTypeLowerCased.Trailer);
  const tractorIndex = categoryNames.indexOf(AssetTypeLowerCased.Tractor);
  const reeferIndex = categoryNames.indexOf(AssetTypeLowerCased.Reefer);
  const vehicleIndex = categoryNames.indexOf(AssetTypeLowerCased.Vehicle);
  const otherIndex = categoryNames.indexOf(AssetTypeLowerCased.Other);

  const categoryNamesOrdered = [
    categoryNames[boatIndex],
    categoryNames[containerIndex],
    categoryNames[chassisIndex],
    categoryNames[dollyIndex],
    categoryNames[trailerIndex],
    categoryNames[tractorIndex],
    categoryNames[reeferIndex],
    categoryNames[vehicleIndex],
    categoryNames[otherIndex],
  ];
  return categoryNamesOrdered.map((category) => ({
    icon: mapCategoryIcon(category as AssetTypeLowerCased),
    label: category,
    count: data.categories[category as AssetTypeLowerCased],
  }));
};

const mapCategoryIcon = (category: AssetTypeLowerCased) => {
  switch (category) {
    case AssetTypeLowerCased.Boat:
      return <DirectionsBoatIcon />;
    case AssetTypeLowerCased.Container:
      return <ViewColumnIcon />;
    case AssetTypeLowerCased.Chassis:
      return <SchemaIcon />;
    case AssetTypeLowerCased.Dolly:
      return <MopedIcon />;
    case AssetTypeLowerCased.Trailer:
      return <LocalShippingIcon />;
    case AssetTypeLowerCased.Tractor:
      return <AgricultureIcon />;
    case AssetTypeLowerCased.Reefer:
      return <DirectionsBusIcon />;
    case AssetTypeLowerCased.Vehicle:
      return <DriveEtaIcon />;
    case AssetTypeLowerCased.Other:
    default:
      return <HelpOutlineIcon />;
  }
};

export const AssetsTypeWidget: React.FC<AssetsTypeWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const subTitle = widget.data?.total
    ? {
        subTitle: "Number of Assets",
        subCount: widget.data.total,
      }
    : undefined;

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetAssetsTypeData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);

    return mappedData;
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-type"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets type widget!
          Please try again later.
        </Typography>
      ) : (
        <Highlights
          title={title}
          subTitle={subTitle}
          isLoading={isLoading}
          items={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
