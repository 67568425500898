import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  FileFormat,
  GeofenceAlertHistoryTableData,
  GeofenceAlertHistoryTableDataResponse,
  GetGeofenceAlertHistoryTableDataInput,
  GetTableDataInput,
  SortOrder,
  TableDomain,
  TableViewType,
  useGetGeofenceAlertHistoryTableDataQuery,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { useSettingsTheme } from "../../hooks/useSettingsTheme";
import { columnVisibilityModel, getColumns } from "./columns";

export type GeofenceAlertsListProps = {
  geofenceId: string;
};

const GeofenceAlertsList: FC<GeofenceAlertsListProps> = ({
  geofenceId,
}: GeofenceAlertsListProps) => {
  const theme = useSettingsTheme();
  const {
    state: { appConfig },
  } = useAppContext();

  const apiRef = useGridApiRef();

  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
  const [isExporting, setIsExporting] = useState(false);

  const timezone = usePreferredTimezone();
  const columns = useMemo(() => getColumns(timezone), [timezone]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [queryInput, setQueryInput] =
    useState<GetGeofenceAlertHistoryTableDataInput>({
      geofenceId,
      sorting: [{ field: "remindedAt", order: SortOrder.Desc }],
      pagination: {
        skip: 0,
        limit: appConfig.table.defaultRowsPerPage,
      },
    });

  useEffect(() => {
    if (geofenceId && geofenceId !== queryInput.geofenceId) {
      setQueryInput((prev) => ({
        ...prev,
        geofenceId,
      }));
    }
  }, [geofenceId, queryInput.geofenceId]);

  const {
    data: queryData,
    isSuccess,
    isLoading,
  } = useGetGeofenceAlertHistoryTableDataQuery<GeofenceAlertHistoryTableDataResponse>(
    { input: queryInput },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getGeofenceAlertHistoryTableData }) =>
        getGeofenceAlertHistoryTableData,
    }
  );

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
    },
    [setQueryInput]
  );

  useTableDataExporter<GeofenceAlertHistoryTableData>({
    apiRef,
    columns,
    queryInput,
    domain: TableDomain.GeofenceAlertHistory,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: queryData?.pagination.total,
    baseFilters: { geofenceId },
  });

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="bg-background h-full overflow-auto">
        <Box className="flex h-full w-full pt-2 px-4 pb-6 md:px-6 lg:px-16">
          <BackEndProcessingTable
            apiRef={apiRef}
            baseFilters={{ geofenceId }}
            domain={TableDomain.GeofenceAlertHistory}
            isSendingEmail={isSendingEmail}
            setSendingEmail={setIsSendingEmail}
            queryInput={queryInput}
            tableName={"geofence-alert-history"}
            tableType={TableViewType.GeofenceAlerts}
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            data={{
              rows: queryData?.data ?? [],
              pagination: queryData?.pagination,
            }}
            updateQueryInput={updateQueryInput}
            onExport={handleExport}
            sorting={queryInput.sorting ?? undefined}
            isLoading={isLoading}
            isSuccess={isSuccess}
            totalCount={queryData?.pagination.total}
          />
          <Spinner counter={Number(isSendingEmail)} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default memo(GeofenceAlertsList);
