import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardProvider } from "../../context/DashboardContext";
import DashboardView from "./DashboardView/DashboardView";
import DashboardsListView from "./DashboardsTable/DashboardsListView";

const DashboardsView = memo(() => {
  return (
    <DashboardProvider>
      <Routes>
        <Route path="" element={<DashboardsListView />} />
        <Route path="/:id" element={<DashboardView />} />
      </Routes>
    </DashboardProvider>
  );
});

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
DashboardsView.displayName = "DashboardsView";

export default DashboardsView;
