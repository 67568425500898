import React from "react";
import { isNil, isNumber } from "lodash";
import { TooltipProps } from "recharts";
import { PressureUnit } from "../../../../../graphql/operations";
import { getPressureUnitLabel } from "../../../../../utils/convertPressure";
import type { IndividualAirTank } from "./BrakesAirTankChart";

export interface CustomBrakesChartTooltipProps extends TooltipProps<any, any> {
  activeIndex?: number;
  pressureUnit: PressureUnit;
}

export const CustomBrakesChartTooltip: React.FC<
  CustomBrakesChartTooltipProps
> = ({ active, payload, activeIndex, pressureUnit }) => {
  if (active && payload?.length && isNumber(activeIndex)) {
    const currentTankAccessor = `airTank${activeIndex + 1}`;
    const currentTank: IndividualAirTank =
      payload?.[activeIndex]?.payload?.airTank?.[currentTankAccessor];

    const tankPressureMin = currentTank?.min ?? "N/A";
    const tankPressureMax = currentTank?.max ?? "N/A";
    const actualPressure = !isNil(currentTank?.median)
      ? Math.trunc(currentTank.median)
      : "N/A";
    const pressureUnitLabel = getPressureUnitLabel(pressureUnit);

    const date = payload?.[activeIndex]?.payload?.toolTipDate;

    return (
      <div
        className="p-6 bg-custom-tooltip-background rounded-lg border border-sub-header-border"
        data-testid="tooltip-wrapper"
      >
        <p
          className="text-base font-normal text-typography mb-[6px]"
          data-testid="tooltip-date"
        >
          {date}
        </p>
        <div className="flex">
          <div>
            <p className="text-base font-bold text-typography mb-[6px]">
              Air Tank {activeIndex + 1} Pressure
            </p>
            <p className="text-base font-normal text-typography">
              Actual:
              <span className="font-bold" data-testid="tooltip-pressure-actual">
                {` ${actualPressure} ${pressureUnitLabel}`}
              </span>
            </p>
            <p className="text-base font-normal text-typography">
              Range:
              <span className="font-bold" data-testid="tooltip-pressure-range">
                {` ${tankPressureMin} ${pressureUnitLabel} - ${tankPressureMax} ${pressureUnitLabel}`}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
