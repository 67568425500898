import React, {
  memo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { FieldValues } from "react-hook-form";
import { Box } from "@mui/material";
import {
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { isUndefined } from "lodash";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import {
  Dashboard,
  DashboardVisibility,
  Maybe,
  TableFiltersInput,
  TableSortingInput,
  useCreateDashboardMutation,
  useGetDashboardsForTableQuery,
} from "../../../graphql/operations";
import Page from "../../../shared/components/Page";
import { SubHeader } from "../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import {
  TableGridColDef,
  TableGridData,
} from "../../../shared/components/Table";
import { dataGridFiltersToTableFilters } from "../../../shared/components/Table/utils";
import { useAvailableOrgs } from "../../../shared/hooks/useAvailableOrgs";
import { usePreferredTimezone } from "../../../shared/hooks/usePreferredTimezone";
import { useUserData } from "../../../shared/hooks/useUserData";
import { useOrgsOptions } from "../../AssetsView/TableView/hooks";
import CreateEditDashboardDialog, {
  DashboardDialogType,
} from "./components/CreateEditDashboardDialog";
import DashboardsTable from "./components/DashboardsTable";
import { getColumns } from "./utils";

const DashboardsListView: React.FC = () => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();

  const queryClient = useQueryClient();

  const page = useRef();
  const userData = useUserData();
  const gridApiRef = useGridApiRef();
  const timezone = usePreferredTimezone();
  const availableOrgs = useOrgsOptions(useAvailableOrgs());
  const orgOptions = availableOrgs.map((org) => ({
    ...org,
    value: org.id,
  }));

  const dataPerPage = appConfig.table.dashboardsPerPage || 25;
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [dashboards, setDashboards] = useState<Maybe<Dashboard>[]>([]);
  const [totalTableRowsCount, setTotalTableRowsCount] = useState<number>(0);
  const [pagination, setPagination] = useState<number>(0);
  const [createDashboardPopupOpened, setCreateDashboardPopupOpened] =
    useState(false);
  const [tableFilters, setTableFilters] = useState<TableFiltersInput | null>(
    null
  );

  const handleSubmit = (formData: FieldValues) => {
    createDashboardMutation({
      input: {
        userId: userData?._id,
        orgId: formData.orgName,
        name: formData.name,
        template: parseInt(formData.template),
        visibility: formData.public
          ? DashboardVisibility.Public
          : DashboardVisibility.Private,
      },
    });
  };
  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      primary: true,
      title: "New Dashboard",
      handler: () => {
        setCreateDashboardPopupOpened(true);
      },
      accessScope: "dashboard.create",
    },
  ];
  const isQueryEnabled = useMemo(
    () =>
      Boolean(userData?.customerOrg?._id) &&
      Boolean(selectedOrganization.value),
    [userData, selectedOrganization]
  );
  const {
    data: dashboardsQuery,
    isFetching,
    isError,
    isRefetching,
    refetch: refetchGetDashboardsForTable,
  } = useGetDashboardsForTableQuery(
    {
      input: {
        orgId: selectedOrganization.value,
        user_id: userData?._id as string,
        pagination: {
          skip: pagination,
          limit: dataPerPage,
        },
        tableFilters: tableFilters,
        sorting: sortModel.map((el) => ({
          field: el.field,
          order: el.sort,
        })) as TableSortingInput[],
      },
    },
    {
      enabled: isQueryEnabled,
    }
  );

  const {
    mutate: createDashboardMutation,
    isLoading: processingCreateDashboard,
  } = useCreateDashboardMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getDashboardsForTable"] });
      await refetchGetDashboardsForTable();

      setCreateDashboardPopupOpened(false);

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Dashboard Created Successfully!",
          text: "You can find the new dashboard in the table.",
          severity: "success",
        },
      });
    },
  });

  useEffect(() => {
    setDashboards(dashboardsQuery?.getDashboardsForList?.dashboards ?? []);
    setTotalTableRowsCount(
      dashboardsQuery?.getDashboardsForList.totalCount ?? 0
    );
  }, [
    dashboardsQuery?.getDashboardsForList?.dashboards,
    dashboardsQuery?.getDashboardsForList.totalCount,
  ]);

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
    },
    [setSortModel]
  );

  const onFiltersChange = useCallback(
    (inputFilters: GridFilterModel) => {
      if (!isUndefined(inputFilters)) {
        const result = dataGridFiltersToTableFilters(
          inputFilters,
          getColumns(timezone, orgOptions) as TableGridColDef<TableGridData>[],
          timezone
        ) as TableFiltersInput;

        setTableFilters(result);
        setCurrentPageNo(1);
        setPagination(0);
      }
    },
    [orgOptions, timezone]
  );

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPageNo(page);
      setPagination(dataPerPage * (page - 1));
    },
    [dataPerPage]
  );

  // Reset the page when the organization changes
  useEffect(() => {
    if (currentPageNo !== 1) setCurrentPageNo(1);
    if (pagination !== 0) setPagination(0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedOrganization]);

  return (
    <Page
      ref={page}
      className={"bg-background"}
      data-testid="dashboards-list-view"
    >
      <Box className="pt-2">
        <SubHeader
          title="Dashboards"
          actions={subHeaderActions}
          hideHeaderBorder={true}
          hideOrgName
        />
      </Box>
      {createDashboardPopupOpened && (
        <CreateEditDashboardDialog
          dashboards={dashboards}
          isLoading={isFetching}
          open={createDashboardPopupOpened}
          processing={processingCreateDashboard}
          type={DashboardDialogType.Create}
          onClose={() => setCreateDashboardPopupOpened(false)}
          onSubmit={handleSubmit}
        />
        // drawer
      )}

      <Box className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16">
        <DashboardsTable
          apiRef={gridApiRef}
          dashboards={dashboards}
          onFilterChange={onFiltersChange}
          timezone={timezone}
          availableOrgsOptions={orgOptions}
          onSortModelChange={onSortModelChange}
          onPageChange={onPageChange}
          isRefetching={isRefetching}
          totalTableRowsCount={totalTableRowsCount}
          isLoading={isFetching}
          isError={isError}
          currentPage={currentPageNo}
          dataPerPage={dataPerPage}
        />
      </Box>
    </Page>
  );
};

export default memo(DashboardsListView);
