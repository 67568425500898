import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  SET_BREADCRUMBS_END_DATE,
  SET_BREADCRUMBS_START_DATE,
} from "../../../../../../constants";
import { BREADCRUMBS_TAB_DATE_RANGE_OPTIONS } from "../../../../../../constants/map";
import { DistanceUnit } from "../../../../../../graphql/operations";
import DateRangePicker from "../../../../../../shared/components/DateRangePicker";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { useUserData } from "../../../../../../shared/hooks/useUserData";
import { convertKmToMiles } from "../../../../../../utils/convertUnits";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../utils/date";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { useBreadcrumbsContext } from "../context/BreadCrumbsContext";
import { HistoryEventsFiltersData } from "../utils";
import BreadcrumbsFilterCard from "./BreadcrumbsFilterCard";

export interface BreadcrumbsFiltersWidgetProps {
  historyEventsFiltersData: HistoryEventsFiltersData[];
  travelledDistance: number;
}

const BreadcrumbsFiltersWidget: FC<BreadcrumbsFiltersWidgetProps> = ({
  travelledDistance,
  historyEventsFiltersData,
}) => {
  const isMobile = useBreakpoint("down", "xl");
  const { dispatch } = useBreadcrumbsContext();

  // default selected range is Last 7 Days
  const [startDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 6))
  );
  const [endDate, setEndDate] = useState<Date | null>(getEndOfToday());

  const { selectedAssetId } = useAssetsDataContext();
  const user = useUserData();
  const distanceUnit = user?.customerOrg.distance_unit_preference;

  const { data: selectedAsset } = useFindAssetById(
    { assetId: selectedAssetId ?? "" },
    { enabled: !!selectedAssetId }
  );

  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    BREADCRUMBS_TAB_DATE_RANGE_OPTIONS[1].value
  );

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMBS_START_DATE,
      payload: startDate,
    });
    dispatch({
      type: SET_BREADCRUMBS_END_DATE,
      payload: endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateRangeChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      setStartDate(startDate);
      setEndDate(endDate);

      dispatch({
        type: SET_BREADCRUMBS_START_DATE,
        payload: startDate,
      });
      dispatch({
        type: SET_BREADCRUMBS_END_DATE,
        payload: endDate,
      });
    },
    [dispatch]
  );

  const hideMileage = useMemo(
    () =>
      selectedAsset?.shared?.organizationId === user?.customerOrg._id &&
      selectedAsset?.shared?.hideProperties?.mileage,
    [
      selectedAsset?.shared?.hideProperties?.mileage,
      selectedAsset?.shared?.organizationId,
      user?.customerOrg._id,
    ]
  );

  const getTravelledDistanceInfo = useCallback(() => {
    if (hideMileage) return "";

    const distance =
      distanceUnit === DistanceUnit.Miles
        ? convertKmToMiles(travelledDistance)
        : travelledDistance;

    return `${distance} ${distanceUnit}`;
  }, [hideMileage, travelledDistance, distanceUnit]);

  return (
    <Grid
      container
      className="flex bg-dashboard_subheader__bg flex w-full p-4 rounded-lg"
    >
      <Grid item className="flex w-full pb-3">
        <Typography className="!text-lg !font-semibold text-brand pr-2">
          Total Mileage
        </Typography>
        <Typography className="!text-lg !font-semibold !text-typography-secondary">
          {getTravelledDistanceInfo()}
        </Typography>
        <Box className="grow">
          <DateRangePicker
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            onChange={handleDateRangeChange}
          />
        </Box>
      </Grid>
      <Grid item className="w-full gap-4 items-center">
        <Grid
          container
          gap={1}
          data-testid="filterCardsContainer"
          sx={{ flexDirection: isMobile ? "column" : "row" }}
        >
          {historyEventsFiltersData.map((filterData) => (
            <BreadcrumbsFilterCard
              key={filterData.filterName}
              circleBackgroundColor={filterData.circleBackgroundColor}
              iconColor={filterData.iconColor}
              filterName={filterData.filterName}
              filterValue={filterData.filterValue}
              filterType={filterData.filterType}
              isSelected={filterData.isSelected}
              onClick={filterData?.onClick}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BreadcrumbsFiltersWidget;
