import {
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import {
  AssetDetentionTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { unloadDurationValueFormatter } from "./helpers";

export const columnVisibilityModel: Record<
  keyof Pick<
    AssetDetentionTableData,
    | "cargoStatusOnEnter"
    | "cargoStatusOnExit"
    | "detentionStart"
    | "detentionEnd"
    | "assetId"
    | "deviceId"
    | "detentionStatus"
    | "currentDate"
    | "totalDaysInDetention"
    | "totalDwell"
    | "unloadDuration"
  >,
  boolean
> = {
  cargoStatusOnEnter: true,
  detentionStart: true,
  assetId: true,
  cargoStatusOnExit: true,
  detentionEnd: true,
  detentionStatus: true,
  totalDaysInDetention: true,
  deviceId: true,
  currentDate: true,
  totalDwell: true,
  unloadDuration: true,
};

export type ColumnForAssetDetentionTableDataFields = Omit<
  TableColumnProps<AssetDetentionTableData>,
  "field"
> & {
  field: keyof AssetDetentionTableData;
};

const baseOptions = {
  flex: 1,
};

const dateTimeOptions = (timezone: string) => ({
  ...baseOptions,
  ...columnTimezoneDateTimeFormatParams(timezone),
  minWidth: 190,
  filterable: false,
});

const statusColumnOptions: TableColumnProps<AssetDetentionTableData>["options"] =
  {
    ...baseOptions,
    minWidth: 130,
    valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
      if (value === "NA") return value;
      return value ? capitalize(value) : "";
    },
  };

export const getColumns = (
  timezone: string
): TableGridColDef<AssetDetentionTableData>[] => {
  const columns: ColumnForAssetDetentionTableDataFields[] = [
    {
      field: "assetId",
      headerName: "Asset ID",
      type: "string",
      options: { ...baseOptions, minWidth: 100 },
    },
    {
      field: "deviceId",
      headerName: "IMEI",
      type: "string",
      options: { ...baseOptions, minWidth: 150 },
    },
    {
      field: "currentDate",
      headerName: "Date And Time",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "totalDaysInDetention",
      headerName: "Days In Detention",
      type: "number",
      options: { ...baseOptions, minWidth: 100 },
    },
    {
      field: "detentionStatus",
      headerName: "Detention Status",
      type: "string",
      options: { ...baseOptions, minWidth: 130 },
    },
    {
      field: "detentionStart",
      headerName: "Entered At",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "detentionEnd",
      headerName: "Exited At",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "unloadDuration",
      headerName: "Unload Duration",
      format: TableColumnFormat.String,
      type: "number",
      options: {
        ...baseOptions,
        minWidth: 130,
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<
          AssetDetentionTableData["unloadDuration"]
        >) => unloadDurationValueFormatter(value),
      },
    },
    {
      field: "cargoStatusOnEnter",
      headerName: "Cargo Status On Enter",
      type: "string",
      options: statusColumnOptions,
    },
    {
      field: "cargoStatusOnExit",
      headerName: "Cargo Status On Exit",
      type: "string",
      options: statusColumnOptions,
    },
    {
      field: "totalDwell",
      headerName: "Total Dwell",
      type: "number",
      options: {
        ...baseOptions,
        minWidth: 150,
        valueGetter: ({
          row,
        }: GridValueGetterParams<AssetDetentionTableData>) =>
          !isNaN(Number(row?.totalDwell)) ? `${row.totalDwell} Days` : "",
      },
    },
  ];

  return columns.map(getTableColumn);
};
