import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { kebabCase } from "lodash";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import DeviceCommandButtons from "../DeviceCommandButtons/DeviceCommandButtons";

type detailItemvalueTypes = string | number | boolean | Date; // Add additional primitive types as needed

export type detailItem<T> = {
  label: string | undefined;
  detailItemvalue: T;
  truncateText?: boolean | undefined | null;
};

export type DetailListWidgetProps<T> = {
  title: string;
  subTitle?: string;
  detailList: Array<detailItem<T>>;
  showSendDeviceCommandButtons?: boolean;
  deviceId?: string;
};

const DetailListWidget = <T extends detailItemvalueTypes>({
  title,
  subTitle,
  detailList,
  showSendDeviceCommandButtons,
  deviceId,
}: DetailListWidgetProps<T>) => {
  const truncateStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  };

  const isMobile = useBreakpoint("down", "sm");

  return (
    <Box
      className="rounded-lg bg-dashboard_subheader__bg px-4 py-6 min-h-full"
      data-testid={`battery-widget-header`}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography
            className="!text-lg !font-semibold !text-brand"
            data-testid="battery-widget-title"
          >
            {title}
          </Typography>
        </Grid>

        {showSendDeviceCommandButtons && (
          <Grid item sx={{ marginBottom: isMobile ? "5px" : 0 }}>
            <DeviceCommandButtons deviceId={deviceId} />
          </Grid>
        )}
      </Grid>

      <Typography
        className="!text-xs !font-medium !mb-3 text-asset-info-subheader"
        data-testid={`battery-widget-subtitle`}
      >
        {subTitle}
      </Typography>
      <Grid container spacing={1}>
        {Object.entries(detailList).map(([key, value]) => {
          const valueToRender = value?.detailItemvalue?.toString()?.trim();
          const shouldCapitalize = value?.label !== "Dwell";

          return (
            <React.Fragment key={key}>
              <Grid item xs={5}>
                <Box
                  className="my-1 flex gap-1"
                  data-testid={`battery-widget-${kebabCase(value.label)}-label`}
                >
                  <Typography
                    className="!text-checked"
                    sx={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {value.label?.trim()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box
                  className="my-1 flex gap-1"
                  data-testid={`battery-widget-${kebabCase(value.label)}-value`}
                >
                  <Typography
                    title={(value.truncateText && valueToRender) || ""}
                    className="!text-checked"
                    sx={{
                      ...(value.truncateText && truncateStyle),
                      ...(shouldCapitalize
                        ? { textTransform: "capitalize" }
                        : {}),
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {valueToRender}
                  </Typography>
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DetailListWidget;
