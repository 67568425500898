import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useGetAssetBalanceReportDataQuery } from "../../../../../graphql/operations";
import { SwitchButtons } from "../../../../../shared/components/SwitchButtons";
import { Table, TableDataModes } from "../../../../../shared/components/Table";
import { ToggleButtonOption } from "../../../../../shared/components/ToggleButtons";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { buildWildcardStructure } from "../../../../../utils";
import { getOrgsHierarchy } from "../../../helpers/getOrgsHierarchy";
import { ReportParameters } from "../../../interfaces";
import {
  deltaOverviewTableColumns,
  distributionOverviewTableColumns,
} from "./columns";
import {
  buildAssetBalanceTableData,
  expandOrCollapseRow,
  getTableRows,
  getTableTitle,
} from "./helpers";
import {
  AssetBalanceTableData,
  AssetBalanceTableReportGridColDef,
  AssetBalanceTableRow,
  TableViewMode,
} from "./types";

const TABLE_SWITCH_OPTIONS: ToggleButtonOption[] = [
  { value: "distribution-overview", label: "Distribution Overview" },
  { value: "delta-overview", label: "Delta Overview" },
];

export type AssetBalanceTablesWrapperProps = {
  parameters: ReportParameters;
};

const initialTableState: {
  columns: AssetBalanceTableReportGridColDef[];
  tableViewMode: TableViewMode;
  title: string;
} = {
  columns: distributionOverviewTableColumns,
  tableViewMode: "distribution-overview",
  title: getTableTitle("distribution-overview"),
};

export const AssetBalanceReportTable = ({
  parameters,
}: AssetBalanceTablesWrapperProps) => {
  const gridApiRef = useGridApiRef();
  const [tableState, setTableState] = useState(initialTableState);
  const [allRows, setAllRows] = useState<AssetBalanceTableRow[]>([]);
  const [visibleRows, setVisibleRows] = useState<AssetBalanceTableRow[]>([]);
  const availableOrgs = useAvailableOrgs();
  const availableOrgsHierarchy = useMemo(
    () => getOrgsHierarchy(availableOrgs),
    [availableOrgs]
  );

  const { data, isLoading, isSuccess } = useGetAssetBalanceReportDataQuery({
    input: {
      assetTypes: parameters?.assetTypes ?? [], // In the future, we can add support for assetSubType here
      orgIds: buildWildcardStructure(
        parameters?.orgIds ?? [],
        availableOrgsHierarchy
      ),
    },
  });
  const tableData = useMemo<AssetBalanceTableData[]>(
    () => buildAssetBalanceTableData(data?.getAssetBalanceData?.data),
    [data?.getAssetBalanceData?.data]
  );

  useEffect(() => {
    const rows = getTableRows(tableData, tableState.tableViewMode);
    setAllRows(rows);
    setVisibleRows(rows.filter(({ visible }) => visible));
  }, [tableData, tableState.tableViewMode]);

  const onRowClick = useCallback(
    (row: AssetBalanceTableRow) => {
      const newRows = expandOrCollapseRow(row.id, allRows);
      setVisibleRows(newRows.filter(({ visible }) => visible));
    },
    [allRows]
  );

  const updateTableState = useCallback(
    (tableViewMode: TableViewMode) => {
      setTableState({
        columns:
          tableViewMode === "distribution-overview"
            ? distributionOverviewTableColumns
            : deltaOverviewTableColumns,
        tableViewMode,
        title: getTableTitle(tableViewMode),
      });
    },
    [setTableState]
  );

  return (
    <>
      <Box className="flex mb-9 justify-between">
        <Box className="bold !text-brand">{tableState.title}</Box>
        <Box className="w-96">
          <SwitchButtons
            id="assets-navigation-switch"
            data-testid="assets-navigation-switch-desktop"
            value={tableState.tableViewMode}
            onChange={(_event, value) => updateTableState(value)}
            options={TABLE_SWITCH_OPTIONS}
            size="small"
            groupclass="h-8 overflow-hidden !rounded-3xl p-0.5 border border-brand"
            className="!text-brand !font-medium"
            fullWidth
            exclusive
          />
        </Box>
      </Box>
      <Box>
        <Table
          apiRef={gridApiRef}
          tableHeight="570px"
          tableName={`report_asset-balance-${tableState.tableViewMode}`}
          sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
          columns={tableState.columns}
          rows={visibleRows}
          loading={isLoading}
          error={!isLoading && !isSuccess ? true : null}
          getRowId={(row) => row.id}
          showPopover={false}
          showToolbar={false}
          allowExport={false}
          enableSearch={false}
          pagination={false}
          handleDataMode={TableDataModes.Client}
          onRowClick={({ row }) => onRowClick(row)}
        />
      </Box>
    </>
  );
};
