import { useMemo } from "react";
import { useCookies } from "react-cookie";
import { SELECTED_ORGANIZATION } from "../../constants";
import { SelectedOrganization } from "../../context/reducers/selectedOrganization";
import { DEFAULT_TIMEZONE } from "../../utils";
import { useCurrentOrg } from "./useCurrentOrg";

/**
 * Returns the current selected organization.
 * Attempts to retrieve from cookies first, then resorts to userData.
 */
export const useSelectedOrg = (): SelectedOrganization => {
  const [cookies] = useCookies([SELECTED_ORGANIZATION]);
  const userOrg = useCurrentOrg();

  // Convert the user's org to match the stored org data type
  const orgFromUserData = useMemo<SelectedOrganization>(
    () => ({
      value: userOrg?._id ?? "",
      label: userOrg?.name ?? "",
      timezone: userOrg?.time_zones ?? DEFAULT_TIMEZONE,
    }),
    [userOrg]
  );

  const orgFromCookie = useMemo(
    () =>
      cookies[SELECTED_ORGANIZATION] as SelectedOrganization | undefined | null,
    [cookies]
  );

  const orgToUse = useMemo(
    () => orgFromCookie ?? orgFromUserData,
    [orgFromCookie, orgFromUserData]
  );

  return orgToUse;
};
