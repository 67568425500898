import { FC, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as AirbagSensorDark } from "../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../assets/svgs/airbagSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  UpdateOrganizationSensorProfileInput,
  SensorProfileResult,
  SensorProfileConfigType,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  airbagDefaultValues,
  extractRules,
  prepareRulesPayload,
  sliderMarks,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import {
  getConvertedPressureValue,
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, AirbagDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type AirbagDrawerProps = {
  sensorType: SensorProfileConfigType;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const AirbagDrawer: FC<AirbagDrawerProps> = ({
  sensorType,
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
  onRequestClose,
}) => {
  const pressureUnit = useGetPressureUnitPreference();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const minAirBagConverted: number = getConvertedPressureValue(
    MinValuesBySensorType.airbag,
    pressureUnit
  );
  const maxAirBagConverted: number = getConvertedPressureValue(
    MaxValuesBySensorType.airbag,
    pressureUnit
  );
  const airbagDataConverted: number[] = useMemo(() => {
    const data =
      extractRules(sensorProfileData?.configuration?.airbag?.measures) ||
      airbagDefaultValues;

    return getConvertedPressureValues(data, pressureUnit);
  }, [sensorProfileData, pressureUnit]);

  const [deleteAirbagPopupState, setDeleteAirbagProfilePopup] =
    useState<boolean>(false);
  const [airbagSliderData, setAirbagSliderData] =
    useState<number[]>(airbagDataConverted);

  const airbagMarks = sliderMarks(
    minAirBagConverted,
    maxAirBagConverted,
    getPressureUnitLabel(pressureUnit)
  );

  const isSliderDirty =
    airbagSliderData.toString() !== airbagDataConverted.toString();

  const toggleDeleteAirbagProfilePopup = () => {
    setDeleteAirbagProfilePopup(!deleteAirbagPopupState);
  };

  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === AirbagDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [isEdit, availableOrgs, sensorProfileData]);

  const { form: mainAirbagForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const handleAirbagProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Airbag Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirbagProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAirbagProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Airbag Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirbagProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAirbagProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Airbag Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleAirbagProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleAirbagProfileCreateSuccess,
    createSensorProfileOnError: handleAirbagProfileCreateError,
    updateSensorProfileOnSuccess: handleAirbagProfileUpdateSuccess,
    updateSensorProfileOnError: handleAirbagProfileUpdateError,
    deleteSensorProfileOnSuccess: handleAirbagProfileDeleteSuccess,
    deleteSensorProfileOnError: handleAirbagProfileDeleteError,
  });

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleSubmitClick = async () => {
    // Check if both forms are valid
    const isMainFormValid = await mainAirbagForm.trigger();

    if (!isMainFormValid) return;

    const allFormData: FieldValues = {
      ...mainAirbagForm.getValues(),
    };

    if (type === AirbagDrawers.Create) {
      createSensorProfile({
        name: allFormData.name,
        configuration: {
          airbag: {
            measures: {
              match: prepareRulesPayload(
                undefined,
                prepareConvertedMbarPressuresPayload(
                  airbagSliderData,
                  pressureUnit
                ),
                MinValuesBySensorType.airbag,
                MaxValuesBySensorType.airbag
              ),
            },
          },
        },
        default: allFormData.default ?? false,
        orgId: allFormData?.orgName ?? "",
      });
    } else if (type === AirbagDrawers.Edit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in mainAirbagForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = allFormData[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = allFormData[fieldUpdated];
      }
      if (isSliderDirty) {
        updateSensorProfilePayload.configuration = {
          airbag: {
            measures: {
              match: prepareRulesPayload(
                undefined,
                prepareConvertedMbarPressuresPayload(
                  airbagSliderData,
                  pressureUnit
                ),
                MinValuesBySensorType.airbag,
                MaxValuesBySensorType.airbag
              ),
            },
          },
        };
      }
      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    }
  };
  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  const text = type === AirbagDrawers.Edit ? "Edit Profile" : "Create Profile";
  return (
    <Drawer
      testId="airbag-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            <ProfilesDrawerMainForm
              form={mainAirbagForm}
              disabled={isLoading}
              isEdit={isEdit}
            />
            <Box className="px-6">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Air Bag Pressure Settings
                </Typography>
                {isLightTheme ? (
                  <AirbagSensorDark style={svgIconSettings} />
                ) : (
                  <AirbagSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={airbagSliderData}
                min={minAirBagConverted}
                max={maxAirBagConverted}
                marks={airbagMarks}
                disabled={isLoading}
                onChange={setAirbagSliderData}
              />
            </Box>
          </Box>
          <DrawerActions
            deleteBtnTestId="btn-delete-airbag-profile"
            showDeleteBtn={isEdit}
            disabled={isLoading}
            onCancel={() => onClose(false)}
            onDelete={toggleDeleteAirbagProfilePopup}
          />

          {deleteAirbagPopupState && isEdit && (
            <DeleteProfileDialog
              isOpen={deleteAirbagPopupState}
              onClose={toggleDeleteAirbagProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteProfile}
              testId="airbag"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={
          isLoadingCreateSensorProfile ||
          isLoadingUpdateSensorProfile ||
          (isEmpty(mainAirbagForm.formState.dirtyFields) && !isSliderDirty)
        }
        testId="btn-airbag-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default AirbagDrawer;
