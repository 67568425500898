import React, { createContext, useContext, useState } from "react";
import { ConfirmationDialog } from "../shared/components/ConfirmationDialog";

interface ProfilesContextProps {
  confirmPctProfileModification: () => Promise<boolean>;
}

const ProfilesContext = createContext<ProfilesContextProps | undefined>(
  undefined
);

export const ProfilesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [confirmation, setConfirmation] = useState<{
    resolve: (value: boolean) => void;
  } | null>(null);

  const confirmPctProfileModification = () => {
    return new Promise<boolean>((resolve) => {
      setConfirmation({ resolve });
    });
  };

  const handleConfirm = () => {
    if (confirmation) {
      confirmation.resolve(true);
      setConfirmation(null);
    }
  };

  const handleCancel = () => {
    if (confirmation) {
      confirmation.resolve(false);
      setConfirmation(null);
    }
  };

  return (
    <ProfilesContext.Provider value={{ confirmPctProfileModification }}>
      {children}
      {confirmation && (
        <>
          <ConfirmationDialog
            title="Change of default Phillips Connect Profile"
            message="You are about to change the default profile settings of Phillips Connect. This will effect all assets and sensor statuses without organization or custom profiles. Do you want to proceed?"
            open={true}
            confirmButtonText={"OK"}
            cancelButtonText="Cancel"
            handleConfirmationResult={(isConfirmed) => {
              isConfirmed ? handleConfirm() : handleCancel();
            }}
          />
        </>
      )}
    </ProfilesContext.Provider>
  );
};

export const useProfilesContext = () => {
  const context = useContext(ProfilesContext);
  if (!context) {
    throw new Error(
      "useProfileContext must be used within a ProfilesContextProvider"
    );
  }
  return context;
};
