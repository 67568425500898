import { useMemo, useState } from "react";
import { Marker } from "@react-google-maps/api";
import { polygon, center, Position, AllGeoJSON } from "@turf/turf";
import { getFeatureIconType } from "../../../../../../constants/map";
import { useAppContext } from "../../../../../../context/AppContext";
import { Asset, GeofenceData } from "../../../../../../graphql/operations";
import { GoogleMapComponent } from "../../../../../../shared/components/GoogleMapComponent";
import { mapAssetToMapFeature } from "../../../../../../utils";
import { getGMMarkers } from "../../../../../../utils/maps/getGMMarkers";
import GeofenceRenderer from "../../../Geofences/GeofenceRenderer/GeofenceRenderer";
import { getGMMarkerIcon } from "../../AssetsMarkers/AssetsMarkers";

const MapWidget = ({
  selectedGeofence,
  selectedAsset,
}: {
  selectedGeofence?: GeofenceData | null;
  selectedAsset?: Asset;
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const { defaultCenter } = appConfig.map;
  const geofencePolygon = selectedGeofence
    ? polygon(
        selectedGeofence?.geofence?.coordinates?.coordinates as Position[][]
      )
    : undefined;
  const geofenceCenter = selectedGeofence
    ? center(geofencePolygon as AllGeoJSON)
    : undefined;

  const assetCoordinates = selectedAsset?.location?.coordinates;

  const googleMapCenter = useMemo(() => {
    let mapCenter: google.maps.LatLngLiteral = defaultCenter;
    if (selectedAsset) {
      const assetLatitude = assetCoordinates?.[1] as number;
      const assetLongitude = assetCoordinates?.[0] as number;
      mapCenter = {
        lat: assetLatitude,
        lng: assetLongitude,
      };
    } else if (selectedGeofence) {
      const geofenceLatitude = geofenceCenter?.geometry
        .coordinates[1] as number;
      const geofenceLongitude = geofenceCenter?.geometry
        .coordinates[0] as number;
      mapCenter = {
        lat: geofenceLatitude,
        lng: geofenceLongitude,
      };
    }
    return mapCenter;
  }, [
    defaultCenter,
    assetCoordinates,
    geofenceCenter?.geometry.coordinates,
    selectedAsset,
    selectedGeofence,
  ]);

  const markerImages = getGMMarkers();

  const geoJsonFeature = selectedAsset
    ? [mapAssetToMapFeature(selectedAsset)]
    : undefined;

  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

  const handleMapLoaded = (mapInstance: google.maps.Map) => {
    if (mapInstance) {
      setGoogleMap(mapInstance);
    }
  };

  const handlePolygonLoaded = (polygon: google.maps.Polygon) => {
    if (polygon) {
      const bounds = new google.maps.LatLngBounds();
      const coordinates = polygon.getPath();
      coordinates.forEach((coord) => bounds.extend(coord));
      if (googleMap) {
        googleMap.fitBounds(bounds);
      }
    }
  };

  const handleCircleLoaded = (circle: google.maps.Circle) => {
    if (circle) {
      const bounds = circle.getBounds();
      if (bounds && googleMap) {
        googleMap.fitBounds(bounds);
      }
    }
  };

  return (
    <GoogleMapComponent
      googleMap={googleMap}
      googleMapCenter={googleMapCenter}
      handleMapLoaded={handleMapLoaded}
    >
      {geoJsonFeature?.map((feature) => {
        const iconType = getFeatureIconType(feature);
        const icon = getGMMarkerIcon(iconType, markerImages);

        return (
          <Marker
            key={feature.properties.asset_id}
            position={{
              lat: assetCoordinates?.[1] as number,
              lng: assetCoordinates?.[0] as number,
            }}
            icon={icon}
          ></Marker>
        );
      })}

      {selectedGeofence && (
        <GeofenceRenderer
          geofences={[selectedGeofence]}
          handlePolygonsLoaded={handlePolygonLoaded}
          handleCirclesLoaded={handleCircleLoaded}
          geofenceForUpdate={null}
        />
      )}
    </GoogleMapComponent>
  );
};

export default MapWidget;
