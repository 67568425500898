import React, {
  FC,
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";
import {
  FileFormat,
  ReportSubscribers,
  ReportType,
  useFindUsersQuery,
  UserData,
} from "../../../../../graphql/operations";
import { ChipsList } from "../../../../../shared/components/ChipsList";
import { roles } from "../../../../../shared/components/SubscribersSelect/constants";
import {
  DatePickerElement,
  HierarchySelectElement,
} from "../../../../../shared/components/react-hook-form-mui";
import { EmailsSelectElement } from "../../../../../shared/components/react-hook-form-mui/EmailsSelectElement";
import { FrequencySelectElement } from "../../../../../shared/components/react-hook-form-mui/FrequencySelectElement";
import { RolesSelectElement } from "../../../../../shared/components/react-hook-form-mui/RolesSelectElement";
import { useExternalChipSelectTheme } from "../../../../../shared/components/react-hook-form-mui/RolesSelectElement/useExternalChipSelectTheme";
import { SelectElement as CustomSelectElement } from "../../../../../shared/components/react-hook-form-mui/SelectElement";
import { UsersSelectElement } from "../../../../../shared/components/react-hook-form-mui/UsersSelectElement";
import {
  AssetTypeNomenclature,
  useAssetTypes,
} from "../../../../../shared/hooks/useAssetTypes";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useCurrentOrg } from "../../../../../shared/hooks/useCurrentOrg";
import { validateYear } from "../../../../../utils";
import { useExportFileTypeOptions } from "../../../../AssetsView/TableView/hooks";
import { getIsDateDisabled } from "../../../components/ReportTemplate/utils";
import { getOrgsHierarchy } from "../../../helpers/getOrgsHierarchy";
import { combineSelectedChips } from "../../../helpers/helpers";
import { ReportWithParameters, ScheduleFormFields } from "../../../interfaces";
import type { ReportSettingsFormValues } from "../../AssetReport";
import { ReportQueryBuilder } from "../ReportQueryBuilder";

export type RunReportFormProps = Omit<
  HTMLAttributes<HTMLFormElement>,
  "onChange" | "onSubmit"
> & {
  onSubmit?: (data: FieldValues) => void;
  onChange?: (value: Partial<ReportSettingsFormValues>) => void;
  scheduleForm: UseFormReturn<Partial<ScheduleFormFields>>;
  parametersForm: UseFormReturn<Partial<Record<string, any>>>;
  queryForm: UseFormReturn;
  compact?: boolean;
  report: ReportWithParameters;
  scheduledToRun?: string;
  scheduleEndDate?: string;
  handleScheduleReportSubmit: () => void;
  wasSaved: boolean;
  handleUpdateField: (value: any, field?: string) => void;
  isNextRunDateCalculating: boolean;
  isSelectOpen?: boolean;
  setIsSelectOpen?: (open: boolean) => void;
};

export const AssetReportForm: FC<RunReportFormProps> = memo(
  ({
    report,
    scheduleForm,
    parametersForm,
    scheduledToRun,
    scheduleEndDate,
    handleScheduleReportSubmit,
    handleUpdateField,
    isNextRunDateCalculating,
    queryForm,
    compact = false,
    isSelectOpen,
    setIsSelectOpen,
  }: RunReportFormProps) => {
    const chipsTheme = useExternalChipSelectTheme();
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(true);
    const isDateDisabled = getIsDateDisabled(report.type);

    const availableOrgs = useAvailableOrgs();
    const availableOrgsHierarchy = useMemo(
      () => getOrgsHierarchy(availableOrgs),
      [availableOrgs]
    );
    const watchStartDate = useWatch({
      name: "startDate",
      control: parametersForm.control,
    });
    const watchEndDate = useWatch({
      name: "endDate",
      control: parametersForm.control,
    });
    const watchEmails = useWatch({
      name: "subscribers.emails",
      control: scheduleForm.control,
    });

    const parentOrg = useCurrentOrg();
    const exportFileTypeOptions = useExportFileTypeOptions(
      report.columns.length
    );

    const { assetTypeOptionsState: assetTypeOptions } = useAssetTypes();

    // use useFindUsersQuery without pagination params to return all records(users) without limiteds
    const { data: usersData, isLoading: isDataLoading } = useFindUsersQuery({
      input: {
        customer_orgs_id: parentOrg?._id ? [parentOrg._id] : [],
        active: true,
      },
    });

    useEffect(() => {
      const currentFormat = scheduleForm.getValues("format");
      if (report.columns.length >= 20 && currentFormat === FileFormat.Pdf) {
        scheduleForm.setValue("format", null);
      }
    }, [report.columns, scheduleForm]);

    const sortedUsersList = useMemo(() => {
      if (Array.isArray(usersData?.findUsers?.data)) {
        return sortBy(usersData?.findUsers?.data, (usr) =>
          `${usr?.firstName} ${usr?.lastName}`.toLowerCase()
        ) as UserData[];
      }
      return [];
    }, [usersData]);

    const breakpoints = compact ? { xs: 12 } : { xs: 12, md: 6, lg: 3 };

    const usersOptions = useMemo(() => {
      return (
        usersData?.findUsers?.data?.map((user) => ({
          value: user?._id ?? "",
          label: `${user?.firstName} ${user?.lastName} (${user?.name})`,
        })) || []
      );
    }, [usersData]);

    const emails = watchEmails?.map((email: string) => ({
      value: email,
      label: email,
    }));

    const combinedSubscribersList = useMemo(() => {
      const users = usersOptions ?? [];
      return [...users, ...roles, ...emails];
    }, [usersOptions, emails]);

    const formValues = scheduleForm.getValues();

    const combinedSelectedChipsList = combineSelectedChips(
      formValues?.subscribers as ReportSubscribers
    );

    const handleAccordionChange = useCallback(() => {
      setIsAccordionExpanded(!isAccordionExpanded);
    }, [isAccordionExpanded, setIsAccordionExpanded]);

    const nextRunDate = isNextRunDateCalculating
      ? "Calculating"
      : scheduledToRun;

    const reportsWithoutQueryBuilder = new Set([
      ReportType.AssetBalance,
      ReportType.Accounts,
      ReportType.DwellHierarchy,
      ReportType.MediaActivities,
      ReportType.AssetDetention,
    ]);

    return (
      <>
        <Paper elevation={0} className="p-10 mb-6 !bg-accordion-bg !rounded-lg">
          <form
            autoComplete="off"
            onSubmit={undefined}
            data-testid="report-parameters-form"
          >
            <Typography className="!text-lg !font-bold !mb-8">
              Parameters
            </Typography>
            <Grid
              container
              className="!max-w-full !m-0 !text-primary !w-full !mb-8"
              rowSpacing={4}
              columnSpacing={6}
            >
              <Grid
                item
                xs={6}
                className="!pl-0"
                data-testid="asset-transfer-report-form-start-date"
              >
                <DatePickerElement
                  label="Start Date"
                  title="Start Date"
                  control={parametersForm.control}
                  name="startDate"
                  placeholder="MM/DD/YYYY"
                  disabled={isDateDisabled}
                  maxDate={watchEndDate}
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [24, 2] } },
                    ],
                  }}
                  fullWidth
                  disableHighlightToday
                  validate={validateYear}
                  handleUpdateField={handleUpdateField}
                  fieldPath="parameters.startDate"
                />
              </Grid>
              <Grid
                item
                xs={6}
                data-testid="asset-transfer-report-form-end-date"
              >
                <DatePickerElement
                  label="End Date"
                  title="End Date"
                  control={parametersForm.control}
                  name="endDate"
                  placeholder="MM/DD/YYYY"
                  disabled={isDateDisabled}
                  minDate={watchStartDate}
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [24, 2] } },
                    ],
                  }}
                  fullWidth
                  disableHighlightToday
                  validate={validateYear}
                  handleUpdateField={handleUpdateField}
                  fieldPath="parameters.endDate"
                />
              </Grid>
              {report?.type === ReportType.AssetBalance && (
                <Grid
                  item
                  className="!pl-0"
                  data-testid="asset-report-form-asset-type"
                  xs={12}
                >
                  <AutocompleteElement
                    required
                    matchId
                    label="Asset Type"
                    control={parametersForm.control}
                    name="assetTypes"
                    options={assetTypeOptions}
                    multiple
                    autocompleteProps={{
                      onChange: (_event, value: AssetTypeNomenclature[]) => {
                        handleUpdateField &&
                          handleUpdateField(
                            value.map(({ value }) => value),
                            "parameters.assetTypes"
                          );
                      },
                      isOptionEqualToValue: (option, value) =>
                        option.id === value.id,
                      renderTags: (values, getTagProps) =>
                        values.map((option, index) => (
                          <Chip
                            label={option?.label}
                            {...getTagProps({ index })}
                          />
                        )),
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                className="!pl-0"
                data-testid="asset-transfer-report-form-company"
                xs={12}
              >
                <HierarchySelectElement
                  hideValue
                  hierarchy={availableOrgsHierarchy}
                  rules={{ required: true }}
                  required
                  loading={!availableOrgsHierarchy.length}
                  label="Organization"
                  control={parametersForm.control}
                  name="orgIds"
                  form={parametersForm}
                  handleUpdateField={handleUpdateField}
                  fieldPath="parameters.orgIds"
                  isSelectOpen={isSelectOpen}
                  setIsSelectOpen={setIsSelectOpen}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
        {!reportsWithoutQueryBuilder.has(report?.type) &&
        report?.type !== ReportType.AssetInstall ? ( // hide query builder for asset install report PRJIND-10510
          <Paper
            elevation={0}
            className="p-10 mb-6 !bg-accordion-bg !rounded-lg"
          >
            <ReportQueryBuilder
              form={queryForm}
              handleUpdateField={handleUpdateField}
              fieldPath="trigger"
              reportType={report?.type}
            />
          </Paper>
        ) : (
          <></>
        )}
        <form
          autoComplete="off"
          data-testid="report-schedule-form"
          onSubmit={undefined}
        >
          <Paper
            elevation={0}
            className="p-10 mb-6 !bg-accordion-bg !rounded-lg"
          >
            <Typography className="!text-lg !font-bold !mb-14">
              Schedule
            </Typography>
            <Grid container className="!max-w-full !m-0">
              <Grid
                container
                item
                xs={12}
                columnSpacing={6}
                rowSpacing={4}
                className="items-baseline !mb-14"
              >
                <Grid
                  item
                  data-testid="schedule-report-form-frequency"
                  xs={12}
                  md={6}
                  lg={6}
                  xl={4}
                >
                  <FrequencySelectElement
                    form={scheduleForm}
                    rules={{ required: true }}
                    required
                    label="Frequency"
                    control={scheduleForm.control}
                    name="frequency"
                    handleScheduleReportSubmit={handleScheduleReportSubmit}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                  <CustomSelectElement
                    required
                    placeholder="Select"
                    name="format"
                    dataTestId="report-schedule-export-file-type"
                    label="Export File Type"
                    control={scheduleForm.control}
                    options={exportFileTypeOptions}
                    handleChange={(value: string) => {
                      handleUpdateField(value, "schedule[0].format");
                      handleScheduleReportSubmit();
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={2}>
                  <Grid item className="!mb-2">
                    <Typography className="!text-sm !font-bold">
                      Scheduled to Run
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      data-testid="schedule-startDate"
                      className="!text-base !font-normal"
                    >
                      {nextRunDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="!mb-6" item xs={12} md={6} lg={6} xl={2}>
                  <Grid item className="!mb-2">
                    <Typography className="!text-sm !font-bold">
                      End Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      data-testid="schedule-endDate"
                      className="!text-base !font-normal"
                    >
                      {scheduleEndDate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={0}
            className="p-10 mb-6 !bg-accordion-bg !rounded-lg"
          >
            <Grid container className="!max-w-full !m-0">
              <Accordion
                className="w-full"
                expanded={isAccordionExpanded}
                onChange={handleAccordionChange}
                data-testid="schedule-accordion"
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="!px-0 !py-0"
                  data-testid="schedule-accordion-summary"
                >
                  <Typography className="!text-lg !font-bold">
                    Recipients
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="!p-0 !mt-12">
                  <Grid
                    item
                    data-testid="schedule-report-form-subscribers-roles"
                    {...breakpoints}
                  >
                    <RolesSelectElement
                      form={scheduleForm}
                      loading={false}
                      label="Roles"
                      control={scheduleForm.control}
                      name="subscribers.roles"
                      disabled={
                        !report.schedule[0]?.format ||
                        !report.schedule[0]?.startDate
                      }
                      handleUpdateField={handleUpdateField}
                      fieldPath="schedule[0].subscribers.roles"
                    />
                  </Grid>
                  <Grid
                    item
                    className="!pt-6"
                    data-testid="schedule-report-form-subscribers-users"
                    {...breakpoints}
                  >
                    <UsersSelectElement
                      form={scheduleForm}
                      loading={isDataLoading}
                      label="Users"
                      control={scheduleForm.control}
                      name="subscribers.users"
                      usersData={sortedUsersList}
                      users={usersOptions ?? []}
                      disabled={
                        !report.schedule[0]?.format ||
                        !report.schedule[0]?.startDate
                      }
                      handleUpdateField={handleUpdateField}
                      fieldPath="schedule[0].subscribers.users"
                    />
                  </Grid>
                  <Grid
                    item
                    className="!pt-6"
                    data-testid="schedule-report-form-subscribers-emails"
                    {...breakpoints}
                  >
                    <EmailsSelectElement
                      loading={isDataLoading}
                      label="Emails"
                      control={scheduleForm.control}
                      name="subscribers.emails"
                      disabled={
                        !report.schedule[0]?.format ||
                        !report.schedule[0]?.startDate
                      }
                      handleUpdateField={handleUpdateField}
                      fieldPath="schedule[0].subscribers.emails"
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {!isAccordionExpanded && (
                <ThemeProvider theme={chipsTheme}>
                  <ChipsList
                    selectedChips={combinedSelectedChipsList}
                    chips={
                      combinedSubscribersList as {
                        label: string;
                        value: string;
                      }[]
                    }
                    compact={compact}
                  />
                </ThemeProvider>
              )}
            </Grid>
          </Paper>
        </form>
      </>
    );
  }
);
