import { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import {
  useGetMergedProfileForAssetQuery,
  useGetTpmsSensorHistoricalDataQuery,
} from "../../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { convertToIsoStringIgnoringTimezoneOffset } from "../../../../../../utils/date";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { Axle } from "./charts/components/tpms/TiresChartBody";
import TiresChartRow from "./charts/components/tpms/TiresChartRow";
import { useDateFilters } from "./charts/sensors/hooks/useDateFilters";

const TPMSChartsSection: React.FC = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const imei = selectedAsset?.imei ?? "";
  const assetObjectId = selectedAsset?._id as string;
  const assetCustomerOrgId = selectedAsset?.customer_orgs_id as string;

  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    useDateFilters();

  const { data: initialData, isLoading } = useGetTpmsSensorHistoricalDataQuery(
    {
      input: {
        imei,
        customerOrgId: assetCustomerOrgId,
        includeEmptyDates: true,
        startDate: convertToIsoStringIgnoringTimezoneOffset(defaultStartDate),
        endDate: convertToIsoStringIgnoringTimezoneOffset(defaultEndDate),
      },
    },
    {
      enabled: Boolean(imei),
    }
  );

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: assetObjectId },
    },
    { enabled: Boolean(assetObjectId) }
  )?.data;
  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  // Determine axles to display by taking look at how many side numbers are present
  const axles = useMemo(() => {
    const data = initialData?.searchHistoricalEventHistory?.data;
    const pressure = data?.[0]?.tpms?.pressure;
    const axles = selectedAsset?.sensors?.tpmsBeta?.data?.sensors;
    if (!pressure || !axles) return [];

    const sidesToAxles = {
      innerCurbside1: Axle.ONE,
      innerCurbside2: Axle.TWO,
      innerCurbside3: Axle.THREE,
    };

    return Object.keys(sidesToAxles)
      .slice(0, axles.length) // Only take the number of axles present
      .filter((key) => key in pressure) // Only take axles that have data, even if it's null
      .map((key) => sidesToAxles[key as keyof typeof sidesToAxles]);
  }, [initialData?.searchHistoricalEventHistory?.data, selectedAsset]);

  // Determine number of wheels to display by looking at primary/inner sides
  const numberOfWheels = useMemo(() => {
    const data = initialData?.searchHistoricalEventHistory?.data;
    const pressure = data?.[0]?.tpms?.pressure;
    if (!pressure) return 0;

    const wheelsToCheck: ("innerCurbside1" | "primaryCurbside1")[] = [
      "innerCurbside1",
      "primaryCurbside1",
    ];

    return wheelsToCheck.filter((key) => {
      const pressureData = pressure[key];
      return pressureData?.median;
    }).length;
  }, [initialData?.searchHistoricalEventHistory?.data]);

  return (
    <Box
      className="hidden lg:block mt-4 w-full"
      data-testid="asset-dashboard--tires-tab--tpms-charts-section"
    >
      {Boolean(
        !isLoading && selectedAsset?.sensors?.tpmsBeta?.data?.sensors?.length
      ) && (
        <Grid
          container
          justifyContent="center"
          rowSpacing={2}
          data-testid="tires-chart-grid"
        >
          {axles.map((axle) => (
            <Grid
              item
              lg={12}
              key={axle}
              data-testid={`tires-chart-row-${axle}`}
            >
              <TiresChartRow
                axle={axle}
                imei={imei}
                customerOrgId={assetCustomerOrgId}
                numberOfAxles={axles.length}
                numberOfWheels={numberOfWheels}
                sensorProfile={mergedSensorProfile}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TPMSChartsSection;
