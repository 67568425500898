import { PAGE_SNACKBAR } from "../../../constants";
import { DEVICE_EVENT_LOAD_FAILED_PAYLOAD } from "../../../constants/device";
import { useAppContext } from "../../../context/AppContext";
import { useFindDeviceEventsQuery } from "../../../graphql/operations";

interface DeviceHistoryModelProps {
  device_id: string | undefined;
}

export const useDeviceHistoryModel = ({
  device_id,
}: DeviceHistoryModelProps) => {
  const { dispatch } = useAppContext();

  const {
    data: findDeviceEvents,
    isSuccess,
    isLoading,
  } = useFindDeviceEventsQuery(
    { input: { device_id: device_id ?? "" } },
    {
      enabled: Boolean(device_id),
      cacheTime: 0,
      onError: (error: Error) => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: DEVICE_EVENT_LOAD_FAILED_PAYLOAD,
        });
      },
    }
  );
  return {
    findDeviceEvents,
    isSuccess,
    isLoading,
  };
};
