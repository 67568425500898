import { FC } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { nanoid } from "nanoid";
import { useAppContext } from "../../../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import { DAY_MONTH_FORMAT, formatDate } from "../../../../../../../utils";
import { HOURS_MAP } from "../constants";
import {
  CargoCameraSpaceData,
  InternalCameraSpaceStatus,
  InternalCameraGraphType,
} from "../types";
import GraphChip from "./GraphChip";

export interface BodyProps {
  graphType: InternalCameraGraphType;
  data: CargoCameraSpaceData[];
}

const Body: FC<BodyProps> = ({ graphType, data }) => {
  const {
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === "light";

  const GRAPH_LEGEND: Array<{
    key: InternalCameraSpaceStatus;
    label: string;
    color: string;
  }> = [
    {
      key: InternalCameraSpaceStatus.Loaded,
      label: "Loaded",
      color: "var(--brand-light-blue)",
    },
    {
      key: InternalCameraSpaceStatus.Empty,
      label: "Empty",
      color: isLightTheme ? "var(--off-white)" : "var(--border)",
    },
    {
      key: InternalCameraSpaceStatus.Unknown,
      label: "Unknown",
      color: isLightTheme ? "var(--border-color)" : "var(--dark-grey)",
    },
  ];

  return (
    <>
      <Box
        className="flex font-medium text-[10px] overflow-auto"
        data-testid={`camera-${graphType}-space-trending-graph-wrapper`}
      >
        <Box
          className="flex flex-col mr-2 pt-1 text-card-sensors-text"
          data-testid={`camera-${graphType}-space-trending-graph-hour-list`}
        >
          {HOURS_MAP.map((hour) => (
            <Box
              key={nanoid()}
              className="h-[36px] mb-2 flex items-center justify-end"
            >
              {hour}
            </Box>
          ))}
        </Box>

        <Box
          className="flex h-full w-full"
          data-testid={`camera-${graphType}-space-trending-graph-content`}
        >
          {data.map((day) => (
            <Box className="w-full" key={nanoid()}>
              {!!day?.eventHistoryData?.cargoCamera?.cargoCameraStats &&
                day.eventHistoryData.cargoCamera.cargoCameraStats
                  .slice()
                  .sort((statA, statB) =>
                    (statA?.hour ?? 0) > (statB?.hour ?? 0) ? -1 : 1
                  )
                  .map((item) => (
                    <GraphChip
                      graphType={graphType}
                      key={nanoid()}
                      item={item!}
                      isPhotoTaken={!!item?.imageUrl}
                    />
                  ))}

              <Box
                className="mt-4 text-center text-card-sensors-text"
                data-testid={`camera-${graphType}-space-trending-graph-day`}
              >
                {formatDate(day?.eventHistoryData?.date, DAY_MONTH_FORMAT)}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        className="mt-8 flex justify-center items-center"
        data-testid={`camera-${graphType}-space-trending-graph-legend`}
      >
        {GRAPH_LEGEND.map((item) => (
          <Chip
            key={item.key}
            label={item.label}
            icon={
              <Circle
                sx={{
                  width: "16px",
                  height: "16px",
                  fill: item.color,
                  borderRadius: "50%",
                }}
              />
            }
            sx={{
              height: "1.5rem",
              color: ColorsPalette.BrightWhite,
              borderRadius: "9999px",
              marginBottom: "0.5rem",
              marginRight: "0.5rem",
              backgroundColor: "var(--grayscale-feather-gray)",
              "& .MuiChip-label": {
                color: "var(--mid-charcoal)",
              },
            }}
          />
        ))}
      </Box>
    </>
  );
};

export default Body;
