import {
  AssetDetentionTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import Status from "../../../../../shared/components/Status";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ColumnForAssetDetentionTableDataFields } from "../../Geofences/GeofenceDwellHistory/columns";

export const columnVisibilityModel: Record<
  keyof Pick<
    AssetDetentionTableData,
    | "geofenceName"
    | "freeDaysStart"
    | "freeDaysEnd"
    | "detentionEnd"
    | "totalDaysInDetention"
    | "detentionCharges"
    | "detentionStatus"
  >,
  boolean
> = {
  geofenceName: true,
  freeDaysStart: true,
  freeDaysEnd: true,
  detentionEnd: true,
  totalDaysInDetention: true,
  detentionCharges: true,
  detentionStatus: true,
};

export const EVENT_HISTORY_DEFAULT_SORT_COLUMN = "eventDate";
export const EVENT_HISTORY_PAGE_SIZE = 100;

const dateColumnOptions = (
  timezone: string
): TableColumnProps<AssetDetentionTableData>["options"] => ({
  ...columnTimezoneDateTimeFormatParams(timezone),
  filterable: false,
});

export const getColumns = (
  timezone: string,
  isDarkTheme: boolean
): TableGridColDef<AssetDetentionTableData>[] => {
  const columns: ColumnForAssetDetentionTableDataFields[] = [
    {
      field: "geofenceName",
      headerName: "Geofence",
      type: "string",
    },
    {
      field: "freeDaysStart",
      headerName: "Free Days Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateColumnOptions(timezone),
    },
    {
      field: "freeDaysEnd",
      headerName: "Detention Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateColumnOptions(timezone),
    },
    {
      field: "detentionEnd",
      headerName: "Detention End",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateColumnOptions(timezone),
    },
    {
      field: "totalDaysInDetention",
      headerName: "Total Days In Detention",
      type: "number",
    },
    {
      field: "detentionCharges",
      headerName: "Charges and Details ($)",
      type: "number",
    },
    {
      field: "detentionStatus",
      headerName: "Status",
      type: "string",
      options: {
        renderCell: (params) =>
          getDetentionStatus(params.value as string, isDarkTheme),
      },
    },
  ];

  return columns.map(getTableColumn);
};

export const getDetentionStatus = (status: string, isDarkTheme: boolean) => {
  const maxWidth: number = 103;
  const bigTextMaxWidth: number = 130;
  switch (status) {
    case "In detention":
      return (
        <Status
          bgColor="bg-green"
          iconColor=""
          text="In detention"
          maxWidth={maxWidth}
        />
      );
    case "Out of detention":
      return (
        <Status
          bgColor={`${isDarkTheme ? "bg-light-charcoal" : "bg-concrete"}`}
          textColor={`${isDarkTheme ? "text-white" : "var(--primary)"}`}
          iconColor=""
          text="Out of detention"
          maxWidth={bigTextMaxWidth}
        />
      );
    case "Free days":
      return (
        <Status
          bgColor={`${isDarkTheme ? "bg-light-charcoal" : "bg-concrete"}`}
          iconColor=""
          textColor={`${isDarkTheme ? "text-white" : "var(--primary)"}`}
          text="Free days"
          maxWidth={maxWidth}
        />
      );
    default:
      return <></>;
  }
};
