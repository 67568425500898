import CircleIcon from "@mui/icons-material/Circle";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import {
  AssetWithSensors,
  SensorProfileType,
  TableColumnFormat,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";
import type { TableGridColDef } from "../../../../../../shared/components/Table/types";
import { getTableColumn } from "../../../../../../shared/components/Table/utils";
import TableHeader from "../../../../../../shared/components/TableHeader/TableHeader";
import { createField } from "../../../../../../shared/helpers/utils";
import { tableStyleMap } from "../../../Profiles/profileUtils";
import { tableSensorStatusColorsMapping } from "../../sensorsUtils";

export enum PsiWheelEndState {
  Healthy = "Healthy Level",
  Warning = "Warning Level",
  Alert = "Alert Level",
  Critical = "Critical Level",
  Unknown = "Unknown",
}

const createFieldWithOptions = (
  field: string,
  label: string,
  options: Record<string, any> = {}
) => ({
  ...createField(field, label, { flex: 1, minWidth: 120, ...options }),
});

export const sensorsPsiWheelEndTableColumns: TableGridColDef<
  AssetWithSensors & { id: string }
>[] = [
  {
    ...createField("sensors.psiWheelEnd.statusPsiWheelEnd", "Status", {
      minWidth: 64,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CircleIcon
            sx={{
              color:
                tableSensorStatusColorsMapping[
                  params.value as SensorStatusUppercase
                ],
            }}
          />
        );
      },
    }),
  },
  createFieldWithOptions("asset_id", "Asset ID"),
  createFieldWithOptions("imei", "Device ID"),
  createFieldWithOptions("prd_cde", "Product Name"),
  {
    ...createField("sensorProfile.configuration.psiWheelEnd", "Profile", {
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.value?.type === SensorProfileType.Asset) {
          return "Custom";
        } else {
          return params?.value?.profileName;
        }
      },
    }),
  },
  {
    ...createField("psiWheelEnd.healthy", "Healthy Level", {
      renderHeader: () => {
        return <TableHeader text={PsiWheelEndState.Healthy} />;
      },
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.value?.healthyFrom} - ${params.value?.healthyTo}`;
      },
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("psiWheelEnd.warning", "Warning Level", {
      renderHeader: () => <TableHeader text={PsiWheelEndState.Warning} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("psiWheelEnd.alert", "Alert Level", {
      renderHeader: () => <TableHeader text={PsiWheelEndState.Alert} />,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      filterable: false,
      sortable: false,
    }),
  },
  {
    ...createField("psiWheelEnd.critical", "Critical Level", {
      renderHeader: () => <TableHeader text={PsiWheelEndState.Critical} />,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      filterable: false,
      sortable: false,
    }),
  },
].map(({ field, headerName, ...rest }) => {
  return getTableColumn({
    field: field ?? "",
    headerName: headerName ?? "",
    type: "string",
    format: TableColumnFormat.String,
    options: {
      ...rest,
    },
  });
});
