import { FC, useEffect, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  DashboardWidgetOrganizationsPerTypeData,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface DashboardWidgetOrganizationsPerType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetOrganizationsPerTypeData;
}

export interface OrganizationsPerTypeWidgetProps {
  widget: DashboardWidgetOrganizationsPerType;
}

export const mapWidgetData = (
  data: DashboardWidgetOrganizationsPerTypeData
): HorizontalBarChartData[] => {
  if (!data || !Array.isArray(data?.types)) {
    return [];
  }

  return data.types.map((orgType, index) => ({
    name: orgType?.type ?? `Unknown ${index + 1}`,
    value: orgType?.subOrgCount as number,
    color: mapChartColors(index),
  }));
};

export const sortWidgetData = (
  data: HorizontalBarChartData[]
): HorizontalBarChartData[] => {
  const desiredDataOrder = ["Fleet", "Retail", "Factory"];

  const sortedData =
    data?.sort(
      (a, b) =>
        desiredDataOrder.indexOf(a?.name) - desiredDataOrder.indexOf(b?.name)
    ) ?? [];

  return sortedData;
};

export const mapChartColors = (index: number): string => {
  const colors = [
    "primary-blue",
    "rechartsGreen",
    "rechartsPurple",
    "rechartsCoral",
    "rechartsMarineBlue",
    "rechartsTeal",
    "rechartsViolet",
    "rechartsGold",
    "rechartsRed",
    "rechartsOrange",
  ];

  return `var(--${colors[index]})`;
};

export const OrganizationsPerTypeWidget: FC<
  OrganizationsPerTypeWidgetProps
> = ({ widget }) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetOrganizationsPerTypeData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = sortWidgetData(mapWidgetData(parsedData));
    const total = parsedData?.totalCount ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--organizations-per-type"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the organizations per
          type widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          showTotal={true}
          isLoading={isLoading}
          subtitle="Number of Suborganizations"
          totalRecords={total}
          data={data}
          showXAxis={false}
          showPercentages={true}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
