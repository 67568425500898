import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { isArray, property, uniqBy } from "lodash";
import { LastReportedDateOptions } from "../../../../../../constants/map";
import { useAppContext } from "../../../../../../context/AppContext";
import { AssetType, SensorStatus } from "../../../../../../graphql/operations";
import { SwitchButtons } from "../../../../../../shared/components/SwitchButtons";
import ToggleButtons, {
  ToggleButtonOption,
} from "../../../../../../shared/components/ToggleButtons";
import { useGetAssetsManufacturers } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsManufacturers";
import { useGetAssetsTags } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsTags";
import { useCurrentTheme } from "../../../../../../shared/hooks/theme/useCurrentTheme";
import { useAssetTypes } from "../../../../../../shared/hooks/useAssetTypes";
import { useGetAssetsSharedGroupNamesOS } from "../../../../../../shared/hooks/useGetSharedGroupNames";
import { useProductsList } from "../../../../../../shared/hooks/useProductsList";
import { useRegionsList } from "../../../../../../shared/hooks/useRegionsList";
import { ZonesFilter } from "../../../../MapView/Shared/ZonesFilter/ZonesFilter";
import { useDropdownOptions } from "../../../../MapView/hooks/useDropdownOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../TableView/hooks";
import {
  AssetRegionsFilter,
  useAssetsDataContext,
} from "../../../AssetsDataContext";
import AssetYearSlider from "./components/sliders/AssetYearSlider";
import TotalMileageSlider from "./components/sliders/TotalMileageSlider";
import WeightStatusSlider from "./components/sliders/WeightStatusSlider";
import { ASSET_DETENTION_STATE_OPTIONS } from "./utils";

export type SelectedValueType =
  | ToggleButtonOption["value"]
  | Array<ToggleButtonOption["value"]>
  | undefined;

const ASSET_FILTERS_TYPES = [
  AssetType.Trailer,
  AssetType.Chassis,
  AssetType.Container,
  AssetType.Other,
];
export enum AssetSharedType {
  SharedWith = "sharedWith",
  SharedBy = "sharedBy",
  Both = "sharedBoth",
}

const SUB_ORGS_OPTIONS: ToggleButtonOption[] = [
  {
    value: true,
    label: "Include",
    isFalsy: false,
  },
  {
    value: false,
    label: "Exclude",
    isFalsy: true,
  },
];

const ASSET_HEALTH_OPTIONS = [
  {
    label: SensorStatus.Healthy,
    value: SensorStatus.Healthy,
    style: "var(--success)",
  },
  {
    label: SensorStatus.Warning,
    value: SensorStatus.Warning,
    style: "var(--caution)",
  },
  {
    label: SensorStatus.Alert,
    value: SensorStatus.Alert,
    style: "var(--warning)",
  },
  {
    label: SensorStatus.Critical,
    value: SensorStatus.Critical,
    style: "var(--error)",
  },
];

const PRODUCT_CODE_REGEX = new RegExp("\\s\\([^)]+\\)$");

const INITIAL_SHARE_TYPE = {
  sharedWith: false,
  sharedBy: false,
};

const AssetFilters: React.FC = () => {
  const {
    currentFilter: {
      assetTags,
      assetTypes,
      productNames,
      assetHealth,
      numberOfAxles,
      numberOfTires,
      manufacturers,
      assetDetentionState = [],
      regions,
      lastReportedDateRange,
      subOrganizations,
      sharedGroupName,
      selectedShareType,
    },
    onChangeFilters,
  } = useAssetsDataContext();

  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const muiTheme = useCurrentTheme();

  const assetNumberOfAxlesOptions = useNomenclatures(
    NOMENCLATURE_NAMES.assetNumberOfAxles
  );
  const assetNumberOfTiresOptions = useNomenclatures(
    NOMENCLATURE_NAMES.assetWheels
  );

  const { isFetchingNomenclatures, assetTypeOptionsState: assetTypeOptions } =
    useAssetTypes(ASSET_FILTERS_TYPES);

  const isLoading = isFetchingNomenclatures > 0;

  const { tags: tagsDropdownOptions } = useGetAssetsTags({
    orgId: selectedOrganization?.value,
    limit: 100,
    skip: 0,
  });

  const { assetManufacturers } = useGetAssetsManufacturers({
    limit: 100,
    skip: 0,
  });

  const { regions: regionsData, isLoading: isRegionsLoading } =
    useRegionsList();
  const regionOptions =
    regionsData
      ?.map((region) => ({
        country: region?.country ?? "",
        label: region?.name,
        value: region?.value,
      }))
      .sort((reg1, reg2) => reg1.country.localeCompare(reg2.country)) ?? [];

  /** 
   fetches only the first value from the artificial list
  * Current implementation works with artificial array 
  * Will be adjusted after new filter logic supports single formFieldOptions with label & value
  */
  const selectedRegion = useMemo(
    () => regions?.[0] ?? { label: "", value: [] },
    [regions]
  );

  const assetTagsSorted = uniqBy(assetTags, (tag) =>
    tag.trim().toLowerCase()
  ).sort((a, b) => a.localeCompare(b));
  const { products, isLoading: isLoadingProducts } = useProductsList(true);
  const productsDropdownOptions = useDropdownOptions(
    products,
    "_id",
    "product_name"
  ).sort((a, b) => a.label.localeCompare(b.label));

  const shareGroupNamesOptions = useGetAssetsSharedGroupNamesOS({
    orgId: selectedOrganization?.value,
    limit: 100,
    skip: 0,
  })?.data;

  const shareGroupNamesOptionsWithLabels =
    shareGroupNamesOptions?.map((item: { value: string; type: string }) => {
      return { ...item, label: item.value };
    }) ?? [];

  const onChangeType = useCallback(
    (value: SelectedValueType) =>
      onChangeFilters({ assetTypes: (value ?? []) as string[] }),
    [onChangeFilters]
  );

  const onChangeHealth = (value: SelectedValueType) => {
    onChangeFilters({
      assetHealth: (isArray(value) ? value : [value]) as SensorStatus[],
    });
  };

  const [shareTypeStatuses, setShareTypeStatuses] = useState<{
    sharedWith: boolean;
    sharedBy: boolean;
  }>(INITIAL_SHARE_TYPE);

  useEffect(() => {
    const newState = Object.keys(INITIAL_SHARE_TYPE).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          selectedShareType?.includes(key) ||
          selectedShareType?.includes(AssetSharedType.Both),
      }),
      INITIAL_SHARE_TYPE
    );

    setShareTypeStatuses(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShareType]);

  const handleShareTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    const newState = {
      ...shareTypeStatuses,
      [value as keyof typeof shareTypeStatuses]: checked,
    };

    setShareTypeStatuses(newState);
    const shareTypeStatusesValue =
      newState.sharedBy && newState.sharedWith
        ? AssetSharedType.Both
        : newState?.sharedBy
        ? AssetSharedType.SharedBy
        : newState?.sharedWith
        ? AssetSharedType.SharedWith
        : undefined;
    onChangeFilters({
      selectedShareType: shareTypeStatusesValue,
    });
  };
  const isDarkMode = useMemo(
    () => muiTheme.palette.mode === "dark",
    [muiTheme.palette.mode]
  );
  const color = useMemo(() => (isDarkMode ? "white" : "black"), [isDarkMode]);

  return (
    <Box className="w-full">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Assets</Box>
      </Box>

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Types</Box>
        {isLoading ? (
          <Box className="flex justify-center">
            <CircularProgress />
          </Box>
        ) : (
          <ToggleButtons
            id="assets-filter__asset-types"
            options={assetTypeOptions}
            value={assetTypes}
            onChange={onChangeType}
            outlined
            parentStyle="grid grid-cols-4 gap-2"
            buttonStyle="!h-[40px] !rounded-[20px] !text-[12px] !font-medium !capitalize !border-2"
            customColor={color}
          />
        )}
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Tags</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__asset-tags"
          limitTags={3}
          multiple
          options={tagsDropdownOptions}
          size="small"
          value={assetTagsSorted}
          onChange={(_, value) => onChangeFilters({ assetTags: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Tags"
              data-testid="asset-tags-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Product Name</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__product-name"
          limitTags={3}
          multiple
          loading={isLoadingProducts}
          disabled={isLoadingProducts}
          options={productsDropdownOptions}
          size="small"
          value={productNames}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => {
            onChangeFilters({
              productNames: value.map(({ label, value }) => ({
                value,
                label: label.replace(PRODUCT_CODE_REGEX, ""),
              })),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Product Name"
              data-testid="products-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="mt-4 mb-2 flex flex-col ">
        <Box
          className="font-bold text-[10px] leading-4"
          data-testid="assets-filter__shared-assets-filter"
        >
          Shared Assets
        </Box>
        {[
          { label: "Shared With", value: AssetSharedType.SharedWith },
          { label: "Shared By", value: AssetSharedType.SharedBy },
        ].map(({ label, value }) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={
                  shareTypeStatuses[value as keyof typeof shareTypeStatuses]
                }
                onChange={handleShareTypeChange}
                name={label}
                value={value}
              />
            }
            label={label}
            data-testid={`share-type-filter-${label}`}
          />
        ))}
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Shared Group Name</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__shared-group-name"
          value={sharedGroupName}
          options={shareGroupNamesOptionsWithLabels}
          size="small"
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, input) => {
            if (input) {
              const { value, label, type } = input;
              onChangeFilters({
                sharedGroupName: {
                  value,
                  label: label.replace(PRODUCT_CODE_REGEX, ""),
                  type: type,
                },
              });
            } else {
              onChangeFilters({ sharedGroupName: null });
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Search Shared Group Name"
                data-testid="share-group-name-input"
              />
            );
          }}
        />
      </Box>

      <Divider />

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Asset Health</Box>

        <ToggleButtons
          id="assets-filter__asset-health"
          options={ASSET_HEALTH_OPTIONS}
          value={assetHealth}
          onChange={onChangeHealth}
          outlined
          parentStyle="grid grid-cols-4 gap-2"
          buttonStyle="!h-[40px] !rounded-[20px] !text-[12px] !font-medium !capitalize !border-2"
          customColor={color}
        />
      </Box>

      <Divider />

      <Box className="h-20 my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Sub-Organizations</Box>

        <SwitchButtons
          id="assets-navigation-switch"
          data-testid="assets-navigation-switch"
          value={subOrganizations}
          onChange={(_, value) =>
            value !== null && onChangeFilters({ subOrganizations: value })
          }
          options={SUB_ORGS_OPTIONS}
          size="small"
          groupclass={`flex justify-between h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 !border-${color}`}
          className={`!text-${color}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Number of Axles</Box>
        <Autocomplete
          data-testid="assets-filter__number-of-axles"
          limitTags={3}
          multiple
          options={assetNumberOfAxlesOptions}
          size="small"
          value={numberOfAxles}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => onChangeFilters({ numberOfAxles: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="number-of-axles-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Number of Tires</Box>
        <Autocomplete
          data-testid="assets-filter__number-of-tires"
          multiple
          options={assetNumberOfTiresOptions}
          size="small"
          value={numberOfTires}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) => onChangeFilters({ numberOfTires: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="number-of-tires-input"
            />
          )}
        />
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Weight Status
        </Box>
        <Box className="w-[90%]">
          <WeightStatusSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Total Mileage
        </Box>
        <Box className="w-[90%]">
          <TotalMileageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Asset Year
        </Box>
        <Box className="w-[90%]">
          <AssetYearSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Manufacturer</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__manufacturer"
          multiple
          options={assetManufacturers}
          size="small"
          value={manufacturers}
          onChange={(_, value) => onChangeFilters({ manufacturers: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="manufacturer-input"
            />
          )}
        />
      </Box>

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Detention State</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__detention-state"
          multiple
          options={ASSET_DETENTION_STATE_OPTIONS}
          size="small"
          value={assetDetentionState}
          onChange={(_, value) =>
            onChangeFilters({ assetDetentionState: value })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="asset-detention-state-input"
            />
          )}
        />
      </Box>

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Regions</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="assets-filter__regions"
          groupBy={property("country")}
          options={regionOptions}
          disabled={isRegionsLoading}
          loading={isRegionsLoading}
          size="small"
          value={selectedRegion as any}
          isOptionEqualToValue={(o, v) => o?.value === v?.value}
          onChange={(_, value) => {
            // sent as list due to current filters logic
            onChangeFilters({
              regions: (value ? [value] : []) as AssetRegionsFilter[],
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search" data-testid="regions-input" />
          )}
        />
      </Box>

      <ZonesFilter type="assets" />

      <Box className="h-[60px] my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Last Reported</Box>
        <Autocomplete
          data-testid="assets-filter__last-reported-date"
          options={Object.values(LastReportedDateOptions)}
          size="small"
          value={lastReportedDateRange}
          onChange={(_, value) =>
            onChangeFilters({ lastReportedDateRange: value })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              data-testid="last-reported-date-input"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default memo(AssetFilters);
