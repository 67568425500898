import { useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import {
  PAGE_SNACKBAR,
  ROOT_ORGANIZATION_NAME,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { TextButton } from "../../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../../shared/components/ConfirmationDialog";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { UnableToDeleteDialog } from "../../../components/UnableToDeleteDialog/UnableToDeleteDialog";
import { useConfigurationSetsApi } from "../../../hooks/useConfigurationSetsApi";
import { ERROR_ARCHIVING_CONFIGURATION_SET_IS_USED_IN_ALERT } from "../../Configurations/configurationsUtils";
import { useGetRegionsData } from "../hooks/useGetRegionsData";
import { countryToDisplayName, RegionCountry } from "../interfaces";
import { CreateEditRegionDialog } from "./CreateEditRegionDialog";
import { Region } from "./Region";

export interface RegionsSectionProps {
  country: RegionCountry;
}

export const RegionsSection: React.FC<RegionsSectionProps> = ({ country }) => {
  // Hooks
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();
  const isMobile = useBreakpoint("down", "sm");
  const isDarkMode = useCurrentTheme().palette.mode === "dark";
  const { regions, isLoading } = useGetRegionsData();
  const selectedOrg = useSelectedOrg();
  const isPhillipsConnectSelectedOrg =
    selectedOrg.label === ROOT_ORGANIZATION_NAME;

  // States
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isRestoreDefaultDialogOpen, setIsRestoreDefaultDialogOpen] =
    useState<boolean>(false);
  const [isUnableToDeleteDialogOpen, setIsUnableToDeleteDialogOpen] =
    useState<boolean>(false);

  // API
  const restoreDefaultRegionsOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Default Regions applied!",
        text: " Default Regions are applied successfully.",
      },
    });

    setIsRestoreDefaultDialogOpen(false);
  };

  const restoreDefaultRegionsOnError = (error: Error) => {
    if (error.message === ERROR_ARCHIVING_CONFIGURATION_SET_IS_USED_IN_ALERT) {
      setIsUnableToDeleteDialogOpen(true);
    } else {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          severity: "error",
          title: "Failed to apply default Regions!",
          text: "An error occurred while applying your default Regions.",
        },
      });
    }

    setIsRestoreDefaultDialogOpen(false);
  };

  const {
    isLoadingDeleteManyConfigurationSets: isLoadingRestoreDefaultRegions,
    deleteManyConfigurationSets: restoreDefaultRegions,
  } = useConfigurationSetsApi({
    deleteManyConfigurationSetsOnSuccess: restoreDefaultRegionsOnSuccess,
    deleteManyConfigurationSetsOnError: restoreDefaultRegionsOnError,
  });

  // Handlers
  const handleRestoreDefaultRegions = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setIsRestoreDefaultDialogOpen(false);

      return;
    }

    const entityIds = regions[country].data.map((region) => region.id);
    restoreDefaultRegions({ entityIds, isPhillipsConnectSelectedOrg });
  };

  // Variables
  const regionGroupData = useMemo(() => regions[country], [regions, country]);
  const iconStyles = { width: "24px", height: "24px", color: "var(--brand)" };

  // For non-PCT users we want to disable/enable the button accordingly
  const shouldDisableRestoreDefaultsButton =
    regionGroupData.isDefault || isEmpty(regionGroupData?.data); // Empty = no defaults + no custom (still makes sense to disable)

  return (
    <Paper
      data-testid="regions-section"
      className="!rounded-lg !border-solid !border-sub-header-border"
      sx={{
        boxShadow: "0px 2px 4px 0px var(--box-shadow)",
        border: "1px solid #DFDFDF",
        overflow: "hidden",
      }}
    >
      <Accordion>
        <AccordionSummary
          sx={{
            backgroundColor: isDarkMode
              ? ColorsPalette.FlatBlack
              : "var(--card-bg)",
            margin: 0,
            padding: "0 1rem",
          }}
        >
          {countryToDisplayName[country]}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "0 0 1rem 0",
            backgroundColor: isDarkMode
              ? ColorsPalette.FlatBlack
              : "var(--card-bg)",
          }}
        >
          <Box
            data-testid="region-section--buttons"
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingX: "1rem",
              gap: "2rem",
            }}
          >
            <TextButton
              text="Restore Default Regions"
              icon={<RestoreIcon sx={iconStyles} />}
              iconPosition="left"
              disabled={shouldDisableRestoreDefaultsButton}
              onClick={() => setIsRestoreDefaultDialogOpen(true)}
              className="!text-brand !text-base !font-bold"
              data-testid="restore-default-regions-button"
            />
            <TextButton
              text="Add New Region"
              icon={<AddIcon sx={iconStyles} />}
              iconPosition="left"
              onClick={() => setIsCreateDialogOpen(true)}
              className="!text-brand !text-base !font-bold"
              data-testid="add-new-region-button"
            />
          </Box>

          <Box
            sx={{
              backgroundColor: isDarkMode
                ? ColorsPalette.FlatBlack
                : "var(--card-bg)",
              padding: "0 1rem",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: isDarkMode
                  ? ColorsPalette.FlatBlack
                  : "var(--card-bg)",
                gap: "1rem",
              }}
            >
              {isLoading ? (
                <Box
                  className="flex h-full w-full items-center justify-center"
                  data-testid={`region-section-loader-${country}`}
                >
                  <CircularProgress />
                </Box>
              ) : (
                regionGroupData.data.map((region) => (
                  <Region
                    key={region.id}
                    regionData={region}
                    isDefault={regionGroupData.isDefault}
                  />
                ))
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Restore Default Regions Confirmation Dialog */}
      <ConfirmationDialog
        open={isRestoreDefaultDialogOpen}
        isLoading={isLoadingRestoreDefaultRegions}
        handleConfirmationResult={handleRestoreDefaultRegions}
        title="Are You Sure You Want To Restore The Default Regions?"
        message="This action will revert any customizations made to the regions back to their default settings."
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
      />

      {/* Create Region Dialog */}
      <CreateEditRegionDialog
        open={isCreateDialogOpen}
        country={country}
        onClose={() => setIsCreateDialogOpen(false)}
      />

      <UnableToDeleteDialog
        open={isUnableToDeleteDialogOpen}
        onConfirm={() => setIsUnableToDeleteDialogOpen(false)}
        type="region"
      />
    </Paper>
  );
};
