import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import {
  AssetFilters,
  useAssetsDataContext,
} from "../../../../../AssetsDataContext";

const MARKS = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export type InternalCameraPercentageFilterProps = {
  filterKey: Extract<
    keyof AssetFilters,
    "internalCameraFloorUsagePercentage" | "internalCameraCubeUsagePercentage"
  >;
};

const InternalCameraPercentageFilter: FC<
  InternalCameraPercentageFilterProps
> = ({ filterKey }) => {
  const { onChangeFilters, currentFilter } = useAssetsDataContext();
  const currentSensor = currentFilter[filterKey];
  const min = 0;
  const max = 100;
  const [rangeValues, setRangeValues] = useState<number[]>([
    currentSensor?.minPercentage ?? min,
    currentSensor?.maxPercentage ?? max,
  ]);

  useEffect(() => {
    const incomingValue = [
      currentSensor?.minPercentage ?? min,
      currentSensor?.maxPercentage ?? max,
    ];
    if (
      incomingValue[0] !== rangeValues[0] ||
      incomingValue[1] !== rangeValues[1]
    ) {
      setRangeValues(incomingValue);
    }
    // We only want to trigger this effect when the value in store is different than our initial value
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentSensor, filterKey]);

  const handleChange = useCallback(
    (updatedValues: number | number[]) => {
      if (Array.isArray(updatedValues)) {
        onChangeFilters({
          [filterKey]: {
            minPercentage: updatedValues[0],
            maxPercentage: updatedValues[1],
          },
        });
      }
    },
    [onChangeFilters, filterKey]
  );

  return (
    <Box className="flex items-center justify-between h-8 text-white py-[5px] rounded-[4px]">
      <Box className={`flex items-center grow `}>
        <RangeSlider
          data-testid={`${filterKey}-range-slider`}
          min={min}
          max={max}
          step={1}
          values={rangeValues}
          marks={MARKS}
          defaultValues={[min, max]}
          setValues={setRangeValues}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default InternalCameraPercentageFilter;
