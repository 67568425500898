import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";
import { DrawingManager, GoogleMap } from "@react-google-maps/api";
import { useQueryClient } from "@tanstack/react-query";
import bboxPolygon from "@turf/bbox-polygon";
import { BBox } from "@turf/helpers";
import debounce from "lodash/debounce";
import {
  CENTER_FOCUS_ICON,
  getGeofenceRenderOptions,
} from "../../../constants/map";
import { useAppContext } from "../../../context/AppContext";
import {
  GeofenceData,
  GeofenceGeometry,
  GeofenceShape,
} from "../../../graphql/operations";
import Spinner from "../../../shared/components/Spinner";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../shared/hooks/useCurrentOrg";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { getGMMarkers } from "../../../utils/maps/getGMMarkers";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useFeatureFlag } from "../../../utils/useFeatureFlag";
import { PREDEFINED_MAP_VIEWPORT } from "../helpers/helpers";
import {
  Feature,
  PageTypes,
  useAssetsDataContext,
  initialFilterState,
} from "../shared/AssetsDataContext";
import { MapLegend, MapViewSwitcher } from "../shared/AssetsFilterControls";
import AssetsMarkers from "./Assets/AssetsMarkers/AssetsMarkers";
import { AddGeofenceDrawer } from "./Geofences/GeofenceDrawer/AddGeofenceDrawer";
import {
  calculateCircleArea,
  calculateCircleCenterAndRadius,
  calculatePolygonArea,
  CircleProperties,
  convertCircleToGeoJSONPolygon,
  convertGeofenceAreaUnit,
  convertGeofenceGeometryToLatLngArray,
  convertPolygonToGeoJSONFeature,
} from "./Geofences/GeofenceRenderer/GeofenceGeoJSONHelper";
import GeofenceRenderer from "./Geofences/GeofenceRenderer/GeofenceRenderer";
import { useGeocodeFromAddress } from "./MapComponent.queries";
import useGoogleMapFullscreen from "./hooks/useGoogleMapFullscreen";
import useGoogleMapStyle from "./hooks/useGoogleMapStyle";
import useGoogleMapViewChange from "./hooks/useGoogleMapViewChange";
import useGoogleMapZoom from "./hooks/useGoogleMapZoom";

const getDefaultMapBounds = () => {
  if (window.google)
    return new google.maps.LatLngBounds({
      south: -23.32209149317482,
      west: -133.08899687500002,
      north: 72.73799930805474,
      east: 71.34459687499998,
    });
};

/*
  If we do not provide a viewport (which we don't unless one is provided via the URL)
  then the getAssetsClusters query will fail because it expects to receive
  a valid polygon in the viewport input param.
*/
export const getDefaultMapViewport = () => {
  const defaultBounds = getDefaultMapBounds();
  if (!defaultBounds) return PREDEFINED_MAP_VIEWPORT;
  if (defaultBounds) {
    // Southwest and northeast corners
    const sw = defaultBounds.getSouthWest();
    const ne = defaultBounds.getNorthEast();

    // Calculate northwest and southeast corners
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    const se = new google.maps.LatLng(sw.lat(), ne.lng());

    // Create the viewport array (default polygon, used by the getAssets lambda)
    const viewport = [
      [sw.lng(), sw.lat()], // Southwest (SW)
      [se.lng(), se.lat()], // Southeast (SE)
      [ne.lng(), ne.lat()], // Northeast (NE)
      [nw.lng(), nw.lat()], // Northwest (NW)
      [sw.lng(), sw.lat()], // Closing the loop back to Southwest (SW)
    ];

    return viewport;
  }
};

export type GoogleMapComponentProps = {
  serverSideMapFeatures: Feature[];
  loadingIndicatorState: boolean;
};

const arrowRightStyles =
  "absolute z-50 top-[46%] h-[53px] w-[23px] flex flex-col justify-center text-black bg-white rounded-tr-none rounded-tl-lg rounded-bl-lg rounded-br-none hover:cursor-pointer";

const GoogleMapComponent: FC<GoogleMapComponentProps> = ({
  serverSideMapFeatures,
  loadingIndicatorState,
}: GoogleMapComponentProps) => {
  const { state } = useAppContext();
  const queryClient = useQueryClient();
  const {
    allGeofences,
    allAssetsCoordinates,
    drawerFocused,
    drawnGeofenceType,
    geofenceForUpdate,
    geofences,
    isAssetsVisibleOnGeoFence,
    isDrawingGeofence,
    isInitialLoad,
    mapViewportCoordinates,
    isLoadingAllAssetsCoordinates,
    pageType,
    selectedAssetId,
    shouldRecenterMap,
    onPageChange,
    resetGeofenceFiltersToDefault,
    restoreGeofencePreviousFilters,
    clearAllAssetFilters,
    setDrawnGeofence,
    setDrawnGeofenceArea,
    shouldFitBounds,
    setDrawnGeofenceAreaInSqKm,
    setInitialMapCoordinates,
    setInitialMapZoom,
    setIsDrawingGeofence,
    setIsInitialLoad,
    setMapViewportCoordinates,
    setSelectedAssetId,
    setSelectedGeofence,
    setShouldRecenterMap,
    setAddGeofenceDrawerOpen,
    addGeofenceDrawerOpen,
    setUserMapViewportCoordinates,
    userMapViewportCoordinates,
    setShouldFitBounds,
  } = useAssetsDataContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isScreenExtraLarge = useBreakpoint("up", "xl");
  const location = useLocation();
  const currentOrg = useCurrentOrg();
  const { minZoom, defaultCenter } = state.appConfig.map;
  const [googleMapLoaded, setGoogleMapLoaded] = useState(false);
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [googleDraw, setGoogleDraw] =
    useState<google.maps.drawing.DrawingManager | null>(null);
  const activePolygonRef = useRef<google.maps.Polygon | null>(null);
  const [polygonHistory, setPolygonHistory] = useState<
    Array<google.maps.LatLngLiteral[]>
  >([]);
  const [circleHistory, setCircleHistory] = useState<Array<CircleProperties>>(
    []
  );
  const [isEditGeofence, setIsEditGeofence] = useState<boolean>(true);
  const activeCircleRef = useRef<google.maps.Circle | null>(null);
  const [removeChanges, setRemoveChanges] = useState(false);
  const { googleMapTypeId, setGoogleMapTypeId } = useGoogleMapStyle();
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const { toggleFullscreen } = useGoogleMapFullscreen({
    mapInstance: googleMap,
    isMapFullscreen,
    setIsMapFullscreen,
  });

  const shouldShowAssetMarkers = useMemo(
    () =>
      pageType === PageTypes.AssetMap ||
      (pageType === PageTypes.Geofences && isAssetsVisibleOnGeoFence),
    [pageType, isAssetsVisibleOnGeoFence]
  );

  const markerImagesList = getGMMarkers();
  const [markerImages] = useState<any[]>(markerImagesList);

  const [mapZoom, setMapZoom] = useState(minZoom ?? 3);

  const { handleZoomIn, handleZoomOut } = useGoogleMapZoom({
    mapInstance: googleMap,
    setMapZoom,
  });
  const onGoogleMapViewChange = useGoogleMapViewChange({
    mapInstance: googleMap,
    setGoogleMapTypeId,
  });

  /**
   * Added as part of https://phillips-connect.atlassian.net/browse/PRJIND-10117
   * There is a bug when the user opens the map then quickly switches to a different page, while url params are still being updated,
   * and the user is returned back to the map.
   * The reason is that setSearchParams from react-router causes a navigation after setting the url params.
   * By tracking if the component is unmounted, we can prevent the url params update and avoid this bug.
   */
  const isComponentMounted = useRef(true);
  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const handleMapLoaded = (mapInstance: google.maps.Map) => {
    if (mapInstance) {
      setGoogleMap(mapInstance);
      setGoogleMapLoaded(true);

      const zoomLevelUrlParam = searchParams.get("zoomLevel");
      const viewportFromUrl = JSON.parse(searchParams.get("viewport") ?? "[]");
      const hasZoomLevel = zoomLevelUrlParam !== null;
      const hasViewport = viewportFromUrl.length > 0;

      if (hasZoomLevel || hasViewport) {
        const zoom = hasZoomLevel ? parseFloat(zoomLevelUrlParam) : minZoom;
        mapInstance.setZoom(zoom);

        if (hasViewport) {
          const bounds = new window.google.maps.LatLngBounds();
          for (let [longitude, lat] of viewportFromUrl ?? []) {
            /**
             * This is a workaround for the case where we have zoom level 3 on extra large screens.
             * In this case google maps will set longitude to opposite value(*-1), so we multiply it by -1 to keep the correct value.
             */
            const lng =
              isScreenExtraLarge && zoom <= minZoom
                ? longitude * -1
                : longitude;

            bounds.extend(new window.google.maps.LatLng({ lat, lng }));
          }
          mapInstance.fitBounds(bounds, 0); // 0 padding prevents the map from zooming out

          setMapViewportCoordinates(viewportFromUrl);
          setInitialMapCoordinates(viewportFromUrl);
        }
        setIsInitialLoad(false);
      } else {
        // Listen for tilesloaded event to capture initial bounds
        const tilesLoadedListener = mapInstance.addListener(
          "tilesloaded",
          () => {
            const bounds = mapInstance.getBounds();
            if (bounds) {
              const viewportCoordinates =
                getGoogleMapViewportCoordinates(mapInstance);
              const zoom = mapInstance.getZoom();

              setInitialMapCoordinates(viewportCoordinates);
              setInitialMapZoom(Math.round(zoom as number));
              setMapViewportCoordinates(viewportCoordinates);

              const zoomLevel = {
                zoomLevel: Math.round(zoom as number).toString(),
              };
              const viewport = {
                viewport: JSON.stringify(viewportCoordinates),
              };

              if (isComponentMounted?.current) {
                setSearchParams({
                  ...searchParams,
                  ...zoomLevel,
                  ...viewport,
                });
              }
            }
            setIsInitialLoad(false);
            // Remove the event listener to prevent capturing subsequent bounds changes
            tilesLoadedListener.remove();
          }
        );
      }
    }
  };

  const onDrawingManagerLoad = (
    drawingManager: google.maps.drawing.DrawingManager
  ) => {
    if (!drawingManager) return;
    setGoogleDraw(drawingManager);
  };

  const drawModeHandler = (geofenceType: GeofenceShape) => {
    if (!googleDraw) return;
    cleanUpMap();
    if (!geofenceType) {
      googleDraw.setMap(null);
    } else {
      googleDraw.setMap(googleMap);
    }
    if (geofenceType === GeofenceShape.Polygon) {
      googleDraw.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else if (geofenceType === GeofenceShape.Circle) {
      googleDraw.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    }
  };

  const cleanUpMap = () => {
    activePolygonRef.current?.setMap(null);
    activeCircleRef.current?.setMap(null);
  };

  const persistPolygonAndFeatureInState = (polygon: google.maps.Polygon) => {
    activePolygonRef.current = polygon;
    const path = polygon
      .getPath()
      .getArray()
      .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
    setPolygonHistory((prevPolygonPaths) => [...prevPolygonPaths, path]);
    const feature = convertPolygonToGeoJSONFeature(polygon);
    feature && setDrawnGeofence(feature);
    const area = calculatePolygonArea(polygon);
    if (area) {
      setDrawnGeofenceAreaInSqKm(area);
      const formattedArea = convertGeofenceAreaUnit(
        area,
        currentOrg?.distance_unit_preference
      );
      formattedArea && setDrawnGeofenceArea(formattedArea);
    }
  };

  const persistCircleAndFeatureInState = (circle: google.maps.Circle) => {
    activeCircleRef.current = circle;
    const center = circle.getCenter();
    if (center) {
      const circleProperties = {
        center: {
          lat: center.lat(),
          lng: center.lng(),
        },
        radius: circle.getRadius(),
      };
      setCircleHistory((prevProps) => [...prevProps, circleProperties]);
    }
    const feature = convertCircleToGeoJSONPolygon(circle);
    feature && setDrawnGeofence(feature);
    const area = calculateCircleArea(circle);
    if (area) {
      setDrawnGeofenceAreaInSqKm(area);
      const formattedArea = convertGeofenceAreaUnit(
        area,
        currentOrg?.distance_unit_preference
      );
      formattedArea && setDrawnGeofenceArea(formattedArea);
    }
  };

  const resetDrawingManager = () => {
    const isPolygon = drawnGeofenceType === GeofenceShape.Polygon;
    const isCircle = drawnGeofenceType === GeofenceShape.Circle;
    const activePolygon = activePolygonRef.current;
    const activeCircle = activeCircleRef.current;

    if (isPolygon && activePolygon) {
      handleResetPolygon(activePolygon);
    } else if (isCircle && activeCircle) {
      handleResetCircle(activeCircle);
    }

    setIsEditGeofence(true);
  };

  const handleResetPolygon = (polygon: google.maps.Polygon) => {
    if (geofenceForUpdate) {
      handleUpdatePolygon(polygon);
    } else {
      handleCreateNewPolygon(polygon);
    }
  };

  const handleResetCircle = (circle: google.maps.Circle) => {
    if (geofenceForUpdate) {
      handleUpdateCircle(circle);
    } else {
      handleCreateNewCircle(circle);
    }
  };

  const handleUpdatePolygon = (polygon: google.maps.Polygon) => {
    if (polygonHistory.length) {
      const initialPath = polygonHistory[0];
      polygon.setPaths(initialPath);
      setPolygonHistory([initialPath]);
      persistPolygonAndFeatureInState(polygon);
    }
  };

  const handleCreateNewPolygon = (polygon: google.maps.Polygon) => {
    polygon.setMap(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);
    setDrawnGeofence(null);
    setPolygonHistory([]);
    if (googleDraw) {
      googleDraw.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    }
  };

  const handleUpdateCircle = (circle: google.maps.Circle) => {
    if (circleHistory.length) {
      const initialProps = circleHistory[0];
      circle.setCenter(initialProps.center);
      circle.setRadius(initialProps.radius);
      setCircleHistory([initialProps]);
      persistCircleAndFeatureInState(circle);
    }
  };

  const handleCreateNewCircle = (circle: google.maps.Circle) => {
    circle.setMap(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);
    setDrawnGeofence(null);
    setCircleHistory([]);
    if (googleDraw) {
      googleDraw.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    }
  };

  const undoDrawingManager = () => {
    const isPolygon = drawnGeofenceType === GeofenceShape.Polygon;
    const isCircle = drawnGeofenceType === GeofenceShape.Circle;

    const activePolygon = activePolygonRef.current;
    const activeCircle = activeCircleRef.current;

    if (isPolygon && activePolygon) {
      if (polygonHistory.length) {
        const previousPath = polygonHistory[polygonHistory.length - 2];
        if (previousPath) {
          activePolygon.setPaths(previousPath);
          attachPolygonEventListeners(activePolygon);

          const area = calculatePolygonArea(activePolygon);
          if (area) {
            setDrawnGeofenceAreaInSqKm(area);
            const formattedArea = convertGeofenceAreaUnit(
              area,
              currentOrg?.distance_unit_preference
            );
            setDrawnGeofenceArea(formattedArea);
          }
          polygonHistory.pop();
        }
      }
    } else if (isCircle && activeCircle) {
      if (circleHistory.length) {
        const previousProps = circleHistory[circleHistory.length - 2];

        if (previousProps) {
          setCircleHistory((prevProps) => prevProps.slice(0, -2));

          setCircleProperties(
            activeCircle,
            previousProps.center,
            previousProps.radius
          );

          const area = calculateCircleArea(activeCircle);

          if (area) {
            setDrawnGeofenceAreaInSqKm(area);
            const formattedArea = convertGeofenceAreaUnit(
              area,
              currentOrg?.distance_unit_preference
            );
            setDrawnGeofenceArea(formattedArea);
          }
        }
      }
    }
  };

  const setCircleProperties = (
    circle: google.maps.Circle,
    newCenter?: { lat: number; lng: number },
    newRadius?: number
  ) => {
    const currentCenter = circle.getCenter();
    const currentRadius = circle.getRadius();

    if (
      newCenter &&
      currentCenter &&
      (newCenter.lat !== currentCenter.lat() ||
        newCenter.lng !== currentCenter.lng())
    ) {
      circle.setCenter(newCenter);
    }

    if (newRadius && currentRadius && newRadius !== currentRadius) {
      circle.setRadius(newRadius);
    }
  };

  const attachPolygonEventListeners = (polygon: google.maps.Polygon) => {
    // if a new vertex is added to the polygon, save the changes in state
    google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
      persistPolygonAndFeatureInState(polygon);
    });

    google.maps.event.addListener(polygon.getPath(), "set_at", () => {
      persistPolygonAndFeatureInState(polygon);
    });

    // if a vertex is removed from the polygon, save the changes in state
    google.maps.event.addListener(polygon.getPath(), "remove_at", () => {
      persistPolygonAndFeatureInState(polygon);
    });
  };

  const attachCircleEventListeners = (circle: google.maps.Circle) => {
    // If the circle's center is being dragged, save the changes in state
    google.maps.event.addListener(circle, "center_changed", () => {
      persistCircleAndFeatureInState(circle);
    });

    // If the circle's radius is being changed, save the changes in state
    google.maps.event.addListener(circle, "radius_changed", () => {
      persistCircleAndFeatureInState(circle);
    });
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    // Save the polygon and geoJSON feature in state
    persistPolygonAndFeatureInState(polygon);

    // Attach polygon event listeners to track and store coordinate changes
    attachPolygonEventListeners(polygon);

    // Prevent the user from drawing multiple polygons
    if (googleDraw) {
      googleDraw.setDrawingMode(null);
      googleDraw.setOptions({
        drawingControl: false,
      });
    }
  };

  const onCircleComplete = (circle: google.maps.Circle) => {
    // Save the circle and geoJSON feature in state
    persistCircleAndFeatureInState(circle);

    // Attach polygon event listeners to track and store coordinate changes
    attachCircleEventListeners(circle);

    // Prevent the user from drawing multiple polygons
    if (googleDraw) {
      googleDraw.setDrawingMode(null);
      googleDraw.setOptions({
        drawingControl: false,
      });
    }
  };

  // Effect to disable editing and dragging of the active polygon when isDrawingGeofence is not true
  useEffect(() => {
    if (
      (activePolygonRef.current || activeCircleRef.current) &&
      !isDrawingGeofence
    ) {
      if (activePolygonRef.current) {
        activePolygonRef.current.setEditable(false);
        activePolygonRef.current.setDraggable(false);
        activePolygonRef.current.setMap(null);
      } else if (activeCircleRef.current) {
        activeCircleRef.current.setEditable(false);
        activeCircleRef.current.setDraggable(false);
        activeCircleRef.current.setMap(null);
      }
    }
  }, [isDrawingGeofence]);

  // Effect to listen for the "Escape" key press and set the "removeChanges" state to true
  useEffect(() => {
    const keyPressListener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setRemoveChanges(true);
      } else if (event.key === "Enter" && !drawerFocused) {
        activePolygonRef.current?.setEditable(false);
        activePolygonRef.current?.setDraggable(false);
        activeCircleRef.current?.setEditable(false);
        activeCircleRef.current?.setDraggable(false);
        setIsEditGeofence(false);
      }
    };

    // Add event listener for escape key and enter key
    document.addEventListener("keydown", keyPressListener);

    // Clean up function to remove event listener
    return () => {
      document.removeEventListener("keydown", keyPressListener);
    };
  }, [drawerFocused]);

  // Effect to remove changes when "removeChanges" state is true
  useEffect(() => {
    if (googleMap && geofenceForUpdate && removeChanges) {
      // If Google Map and geofenceForUpdate are available and "removeChanges" is true,
      // remove the active polygon from the map
      activePolygonRef.current?.setMap(null);
      // remove the active circle from the map
      activeCircleRef.current?.setMap(null);
      // reset "removeChanges" state to false
      setRemoveChanges(false);
    }
  }, [googleMap, geofenceForUpdate, removeChanges]);

  // Effect for updating geofence shape
  useEffect(() => {
    // If googleMap is not initialized yet, return early
    if (!googleMap || !geofenceForUpdate || removeChanges) {
      return;
    }
    // If googleMap and other dependencies are available, proceed with the effect
    if (
      geofenceForUpdate.geofence?.gisConfig?.shape === GeofenceShape.Polygon
    ) {
      updatePolygon(geofenceForUpdate);
    } else if (
      geofenceForUpdate.geofence?.gisConfig?.shape === GeofenceShape.Circle
    ) {
      updateCircle(geofenceForUpdate);
    }
  }, [googleMap, geofenceForUpdate, removeChanges]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePolygon = (geofenceForUpdate: GeofenceData) => {
    // Create polygon from geofence for update
    const path = convertGeofenceGeometryToLatLngArray(
      geofenceForUpdate.geofence.coordinates as GeofenceGeometry
    );
    const polygon = new google.maps.Polygon({
      ...getGeofenceRenderOptions(true, true),
      paths: path,
    });
    if (googleMap) {
      setShouldFitBounds(false);
      setUserMapViewportCoordinates(mapViewportCoordinates);
    }
    if (googleMap) {
      polygon.setMap(googleMap);

      fitMapToGeofenceBounds(geofenceForUpdate);
      if (geofenceForUpdate.configuration?.parentId) {
        // Fit map to parent bounds
        const parentGeofence = allGeofences.find(
          (geofence) =>
            geofence._id === geofenceForUpdate.configuration?.parentId
        );
        if (parentGeofence) {
          fitMapToGeofenceBounds(parentGeofence);
        }
      }

      // Save the polygon and geoJSON feature in state
      persistPolygonAndFeatureInState(polygon);

      // Attach polygon event listeners to track and store coordinate changes
      attachPolygonEventListeners(polygon);
    } else {
      console.error("googleMap is not an instance of google.maps.Map");
    }
  };

  const updateCircle = (geofenceForUpdate: GeofenceData) => {
    // Create circle from geofence for update
    const { center, radius } = calculateCircleCenterAndRadius(
      geofenceForUpdate.geofence.coordinates as GeofenceGeometry
    );
    const circle = new google.maps.Circle({
      ...getGeofenceRenderOptions(true, false),
      center,
      radius,
    });
    if (googleMap) {
      setShouldFitBounds(false);
      setUserMapViewportCoordinates(mapViewportCoordinates);
    }
    if (googleMap) {
      circle.setMap(googleMap);

      fitMapToGeofenceBounds(geofenceForUpdate);

      if (geofenceForUpdate.configuration?.parentId) {
        // Fit map to parent bounds
        const parentGeofence = allGeofences.find(
          (geofence) =>
            geofence._id === geofenceForUpdate.configuration?.parentId
        );
        if (parentGeofence) {
          fitMapToGeofenceBounds(parentGeofence);
        }
      }

      // Save the circle and geoJSON feature in state
      persistCircleAndFeatureInState(circle);

      // Attach circle event listeners to track and store coordinate changes
      attachCircleEventListeners(circle);
    } else {
      console.error("googleMap is not an instance of google.maps.Map");
    }
  };

  const fitMapToGeofenceBounds = useCallback(
    (geofence: GeofenceData) => {
      if (googleMap) {
        if (geofence.geofence?.gisConfig?.shape === GeofenceShape.Polygon) {
          const path = convertGeofenceGeometryToLatLngArray(
            geofence.geofence.coordinates as GeofenceGeometry
          );
          // Fit map to polygon bounds
          const bounds = new google.maps.LatLngBounds();
          path.forEach((coord) => bounds.extend(coord));
          // The right padding is added because addGeofenceDrawer is rendered on top of the map
          googleMap.fitBounds(bounds, { right: 375 });
        } else if (
          geofence.geofence?.gisConfig?.shape === GeofenceShape.Circle
        ) {
          const { center, radius } = calculateCircleCenterAndRadius(
            geofence.geofence.coordinates as GeofenceGeometry
          );
          const circle = new google.maps.Circle({
            ...getGeofenceRenderOptions(false, false),
            center,
            radius,
          });
          // Fit map to circle bounds
          const bounds = circle.getBounds();
          if (bounds) {
            // The right padding is added because addGeofenceDrawer is rendered on top of the map
            googleMap.fitBounds(bounds, { right: 375 });
          }
        }
      } else {
        console.error("googleMap is not an instance of google.maps.Map");
      }
    },
    [googleMap]
  );

  //------------------------------
  // Listeners
  //------------------------------

  useEffect(() => {
    if (location.pathname.includes(NavigationRoutes.Geofences)) {
      const geofenceId = location.pathname.split("/").at(-1);
      if (geofenceId && allGeofences) {
        const targetGeofence =
          allGeofences.find((geofence) => geofence._id === geofenceId) ?? null;
        if (targetGeofence) {
          setSelectedGeofence(targetGeofence);
        }
      }
    }
  }, [
    allGeofences,
    setSelectedGeofence,
    setSelectedAssetId,
    location.pathname,
    isDrawingGeofence,
  ]);
  useEffect(() => {
    if (state.geofence?.parentGeofence) {
      const path = convertGeofenceGeometryToLatLngArray(
        state.geofence.parentGeofence.geofence.coordinates as GeofenceGeometry
      );
      const bounds = new google.maps.LatLngBounds();
      path.forEach((coord) => bounds.extend(coord));
      googleMap?.fitBounds(bounds, { right: 375 });
    }
  }, [state.geofence?.parentGeofence, googleMap]);

  const { data: geocode } = useGeocodeFromAddress(state.location.locationName);

  const getGoogleMapViewportCoordinates = (map: google.maps.Map) => {
    const bbox = [
      map?.getBounds()?.getNorthEast().lng(),
      map?.getBounds()?.getNorthEast().lat(),
      map?.getBounds()?.getSouthWest().lng(),
      map?.getBounds()?.getSouthWest().lat(),
    ];
    const result = bboxPolygon(bbox as BBox).geometry.coordinates[0];
    return result.map((x) => [
      x[0] > 180 || x[0] < -180 ? Math.round(x[0]) : x[0], // to prevent opensearch illegal longitude error (lon to be in 180/-180)
      x[1],
    ]);
  };

  // google maps zoom change event
  const handleGoogleMapZoomChange = debounce(() => {
    if (!googleMap) return;

    const filterParams = searchParams.get("filters");

    const zoom = googleMap?.getZoom();
    const zoomLevel = {
      zoomLevel: Math.round(zoom as number).toString(),
    };

    const viewportCoordinates = getGoogleMapViewportCoordinates(googleMap);
    const viewport = {
      viewport: JSON.stringify(viewportCoordinates),
    };

    // Reset the page number when the zoom level changes
    onPageChange(1);
    if (isComponentMounted?.current) {
      setSearchParams({
        ...(filterParams ? { filters: filterParams } : {}),
        ...zoomLevel,
        ...viewport,
      });
    }

    setMapViewportCoordinates(viewportCoordinates);
  }, state.appConfig.debounceTimeLong);

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  // google maps onDragEnd event
  const handleMapNavigation = debounce(() => {
    if (!googleMap) return;

    // TODO: Cleanup with PRJIND-9218
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      queryClient.cancelQueries({
        queryKey: ["getAssetsForClustersOS", "getAssetsForListOS"],
      });
    } else {
      queryClient.cancelQueries({
        queryKey: ["getAssetsClusters", "getAssetsForList"],
      });
    }

    const zoom = googleMap?.getZoom();
    const viewportCoordinates = getGoogleMapViewportCoordinates(googleMap);
    setMapViewportCoordinates(viewportCoordinates);

    if (viewportCoordinates) {
      let filters = {};
      let filtersParameter = searchParams.get("filters");

      if (filtersParameter) {
        filters = {
          filters: filtersParameter,
        };
      }

      let viewport = {};
      let zoomLevel = null;
      viewport = {
        viewport: JSON.stringify(viewportCoordinates),
      };
      zoomLevel = {
        zoomLevel: Math.round(zoom as number).toString(),
      };

      // Reset the page number when the map is dragged
      onPageChange(1);
      if (isComponentMounted?.current) {
        setSearchParams({
          ...filters,
          ...viewport,
          ...zoomLevel,
        });
      }

      // default user view: save coordinates & zoom value on initial load
      if (isInitialLoad) {
        setIsInitialLoad(false);
        setInitialMapCoordinates(viewportCoordinates);
        setInitialMapZoom(Math.round(zoom as number));
      }
    }
  }, state.appConfig.debounceTimeLong);

  const resetToDefaultViewGoogleMap = useCallback(
    (isResetBtnClicked: boolean = false) => {
      if (pageType === PageTypes.AssetMap) {
        // Reset filters
        if (isResetBtnClicked) clearAllAssetFilters(initialFilterState);

        // Generate the map bounds based on the coordinates of all assets
        const bounds = new window.google.maps.LatLngBounds();
        for (let [lng, lat] of allAssetsCoordinates ?? []) {
          bounds.extend(new window.google.maps.LatLng({ lat, lng }));
        }

        if (googleMap) {
          if (bounds.isEmpty()) {
            const defaultBounds = getDefaultMapBounds();
            // In case there are no clusters to focus on - reset to original bounds
            if (defaultBounds) googleMap.fitBounds(defaultBounds);
          } else {
            /*
            Center the map using the new bounds and add
            some padding to ensure we don't cut off some markers
            when they expand from the clusters after zooming in.
          */
            googleMap.fitBounds(bounds, { top: 100, bottom: 100 });
            handleMapNavigation(); // Needs to be called to ensure we trigger the re-fetching of asset clusters
          }
        }
      } else {
        const defaultBounds = getDefaultMapBounds();
        // For the moment this is used for maps other than the AssetMap
        if (googleMap && defaultBounds) googleMap.fitBounds(defaultBounds);
      }
    },
    [
      pageType,
      googleMap,
      allAssetsCoordinates,
      clearAllAssetFilters,
      handleMapNavigation,
    ]
  );

  //-------------------------------
  // Effects
  //-------------------------------

  useEffect(() => {
    // If a recenter has been requested from another component
    if (!isLoadingAllAssetsCoordinates && shouldRecenterMap) {
      resetToDefaultViewGoogleMap();
      // Reset flag after recentering
      setShouldRecenterMap(false);
    }
  }, [
    shouldRecenterMap,
    allAssetsCoordinates,
    isLoadingAllAssetsCoordinates,
    resetToDefaultViewGoogleMap,
    setShouldRecenterMap,
  ]);

  useEffect(() => {
    if (!shouldFitBounds || !googleMap || !userMapViewportCoordinates) return;

    const bounds = new google.maps.LatLngBounds();
    const latLngArray: google.maps.LatLng[] = [];
    userMapViewportCoordinates.forEach((coordinateSet) => {
      const lat = coordinateSet[1];
      const lng = coordinateSet[0];
      latLngArray.push(new google.maps.LatLng(lat, lng));
    });
    latLngArray.forEach((coord) => bounds.extend(coord));
    googleMap.fitBounds(bounds);
  }, [shouldFitBounds, userMapViewportCoordinates, googleMap]);

  useEffect(() => {
    if (geocode?.geometry?.bounds && !state.location.isDefault) {
      const southwest = geocode.geometry.bounds.getSouthWest();
      const northeast = geocode.geometry.bounds.getNorthEast();

      const googleMapSouthwest = new google.maps.LatLng(
        southwest.lat(),
        southwest.lng()
      );
      const googleMapNortheast = new google.maps.LatLng(
        northeast.lat(),
        northeast.lng()
      );

      const googleMapBounds = new google.maps.LatLngBounds(
        googleMapSouthwest,
        googleMapNortheast
      );

      googleMap?.fitBounds(googleMapBounds);
    }
  }, [geocode, googleMap, state.location.isDefault]);

  // Switches the map to satellite view when drawing a geofence polygon
  useEffect(() => {
    if (pageType === PageTypes.Geofences && isDrawingGeofence) {
      setGoogleMapTypeId(google.maps.MapTypeId.SATELLITE);
    }
  }, [pageType, isDrawingGeofence]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex h-full">
      <div
        className={`flex-grow h-full${
          addGeofenceDrawerOpen ? "w-[calc(100%-375px)]" : "w-full"
        }`}
      >
        <GoogleMap
          key={`${location.pathname} ${isDrawingGeofence && "drawing"}`}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          center={defaultCenter}
          zoom={mapZoom}
          onLoad={handleMapLoaded}
          options={{
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              strictBounds: true,
            },
            mapTypeId: googleMapTypeId,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            streetViewControl: false,
            rotateControl: false,
            minZoom,
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          onDragEnd={handleMapNavigation}
          onZoomChanged={handleGoogleMapZoomChange}
        >
          {loadingIndicatorState && !selectedAssetId && (
            <Box
              data-testid="map-loading-indicator"
              className="absolute top-5 left-14
          !sm:top-16 !sm:left-14 h-45 w-56 py-2
          bg-background bg-opacity-20 text-primary
          cursor-default z-20 shadow-text-card
          text-center text-base font-sans font-bold rounded-lg loading-text"
            >
              Map loading <span>.</span>
              <span>.</span>
              <span>.</span>
            </Box>
          )}
          <Spinner counter={Number(!googleMapLoaded)} />
          <section className="absolute top-5 !sm:top-16 right-14">
            <MapViewSwitcher
              mapStyleSelected={googleMapTypeId}
              onMapViewChange={onGoogleMapViewChange}
            />
          </section>
          <section className="absolute bottom-2 !sm:bottom-16 right-14">
            {pageType === PageTypes.AssetMap && (
              <div className="mb-4 w-full">
                <MapLegend />
              </div>
            )}
          </section>
          {!selectedAssetId && (
            <>
              <button
                onClick={() => toggleFullscreen()}
                className="default-view-btn shadow-card-sm absolute top-[10px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
                data-testid="map-fullscreen-btn"
              >
                {isMapFullscreen && (
                  <FullscreenExitIcon sx={CENTER_FOCUS_ICON} />
                )}
                {!isMapFullscreen && <FullscreenIcon sx={CENTER_FOCUS_ICON} />}
              </button>
              <button
                onClick={() => {
                  resetToDefaultViewGoogleMap(true);
                }}
                disabled={isLoadingAllAssetsCoordinates}
                className="default-view-btn shadow-card-sm absolute top-[48px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
                data-testid="map-default-view-btn"
              >
                <Box sx={CENTER_FOCUS_ICON}>
                  <FilterCenterFocusIcon />
                </Box>
              </button>
              <button
                onClick={() => handleZoomIn()}
                className="default-view-btn shadow-card-sm absolute bottom-[48px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
                data-testid="map-zoom-in-btn"
              >
                {<AddIcon sx={CENTER_FOCUS_ICON} />}
              </button>
              <button
                onClick={() => handleZoomOut()}
                className="default-view-btn shadow-card-sm absolute bottom-[10px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
                data-testid="map-zoom-out-btn"
              >
                {<RemoveIcon sx={CENTER_FOCUS_ICON} />}
              </button>
              {pageType === PageTypes.Geofences && isDrawingGeofence && (
                <DrawingManager
                  drawingMode={null}
                  onLoad={onDrawingManagerLoad}
                  onPolygonComplete={onPolygonComplete}
                  onCircleComplete={onCircleComplete}
                  options={{
                    drawingControl: false,
                    polygonOptions: getGeofenceRenderOptions(
                      isDrawingGeofence,
                      isDrawingGeofence
                    ),
                    circleOptions: getGeofenceRenderOptions(
                      isDrawingGeofence,
                      false
                    ),
                  }}
                />
              )}
              {pageType === PageTypes.Geofences && serverSideMapFeatures && (
                <GeofenceRenderer
                  geofences={geofences}
                  geofenceForUpdate={geofenceForUpdate}
                />
              )}
            </>
          )}
          {shouldShowAssetMarkers && (
            <AssetsMarkers
              serverSideMapFeatures={serverSideMapFeatures}
              markerImages={markerImages}
              googleMap={googleMap}
            />
          )}
        </GoogleMap>
      </div>
      <div
        className={`h-full relative ${
          addGeofenceDrawerOpen ? "w-[375px]" : "w-0"
        }`}
      >
        {pageType === PageTypes.Geofences && isDrawingGeofence && (
          <>
            <AddGeofenceDrawer
              open={addGeofenceDrawerOpen}
              setOpen={(isOpen) => {
                setIsDrawingGeofence(isOpen);
                if (isOpen) {
                  resetGeofenceFiltersToDefault();
                } else {
                  restoreGeofencePreviousFilters();
                }
              }}
              changeDrawMode={drawModeHandler}
              resetDrawManager={resetDrawingManager}
              undoDrawManager={undoDrawingManager}
              existingGeofence={geofenceForUpdate}
              isEditGeofence={isEditGeofence}
              cleanUpMap={cleanUpMap}
            />
            <Box
              className={arrowRightStyles}
              onClick={() => setAddGeofenceDrawerOpen(!addGeofenceDrawerOpen)}
              sx={{
                right: addGeofenceDrawerOpen ? 374 : 0.5,
              }}
            >
              {addGeofenceDrawerOpen ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(GoogleMapComponent);
