import { useState, FC, useMemo } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  ThemeProvider,
  Grid,
} from "@mui/material";
import { sortBy } from "lodash";
import * as yup from "yup";
import {
  useFindOrgsQuery,
  BatchTemplate,
  DevicesTableData,
} from "../../../../graphql/operations";
import { DATE_FORMAT_GLUED, formatDate } from "../../../../utils/date";
import { useUploadJsonBatchFileToS3 } from "../../../../views/BatchesView/hooks/useUploadJsonBatchFileToS3";
import { useFormTheme } from "../../../hooks/theme/useFormTheme";
import { useUserData } from "../../../hooks/useUserData";
import { Button as SubmitButton, TextButton } from "../../Button";
import {
  getMappedTransferDevices,
  getMappedTransferDevicesMissingFields,
  getMappedTransferAssets,
  getMappedTransferAssetsMissingFields,
  parseContentAsJsonBlobFile,
  AssetToTransfer,
  TypeTransfer,
} from "../helpers";
import { TransferAsset, TransferDevice } from "../types";

export interface TransferAssetsProps {
  open: boolean;
  onClose: () => void;
  onSnack: (
    success: boolean,
    customTitle?: string,
    customMessage?: string
  ) => void;
  selectedAssetIds: string[];
  typeTransfer: TypeTransfer;
  devices?: DevicesTableData[];
  assets?: Array<AssetToTransfer & { _id: string }>;
}

export const transferSchema = yup.object().shape({
  customer_orgs_id: yup.string().required("required"),
});

const TransferAssetsDialog: FC<TransferAssetsProps> = ({
  open,
  onClose,
  onSnack,
  selectedAssetIds,
  typeTransfer,
  devices = [],
  assets = [],
}) => {
  const [transferBtnState, setTransferBtnState] = useState(true);

  const formTheme = useFormTheme();
  const breakpoints = { xs: 12 };
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();

  const isAssetsTransfer = typeTransfer === TypeTransfer.Assets;

  const { control, getValues, handleSubmit } = useForm({
    resolver: yupResolver(transferSchema),
  });
  const userData = useUserData();
  const userOrgName = userData?.customerOrg?.name || "";
  const batchName = `${userOrgName} Table transfer - ${formatDate(
    new Date(),
    DATE_FORMAT_GLUED
  )}`;

  const submitTransfer = async (data: FieldValues) => {
    const orgObj = options.find((obj) => obj.id === data.customer_orgs_id);
    const org = orgObj?.label;

    const selectedItems = isAssetsTransfer
      ? getMappedTransferAssets(
          assets.filter((asset) => selectedAssetIds.includes(asset._id)),
          org
        )
      : getMappedTransferDevices(
          devices.filter((device) => selectedAssetIds.includes(device.id)),
          org
        );

    const isValid = validateFields(selectedItems);
    if (!isValid) return;
    const transferJsonFile = parseContentAsJsonBlobFile(
      selectedItems,
      `selected${typeTransfer}JsonFile.json`
    );
    const transferTemplate = isAssetsTransfer
      ? BatchTemplate.TransferAssets
      : BatchTemplate.TransferDevices;

    try {
      await uploadBatchFileToS3(
        transferJsonFile,
        transferTemplate,
        batchName,
        orgObj?.value
      );
      onSnack(
        true,
        "Transfer Batch Created Successfully!",
        `The selected records will be processed shortly. You can track the progress in Batches.`
      );
    } catch (error) {
      onSnack(false);
    }
  };

  const validateFields = (items: TransferAsset[] | TransferDevice[]) => {
    const missingFields = isAssetsTransfer
      ? getMappedTransferAssetsMissingFields(items as TransferAsset[])
      : getMappedTransferDevicesMissingFields(items as TransferDevice[]);
    if (missingFields.size > 0) {
      onSnack(
        false,
        undefined,
        `Missing fields: ${[...missingFields].join(", ")}`
      );
      return false;
    }
    return true;
  };

  const {
    data: orgsData,
    isSuccess: orgsDataSuccess,
    isLoading: isLoadingFindOrgs,
  } = useFindOrgsQuery(
    {},
    {
      enabled: true,
    }
  );

  const options = useMemo(() => {
    if (orgsDataSuccess) {
      return sortBy(
        orgsData?.findOrgs.map((org) => ({
          value: org._id,
          label: org.name,
          id: org._id,
        })),
        "label"
      );
    }

    return [];
  }, [orgsDataSuccess, orgsData]);

  const handleChange = (evenValue: null | object) => {
    setTransferBtnState(evenValue === null);
  };

  return (
    <Dialog data-testid="transfer-dialog" open={open} onClose={onClose}>
      <DialogTitle>Transfer {typeTransfer}</DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(submitTransfer)}>
          <DialogContent>
            <DialogContentText>
              You've selected {selectedAssetIds.length} {typeTransfer}. Please
              specify the receiving Organization.
            </DialogContentText>
            <Grid container className="bg-background topPaddingDrawerSection">
              <Grid
                item
                {...breakpoints}
                data-testid="transfer-asset-autocomplete"
              >
                <AutocompleteElement
                  autocompleteProps={{
                    onChange: (_, data) => {
                      handleChange(data);
                    },
                  }}
                  loading={isLoadingFindOrgs}
                  rules={{ required: true }}
                  matchId={true}
                  label={`Transfer ${typeTransfer.toLowerCase()} to:`}
                  control={control}
                  name="customer_orgs_id"
                  key="customer_orgs_id"
                  options={options}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: "24px" }}>
            <TextButton
              text="Cancel"
              size="medium"
              theme="blue"
              onClick={onClose}
            />

            <SubmitButton
              text="Transfer"
              disabled={transferBtnState}
              size="medium"
              theme="blue"
              variant="default"
              dataTestid="transfer-submit-btn"
              onClick={() => submitTransfer(getValues())}
            />
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default TransferAssetsDialog;
