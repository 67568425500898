import { QueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import {
  Asset,
  QueryFindAssetByIdOsArgs,
  UpdateAssetMutation,
} from "../../../../../graphql/operations";

interface OnAssetUpdateSuccessProps {
  updated: UpdateAssetMutation;
  selectedAsset: Asset | null;
  setSelectedAssetId: (assetId: string | null) => void;
  queryClient: QueryClient;
  fetchAssetsFromOpenSearchFeatureFlag: boolean;
}

interface OnAssetRemoveSuccessProps {
  dispatch: React.Dispatch<any>;
  selectedAsset: Asset | null;
  queryClient: QueryClient;
}

export const onAssetUpdateSuccess = async ({
  updated,
  selectedAsset,
  setSelectedAssetId,
  queryClient,
  fetchAssetsFromOpenSearchFeatureFlag,
}: OnAssetUpdateSuccessProps) => {
  setSelectedAssetId(updated.updateAsset._id);

  // TODO: Cleanup with PRJIND-9218
  if (fetchAssetsFromOpenSearchFeatureFlag) {
    await queryClient.invalidateQueries(["getAssetsForClustersOS"]);
    await queryClient.invalidateQueries(["getAssetsForTableOS"]);
    await queryClient.invalidateQueries(["getAssetsForListOS"]);
  } else {
    await queryClient.invalidateQueries(["getAssetsClusters"]);
    await queryClient.invalidateQueries(["getAssetsForTable"]);
    await queryClient.invalidateQueries(["getAssetsForList"]);
  }

  if (
    // If device imei set for the first time
    (!selectedAsset && updated.updateAsset.imei) ||
    // Or device imei has changed
    (selectedAsset && updated.updateAsset.imei !== selectedAsset.imei) ||
    // Or asset was transferred to another org
    (selectedAsset &&
      updated.updateAsset.customer_orgs_id !== selectedAsset.customer_orgs_id)
  ) {
    queryClient.invalidateQueries(["findDevices"], {
      refetchType: "all",
    });
  }
};

export const onAssetRemoveSuccess = ({
  dispatch,
  selectedAsset,
  queryClient,
}: OnAssetRemoveSuccessProps) => {
  if (selectedAsset?.imei) {
    queryClient.invalidateQueries(["findDevices"], {
      refetchType: "all",
    });
  }
  // Invalidate all queries related to getting assets
  queryClient.invalidateQueries({
    predicate: (query) => {
      const [key, params] = query.queryKey as [string, unknown];

      // Invalidate all queries starting with `getAssets`
      if (key.startsWith("getAssets")) {
        return true;
      }

      // Invalidate findAssetById query for the removed asset
      if (key.startsWith("findAssetById")) {
        const queryInput = (params as QueryFindAssetByIdOsArgs).input;
        return queryInput?.assetId === selectedAsset?._id;
      }

      return false;
    },
  });

  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success",
      text: "Asset was inactivated Successfully!",
      severity: "success",
      onClose: () => {},
    },
  });
};

export const UpdateAssetInputKeys: Array<string> = [
  "_id",
  "asset_group",
  "asset_id",
  "category",
  "fullAddress.city",
  "customer_orgs_id",
  "org_name",
  "door_type",
  "height",
  "imei",
  "length",
  "location",
  "name",
  "num_of_axles",
  "prd_cde",
  "fullAddress.state",
  "tags",
  "trip_st",
  "vin",
  "wheel_config",

  "externalHeight",
  "internalHeight",
  "internalLength",
  "internalWidth",
  "subAssetType",
  "externalLength",
  "manufacturer",
  "assetModel",
  "emptyWeight",

  "engineHours",

  "tripStatus.odometer",

  "metadata.year",
  "metadata.assetInserviceDate",
  "metadata.cubeCapacity",
  "metadata.railCompliant",
  "metadata.airRide",

  "metadata.liftgate",
  "metadata.liftgateMACAddress",
  "metadata.liftgateManufacturer",
  "metadata.liftgateModel",
  "metadata.liftgateSerial",
  "metadata.liftgateYear",
  "metadata.liftgateFWVersion",

  "metadata.CARBNumber",
  "metadata.reeferModel",
  "metadata.reeferSerialNumber",
  "metadata.reeferManufacturer",
  "metadata.reeferCARBCompliant",
  "metadata.reeferYear",

  "metadata.flushMountedNose",
  "metadata.gridExtensionPlate",
  "metadata.liftPads",

  "metadata.absCanbus",
  "metadata.absConfiguration",
  "metadata.absManufacturer",
  "metadata.absModel",
  "metadata.absYear",

  "metadata.atis",
  "metadata.atisManufacturer",
  "metadata.atisModel",

  "metadata.lightWeight",
  "metadata.ttPairCapable",
  "metadata.aeroKitType",
  "metadata.batteryCharger",
  "metadata.doorLatchType",
  "metadata.doorLatchManufacturer",
  "metadata.electricalHarnessManufacturer",
  "metadata.electronicLandingGear",
  "metadata.etrack",
  "metadata.gladhandManufacturer",
  "metadata.gladhandType",
  "metadata.howIsNoseboxMounted",
  "metadata.innerLinerAvailable",
  "metadata.nosePostDepth",
  "metadata.interiorDropDownCargoSystem",
  "metadata.kingpinOffset",
  "metadata.landingGearManufacturer",
  "metadata.landingGearModel",
  "metadata.landingGearModelRating",
  "metadata.lightingManufacture",
  "metadata.preferredMidturnLightPartNumber",
  "metadata.preferredTailLightPartNumber",
  "metadata.logisticsPosts",
  "metadata.noseTypeBody",
  "metadata.noseboxManufacturer",
  "metadata.noseboxModel",
  "metadata.permalogic",
  "metadata.rearAero",
  "metadata.rearAeroManufacturer",
  "metadata.roadtrainAllowed",
  "metadata.skirt",
  "metadata.skirtManufacturer",
  "metadata.suspensionManufacturer",
  "metadata.suspensionModel",
  "metadata.suspensionType",
  "metadata.suspensionYear",
  "metadata.tireSize",
  "metadata.tireRimType",
];
