import { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  DualImbalanceSensorValue,
  useGetMergedProfileForAssetQuery,
  useSearchDualImbalanceEventHistoryQuery,
} from "../../../../../../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useGetPressureUnitPreference } from "../../../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { useAssetsDataContext } from "../../../../../../../../shared/AssetsDataContext";
import {
  hasRelevantDualImbalanceData,
  mapOSDualImbalanceChartData,
  sensorConfigToReferenceAreaValues,
} from "../../../../../helpers";
import { IMappedDualImbalanceChartData } from "../../../../../interfaces";
import { useDateFilters } from "../../../sensors/hooks/useDateFilters";
import TiresChartHeader from "../TiresChartHeader";
import DualImbalanceChartBody from "./DualImbalanceChartBody";

const DualImbalanceChartSection: React.FC = () => {
  const [chartData, setChartData] = useState<IMappedDualImbalanceChartData[]>(
    []
  );
  const pressureUnit = useGetPressureUnitPreference();

  const { selectedAssetId } = useAssetsDataContext();

  const { data: selectedAsset } = useFindAssetById(
    { assetId: selectedAssetId ?? "" },
    { enabled: Boolean(selectedAssetId) }
  );

  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: selectedAssetId as string },
    },
    { enabled: Boolean(selectedAssetId) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const { data, isLoading } = useSearchDualImbalanceEventHistoryQuery(
    {
      input: {
        imei: selectedAsset?.imei,
        startDate,
        endDate,
      },
    },
    { enabled: Boolean(selectedAsset?.imei) }
  );

  useEffect(() => {
    if (data?.searchEventHistory?.dualImbalanceSensorDetails?.data) {
      setChartData(
        mapOSDualImbalanceChartData(
          data?.searchEventHistory?.dualImbalanceSensorDetails
            ?.data as DualImbalanceSensorValue[],
          pressureUnit
        )
      );
    }
  }, [data, selectedAsset?.sensors?.tpmsBeta?.data?.sensors, pressureUnit]);

  const referenceAreaValues = sensorConfigToReferenceAreaValues(
    mergedSensorProfile?.configuration.tpmsBeta?.imbalance?.match?.thresholds
  );

  const hasShowableData = useMemo(
    () => hasRelevantDualImbalanceData(chartData),
    [chartData]
  );
  return (
    <>
      {Boolean(
        data?.searchEventHistory?.dualImbalanceSensorDetails?.data?.length &&
          hasShowableData
      ) && (
        <Box
          className="hidden lg:block mt-4 w-full rounded-lg border border-asset-card-border p-4 bg-dashboard_subheader__bg"
          data-testid="asset-dashboard--tires-tab--dual-imbalance-charts-section"
        >
          <Box
            sx={{
              width: "100%",
            }}
            data-testid="dual-imbalance-grid"
          >
            <>
              <TiresChartHeader
                title="Tire pressure imbalance"
                subtitle="Trending imbalance in the pressure over time"
                startDate={startDate}
                setStartDate={setStartDate}
                dateRangeOption={dateRangeOption}
                setDateRangeOption={setDateRangeOption}
                endDate={endDate}
                setEndDate={setEndDate}
                changeDateRangeOption={handleDateRangeChange}
              />
              {isLoading ? (
                <Box
                  className="flex h-full w-full self-center justify-center"
                  data-testid="asset-dashboard--tires-tab--dual-imbalance-chart-loader"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <DualImbalanceChartBody
                  data={chartData}
                  referenceAreaValues={referenceAreaValues}
                  pressureUnit={pressureUnit}
                />
              )}
            </>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DualImbalanceChartSection;
