import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/formatters";
import { DoubleGridWidgetOverlay } from "../../LoadingOverlaySkeletons/DoubleGridWidgetLoader/DoubleGridWidgetOverlay";
import BaseDashboardWidget from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";

export interface DoubleGridWidgetData {
  title: string;
  subTitle?: string;
  count: number;
  itemsName: string;
  percentage: number;
}

interface DoubleGridWidgetProps {
  title: string;
  totalCountTitle: string;
  totalCount: number;
  isLoading: boolean;
  data: DoubleGridWidgetData[];
  noPermission?: boolean;
  widgetId: number;
}

const colors = [
  "bg-rechartsMarineBlue",
  "bg-rechartsViolet",
  "bg-rechartsPurple",
  "bg-rechartsTeal",
];

export const DoubleGridWidget: FC<DoubleGridWidgetProps> = ({
  title,
  totalCountTitle,
  totalCount,
  isLoading = false,
  data,
  noPermission,
  widgetId,
}) => {
  return (
    <BaseDashboardWidget
      widgetId={widgetId}
      isLoading={isLoading}
      title={title}
      noPermission={noPermission}
    >
      {isLoading ? (
        <DoubleGridWidgetOverlay />
      ) : totalCount === 0 ? (
        <>
          <NoDataAvailableComponent title={"No data available"} />
        </>
      ) : (
        <>
          <Typography
            data-testid="doubleGridWidget-totalCountTitle"
            className="!font-semibold !text-lg !capitalize !mb-3"
          >
            {totalCountTitle}
          </Typography>
          <Typography
            data-testid="doubleGridWidget-totalCount"
            className="!font-semibold !text-5xl !mb-8"
          >
            {formatNumber(totalCount)}
          </Typography>
          <Grid container rowSpacing={3} columnSpacing={6}>
            {data.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.title}
                  data-testid={`doubleGridWidget-data-row-${index + 1}`}
                >
                  <Typography
                    data-testid={`doubleGridWidget-data-row-${index + 1}-title`}
                    className="!font-semibold !text-lg !capitalize !mb-2"
                  >
                    {item.title}{" "}
                    {item.subTitle && (
                      <span className="!font-medium	">{item.subTitle}</span>
                    )}
                  </Typography>
                  <div
                    className={`flex w-full rounded p-2 justify-between ${colors[index]}`}
                  >
                    <Typography
                      data-testid={`doubleGridWidget-data-row-${
                        index + 1
                      }-items-count`}
                      className="!text-2xl capitalize text-white"
                    >{`${formatNumber(item.count)} ${
                      item.itemsName
                    }`}</Typography>
                    <Typography
                      className="!text-2xl !text-white"
                      data-testid={`doubleGridWidget-data-row-${
                        index + 1
                      }-percentage`}
                    >
                      {item.percentage}%
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </BaseDashboardWidget>
  );
};
