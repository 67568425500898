import { GridColDef } from "@mui/x-data-grid-premium";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { MediaActivityItem } from "../../Assets/CargoTabPanel/InternalCameraStatus/interface";
import { ViewImageBtn } from "./ViewImageBtn";

export const defaultColumnVisibilityModel = {
  address: true,
  floorUsagePercentage: true,
  cubeUsagePercentage: true,
  floorUsageStatus: true,
  geofenceName: true,
  imageUrl: true,
  latitude: true,
  loadedEmptyConfidence: true,
  longitude: true,
  uploadDate: true,
};

export const getColumns = (
  timezone: string
): GridColDef<MediaActivityItem>[] => [
  {
    field: "uploadDate",
    headerName: "Date and Time",
    flex: 1,
    minWidth: 200,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "address",
    headerName: "Location",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "latitude",
    headerName: "Latitude",
    flex: 1,
    minWidth: 120,
    type: "number",
    filterable: false,
  },
  {
    field: "longitude",
    headerName: "Longitude",
    flex: 1,
    minWidth: 120,
    type: "number",
    filterable: false,
  },
  {
    field: "geofenceName",
    headerName: "Geofence Name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "geofenceEntry",
    headerName: "Geofence Entry",
    flex: 1,
    minWidth: 120,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "floorUsagePercentage",
    headerName: "Floor Space",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "cubeUsagePercentage",
    headerName: "Cube Space",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "floorUsageStatus",
    headerName: "Cargo status",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "loadedEmptyConfidence",
    headerName: "Empty Confidence",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "imageUrl",
    headerName: "Historical Images",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => <ViewImageBtn data={params?.row} />,
  },
];

export const SEARCH_KEYS: Array<string> = [
  "address",
  "floorUsagePercentage",
  "floorUsageStatus",
  "cubeUsagePercentage",
  "geofenceName",
  "imageUrl",
  "latitude",
  "loadedEmptyConfidence",
  "longitude",
  "uploadDate",
];
