import {
  ChangeEvent,
  memo,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AddCircle, FileUpload } from "@mui/icons-material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  TextField,
  Menu,
  MenuItem,
  popoverClasses,
  Typography,
  CircularProgress,
} from "@mui/material";
import { debounce, isEqual } from "lodash";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import * as yup from "yup";
import {
  SET_PARENT_GEOFENCE,
  USE_PARENT_GEOFENCE_ORGID,
  SET_APP_CONFIG,
  PAGE_SNACKBAR,
} from "../../../../constants";
import { BatchTitles } from "../../../../constants/batches";
import { ASSETS_LIST_WIDTH } from "../../../../constants/map";
import { useAppContext } from "../../../../context/AppContext";
import {
  AssetFilter,
  CreateAssetFilterMutation,
  DeleteAssetFilterMutation,
  UpdateAssetFilterMutation,
  useCreateAssetFilterMutation,
  useDeleteAssetFilterMutation,
  useUpdateAssetFilterMutation,
} from "../../../../graphql/operations";
import BackIcon from "../../../../shared/components/BackIcon/BackIcon";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import { SwitchButtons } from "../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../shared/components/ToggleButtons";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { Modes } from "../../MapView/Shared/FiltersDrawer/enums";
import { AssetPagesUrlTypes, getViewFromUrl } from "../../utils";
import {
  AssetFilters,
  initialFilterState,
  PageTypes,
  useAssetsDataContext,
  initialGeofenceFilterState,
} from "../AssetsDataContext";
import { FiltersStrip } from "./FiltersStrip";
import FilterItem from "./MoreActions/FilterItem";
import FilterModal, { ModalModeMap } from "./MoreActions/FilterModal";

export type StatusFilter = Pick<AssetFilters, "status">;
export type AssetsRouteKeys = "map" | "table" | "gallery";
export type GeofenceRouteKeys = "show-assets" | "hide-assets";

//-----------------------------
// constants
///-----------------------------
export const initialDayRange = {
  minDays: 0,
  maxDays: 0,
};

const assetsViewActionsOptions = [
  {
    title: "New Asset",
    action: "create",
    icon: <AddCircle fontSize="inherit" />,
  },
  {
    title: BatchTitles.CreateUpdateAssets,
    action: "upload",
    icon: <FileUpload fontSize="inherit" />,
  },
];

const geofenceViewMenuActionsOptions = [
  {
    title: "New Geofence",
    action: "create",
    icon: <AddCircle fontSize="inherit" />,
  },
  {
    title: BatchTitles.CreateUpdateGeofences,
    action: "upload",
    icon: <FileUpload fontSize="inherit" />,
  },
];

export const schema = yup.object({
  maxDays: yup.number().min(0),
  minDays: yup
    .number()
    .min(0)
    .test({
      name: "min-max-validation",
      message: "Min. days cannot be greater than max days.",
      test: (minDays, { parent }) => {
        const { maxDays } = parent;
        if (minDays === undefined) minDays = 0;
        if (minDays > maxDays) return false;
        return true;
      },
    }),
});

//-----------------------------
// Main Component
///-----------------------------
const AssetsFilterControls = () => {
  const location = useLocation();
  const urlView = getViewFromUrl(location.pathname);
  const [searchParams] = useSearchParams();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-actions-popup",
  });

  const {
    dispatch,
    state: {
      appConfig,
      theme: { theme },
    },
  } = useAppContext();

  const { selectedAssetsView } = appConfig;

  const isDarkMode = useMemo(() => theme === "dark", [theme]);
  const initialAssetsView = useMemo(
    () => selectedAssetsView ?? urlView,
    [selectedAssetsView, urlView]
  );

  const [selectedFilter, setSelectedFilter] = useState<AssetFilter | null>(
    null
  );
  const [mode, setMode] = useState<string>("");
  const [name, setName] = useState<string>(selectedFilter?.name ?? "");
  const [isPublic, setPublic] = useState<boolean>(
    selectedFilter?.is_public || false
  );
  const [assetsView, setAssetsView] = useState(initialAssetsView);
  const [geofenceView, setGeofenceView] =
    useState<GeofenceRouteKeys>("hide-assets");
  const [isInitLoadComponent, setIsInitLoadComponent] = useState(false);
  const [isInitLoadWithStatus, setIsInitLoadWithStatus] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const [showMinTextLengthWarning, setShowMinTextLengthWarning] =
    useState(false);
  const navigate = useNavigate();

  const {
    pageType,
    currentFilter: criteria,
    isDrawingGeofence,
    setIsDrawingGeofence,
    isGeofenceFormDirty,
    setDrawnGeofence,
    setDrawnGeofenceType,
    setDrawnGeofenceArea,
    setDrawnGeofenceAreaInSqKm,
    setGeofenceNameInput,
    geofenceForUpdate,
    shouldShowFiltersStripAndViewToggle,
    restoreGeofencePreviousFilters,
    setAddGeofenceDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
    setIsAssetsDrawerVisible,
    setIsFiltersDrawerVisible,
    assetsSearchInput,
    setAssetsSearchInput,
    onPageChange,
    isFetchingUserFilters,
    setShowAddAsset,
    setIsFileUploadDialogOpen,
    setIsGeofencesFileUploadDialogOpen,
    savedFilters,
    onChangeFilters,
    setSelectedGeofence,
    setGeofenceForUpdate,
    setIsGeofenceDashboardVisible,
    resetGeofenceFiltersToDefault,
    isFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsVisibleOnGeoFence,
    isGeofenceDashboardVisible,
    selectedGeofence,
    clearAllAssetFilters,
    removeAssetFilterCache,
    onChangeGeofenceFilters,
    refetchAssetFilters,
  } = useAssetsDataContext();

  const userMadeChange = !isEqual(criteria, initialFilterState);

  const isGeofenceView = pageType === PageTypes.Geofences;

  const menuActionsOptions = isGeofenceView
    ? geofenceViewMenuActionsOptions
    : assetsViewActionsOptions;

  const filterAlreadyExists = useCallback(
    () => !!name && savedFilters?.some((filter) => filter.name === name),
    [name, savedFilters]
  );

  const onCloseModal = useCallback(() => setMode(""), [setMode]);

  const onReset = useCallback(() => {
    popupState.close();

    if (
      pageType === PageTypes.AssetMap ||
      pageType === PageTypes.AssetTable ||
      pageType === PageTypes.AssetGallery
    ) {
      clearAllAssetFilters();
    } else if (isGeofenceView) {
      onChangeGeofenceFilters(initialGeofenceFilterState);
    }
    setSelectedFilter(null);
  }, [
    clearAllAssetFilters,
    popupState,
    onChangeGeofenceFilters,
    isGeofenceView,
    pageType,
  ]);

  const onSuccess = useCallback(
    async (
      data:
        | CreateAssetFilterMutation
        | UpdateAssetFilterMutation
        | DeleteAssetFilterMutation
        | AssetFilter
    ) => {
      const handleCreate = () => {
        if ("createAssetFilter" in data) {
          const { criteria } = data.createAssetFilter ?? {};
          if (criteria) {
            onChangeFilters(JSON.parse(criteria) as Partial<AssetFilters>);
          }
          setSelectedFilter(data.createAssetFilter);
        }
      };

      const handleDelete = () => {
        if ("deleteAssetFilter" in data) {
          onReset();
        }
      };

      // Mode-specific actions
      if (mode === Modes.Save) {
        handleCreate();
      } else if (mode === Modes.Delete) {
        handleDelete();
      }

      // Common actions
      onCloseModal();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode}d `,
          text: "Success!",
          severity: "success",
          onClose: () => {},
        },
      });

      if (removeAssetFilterCache && refetchAssetFilters) {
        removeAssetFilterCache();
        refetchAssetFilters();
      }
    },
    [
      mode,
      name,
      dispatch,
      refetchAssetFilters,
      removeAssetFilterCache,
      onChangeFilters,
      onCloseModal,
      onReset,
    ]
  );

  const onError = useCallback(
    (error: unknown) => {
      onCloseModal();
      console.error(error);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode} Failed`,
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
    [mode, dispatch, onCloseModal, name]
  );

  const { mutate: createAssetFilter, isLoading: isCreatingAssetFilter } =
    useCreateAssetFilterMutation({ onSuccess, onError });

  const { mutate: updateAssetFilter, isLoading: isUpdatingAssetFilter } =
    useUpdateAssetFilterMutation({
      onSuccess,
      onError,
    });

  const { mutate: deleteAssetFilter, isLoading: isDeletingAssetFilter } =
    useDeleteAssetFilterMutation({
      onSuccess,
      onError,
    });

  const isSaveFilterDisabled = filterAlreadyExists();

  const modalModeMapping: ModalModeMap = useMemo(
    () => ({
      Save: {
        title: "Save New Filter",
        action: () => {
          createAssetFilter({
            input: {
              name,
              is_public: isPublic,
              criteria: JSON.stringify(criteria),
            },
          });
        },
        disabled: isSaveFilterDisabled,
      },
      Update: {
        title: "Edit Filter",
        action: () => {
          selectedFilter &&
            updateAssetFilter({
              input: {
                ...(({ email, ...rest }) => rest)(selectedFilter),
                name,
                is_public: isPublic,
              },
            });
        },
      },
      Delete: {
        title: "Delete Filter",
        action: () => {
          selectedFilter && deleteAssetFilter({ id: selectedFilter._id });
        },
      },
    }),
    [
      selectedFilter,
      createAssetFilter,
      updateAssetFilter,
      deleteAssetFilter,
      name,
      isPublic,
      criteria,
      isSaveFilterDisabled,
    ]
  );

  const isUpdatingName = useMemo(
    () => mode === Modes.Update && name !== selectedFilter?.name,
    [selectedFilter, mode, name]
  );

  const handleHelperText = useCallback((): string => {
    if ((mode === Modes.Save || isUpdatingName) && isSaveFilterDisabled) {
      return "This filter name already exists.";
    }
    return "";
  }, [mode, isUpdatingName, isSaveFilterDisabled]);

  const isNotGeofencesPage = pageType !== PageTypes.Geofences;

  const { publicFilters, userFilters } = useMemo(() => {
    const filtersSorted = savedFilters.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const publicFilters: AssetFilter[] = [];
    const userFilters: AssetFilter[] = [];

    for (let filter of filtersSorted) {
      if (filter.is_public) publicFilters.push(filter);
      else userFilters.push(filter);
    }

    return { publicFilters, userFilters };
  }, [savedFilters]);

  // set the reducer selectedAssetsView state from URL on initial load
  useEffect(() => {
    if (selectedAssetsView === "" && assetsView !== initialAssetsView) {
      dispatch({
        type: SET_APP_CONFIG,
        payload: { selectedAssetsView: assetsView },
      });
    } else if (selectedAssetsView) {
      // If we have a previously selected view -> ensure it's set again
      /*
        TODO: PRJIND-9343 Ensure the Filters Strip is hidden if an asset is selected and we're showing the Asset Dashboard
        (this else should be deleted once the new Asset Dashboard page is in place)
      */
      dispatch({
        type: SET_APP_CONFIG,
        payload: { selectedAssetsView },
      });
    }
  }, [assetsView, initialAssetsView, selectedAssetsView, dispatch]);

  // update the assets toggle button on URL change
  useEffect(() => {
    const view = getViewFromUrl(location.pathname);
    if (
      [
        AssetPagesUrlTypes.Map,
        AssetPagesUrlTypes.Table,
        AssetPagesUrlTypes.Gallery,
      ].includes(view)
    ) {
      setAssetsView(view);
    }
  }, [location.pathname]);

  // set value for cards in filter status only on initial load. No refresh numbers on cards on click on it
  useEffect(() => {
    // check if it is initial load with URL contains status
    if (!isInitLoadWithStatus && !isInitLoadComponent) {
      setIsInitLoadWithStatus(
        searchParams.get("filters")?.includes("status") ?? false
      );
    }

    if (!isInitLoadComponent) {
      setIsInitLoadComponent(true);
    }
  }, [isInitLoadComponent, searchParams, isInitLoadWithStatus]);

  // if it is initial load with URL contains status on click it should reset status card value with all statuses
  // once show cards with all status values not update them any more just like initial load without status as filter in URL
  useEffect(() => {
    if (hasClick) {
      setHasClick(false);
    }
  }, [hasClick, searchParams]);

  const isMobile: boolean = useBreakpoint("down", "md");

  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };

  const handleLeave = () => {
    setIsDrawingGeofence(!isDrawingGeofence);
    restoreGeofencePreviousFilters();
    setDrawnGeofence(null);
    setDrawnGeofenceType(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);
    setAddGeofenceDrawerOpen(false);
    setIsAssetsDrawerVisible(true);
    setIsFiltersDrawerVisible(true);

    // Open the drawers back
    setIsAssetsDrawerOpen(true);
    setIsFiltersDrawerOpen(true);

    handleLeaveModalClose();
    dispatch({
      type: SET_PARENT_GEOFENCE,
      payload: null,
    });
    dispatch({
      type: USE_PARENT_GEOFENCE_ORGID,
      payload: false,
    });
    if (geofenceForUpdate) {
      navigate(`${NavigationRoutes.Geofences}/${geofenceForUpdate._id}`);
    } else if (selectedGeofence) {
      // if there is a selected geofence go back one step behind to the geofence dashboard
      setIsGeofenceDashboardVisible(true);
      navigate(`${NavigationRoutes.Geofences}/${selectedGeofence._id}`);
    } else {
      navigate(NavigationRoutes.Geofences);
    }
  };

  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } = usePrompt(
    isGeofenceFormDirty,
    handleLeave
  );

  const handleAssetsViewChange = (value: AssetsRouteKeys) => {
    if (!value) return;

    const routes: { [key in AssetsRouteKeys]: NavigationRoutes } = {
      map: NavigationRoutes.AssetMap,
      table: NavigationRoutes.AssetTable,
      gallery: NavigationRoutes.AssetGallery,
    };
    const selectedRoute = routes[value] ?? AssetPagesUrlTypes.Map;
    const fullRoute = location.search
      ? `${selectedRoute}${location.search}`
      : selectedRoute;

    setAssetsView(value);

    dispatch({
      type: SET_APP_CONFIG,
      payload: { selectedAssetsView: value },
    });

    navigate(fullRoute);
  };

  const geofenceHandleViewChange = (value: GeofenceRouteKeys) => {
    if (!value) return;
    setIsAssetsVisibleOnGeoFence(value === "show-assets");
    setGeofenceView(value);
  };

  const ASSETS_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "map",
      label: "Map",
    },
    {
      value: "table",
      label: "List",
    },
    {
      value: "gallery",
      label: "Gallery",
    },
  ];
  const GEOFENCES_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "hide-assets",
      label: "Hide Assets",
    },
    {
      value: "show-assets",
      label: "Show Assets",
    },
  ];

  const switchOptionsMap: { [key: number]: ToggleButtonOption[] } = {
    [PageTypes.Geofences]: GEOFENCES_SWITCH_OPTIONS,
    [PageTypes.AssetMap]: ASSETS_SWITCH_OPTIONS,
  };

  const switchOptions = switchOptionsMap[pageType] ?? ASSETS_SWITCH_OPTIONS;

  const color = isDarkMode ? "white" : "black";
  const borderColor = isDarkMode ? "gray" : "var(--border-color)";

  const wrapperStyles = `${
    isAssetsDrawerOpen ? "fixed" : "hidden"
  } relative h-full px-4 flex justify-center items-center self-end ml-auto`;

  const shouldKeepTyping = (text: string) =>
    text.length > 0 && text.length < appConfig.searchMinLength;

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim();

    // check if user should keep typing
    if (shouldKeepTyping(searchText)) {
      setShowMinTextLengthWarning(true);
      return;
    } else {
      setShowMinTextLengthWarning(false);
    }

    if (isGeofenceView) {
      setGeofenceNameInput(searchText);
    } else {
      setAssetsSearchInput(searchText);
      onPageChange(1);
    }
  }, appConfig.debounceTime);

  const handleActionMenuClick = (action: string) => {
    if (!action) return;

    if (action === "create") {
      isGeofenceView ? handleNewGeofence() : setShowAddAsset(true);
    }
    if (action === "upload") {
      isGeofenceView
        ? setIsGeofencesFileUploadDialogOpen(true)
        : setIsFileUploadDialogOpen(true);
    }

    popupState.close();
  };

  const handleFilterMenuClick = (clickMode: string, filter?: AssetFilter) => {
    popupState.close();

    // Determine the filter to apply
    const filterToApply = filter
      ? userMadeChange && clickMode
        ? { ...filter, criteria: JSON.stringify(criteria) }
        : filter
      : null;

    // Filter Update or Delete mode (Do not apply the filters)
    if (clickMode) {
      setMode(clickMode);
      setName(filterToApply?.name || "");
      setPublic(filterToApply?.is_public || false);
      setSelectedFilter(filterToApply);
      return;
    }

    // Apply the filters
    onChangeFilters(
      filterToApply?.criteria ? JSON.parse(filterToApply.criteria) : []
    );
  };

  // Geofences
  const handleNewGeofence = () => {
    setSelectedGeofence(null);
    setGeofenceForUpdate(null);
    if (isDrawingGeofence) {
      restoreGeofencePreviousFilters();
    } else {
      resetGeofenceFiltersToDefault();
    }
    setIsDrawingGeofence(!isDrawingGeofence);
    setIsAssetsDrawerOpen(false);
    setIsFiltersDrawerOpen(false);
    setAddGeofenceDrawerOpen(true);
    setIsGeofenceDashboardVisible(false);
    setIsAssetsDrawerVisible(false);
    setIsFiltersDrawerVisible(false);
  };

  // Do not render the filter controls conditionally
  if (
    pageType === PageTypes.ShareAssetsInvitation ||
    !shouldShowFiltersStripAndViewToggle
  ) {
    return null;
  }

  return (
    <>
      {pageType !== PageTypes.Geofences && isMobile && (
        <Box className="flex justify-center align-center bg-secondary p-2">
          <SwitchButtons
            id="assets-navigation-switch"
            data-testid="assets-navigation-switch-mobile"
            value={assetsView}
            onChange={(_, value) => handleAssetsViewChange(value)}
            options={ASSETS_SWITCH_OPTIONS}
            size="small"
            groupclass={`!w-[300px] min-w-80 flex justify-between h-8 overflow-hidden !rounded-3xl p-0.5 !border ${
              isDarkMode ? "!border-white" : "!border-black"
            }`}
            className={`!text-${color} !font-medium`}
            fullWidth
            exclusive
          />
        </Box>
      )}
      <Box
        data-testid="filter-bar-sub-header"
        className={`relative ${
          !isGeofenceDashboardVisible ? "flex  h-[68px] min-h-[68px]" : "hidden"
        } items-center  md:justify-between justify-center gap-4 md:gap-0 overflow-visible bg-secondary text-base font-normal leading-none text-typography`}
        sx={{
          flexDirection: "row",
        }}
      >
        {isFiltersDrawerOpen && (
          <Box
            className="h-full px-4 flex justify-between items-center"
            sx={{
              width: "330px",

              borderRight: `1px solid ${borderColor}`,
            }}
          >
            <ListAltIcon
              className="hover: cursor-pointer"
              {...bindTrigger(popupState)}
            />

            <Menu
              {...bindMenu(popupState)}
              id="filters-operations-menu"
              data-testid="filters-operations-menu"
              className="!text-xs"
              sx={{
                [`& .${popoverClasses.paper}`]: {
                  backgroundColor: "var(--background)",
                  minWidth: "240px",
                  maxWidth: "280px",
                },
                "& .MuiList-root": {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: ".75rem",
                  "*": {
                    fontWeight: "500 !important",
                  },
                },
              }}
            >
              {isFetchingUserFilters ? (
                <span
                  color="var(--secondary)"
                  className="px-4 py-2 text-inherit"
                >
                  <CircularProgress size={25} color="inherit" />
                </span>
              ) : (
                <div>
                  <Typography
                    key="actions-title"
                    className="px-4 !text-inherit text-menu-group"
                    variant="subtitle2"
                  >
                    Actions
                  </Typography>
                  {menuActionsOptions.map((option) => (
                    <MenuItem
                      key={option.title}
                      className="flex !justify-between !text-inherit"
                      onClick={() => handleActionMenuClick(option.action)}
                    >
                      <p>{option.title}</p>
                      {option.icon}
                    </MenuItem>
                  ))}

                  {isNotGeofencesPage && <Divider />}

                  {isNotGeofencesPage && !!userFilters?.length && (
                    <>
                      <Typography
                        key="my-filters-title"
                        className="px-4 !text-inherit text-menu-group"
                        variant="subtitle2"
                      >
                        My Filters
                      </Typography>
                      {userFilters?.map((filter) => (
                        <FilterItem
                          key={`id-${filter?._id ?? ""}-${filter.name}`}
                          filter={filter}
                          onClick={handleFilterMenuClick}
                          selectedFilter={selectedFilter}
                        />
                      ))}
                    </>
                  )}
                  {isNotGeofencesPage &&
                    userFilters?.length > 0 &&
                    publicFilters?.length > 0 && <Divider />}
                  {isNotGeofencesPage && !!publicFilters?.length && (
                    <>
                      <Typography
                        key="public-filters-title"
                        className="px-4 !text-inherit text-menu-group"
                        variant="subtitle2"
                      >
                        Shared Filters
                      </Typography>
                      {publicFilters?.map((filter, index) => (
                        <FilterItem
                          key={`id-${filter?._id ?? ""}-${filter.name}`}
                          filter={filter}
                          onClick={handleFilterMenuClick}
                          selectedFilter={selectedFilter}
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
            </Menu>

            <TextField
              id="assets-search-field"
              label=""
              defaultValue={assetsSearchInput ?? ""}
              placeholder={isGeofenceView ? "Geofence Search" : "Assets Search"}
              InputProps={{
                endAdornment: (
                  <>
                    {showMinTextLengthWarning && (
                      <div className="absolute top-[25%] right-[10%] text-xs font-normal text-typography">
                        Keep typing ...
                      </div>
                    )}
                    <SearchIcon />
                  </>
                ),
              }}
              onChange={handleSearch}
              sx={{
                width: "88%",
              }}
              data-testid={
                isGeofenceView ? "geofence-search-field" : "assets-search-field"
              }
            />
          </Box>
        )}
        <FiltersStrip />
        <LeaveModal
          isOpen={isLeaveModalOpen}
          confirmNavigation={() => setIsConfirmed(true)}
          onClose={handleLeaveModalClose}
        />
        {isGeofenceView && (
          <>
            {isDrawingGeofence && (
              <Box className="h-full px-4 flex justify-between items-center">
                <Button
                  className="!text-sm !font-bold !text-brand !normal-case"
                  onClick={(e: MouseEvent) => {
                    if (isGeofenceFormDirty) {
                      setIsLeaveModalOpen(true);
                    } else {
                      handleLeave();
                    }
                  }}
                  color="inherit"
                  size="small"
                  startIcon={<BackIcon color={"var(--brand)"} />}
                  data-testid="back-geofence-draw-button"
                >
                  Back
                </Button>
              </Box>
            )}
          </>
        )}
        {!isMobile && (
          <Box
            className={`${wrapperStyles}`}
            sx={{
              borderLeft: `1px solid ${borderColor}`,
              width: ASSETS_LIST_WIDTH,
            }}
          >
            <SwitchButtons
              id="assets-navigation-switch"
              data-testid="assets-navigation-switch-desktop"
              value={
                pageType !== PageTypes.Geofences ? assetsView : geofenceView
              }
              onChange={(_, value) =>
                pageType !== PageTypes.Geofences
                  ? handleAssetsViewChange(value)
                  : geofenceHandleViewChange(value)
              }
              options={switchOptions}
              size="small"
              groupclass={`h-8 overflow-hidden !rounded-3xl p-0.5 border border-${color}`}
              className={`!text-${color} !font-medium`}
              fullWidth
              exclusive
            />
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          backgroundColor: "var(--border-color)",
        }}
      />

      {mode && (
        <FilterModal
          open={Boolean(mode)}
          mode={(mode || "") as string}
          onClose={onCloseModal}
          modalModeMapping={modalModeMapping || ({} as ModalModeMap)}
          name={name}
          isPublic={isPublic}
          originalIsPublic={selectedFilter?.is_public ?? isPublic}
          setPublic={setPublic}
          setName={setName}
          isCreatingAssetFilter={isCreatingAssetFilter}
          isUpdatingAssetFilter={isUpdatingAssetFilter}
          isDeletingAssetFilter={isDeletingAssetFilter}
          helperText={handleHelperText()}
        />
      )}
    </>
  );
};

export default memo(AssetsFilterControls);
