import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { isString } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  ReportsPerSuborgRecord as _ReportsPerSuborgRecord,
  ReportsPerSuborgData as _ReportsPerSuborgData,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export const DEFAULT_PLACEHOLDER_SUBORG_NAME = "Unknown Suborganization";
export const REPORTS_PER_SUBORG_WIDGET_TEST_ID =
  "dashboard-widget--reports-per-suborg";
export const REPORTS_PER_SUBORG_WIDGET_SUBTITLE = "Total Number Of Reports";

export type ReportsPerSuborgRecord = Omit<
  _ReportsPerSuborgRecord,
  "__typename"
>;
export type DashboardWidgetReportsPerSubOrgData = Omit<
  _ReportsPerSuborgData,
  "__typename"
>;

export interface DashboardWidgetReportsPerSubOrg
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetReportsPerSubOrgData;
}

export interface ReportsPerSubOrgWidgetProps {
  widget: DashboardWidgetReportsPerSubOrg;
}

export const mapWidgetData = (
  data: DashboardWidgetReportsPerSubOrgData
): HorizontalBarChartData[] => {
  if (!data?.perSuborg?.length) {
    return [];
  }

  const perSubOrgRecords: ReportsPerSuborgRecord[] = isString(data?.perSuborg)
    ? JSON.parse(data?.perSuborg)
    : data?.perSuborg;

  // Leave in only records with totalCount > 0
  const filteredSubOrgRecords = perSubOrgRecords.filter((record) =>
    Boolean(Number(record?.totalCount))
  );

  return filteredSubOrgRecords
    .map((record, index) => {
      const name =
        record?.suborgName ?? `${DEFAULT_PLACEHOLDER_SUBORG_NAME} ${index + 1}`;
      const value = record?.totalCount ?? 0;
      const incomingPercentage = record?.percentage ?? 0;
      let percent: number;

      if (incomingPercentage > 0) percent = incomingPercentage;
      // If we get a value that's too low and percentage gets rounded off to 0
      else if (value > 0) percent = 0.1;
      else percent = 0;

      return {
        name,
        value,
        percent,
        color: mapChartColors(record?.suborgName ?? ""),
      };
    })
    .sort(({ value: valueA }, { value: valueB }) => valueB - valueA);
};

export const mapChartColors = (subOrgName: string): string =>
  subOrgName === "Other" ? "var(--concrete)" : ColorsPalette.ReChartsGreen;

export const ReportsPerSuborgWidget: React.FC<ReportsPerSubOrgWidgetProps> = ({
  widget,
}) => {
  const title = widget?.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetReportsPerSubOrgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid={REPORTS_PER_SUBORG_WIDGET_TEST_ID}
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
          data-testid="reports-per-suborgs-error"
        >
          There was an error when fetching the data for the reports per
          suborganization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          subtitle={REPORTS_PER_SUBORG_WIDGET_SUBTITLE}
          showTotal={true}
          isLoading={isLoading}
          showPercentages={true}
          totalRecords={total}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
