import { FC, useMemo } from "react";
import { SvgIconComponent } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutlineOutlined";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportantOutlined";
import QuestionMark from "@mui/icons-material/QuestionMarkOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmberOutlined";
import { Card, Typography } from "@mui/material";
import { capitalize, kebabCase } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type AssetsHealthData = {
  count: number;
  percentage: number;
};

export type DashboardWidgetAssetsPerHealthStatusData = {
  total_count_all: number;
  healthy: AssetsHealthData;
  warning: AssetsHealthData;
  alert: AssetsHealthData;
  critical: AssetsHealthData;
  unknown: AssetsHealthData;
};

export interface DashboardWidgetSensorsHealthType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsPerHealthStatusData;
}

export interface AssetsSensorsWidgetProps {
  widget: DashboardWidgetSensorsHealthType;
}

export interface StatusRecord {
  textColor: string;
  icon: SvgIconComponent;
  iconColor: string;
}

const AttributesByHealthMapping: Record<
  Exclude<keyof DashboardWidgetAssetsPerHealthStatusData, "total_count_all">,
  StatusRecord
> = {
  healthy: {
    textColor: ColorsPalette.SuccessOpacity20,
    icon: CheckCircleIcon,
    iconColor: ColorsPalette.Success,
  },
  warning: {
    textColor: ColorsPalette.CautionOpacity20,
    icon: NotificationImportantIcon,
    iconColor: ColorsPalette.Caution,
  },
  alert: {
    textColor: ColorsPalette.AlertOpacity20,
    icon: WarningAmberIcon,
    iconColor: ColorsPalette.Alert,
  },
  critical: {
    textColor: ColorsPalette.ErrorOpacity20,
    icon: ErrorOutlineIcon,
    iconColor: ColorsPalette.Error,
  },
  unknown: {
    textColor: ColorsPalette.Concrete,
    icon: QuestionMark,
    iconColor: ColorsPalette.LightCharcoal,
  },
};

const mapWidgetData = (
  widgetData: DashboardWidgetAssetsPerHealthStatusData
) => {
  const mappedData = (
    Object.entries(widgetData) as [
      keyof DashboardWidgetAssetsPerHealthStatusData,
      AssetsHealthData
    ][]
  )
    .filter(([key]) => key !== "total_count_all")
    .reduce(
      (
        acc: HorizontalBarChartData[],
        [key, value]: [
          keyof DashboardWidgetAssetsPerHealthStatusData,
          AssetsHealthData
        ]
      ) => {
        const resultObject: HorizontalBarChartData = {
          name: capitalize(key),
          icon: AttributesByHealthMapping[
            key as keyof typeof AttributesByHealthMapping
          ].icon,
          color:
            AttributesByHealthMapping[
              key as keyof typeof AttributesByHealthMapping
            ].textColor,
          value: value.count,
          percent: value.percentage,
          iconColor:
            AttributesByHealthMapping[
              key as keyof typeof AttributesByHealthMapping
            ].iconColor,
        };

        acc.push(resultObject);

        return acc;
      },
      []
    );

  return mappedData;
};

export const AssetsSensorsWidget: FC<AssetsSensorsWidgetProps> = ({
  widget,
}) => {
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetAssetsPerHealthStatusData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const total = parsedData?.total_count_all ?? 0;
    const mappedData = total ? mapWidgetData(parsedData ?? {}) : [];

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid={`dashboard-widget--${kebabCase(widget.name ?? "sensor")}`}
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets sensors
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={data}
          title={widget.name ?? "Sensor"}
          totalRecords={total}
          isLoading={isLoading}
          showXAxis={false}
          showPercentages
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
