import { useMemo, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useDashboardContext } from "../../../../../context/DashboardContext";
import { useUserData } from "../../../../hooks/useUserData";
import { isAdmin } from "../../../WithPermissions";

export interface HeaderOptionsProps {
  widgetId: number;
  onWidgetDelete?: () => void;
}

const HeaderOptions: React.FC<HeaderOptionsProps> = ({
  widgetId,
  onWidgetDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(anchorEl);
  const {
    dashboardWidgets,
    setDashboardWidgets,
    dashboardUserId,
    initialDashboardWidgets,
    setInitialDashboardWidgets,
  } = useDashboardContext();

  const userData = useUserData();
  const isAnyAdmin = useMemo(
    () => isAdmin(userData?.groups ?? []),
    [userData?.groups]
  );
  const isDashboardOwner = useMemo(
    () => userData?._id === dashboardUserId,
    [userData?._id, dashboardUserId]
  );

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteWidget = () => {
    // if we try to delete a widget make a copy of the initialDashboardWidgets so we can restore it if needed
    if (initialDashboardWidgets.length === 0) {
      setInitialDashboardWidgets(dashboardWidgets);
    }
    // remove the widget from the dashboardWidgets
    const widgetsAfterDeletion = dashboardWidgets.filter(
      (widget) => widget.id !== widgetId
    );
    setDashboardWidgets(widgetsAfterDeletion);
    if (onWidgetDelete) {
      onWidgetDelete();
    }
    closeActionsMenu();
  };
  // do not render if not an admin or not the owner of the dashboard
  if (!isAnyAdmin && !isDashboardOwner) {
    return null;
  }

  return (
    <div data-testid="dashboard-widget-options">
      <IconButton
        aria-label="Options"
        id="dashboard-widget-options-button"
        data-testid="dashboard-widget-options-button"
        aria-controls={
          isActionsMenuOpen ? "dashboard-widget-options-menu" : undefined
        }
        aria-expanded={isActionsMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={openActionsMenu}
        sx={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="dashboard-widget-options-menu"
        data-testid="dashboard-widget-options-menu"
        MenuListProps={{
          "aria-labelledby": "dashboard-widget-options-button",
        }}
        anchorEl={anchorEl}
        open={isActionsMenuOpen}
        onClose={closeActionsMenu}
      >
        <MenuItem onClick={handleDeleteWidget}>
          <DeleteOutlineIcon /> Delete Widget
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderOptions;
