import { MediaActivity } from "../../../../../../graphql/operations";
import { MediaActivityItem } from "./interface";

export const parseMediaActivityData = (
  data: MediaActivity[]
): MediaActivityItem[] => {
  const items: MediaActivityItem[] = [];

  data?.forEach((mediaActivity) => {
    if (!mediaActivity || !mediaActivity?.mediaImages) {
      return;
    }

    for (let index = 0; index < mediaActivity.mediaImages.length; index++) {
      const mediaActivityItem = parseMediaActivityItem(mediaActivity, index);

      if (mediaActivityItem) {
        items.push(mediaActivityItem);
      }
    }
  });

  return items;
};

export const parseMediaActivityItem = (
  mediaActivity: MediaActivity,
  index: number
): MediaActivityItem | null => {
  if (!mediaActivity) {
    return null;
  }

  const { mediaImages, mediaTimestamps, geofence, mediaLocation } =
    mediaActivity;
  if (!mediaImages || !mediaTimestamps || !mediaLocation || !geofence) {
    return null;
  }

  const image = mediaImages[index];
  const timestamp = mediaTimestamps[index];
  const geofenceName = geofence[index]?.geofenceName ?? [];

  const latitude = mediaLocation?.[index]?.GpsLocation?.[0]?.[1] ?? 0;
  const longitude = mediaLocation?.[index]?.GpsLocation?.[0]?.[0] ?? 0;

  return {
    imageUrl: image?.imageUrl ?? "",
    address: image?.address ?? "",
    floorUsagePercentage: image?.floorUsagePercentage ?? "",
    floorUsageStatus: image?.floorUsageStatus ?? "",
    cubeUsagePercentage: image?.cubeUsagePercentage ?? "",
    cubeUsageStatus: image?.cubeUsageStatus ?? "",
    loadedEmptyConfidence: image?.loadedEmptyConfidence ?? "",
    uploadDate: timestamp?.uploadDate ?? "",
    geofenceName: geofenceName.join(", ") ?? "",
    geofenceEntry: "", // not used at the moment
    latitude,
    longitude,
  };
};

export const paginateMediaActivityItems = (
  items: MediaActivityItem[],
  itemPerPage: number
): MediaActivityItem[][] => {
  const paginatedItems: MediaActivityItem[][] = [];

  for (let index = 0; index < items.length; index += itemPerPage) {
    paginatedItems.push(items.slice(index, index + itemPerPage));
  }

  return paginatedItems;
};

export const downloadMediaActivityItem = (item: MediaActivityItem): void => {
  const url = item.imageUrl;
  const fileName = url.substring(url.lastIndexOf("/") + 1, url.length);

  // Create an anchor element
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.setAttribute("data-testid", `download-${fileName}`);

  // Append the anchor to the document and trigger a click event
  document.body.appendChild(anchor);
  anchor.click();

  // Remove the anchor from the document
  setTimeout(() => {
    document.body.removeChild(anchor);
  }, 100);
};
