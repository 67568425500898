import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../views/AssetsView/TableView/hooks";

export const SENSOR_STATUSES = {
  critical: "Critical",
  replace: "Replace",
  healthy: "Healthy",
  loaded: "Loaded",
  comingSoon: "Coming Soon",
  notInstalled: "Not Installed",
};

export interface IAssetDoorTypes {
  [k: string]: string;
}

// TODO: Better use useGetAssetDoorTypes hook instead of ASSET_DOOR_TYPES
// hardcoded constants in the future
export const ASSET_DOOR_TYPES: IAssetDoorTypes = {
  roll: "Roll Door",
  swing: "Swing Door",
  side: "Side Door",
  trifold: "Tri-Fold Door",
  other: "Other Door",
};

// Note: This is left here intentionally until we migrate to using useGetAssetDoorTypes hook
// instead the hardcoded ASSET_DOOR_TYPES constant
export const useGetAssetDoorTypes = (): IAssetDoorTypes => {
  const formattedDoorTypes: IAssetDoorTypes = {};

  const options = useNomenclatures(NOMENCLATURE_NAMES.assetDoorType);
  options.forEach((o: { value: string; label: string }) => {
    formattedDoorTypes[o.value] = o.label;
  });

  return formattedDoorTypes;
};

export type AssetType = {
  boat: "boat";
  chassis: "chassis";
  container: "container";
  dolly: "dolly";
  other: "other";
  reefer: "reefer";
  tractor: "tractor";
  trailer: "trailer";
  vehicle: "vehicle";
};

/**
 * NOTE: This object is only used in Report Tables to generate column names
 * Prefer using the values from nomenclatures collection if you need to match asset types.
 */
export const ASSET_FORM_TYPES = {
  boat: "Boat",
  chassis: "Chassis",
  container: "Container",
  dolly: "Dolly",
  other: "Other",
  reefer: "Reefer",
  tractor: "Tractor",
  trailer: "Trailer",
  vehicle: "Vehicle",
};

export const ASSET__WHEELS_CONFIGS = [2, 4, 8, 12];

export const ASSET_VIN_LENGTH = 17;

export enum AssetSettingsTitle {
  Information = "Asset Information",
  Battery = "Battery",
  Cargo = "Cargo",
  Tires = "Tires",
  Temperature = "Temperature",
  Brakes = "Brakes",
  LightCircuit = "Light Circuit",
  Liftgate = "Liftgate",
  OtherSensors = "Other Sensors",
}

export enum AssetSettingsType {
  Information = "asset-information",
  Battery = "battery",
  Cargo = "cargo",
  Tires = "tires",
  Temperature = "temperature",
  Brakes = "brakes",
  LightCircuit = "light-circuit",
  OtherSensors = "other-sensors",
  Liftgate = "liftgate",
}

export const assetSettingsTitles: Record<AssetSettingsType, string> = {
  [AssetSettingsType.Information]: "Primary Asset Details",
  [AssetSettingsType.Battery]: "Battery Settings",
  [AssetSettingsType.Cargo]: "Cargo Settings",
  [AssetSettingsType.Tires]: "Tires Settings",
  [AssetSettingsType.Brakes]: "Brakes Settings",
  [AssetSettingsType.Temperature]: "Temperature Settings",
  [AssetSettingsType.LightCircuit]: "Light Circuit Settings",
  [AssetSettingsType.OtherSensors]: "Other Sensors Settings",
  [AssetSettingsType.Liftgate]: "Liftgate Settings",
};

export const ASSET_SETTINGS_TAB_TYPES = [
  {
    label: AssetSettingsTitle.Information,
    value: AssetSettingsType.Information,
  },
  {
    label: AssetSettingsTitle.Battery,
    value: AssetSettingsType.Battery,
  },
  {
    label: AssetSettingsTitle.Cargo,
    value: AssetSettingsType.Cargo,
  },
  {
    label: AssetSettingsTitle.Tires,
    value: AssetSettingsType.Tires,
  },
  {
    label: AssetSettingsTitle.Temperature,
    value: AssetSettingsType.Temperature,
  },
  {
    label: AssetSettingsTitle.Brakes,
    value: AssetSettingsType.Brakes,
  },
  {
    label: AssetSettingsTitle.LightCircuit,
    value: AssetSettingsType.LightCircuit,
  },
  {
    label: AssetSettingsTitle.Liftgate,
    value: AssetSettingsType.Liftgate,
  },
  {
    label: AssetSettingsTitle.OtherSensors,
    value: AssetSettingsType.OtherSensors,
  },
];
