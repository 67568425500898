import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import PieChartWidget, {
  DataItem,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget";
import {
  mapChartColors,
  mapLegendTextColor,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget/utils";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetAssetsPerProductData = {
  products: AssetsPerProductData[];
};

export type AssetsPerProductData = {
  name: string;
  count: number;
};

export interface DashboardWidgetAssetsPerProduct
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsPerProductData;
}

export interface AssetsPerProductWidgetProps {
  widget: DashboardWidgetAssetsPerProduct;
}

export const mapWidgetData = (
  data: DashboardWidgetAssetsPerProductData
): DataItem[] => {
  if (!data || !data.products) {
    return [];
  }

  return data.products.map((product, index) => ({
    name: product.name,
    value: product.count,
    color: mapChartColors(product.name, index),
    legendTextColor: mapLegendTextColor(product.name),
  }));
};

export const AssetsPerProductWidget: React.FC<AssetsPerProductWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const data = useMemo(() => {
    const parsedData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);

    return mappedData;
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-per-product"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets per product
          widget! Please try again later.
        </Typography>
      ) : (
        <PieChartWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
