import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { DeviceEvents } from "../../../graphql/operations";
import { getRows } from "../../../shared/components/Table";
import { useExportedFileName } from "../../../shared/hooks/useExportedFileName";
import { DEVICE_HISTORY_COLUMNS } from "./constants";
import { useDeviceHistoryViewModel } from "./useDeviceHistoryViewModel";
import { getSortedDeviceEventsForTable } from "./utils";

interface DeviceHistoryTableControllerProps {
  device_id: string | undefined;
}

export const useDeviceHistoryTableViewController = ({
  device_id,
}: DeviceHistoryTableControllerProps) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const fileName = useExportedFileName("Device History");

  const [rows, setRows] = useState<DeviceEvents[]>();

  const { deviceEvents, isSuccess, isLoading } = useDeviceHistoryViewModel({
    device_id,
  });

  useEffect(() => {
    const sortedEventsForTable = getSortedDeviceEventsForTable(deviceEvents);
    setRows(
      getRows<typeof sortedEventsForTable>(
        sortedEventsForTable,
        DEVICE_HISTORY_COLUMNS,
        "",
        "",
        "_id"
      )
    );
  }, [deviceEvents]);

  return {
    rows,
    isLoading,
    isSuccess,
    fileName,
    searchMinLength: appConfig.searchMinLength,
  };
};
