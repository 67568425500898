import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  DateRangeKey,
  REGULATOR_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../../../../constants/map";
import {
  Asset,
  HistoricalEventHistory,
  SortOrder,
  useGetRegulatorSensorHistoricalDataQuery,
} from "../../../../../../../../graphql/operations";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { convertToIsoStringIgnoringTimezoneOffset } from "../../../../../../../../utils/date";
import { FIVE_MINUTES_REFETCH_INTERVAL } from "../../../../SummaryTabPanel/Charts/chartUtils";
import { mapRegulatorSensorDataToChartData } from "../../../helpers";
import RegulatorChartBody, {
  RegulatorChartData,
} from "../components/regulator/RegulatorChartBody";
import TiresChartHeader from "../components/tpms/TiresChartHeader";
import { useDateFilters } from "./hooks/useDateFilters";
import { useRegulatorSensorProfile } from "./hooks/useRegulatorSensorProfile";

type RegulatorChartProps = {
  asset: Asset | null | undefined;
};

// Note: Same as TemperatureReferenceAreaValueType, think about exporting a generic type
export type RegulatorReferenceAreaValueType = {
  state: string;
  min: string;
  max: string;
};

const RegulatorChart: React.FC<RegulatorChartProps> = ({ asset }) => {
  const [chartData, setChartData] = useState<RegulatorChartData[]>([]);
  const pressureUnit = useGetPressureUnitPreference();
  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const { data: initialData, isLoading } =
    useGetRegulatorSensorHistoricalDataQuery(
      {
        input: {
          startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
          endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
          imei: asset?.imei ?? "",
          customerOrgId: asset?.customer_orgs_id ?? "",
          sort: SortOrder.Asc,
          includeEmptyDates: true,
          period:
            REGULATOR_CHART_DATE_RANGE_OPTIONS.find(
              (option) => option.value === dateRangeOption
            )?.key ?? DateRangeKey.Last30Days,
        },
      },
      {
        refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
        enabled: Boolean(asset?.imei) && Boolean(asset?.customer_orgs_id),
      }
    );

  useEffect(() => {
    if (initialData?.searchHistoricalEventHistory?.data) {
      setChartData(
        mapRegulatorSensorDataToChartData(
          initialData?.searchHistoricalEventHistory
            ?.data as HistoricalEventHistory[],
          pressureUnit
        )
      );
    }
  }, [initialData, pressureUnit]);

  const { referenceAreaValues } = useRegulatorSensorProfile(asset?._id);

  return (
    <Box
      className="h-full flex-1 rounded-lg bg-dashboard_subheader__bg p-4 border border-asset-card-border"
      data-testid="asset-dashboard--tires-tab--regulator-chart"
    >
      <>
        <TiresChartHeader
          title="Regulator Pressure Moving"
          subtitle="Trending pressure data over time"
          startDate={startDate}
          setStartDate={setStartDate}
          dateRangeOption={dateRangeOption}
          setDateRangeOption={setDateRangeOption}
          endDate={endDate}
          setEndDate={setEndDate}
          changeDateRangeOption={handleDateRangeChange}
        />

        {isLoading ? (
          <Box
            className="flex h-full w-full self-center justify-center"
            data-testid="asset-dashboard--tires-tab--regulator-chart-loader"
          >
            <CircularProgress />
          </Box>
        ) : (
          <RegulatorChartBody
            data={chartData}
            referenceAreaValues={referenceAreaValues}
            pressureUnit={pressureUnit}
          />
        )}
      </>
    </Box>
  );
};

export default RegulatorChart;
