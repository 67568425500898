import React, { memo } from "react";
import { Grid, Paper, Typography, SvgIcon, capitalize } from "@mui/material";
import Box from "@mui/material/Box";
import { formatNumber } from "../../../../utils/formatters";
import { HighlightsOverlay } from "../../LoadingOverlaySkeletons/HighlightsWidgetLoader/HighlightsOverlay";
import BaseDashboardWidget from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";

export interface HighlightsComponentProps {
  title: string;
  items: HighlightItem[];
  subTitle?: HighlightSubTitle;
  isLoading: boolean;
  noPermission?: boolean;
  widgetId: number;
}
interface HighlightSubTitle {
  subTitle: string;
  subCount: number;
}

export interface HighlightItem {
  icon: JSX.Element; // svg
  label: string;
  count: number;
}

export const HighlightsComponent: React.FC<HighlightsComponentProps> = (
  props
) => {
  const { title, items, subTitle, isLoading, noPermission, widgetId } = props;

  let totalCount = 0;
  items.forEach((x) => {
    totalCount += x.count;
  });

  const subTitleLabelStyles = {
    fontSize: "18px",
    lineHeight: "32px",
    fontWeight: 600,
  };
  const subTitleCountStyles = {
    fontSize: "36px",
    lineHeight: "44px",
    fontWeight: 600,
  };

  const iconsStyles = {
    fontSize: "32px",
    color: "var(--primary-blue)",
    verticalAlign: "sub",
    paddingRight: "5px",
  };

  const countStyles = {
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "64px",
    letterSpacing: "-0.96px",
    textTransform: "capitalize",
  };

  const labelStyles = {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 400,
  };

  return (
    <Paper
      data-testid="hilights-widget-component"
      className="!bg-background"
      square
      elevation={0}
      sx={{
        borderRadius: "8px",
        backgroundImage: "none !important",
      }}
    >
      <Box>
        <BaseDashboardWidget
          title={title}
          widgetId={widgetId}
          isDateFilterEnabled={false}
          isLoading={isLoading}
          noPermission={noPermission}
        >
          {isLoading ? (
            <HighlightsOverlay />
          ) : (
            <>
              {totalCount === 0 ? (
                <>
                  <NoDataAvailableComponent title={"No data available"} />
                </>
              ) : (
                <>
                  <Grid
                    container
                    className="!pt-0"
                    data-testid="hilights-widget-grid-component"
                  >
                    <Grid item xs={12} className="pb-2">
                      {subTitle && (
                        <>
                          <Typography sx={subTitleLabelStyles}>
                            {subTitle.subTitle}
                          </Typography>
                          <Typography sx={subTitleCountStyles}>
                            {formatNumber(subTitle.subCount)}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <React.Fragment>
                      {!!items?.length &&
                        items?.map((item, index) => (
                          <Grid item xs={4} className="pb-2" key={index}>
                            <Typography sx={labelStyles}>
                              <SvgIcon
                                data-testid={
                                  item.label.replace(" ", "") + "-icon"
                                }
                                sx={iconsStyles}
                              >
                                {item.icon}
                              </SvgIcon>
                              <span>{capitalize(item.label)}</span>
                            </Typography>
                            <Typography sx={countStyles}>
                              {formatNumber(item.count)}
                            </Typography>
                          </Grid>
                        ))}
                    </React.Fragment>
                  </Grid>
                </>
              )}
            </>
          )}
        </BaseDashboardWidget>
      </Box>
    </Paper>
  );
};

export default memo(HighlightsComponent);
