import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { BRAND_ID } from "../../../constants";
import { env } from "../../../env";
import {
  type AuthenticationViewBrand,
  getBrand,
  getCachedBrand,
} from "../../AdminPanel/tabs/Brands/BrandUtils";

export const useBrandId = () => {
  const [searchParams] = useSearchParams();

  const brandIdFromParams = searchParams.get("brandId");
  const brandIdFromStorage = localStorage.getItem(BRAND_ID);
  const defaultBrandIdFromEnv = env.REACT_APP_DEFAULT_PCT_BRAND_ID;

  const brandId =
    brandIdFromParams ?? brandIdFromStorage ?? defaultBrandIdFromEnv;

  // save brandId to a local storage
  localStorage.setItem(BRAND_ID, brandId);

  return brandId;
};

interface AuthenticationViewBrandHook {
  brand: AuthenticationViewBrand | undefined;
  refetchBrand: (
    forceCacheRefresh: boolean
  ) => Promise<AuthenticationViewBrand>;
}

/**
 * This hook is used to obtain brand data, that is used in branding the authentication flows.
 */
export const useAuthenticationViewBrand = (): AuthenticationViewBrandHook => {
  const brandId = useBrandId();
  const [brand, setBrand] = useState<AuthenticationViewBrand | undefined>(
    getCachedBrand(brandId)
  );

  const { data, refetch } = useQuery<AuthenticationViewBrand>(
    ["useGetBrandQuery", brandId],
    ({ queryKey }) => {
      const [, brandId, forceCacheRefresh] = queryKey as [
        string,
        string,
        boolean
      ];

      return getBrand(brandId, forceCacheRefresh ?? false);
    },
    {
      enabled: !!brandId,
    }
  );

  const refetchBrand = (
    forceCacheRefresh: boolean = false
  ): Promise<AuthenticationViewBrand> => {
    return getBrand(brandId, forceCacheRefresh);
  };

  useEffect(() => {
    if (data) {
      setBrand(data);
    }
  }, [data]);

  return {
    brand,
    refetchBrand,
  };
};
