import { ASSET_DOOR_TYPES } from "../../../../../constants/assets";
import { Asset } from "../../../../../graphql/operations";
import { formatBooleanLabel } from "../../../../../shared/helpers/utils";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../utils/date";
import { detailItem } from "../../Shared/DashboardWidgets/DetailListWidget";

export function getAssetDetails(
  asset: Asset | undefined | null,
  userPreferredTimezone?: string | null
) {
  const detailList: Array<detailItem<string | number | boolean>> = [];
  const emptyValue = "";

  const assetInServiceDate =
    asset?.metadata?.assetInserviceDate &&
    formatDateInTimezone(
      asset?.metadata?.assetInserviceDate,
      DATE_TIME_FORMAT_SHORT,
      mapTimezoneToIANAValue(userPreferredTimezone)
    );

  detailList.push({
    label: "External Length",
    detailItemvalue: asset?.externalLength ?? emptyValue,
  });
  detailList.push({
    label: "Manufacturer",
    detailItemvalue: asset?.manufacturer ?? emptyValue,
  });
  detailList.push({
    label: "Model Year",
    detailItemvalue: asset?.assetModel ?? emptyValue,
  });
  detailList.push({
    label: "Asset Inservice Date",
    detailItemvalue: assetInServiceDate ?? emptyValue,
  });
  detailList.push({
    label: "Empty Weight",
    detailItemvalue: asset?.emptyWeight ?? emptyValue,
  });
  detailList.push({
    label: "Cube Capacity",
    detailItemvalue: asset?.metadata?.cubeCapacity ?? emptyValue,
  });
  detailList.push({
    label: "Rail Compliant",
    detailItemvalue: formatBooleanLabel(
      asset?.metadata?.railCompliant,
      emptyValue
    ),
  });
  detailList.push({
    label: "Door Type",
    detailItemvalue: asset?.door_type
      ? ASSET_DOOR_TYPES[asset?.door_type as keyof typeof ASSET_DOOR_TYPES]
      : "",
  });
  detailList.push({
    label: "Number of Tires",
    detailItemvalue: asset?.wheel_config ?? emptyValue,
  });
  detailList.push({
    label: "Number of Axles",
    detailItemvalue: asset?.num_of_axles ?? emptyValue,
  });

  detailList.push({
    label: "Air Ride",
    detailItemvalue: formatBooleanLabel(asset?.metadata?.airRide, emptyValue),
  });

  return detailList;
}
