import {
  AssetWithSensorsInputOs,
  useGetAssetsWithSensorProfilesOsQuery,
  SortOrder,
} from "../../../graphql/operations";

export const useGetAssetsWithSensorProfiles = (
  input: AssetWithSensorsInputOs
) => {
  const {
    data: sensorsDataOS,
    isError: isErrorSensorOS,
    isFetching: isFetchingSensorOS,
    refetch: refetchAssetsOS,
  } = useGetAssetsWithSensorProfilesOsQuery(
    {
      input: {
        orgId: input.orgId,
        sensorType: input.sensorType,
        search: input.search,
        skip: input.skip,
        limit: input.limit,
        filterList: input.filterList,
        sort: {
          field: input.sort?.field || "asset_id",
          order: input.sort?.order || ("asc" as SortOrder),
        },
      },
    },
    { enabled: Boolean(input.orgId) }
  );

  return {
    data: sensorsDataOS?.getAssetsWithSensorProfilesOS,
    isError: isErrorSensorOS,
    isFetching: isFetchingSensorOS,
    refetch: refetchAssetsOS,
  };
};
