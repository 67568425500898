import { DeviceData, Asset } from "../../../../graphql/operations";
import { formatDate } from "../../../../utils";
import { detailItem } from "../../../AssetsView/MapView/Shared/DashboardWidgets/DetailListWidget";

export const getDeviceDetails = (
  device: DeviceData
): Array<detailItem<string | number>> => [
  {
    label: "Device Tags",
    detailItemvalue: device.tags ? device.tags.join(", ") : "",
    truncateText: true,
  },
  {
    label: "Order #",
    detailItemvalue: device.order_num ?? "",
  },
  {
    label: "Order Group",
    detailItemvalue: device.box_id ?? "",
  },
  {
    label: "Sim ID",
    detailItemvalue: device.sim_num ?? "",
  },
  {
    label: "Asset ID",
    detailItemvalue: device.asset_name ?? "",
  },
  {
    label: "Packing List",
    detailItemvalue: device.packing_list ?? "",
  },
  {
    label: "Associated",
    detailItemvalue: device.associated ?? "",
  },
  {
    label: "Firmware Version",
    detailItemvalue: device.firmwareVersion ?? "",
  },
  {
    label: "Config",
    detailItemvalue: device.config ?? "",
  },
  {
    label: "Installation Date",
    detailItemvalue: device.install_dt
      ? formatDate(new Date(device.install_dt), "MM/dd/yy hh:mm aa")
      : "",
  },
  {
    label: "Updated Date",
    detailItemvalue: device.updated_date
      ? formatDate(new Date(device.updated_date), "MM/dd/yy hh:mm aa")
      : "",
  },
];

export const isAssetHasGeoInfo = (asset: Asset) => {
  return (
    asset.geofence?.length !== 0 ||
    asset.location?.coordinates?.[0] !== 0 ||
    asset.location?.coordinates?.[1] !== 0
  );
};
