import { GridColDef } from "@mui/x-data-grid/models";

export type Dwell = {
  assetId: string;
  entryPing: Date;
  cargoStatus: string;
  lastPing: Date;
  unloadDuration: number;
  exitPing: Date;
  cargoStatusOnEntry: string;
  cargoStatusOnExit: string;
  totalDwelling: string;
};

export type DwellGridColDef = GridColDef<Dwell>;

/**
 * Takes in seconds and converts them to a string in the format HH:MM.
 * For 0 it just returns 0 (keeps it as is).
 */
export const unloadDurationValueFormatter = (
  value: number | undefined | null
): number | string | undefined | null => {
  if (value) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  }

  return value;
};
