import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { roleToHumanReadableMapper } from "../../../../../constants/users";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
  UserRole,
} from "../../../../../graphql/operations";
import PieChartWidget, {
  DataItem,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget";
import { DEFAULT_LEGEND_TEXT_COLOR } from "../../../../../shared/components/DashboardWidgets/PieChartWidget/constants";
import {
  mapChartColors,
  mapLegendTextColor,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget/utils";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface DashboardWidgetUsersPerRoleData {
  roles: UsersPerRoleData[];
}

export interface UsersPerRoleData {
  count: number;
  percentage: number;
  role?: UserRole;
}

export interface DashboardWidgetUsersPerRole
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetUsersPerRoleData;
}

export interface UsersPerRoleWidgetProps {
  widget: DashboardWidgetUsersPerRole;
}

export const mapWidgetData = (
  data: DashboardWidgetUsersPerRoleData
): DataItem[] => {
  if (!data?.roles) {
    return [];
  }

  return data.roles.map((item, index) => ({
    name: item.role ? roleToHumanReadableMapper[item.role] : "Other",
    value: item.count,
    color: mapChartColors(item.role ?? "Other", index),
    legendTextColor: mapLegendTextColor(item.role ?? "Other"),
  }));
};

export const UsersPerRoleWidget: React.FC<UsersPerRoleWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetUsersPerRoleData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);

    return mappedData;
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--users-per-role"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the users per role
          widget! Please try again later.
        </Typography>
      ) : (
        <PieChartWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
