import { FC, memo, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CameraSensorDark } from "../../../../../assets/svgs/cameraSensorDark.svg";
import { ReactComponent as CameraSensorLight } from "../../../../../assets/svgs/cameraSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileConfigInput,
  SensorProfileResult,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  cargoCameraDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { CargoCameraDrawers, DrawerType } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type CargoCameraDrawerProps = {
  sensorType: SensorProfileConfigType;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
};

const CargoCameraDrawer: FC<CargoCameraDrawerProps> = ({
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
}) => {
  const initialFloorSpace =
    extractReversedRules(
      sensorProfileData?.configuration?.cargoCamera?.floorUsagePercentage
    ) || cargoCameraDefaultValues;
  const initialCubeSpace =
    extractReversedRules(
      sensorProfileData?.configuration?.cargoCamera?.cubeUsagePercentage
    ) || cargoCameraDefaultValues;

  const [floorSpace, setFloorSpace] = useState(initialFloorSpace);
  const [cubeSpace, setCubeSpace] = useState(initialCubeSpace);
  const [
    deleteCargoCameraProfilePopupState,
    setDeleteCargoCameraProfilePopupState,
  ] = useState(false);

  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === CargoCameraDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);

  // Forms declaration

  // Main Form is always static & will consist of same fields for every sensor
  const { form: cargoCameraMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const min = MinValuesBySensorType.cargoCamera;
  const max = MaxValuesBySensorType.cargoCamera;

  // event handlers
  const handleClose = () => onClose(false);

  const toggleCargoCameraProfilePopup = () => {
    setDeleteCargoCameraProfilePopupState(!deleteCargoCameraProfilePopupState);
  };

  // GraphQL handlers
  const dispatchSuccessEvent = (text: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: text,
        severity: "success",
      },
    });
  };

  const dispatchErrorEvent = (title: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: title,
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleCargoCameraProfileCreateSuccess = () => {
    onProfileMutation();
    dispatchSuccessEvent("Cargo Camera Sensor Profile Created Successfully!");
  };

  const handleCargoCameraProfileCreateError = () => {
    onProfileMutation();
    dispatchErrorEvent("Sensor Profile Creation Failed");
  };

  const handleCargoCameraProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatchSuccessEvent("Cargo Camera Sensor Profile Updated Successfully!");
  };

  const handleCargoCameraProfileUpdateError = () => {
    onProfileMutation();
    dispatchErrorEvent("Sensor Profile Update Failed");
  };

  const handleCargoCameraProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatchSuccessEvent("Cargo Camera Sensor Profile Deleted Successfully!");
  };

  const handleCargoCameraProfileDeleteError = () => {
    dispatchErrorEvent("Sensor Profile Delete Failed");
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleCargoCameraProfileCreateSuccess,
    createSensorProfileOnError: handleCargoCameraProfileCreateError,
    updateSensorProfileOnSuccess: handleCargoCameraProfileUpdateSuccess,
    updateSensorProfileOnError: handleCargoCameraProfileUpdateError,
    deleteSensorProfileOnSuccess: handleCargoCameraProfileDeleteSuccess,
    deleteSensorProfileOnError: handleCargoCameraProfileDeleteError,
  });

  const handleSubmitClick = async () => {
    const formData: any = cargoCameraMainForm.getValues();
    const configuration: SensorProfileConfigInput = {
      cargoCamera: {
        floorUsagePercentage: {
          match: prepareReversedPayload(undefined, floorSpace, min, max),
        },
        cubeUsagePercentage: {
          match: prepareReversedPayload(undefined, cubeSpace, min, max),
        },
      },
    };

    if (type === CargoCameraDrawers.Create) {
      createSensorProfile({
        name: formData.name,
        default: formData.default ?? false,
        orgId: formData?.orgName ?? "",
        configuration,
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
        configuration,
      };
      for (let fieldUpdated in cargoCameraMainForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = formData[fieldUpdated];
        else updateSensorProfilePayload[fieldUpdated] = formData[fieldUpdated];
      }

      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    }
  };

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const drawerHeaderText = isEdit ? "Edit Profile" : "Create Profile";
  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;
  const { isDirty, isValid } = cargoCameraMainForm.formState;
  const isAnySliderDirty =
    floorSpace.toString() !== initialFloorSpace.toString() ||
    cubeSpace.toString() !== initialCubeSpace.toString();
  const isInvalid = isLoading || !isValid;
  const isEditAndPristine = isEdit && !isDirty && !isAnySliderDirty;

  return (
    <Drawer
      isOpen={isOpen}
      onRequestClose={handleClose}
      testId="cargo-camera-profile-drawer"
    >
      <DrawerHeader text={drawerHeaderText} onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="mb-9">
            <ProfilesDrawerMainForm
              form={cargoCameraMainForm}
              disabled={isLoading}
              isEdit={isEdit}
            />

            <Box className="px-6" data-testid="profiles-camera-floor">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Floor Space Settings
                </Typography>
                {isLightTheme ? (
                  <CameraSensorDark style={svgIconSettings} />
                ) : (
                  <CameraSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={floorSpace}
                min={min}
                max={max}
                marks={sliderMarks(min, max, "%")}
                disabled={isLoading}
                reversed
                onChange={setFloorSpace}
              />
            </Box>

            <Box className="mt-12 px-6" data-testid="profiles-camera-cube">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Cube Space Settings
                </Typography>
                {isLightTheme ? (
                  <CameraSensorDark style={svgIconSettings} />
                ) : (
                  <CameraSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={cubeSpace}
                min={min}
                max={max}
                marks={sliderMarks(min, max, "%")}
                disabled={isLoading}
                reversed
                onChange={setCubeSpace}
              />
            </Box>
          </Box>

          <DrawerActions
            deleteBtnTestId="btn-delete-camera-profile"
            showDeleteBtn={isEdit}
            disabled={isLoading}
            onCancel={handleClose}
            onDelete={toggleCargoCameraProfilePopup}
          />

          {isEdit && deleteCargoCameraProfilePopupState && (
            <DeleteProfileDialog
              isOpen={deleteCargoCameraProfilePopupState}
              onClick={handleDeleteProfile}
              onClose={toggleCargoCameraProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              testId="cargo-camera"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={isInvalid || isEditAndPristine}
        testId="btn-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default memo(CargoCameraDrawer);
