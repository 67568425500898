import { useCallback, useEffect, useState } from "react";
import { Card, Typography } from "@mui/material";
import { Bar, Tooltip } from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import VerticalBarChartWidget from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import {
  dateToExactDateTimeStringNoTz,
  getEndOfDay,
  getStartOfDay,
} from "../../../../../utils";
import {
  ChartTooltip,
  ChartType,
} from "../../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";
import {
  DEFAULT_DASHBOARD_END_DATE,
  DEFAULT_DASHBOARD_START_DATE,
} from "../../shared";
import {
  formatDateIntoLabel,
  formatDateIntoLabelForTooltip,
  hasNoPermission,
} from "../../shared/utils";

export interface MediaPerPeriodCount {
  date: string;
  value: number;
}
export interface DashboardWidgetMediaPerPeriodData {
  mediaPerPeriod: MediaPerPeriodCount[];
}

export interface DashboardWidgetMediaPerPeriod
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetMediaPerPeriodData;
}

export interface MediaPerPeriodWidgetProps {
  widget: DashboardWidgetMediaPerPeriod;
}

export const MediaPerPeriodWidget: React.FC<MediaPerPeriodWidgetProps> = ({
  widget,
}) => {
  const isDarkMode = useCurrentTheme().palette.mode === "dark";
  const customerOrganization = useSelectedOrg();
  const [startDate, setStartDate] = useState<Date>(
    DEFAULT_DASHBOARD_START_DATE
  );
  const [endDate, setEndDate] = useState<Date>(DEFAULT_DASHBOARD_END_DATE);
  const [data, setData] = useState<
    MediaPerPeriodWidgetProps["widget"]["data"]["mediaPerPeriod"]
  >(widget?.data?.mediaPerPeriod || []);

  const handleDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        // They come in as dates and we need exact datetime strings
        setStartDate(getStartOfDay(startDate));
        setEndDate(getEndOfDay(endDate));
      }
    },
    [setStartDate, setEndDate]
  );

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: customerOrganization.value,
        // Note: prevents the AWSDateTime input type resolver from casting to Date again and altering the desired date
        fromDate: dateToExactDateTimeStringNoTz(startDate),
        toDate: dateToExactDateTimeStringNoTz(endDate),
      },
    },
    {
      enabled:
        Boolean(customerOrganization.value) &&
        Boolean(startDate) &&
        Boolean(endDate),
    }
  );

  useEffect(() => {
    setData(
      JSON.parse(getDashboardWidgetData?.getDashboardWidget?.data || "[]")
        ?.mediaPerPeriod ?? []
    );
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
    >
      {data && !dashboardWidgetQueryError && (
        <div data-testid="dashboard-widget--media-per-period">
          <VerticalBarChartWidget
            /*
              startDate and endDate need to be converted to dates again
              to match the requirements of the DateRangePicker
            */
            startDate={startDate}
            endDate={endDate}
            isLoading={isLoading}
            xAxisTickFormatter={formatDateIntoLabel}
            onDateFilterChange={handleDateChange}
            title={widget.name ?? ""}
            yAxisLabel="Number of Files"
            xAsisKey="date"
            barKey="value"
            data={data}
            isDateFilterEnabled
            yAxisLabelColor={isDarkMode ? ColorsPalette.BrightWhite : undefined}
            yAxisDomain={[
              0,
              (dataMax: number) => dataMax + (dataMax * 10) / 100,
            ]}
            noPermission={hasNoPermission(getDashboardWidgetData)}
            widgetId={widget.id}
          >
            <Bar
              fill={ColorsPalette.ReChartsTeal}
              dataKey="value"
              maxBarSize={70}
            ></Bar>
            <Tooltip
              formatter={(value) => [value, "Number of Files:"]}
              labelFormatter={formatDateIntoLabelForTooltip}
              labelStyle={{
                color: "black",
              }}
              cursor={false}
              content={<ChartTooltip type={ChartType.Common} />}
            />
          </VerticalBarChartWidget>
        </div>
      )}
      {Boolean(dashboardWidgetQueryError) && (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the media per period
          chart! Please try again later.
        </Typography>
      )}
    </Card>
  );
};
