import { AggregatedEventHistory } from "../../../../../../graphql/operations";

export type CargoCameraSpaceData = {
  eventHistoryData?: Partial<Pick<
    AggregatedEventHistory,
    "date" | "cargoCamera" | "mediaActivity"
  > | null>;
} | null;

export enum InternalCameraSpaceStatus {
  Loaded = "loaded",
  Empty = "empty",
  Unknown = "unknown",
}

export enum InternalCameraGraphType {
  Floor = "floor",
  Cube = "cube",
}
