import { FC, useMemo, useState } from "react";
import {
  Box,
  Typography,
  FormHelperText,
  FormControl,
  Input,
} from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as DualImbalansSensorDark } from "../../../../../assets/svgs/dualImbalanceSensorDark.svg";
import { ReactComponent as DualImbalansSensorLight } from "../../../../../assets/svgs/dualImbalanceSensorLight.svg";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { ReactComponent as TPMSSensorDark } from "../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../assets/svgs/tpmsSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  UpdateOrganizationSensorProfileInput,
  SensorProfileResult,
  SensorProfileConfigType,
  SensorConfigInputTpmsPressure,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  extractRules,
  extractDoubleRules,
  prepareRulesPayload,
  sliderMarks,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  tpmsTemperatureDefaultValues,
  tpmsImbalanceDefaultValues,
  tpmsPressureMinHealthy,
  tpmsPressureMaxHealthy,
  tpmsPressureDefaultValues,
  prepareDoubleRulesPayload,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../shared/hooks/useGetTemperatureUnitPreference";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressurePayload,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import {
  getConvertedTemperatureValues,
  getTemperatureUnitLabel,
  prepareConvertedCelsiusTemperaturesPayload,
} from "../../../../../utils/convertTemperature";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, TpmsDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type TpmsDrawerProps = {
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const TpmsDrawer: FC<TpmsDrawerProps> = ({
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
  onRequestClose,
}) => {
  const pressureUnit = useGetPressureUnitPreference();
  const tempUnitPreference = useGetTemperatureUnitPreference();

  const [minPsi, maxPsi] = getConvertedPressureValues(
    [MinValuesBySensorType.tpmsPressure, MaxValuesBySensorType.tpmsPressure],
    pressureUnit
  );
  const [minImbalance, maxImbalance] = getConvertedPressureValues(
    [MinValuesBySensorType.imbalance, MaxValuesBySensorType.imbalance],
    pressureUnit
  );
  const [tpmsPressureMinHealthyConverted, tpmsPressureMaxHealthyConverted] =
    getConvertedPressureValues(
      [tpmsPressureMinHealthy, tpmsPressureMaxHealthy],
      pressureUnit
    );

  // Extract sensor values from sensorProfileData
  const temperatureThresholds =
    sensorProfileData?.configuration?.tpmsBeta?.temperature;
  const parsedTemperature = extractRules(temperatureThresholds);
  const initialTemperature = getConvertedTemperatureValues(
    parsedTemperature || tpmsTemperatureDefaultValues,
    tempUnitPreference
  );

  const imbalanceThresholds =
    sensorProfileData?.configuration?.tpmsBeta?.imbalance;
  const parsedImbalance = extractRules(imbalanceThresholds);
  const initialImbalance = getConvertedPressureValues(
    parsedImbalance || tpmsImbalanceDefaultValues,
    pressureUnit
  );

  const pressureThresholds =
    sensorProfileData?.configuration?.tpmsBeta?.pressure;
  const parsedPressure = extractDoubleRules(
    pressureThresholds,
    SensorProfileConfigType.TpmsBeta
  );
  const initialPressure = getConvertedPressureValues(
    parsedPressure || tpmsPressureDefaultValues,
    pressureUnit
  );
  const initialOverPressure = initialPressure.slice(4, 7);
  const initialUnderPressure = initialPressure.slice(0, 3);
  const initialHealthyPressure = initialPressure?.slice(3, 4)[0];

  const [temperature, setTemperature] = useState(initialTemperature);
  const [overPressure, setOverPressure] = useState(initialOverPressure);
  const [underPressure, setUnderPressure] = useState(initialUnderPressure);
  const [imbalance, setImbalance] = useState(initialImbalance);
  const [healthyPressure, setHealthyPressure] = useState(
    initialHealthyPressure
  );
  const [healthyPressureError, setHealthyPressureError] = useState<string>("");
  const [deleteTpmsProfilePopupState, setDeleteTpmsProfilePopup] =
    useState(false);

  const [minTpmsTemperatureConverted, maxTpmsTemperatureConverted] =
    getConvertedTemperatureValues(
      [MinValuesBySensorType.temperature, MaxValuesBySensorType.temperature],
      tempUnitPreference
    );

  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === TpmsDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );
      return foundOrg?._id;
    }
    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);

  const { form: mainTpmsForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });
  const { dirtyFields } = mainTpmsForm.formState;

  const onHealthyValueChange = (e: { target: { value: string } }) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return;

    const pressureErrorMessage = `Number must be between ${tpmsPressureMinHealthyConverted} and ${tpmsPressureMaxHealthyConverted}.`;
    let evenUnderPsi = returnEvenValuesFromRange(minPsi, value);
    let evenOverPsi = returnEvenValuesFromRange(value, maxPsi);
    let errorMessage = "";

    if (value < tpmsPressureMinHealthyConverted) {
      evenUnderPsi = returnEvenValuesFromRange(
        minPsi,
        tpmsPressureMinHealthyConverted
      );
      evenOverPsi = returnEvenValuesFromRange(
        tpmsPressureMinHealthyConverted,
        maxPsi
      );
      errorMessage = pressureErrorMessage;
    } else if (value > tpmsPressureMaxHealthyConverted) {
      evenUnderPsi = returnEvenValuesFromRange(
        minPsi,
        tpmsPressureMaxHealthyConverted
      );
      evenOverPsi = returnEvenValuesFromRange(
        tpmsPressureMaxHealthyConverted,
        maxPsi
      );
      errorMessage = pressureErrorMessage;
    }

    setUnderPressure(evenUnderPsi);
    setOverPressure(evenOverPsi);
    setHealthyPressure(value);
    setHealthyPressureError(errorMessage);
  };

  const handleTpmsProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "TPMS Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleTpmsProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleTpmsProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "TPMS Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleTpmsProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleTpmsProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "TPMS Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleTpmsProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleTpmsProfileCreateSuccess,
    createSensorProfileOnError: handleTpmsProfileCreateError,
    updateSensorProfileOnSuccess: handleTpmsProfileUpdateSuccess,
    updateSensorProfileOnError: handleTpmsProfileUpdateError,
    deleteSensorProfileOnSuccess: handleTpmsProfileDeleteSuccess,
    deleteSensorProfileOnError: handleTpmsProfileDeleteError,
  });

  const toggleTpmsProfilePopup = () => {
    setDeleteTpmsProfilePopup(!deleteTpmsProfilePopupState);
  };

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleSubmitClick = async () => {
    const isMainFormValid = await mainTpmsForm.trigger();

    if (!isMainFormValid || !!healthyPressureError) return;

    const allFormData: any = {
      ...mainTpmsForm.getValues(),
    };

    const configuration = {
      tpmsBeta: {
        pressure: {
          match: prepareDoubleRulesPayload(
            undefined,
            prepareConvertedMbarPressuresPayload(underPressure, pressureUnit),
            prepareConvertedMbarPressuresPayload(overPressure, pressureUnit),
            prepareConvertedMbarPressurePayload(healthyPressure, pressureUnit),
            MinValuesBySensorType.tpmsPressure,
            MaxValuesBySensorType.tpmsPressure,
            "tpmsPressure"
          ) as SensorConfigInputTpmsPressure,
        },
        temperature: {
          match: prepareRulesPayload(
            undefined,
            prepareConvertedCelsiusTemperaturesPayload(
              temperature,
              tempUnitPreference
            ),
            MinValuesBySensorType.temperature,
            MaxValuesBySensorType.temperature
          ),
        },
        imbalance: {
          match: prepareRulesPayload(
            undefined,
            prepareConvertedMbarPressuresPayload(imbalance, pressureUnit),
            MinValuesBySensorType.imbalance,
            MaxValuesBySensorType.imbalance
          ),
        },
      },
    };

    if (type === TpmsDrawers.Create) {
      createSensorProfile({
        name: allFormData.name,
        configuration,
        default: allFormData.default ?? false,
        orgId: allFormData?.orgName ?? "",
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = allFormData[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = allFormData[fieldUpdated];
      }
      if (dirtyFields) {
        updateSensorProfilePayload.configuration = configuration;
        updateSensorProfile(
          updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
        );
      }
    }
  };

  const temperatureMarks = sliderMarks(
    minTpmsTemperatureConverted,
    maxTpmsTemperatureConverted,
    getTemperatureUnitLabel(tempUnitPreference, true)
  );

  const imbalanceMarks = sliderMarks(
    minImbalance,
    maxImbalance,
    getPressureUnitLabel(pressureUnit)
  );

  const dynamicLimit = returnDynamicLimit(
    healthyPressure,
    tpmsPressureMinHealthyConverted,
    tpmsPressureMaxHealthyConverted
  );

  const text = isEdit ? "Edit Profile" : "Create Profile";

  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  const isAnySliderDirty =
    temperature.toString() !== initialTemperature.toString() ||
    overPressure.toString() !== initialOverPressure.toString() ||
    underPressure.toString() !== initialUnderPressure.toString() ||
    imbalance.toString() !== initialImbalance.toString() ||
    healthyPressure !== initialHealthyPressure;

  const isDirty = !isAnySliderDirty && isEmpty(dirtyFields);

  const isSubmitDisabled = isEdit
    ? isLoading || !!healthyPressureError || isDirty
    : isLoading || !!healthyPressureError || isEmpty(dirtyFields);

  return (
    <Drawer
      testId="tpms-profile-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <ProfilesDrawerMainForm
          form={mainTpmsForm}
          disabled={isLoading}
          isEdit={isEdit}
        />

        <Box className="px-6" data-testid="tpms-sensors-wrapper">
          <Box className="mb-16">
            <Typography sx={{ fontWeight: "bold" }}>
              Tire Temperature Settings
            </Typography>
            {isLightTheme ? (
              <TemperatureSensorDark
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            ) : (
              <TemperatureSensorLight
                style={svgIconSettings}
                data-testid="temperature-profile-drawer-thermostat"
              />
            )}
          </Box>
          <SensorSlider
            values={temperature}
            min={minTpmsTemperatureConverted}
            max={maxTpmsTemperatureConverted}
            marks={temperatureMarks}
            disabled={isLoading}
            onChange={setTemperature}
          />

          <Box className="pt-12 pb-2">
            <Typography sx={{ fontWeight: "bold" }}>
              Tire Pressure Settings
            </Typography>
            {isLightTheme ? (
              <TPMSSensorDark style={svgIconSettings} />
            ) : (
              <TPMSSensorLight style={svgIconSettings} />
            )}
          </Box>

          <Box className="pb-4">
            <FormControl sx={{ width: "100%" }}>
              <WithAsterisk>
                <Label htmlFor="Healthy Value">Healthy Value</Label>
              </WithAsterisk>

              <Input
                value={healthyPressure}
                onChange={onHealthyValueChange}
                data-testid="tpms-profiles-healthy-pressure-input"
                id="pressureHealthyVal"
                disabled={isLoading}
                sx={{ width: "100%" }}
              />
              {!!healthyPressureError && (
                <FormHelperText
                  data-testid="tpms-profiles-healthy-pressure-error-text"
                  error={true}
                >
                  {healthyPressureError}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className="py-8">
            <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
              Over Inflation Settings
            </Typography>
            <SensorSlider
              values={overPressure}
              min={dynamicLimit}
              max={maxPsi}
              marks={sliderMarks(
                dynamicLimit,
                maxPsi,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading}
              onChange={setOverPressure}
            />
          </Box>

          <Box className="py-8">
            <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
              Under Inflation Settings
            </Typography>
            <SensorSlider
              values={underPressure}
              min={minPsi}
              max={dynamicLimit}
              marks={sliderMarks(
                minPsi,
                dynamicLimit,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading}
              onChange={setUnderPressure}
              reversed
            />
          </Box>

          <Box className="py-8">
            <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
              Dual Imbalance Settings
              {isLightTheme ? (
                <DualImbalansSensorDark
                  style={svgIconSettings}
                  data-testid="temperature-profile-drawer-thermostat"
                />
              ) : (
                <DualImbalansSensorLight
                  style={svgIconSettings}
                  data-testid="temperature-profile-drawer-thermostat"
                />
              )}
            </Typography>
            <SensorSlider
              values={imbalance}
              min={minImbalance}
              max={maxImbalance}
              marks={imbalanceMarks}
              disabled={isLoading}
              onChange={setImbalance}
            />
          </Box>
        </Box>

        <DrawerActions
          deleteBtnTestId="btn-delete-tpms-profile"
          showDeleteBtn={isEdit}
          disabled={isLoading}
          onCancel={() => onClose(false)}
          onDelete={toggleTpmsProfilePopup}
        />

        {isEdit && deleteTpmsProfilePopupState && (
          <DeleteProfileDialog
            isOpen={deleteTpmsProfilePopupState}
            isLoading={isLoadingDeleteSensorProfile}
            onClose={toggleTpmsProfilePopup}
            onClick={handleDeleteProfile}
            testId="tpms"
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={isSubmitDisabled}
        testId="btn-tpms-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default TpmsDrawer;
