import { FC, useMemo } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { formatNumber } from "../../../../utils/formatters";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { PieChartWidgetOverlay } from "../../LoadingOverlaySkeletons/PieChartWidgetLoader/PieChartWidgetOverlay";
import BaseDashboardWidget, {
  BaseDashboardWidgetProps,
} from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";
import PieCustomLabel from "./components/PieCustomLabel";
import PieLegend from "./components/PieLegend";
import { DEFAULT_PIE_CHART_COLOR } from "./constants";

export type DataItem = {
  name: string;
  value: number;
  color?: string;
  legendTextColor?: string;
};

export interface PieChartWidgetProps extends BaseDashboardWidgetProps {
  data: DataItem[];
  subtitle?: string;
  isLoading: boolean;
  widgetId: number;
}

const PieChartWidget: FC<PieChartWidgetProps> = ({
  data,
  subtitle,
  isLoading,
  widgetId,
  ...baseDashboardWidgetProps
}) => {
  const isMobile = useBreakpoint("down", "sm");
  const isTablet = useMediaQuery("(min-width:640px) and (max-width:1366px)");
  const isVerticalLayout = isMobile || isTablet;

  // Dark magic bellow, the goal is to find a formula that will set an aspect ration that doesn't produce a lot of empty space
  const verticalLayoutAspectRatio = 1 / (1 + data.length * 0.2);
  const horizontalLayoutAspectRatio = 1 / (1 - data.length * 0.01);

  const totalItemsCount = useMemo(
    () => data.reduce((total, currentItem) => total + currentItem.value, 0),
    [data]
  );

  return (
    <BaseDashboardWidget
      {...baseDashboardWidgetProps}
      isLoading={isLoading}
      widgetId={widgetId}
    >
      {isLoading ? (
        <PieChartWidgetOverlay />
      ) : (
        <>
          {totalItemsCount === 0 ? (
            <>
              <NoDataAvailableComponent title={"No data available"} />
            </>
          ) : (
            <>
              <Box className="relative w-full">
                {subtitle && (
                  <Box>
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "18px",
                          lineHeight: "26px",
                          fontWeight: 600,
                        }}
                      >
                        {subtitle}
                      </Typography>
                    </Box>
                    <Box className="mb-5">
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "36px",
                          lineHeight: "44px",
                          fontWeight: 600,
                        }}
                        data-testid="total-items-count"
                      >
                        {formatNumber(totalItemsCount)}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {Boolean(data?.length) && (
                  <ResponsiveContainer
                    aspect={
                      isVerticalLayout
                        ? verticalLayoutAspectRatio
                        : horizontalLayoutAspectRatio
                    }
                  >
                    <PieChart>
                      <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={isVerticalLayout ? "100%" : 170}
                        fill="var(--primary-blue)"
                        label={(props) => <PieCustomLabel {...props} />}
                      >
                        {data.map((item, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={item?.color ?? DEFAULT_PIE_CHART_COLOR}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        align={isVerticalLayout ? "center" : "right"}
                        verticalAlign={isVerticalLayout ? "top" : "middle"}
                        layout="vertical"
                        content={(props) => <PieLegend {...props} />}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                )}

                {!data?.length && !isLoading && (
                  <div className="h-[245px] flex items-center justify-center">
                    <Typography className="!font-medium">
                      No data available
                    </Typography>
                  </div>
                )}
              </Box>
            </>
          )}
        </>
      )}
    </BaseDashboardWidget>
  );
};

export default PieChartWidget;
