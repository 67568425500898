import { FC, useMemo, useState, ChangeEvent } from "react";
import { FieldValues } from "react-hook-form";
import {
  Box,
  Grid,
  ThemeProvider,
  Input,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as ATISSensorLight } from "../../../../../assets/svgs/aitsSensorLight.svg";
import { ReactComponent as ATISSensorDark } from "../../../../../assets/svgs/atisSensorDark.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileConfigInput,
  SensorProfileResult,
  SensorProfileConfigType,
  ProfileConfigProperty,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractRules,
  getMaxValueFromThresholds,
  prepareRulesPayload,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  atisDefaultValues,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../shared/components/Text";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, AtisDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type AtisDrawerProps = {
  sensorType: SensorProfileConfigType | ProfileConfigProperty;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const HEALTHY_POINT_MIN = 10;
const HEALTHY_POINT_MAX = 10000;

const AtisDrawer: FC<AtisDrawerProps> = ({
  sensorType,
  sensorProfileData,
  type,
  isOpen,
  onClose,
  onProfileMutation,
  onRequestClose,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    margin: "0.75rem 0rem",
  };
  const formTheme = useFormTheme();

  // Values extraction
  const min = MinValuesBySensorType.atisAlpha;
  const max = MaxValuesBySensorType.atisAlpha;
  const atisAlphaSensorData =
    sensorProfileData?.configuration?.atisAlpha?.lightActivatedSeconds;
  const moving = atisAlphaSensorData?.moving;
  const parked = atisAlphaSensorData?.parked;
  const initialAtisMoving = extractRules(moving) || atisDefaultValues;
  const initialAtisParked = extractRules(parked) || atisDefaultValues;
  const initialMovingLimit = getMaxValueFromThresholds(moving) ?? max;
  const initialParkedLimit = getMaxValueFromThresholds(parked) ?? max;

  // State
  const [atisMoving, setAtisMoving] = useState(initialAtisMoving);
  const [atisParked, setAtisParked] = useState(initialAtisParked);
  const [movingLimit, setMovingLimit] = useState<number>(initialMovingLimit);
  const [parkedLimit, setParkedLimit] = useState<number>(initialParkedLimit);
  const [movingLimitError, setMovingLimitError] = useState<string>("");
  const [parkedLimitError, setParkedLimitError] = useState<string>("");
  const [deletePsiWheelEndPopupState, setDeletePsiWheelEndPopupState] =
    useState(false);

  const isEditMode = useMemo(() => {
    return type === AtisDrawers.Edit;
  }, [type]);

  const availableOrgs = useAvailableOrgs();

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEditMode) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEditMode, sensorProfileData]);

  const { form: atisMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const isDirty =
    atisMoving.toString() !== initialAtisMoving.toString() ||
    atisParked.toString() !== initialAtisParked.toString() ||
    movingLimit !== initialMovingLimit ||
    parkedLimit !== initialParkedLimit;

  const toggleDeletePsiWheelEndProfilePopup = () => {
    setDeletePsiWheelEndPopupState(!deletePsiWheelEndPopupState);
  };

  const handleAtisProfileSubmitClick = async () => {
    const isMainFormValid = await atisMainForm.trigger();
    if (!isMainFormValid || !!movingLimitError || !!parkedLimitError) return;

    const mainFormData: FieldValues = atisMainForm.getValues();
    const configuration: SensorProfileConfigInput = {
      atisAlpha: {
        lightActivatedSeconds: {
          moving: {
            match: prepareRulesPayload(undefined, atisMoving, min, movingLimit),
          },
          parked: {
            match: prepareRulesPayload(undefined, atisParked, min, parkedLimit),
          },
        },
      },
    };

    if (isEditMode) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in atisMainForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = mainFormData[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = mainFormData[fieldUpdated];
      }

      if (isDirty) {
        updateSensorProfilePayload.configuration = configuration;
      }

      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    } else {
      const sensorProfileData = {
        name: mainFormData.name,
        default: mainFormData.default ?? false,
        orgId: mainFormData?.orgName ?? "",
        configuration,
      };
      createSensorProfile(sensorProfileData);
    }
  };

  const handleAtisProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "ATIS Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleAtisProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "ATIS Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAtisProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "ATIS Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleAtisProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "ATIS Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleAtisProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "ATIS Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleAtisProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleAtisProfileCreateSuccess,
    createSensorProfileOnError: handleAtisProfileCreateError,
    updateSensorProfileOnSuccess: handleAtisProfileUpdateSuccess,
    updateSensorProfileOnError: handleAtisProfileUpdateError,
    deleteSensorProfileOnSuccess: handleAtisProfileDeleteSuccess,
    deleteSensorProfileOnError: handleAtisProfileDeleteError,
  });

  const handleDeleteAtisProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }
    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const rangeError = `Number must be between ${HEALTHY_POINT_MIN} and ${HEALTHY_POINT_MAX}.`;

  const onMovingLimitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const movingValue = Number(e.target.value);
    if (Number.isNaN(movingValue)) return true;

    let evenMovingValues = returnEvenValuesFromRange(min, movingValue);
    let errorMovingMsg = "";

    if (movingValue < HEALTHY_POINT_MIN) {
      evenMovingValues = returnEvenValuesFromRange(min, HEALTHY_POINT_MIN);
      errorMovingMsg = rangeError;
    } else if (movingValue > HEALTHY_POINT_MAX) {
      evenMovingValues = returnEvenValuesFromRange(min, HEALTHY_POINT_MAX);
      errorMovingMsg = rangeError;
    }

    setAtisMoving(evenMovingValues);
    setMovingLimit(movingValue);
    setMovingLimitError(errorMovingMsg);
  };

  const onParkedLimitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parkedValue = Number(e.target.value);
    if (Number.isNaN(parkedValue)) return true;

    let evenParkedValues = returnEvenValuesFromRange(min, parkedValue);
    let errorParkedMsg = "";

    if (parkedValue < HEALTHY_POINT_MIN) {
      evenParkedValues = returnEvenValuesFromRange(min, HEALTHY_POINT_MIN);
      errorParkedMsg = rangeError;
    } else if (parkedValue > HEALTHY_POINT_MAX) {
      evenParkedValues = returnEvenValuesFromRange(min, HEALTHY_POINT_MAX);
      errorParkedMsg = rangeError;
    }

    setAtisParked(evenParkedValues);
    setParkedLimit(parkedValue);
    setParkedLimitError(errorParkedMsg);
  };

  const movingMax = returnDynamicLimit(
    movingLimit,
    HEALTHY_POINT_MIN,
    HEALTHY_POINT_MAX
  );

  const parkedMax = returnDynamicLimit(
    parkedLimit,
    HEALTHY_POINT_MIN,
    HEALTHY_POINT_MAX
  );

  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  const text = isEditMode ? "Edit Profile" : "Create Profile";

  return (
    <Drawer
      testId="atis-profile-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <Box className="h-full flex flex-col justify-between">
            <Box>
              <ProfilesDrawerMainForm
                form={atisMainForm}
                disabled={isLoading}
                isEdit={isEditMode}
              />
              <Grid
                container
                className="drawerSection"
                spacing={6}
                direction="column"
              >
                <Grid item sx={{ marginBottom: "1.75rem" }}>
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    classes="!text-base !text-primary"
                  >
                    ATIS Settings - Moving
                  </Text>
                  {isLightTheme ? (
                    <ATISSensorDark style={svgIconSettings} />
                  ) : (
                    <ATISSensorLight style={svgIconSettings} />
                  )}

                  <FormControl sx={{ width: "100%" }}>
                    <WithAsterisk>
                      <Label htmlFor="Moving Limit">
                        Set the custom end of the slider
                      </Label>
                    </WithAsterisk>
                    <Input
                      value={movingLimit}
                      onChange={onMovingLimitInputChange}
                      data-testid="atis-profiles-moving-limit-input"
                      disabled={isLoading}
                      sx={{ width: "100%", mb: "1px" }}
                    />
                    {!!movingLimitError && (
                      <FormHelperText
                        data-testid="atis-profiles-moving-limit-error-text"
                        error={true}
                      >
                        {movingLimitError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sx={{ marginBottom: "2.75rem" }}>
                  <SensorSlider
                    values={atisMoving}
                    min={min}
                    max={movingMax}
                    marks={sliderMarks(min, movingMax, "Seconds")}
                    disabled={isLoading}
                    onChange={setAtisMoving}
                  />
                </Grid>

                <Grid item sx={{ marginBottom: "1.75rem" }}>
                  <Text
                    fontSize={14}
                    fontWeight="bold"
                    classes="!text-base !text-primary"
                  >
                    ATIS Settings - Parked
                  </Text>
                  {isLightTheme ? (
                    <ATISSensorDark
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  ) : (
                    <ATISSensorLight
                      style={svgIconSettings}
                      data-testid="temperature-profile-drawer-thermostat"
                    />
                  )}

                  <FormControl sx={{ width: "100%" }}>
                    <WithAsterisk>
                      <Label htmlFor="Parked Limit">
                        Set the custom end of the slider
                      </Label>
                    </WithAsterisk>
                    <Input
                      value={parkedLimit}
                      onChange={onParkedLimitInputChange}
                      data-testid="atis-profiles-parked-limit-input"
                      disabled={isLoading}
                      sx={{ width: "100%", mb: "1px" }}
                    />
                    {!!parkedLimitError && (
                      <FormHelperText
                        data-testid="atis-profiles-parked-limit-error-text"
                        error={true}
                      >
                        {parkedLimitError}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sx={{ marginBottom: "2.75rem" }}>
                  <SensorSlider
                    values={atisParked}
                    min={min}
                    max={parkedMax}
                    marks={sliderMarks(min, parkedMax, "Seconds")}
                    disabled={isLoading}
                    onChange={setAtisParked}
                  />
                </Grid>
              </Grid>
            </Box>

            <DrawerActions
              cancelBtnTestId="atis-profile-drawer-cancel"
              deleteBtnTestId="btn-delete-atis-profile"
              showDeleteBtn={isEditMode}
              disabled={isLoading}
              onCancel={() => onClose(false)}
              onDelete={toggleDeletePsiWheelEndProfilePopup}
            />
          </Box>
          {deletePsiWheelEndPopupState && isEditMode && (
            <DeleteProfileDialog
              isOpen={deletePsiWheelEndPopupState}
              onClose={toggleDeletePsiWheelEndProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteAtisProfile}
              testId="atis"
            />
          )}
        </ThemeProvider>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={
          isLoading ||
          !!movingLimitError ||
          !!parkedLimitError ||
          (isEmpty(atisMainForm.formState.dirtyFields) && !isDirty)
        }
        testId="btn-atis-profile-form-submit"
        submit={handleAtisProfileSubmitClick}
      />
    </Drawer>
  );
};

export default AtisDrawer;
