import { FC, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type AssetsTripData = {
  tripState: string;
  totalCount: number;
  percentage: number;
};

export type DashboardWidgetAssetsPerTripStatusData = {
  totalCount: number;
  assets: AssetsTripData[];
};

export interface DashboardWidgetAssetsPerTripStatusType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsPerTripStatusData;
}

export interface AssetsTripWidgetProps {
  widget: DashboardWidgetAssetsPerTripStatusType;
}

const ColorByTripStatusMapping = {
  moving: ColorsPalette.ReChartsTeal,
  parked: ColorsPalette.ReChartsPurple,
  "N/A": ColorsPalette.ReChartsViolet,
};

const mapWidgetData = (data: DashboardWidgetAssetsPerTripStatusData) => {
  return (
    data?.assets?.map((status) => ({
      name:
        status.tripState !== "N/A"
          ? capitalize(status.tripState)
          : status.tripState,
      value: status.totalCount,
      color:
        ColorByTripStatusMapping[
          status.tripState as keyof typeof ColorByTripStatusMapping
        ],
      percent: status.percentage,
    })) ?? []
  );
};

export const AssetsPerTripStatusWidget: FC<AssetsTripWidgetProps> = ({
  widget,
}) => {
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetAssetsPerTripStatusData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData: HorizontalBarChartData[] = mapWidgetData(parsedData);

    const desiredDataOrder = ["Moving", "Parked", "N/A"];
    const reorderedData =
      mappedData?.sort(
        (a, b) =>
          desiredDataOrder.indexOf(a.name) - desiredDataOrder.indexOf(b.name)
      ) ?? [];

    const total: number = parsedData?.totalCount ?? 0;

    return [reorderedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-trip"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets per trip
          status widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={data}
          title={widget.name ?? "Trip Status"}
          isLoading={isLoading}
          subtitle="All Assets"
          showTotal
          totalRecords={total}
          showXAxis
          showPercentages
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
