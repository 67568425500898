import { useCookies } from "react-cookie";
import { SELECTED_ORGANIZATION } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import { DEFAULT_TIMEZONE } from "../../utils";
import { useUserData } from "./useUserData";

export const useGlobalOrganizationFilter = () => {
  const userData = useUserData();
  const [orgCookie] = useCookies([SELECTED_ORGANIZATION]);
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  if (selectedOrganization && selectedOrganization.label)
    return selectedOrganization;

  if (orgCookie?.SELECTED_ORGANIZATION?.label)
    return orgCookie.SELECTED_ORGANIZATION;

  return {
    label: userData?.customerOrg.name,
    value: userData?.customerOrg._id,
    timezone: userData?.customerOrg.time_zones ?? DEFAULT_TIMEZONE,
  };
};
