import { useCallback, useEffect, useMemo } from "react";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../context/AppContext";
import {
  FileFormat,
  GetTableDataDownloadUrlResponseStatus,
  GetTableDataInput,
  Maybe,
  TableDomain,
  useGetTableDataDownloadUrlQuery,
} from "../../../graphql/operations";
import {
  TableGridColDef,
  TableGridData,
} from "../../../shared/components/Table";
import { getExportColumns } from "../../helpers/tableData";

export interface ExtendedGetTableDataInput extends GetTableDataInput {
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
  imei?: Maybe<string>;
  orgIds?: Maybe<string>[];
  skipSharedAssets?: true;
}

interface TableDataExporterProps<T extends TableGridData> {
  apiRef: React.MutableRefObject<GridApiPremium>;
  columns: TableGridColDef<T>[];
  queryInput: ExtendedGetTableDataInput;
  domain: TableDomain;
  fileFormat: FileFormat;
  isExporting: boolean;
  setExporting: (exporting: boolean) => void;
  totalCount: number | undefined;
  baseFilters?: Record<string, unknown>;
  trigger?: string;
  timezone?: string;
  currentOrgId?: string;
}

export const useTableDataExporter = <T extends TableGridData>({
  apiRef,
  columns,
  queryInput,
  domain,
  fileFormat,
  isExporting,
  setExporting,
  totalCount,
  baseFilters,
  trigger,
  timezone,
  currentOrgId,
}: TableDataExporterProps<T>) => {
  const { dispatch } = useAppContext();

  const onExportSuccess = useCallback(
    (message: string) => {
      dispatch({
        type: "PAGE_SNACKBAR",
        payload: {
          title: "Success!",
          text: message,
          severity: "success",
        },
      });
    },
    [dispatch]
  );

  const onExportFailure = useCallback(
    (message: string) => {
      dispatch({
        type: "PAGE_SNACKBAR",
        payload: {
          title: "Error!",
          text: message,
          severity: "error",
        },
      });
    },
    [dispatch]
  );

  const exportColumns = useMemo(
    () => getExportColumns<T>(apiRef, columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef.current?.state?.columns, columns]
  );

  const { data: tableDataDownloadUrl, refetch } =
    useGetTableDataDownloadUrlQuery(
      {
        input: {
          fileFormat,
          tableState: {
            columns: exportColumns,
            tableFilters: queryInput.tableFilters,
            ...(queryInput?.complexFilters
              ? { complexFilters: JSON.stringify(queryInput.complexFilters) }
              : {}),
            sorting: queryInput.sorting,
            searchText: queryInput.searchText,
            pagination: { limit: totalCount },
            domain,
            baseFilters: JSON.stringify({
              ...baseFilters,
              orgId: queryInput.orgId,
            }),
            imei: queryInput.imei,
            startDate: queryInput.startDate,
            endDate: queryInput.endDate,
            jsonLogic: trigger,
            timezone,
            currentOrgId,
            ...(queryInput.viewport ? { viewport: queryInput.viewport } : {}),
            ...(queryInput.skipSharedAssets ? { skipSharedAssets: true } : {}),
          },
        },
      },
      {
        enabled: false,
        staleTime: 0,
        cacheTime: 0,
      }
    );

  useEffect(() => {
    if (isExporting) {
      if (totalCount === 0) {
        onExportFailure &&
          onExportFailure(
            "Cannot export an empty table. Please ensure there is data in the table before attempting to export."
          );
        setExporting(false);
      } else {
        refetch().finally(() => setExporting(false));
      }
    }
  }, [
    fileFormat,
    isExporting,
    refetch,
    setExporting,
    totalCount,
    onExportFailure,
  ]);

  const downloadFile = useCallback((url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  useEffect(() => {
    if (!tableDataDownloadUrl) return;

    const { status, downloadUrl } =
      tableDataDownloadUrl.getTableDataDownloadUrl;

    switch (status) {
      case GetTableDataDownloadUrlResponseStatus.Success:
        if (downloadUrl) {
          downloadFile(downloadUrl);
          onExportSuccess &&
            onExportSuccess("File was downloaded successfully!");
        } else {
          console.error("Download URL is missing.");
          onExportFailure && onExportFailure("Download URL is missing.");
        }
        break;

      case GetTableDataDownloadUrlResponseStatus.DownloadRedirect:
        onExportSuccess &&
          onExportSuccess(
            "File download URL was sent to your email. Please check the inbox."
          );
        break;

      case GetTableDataDownloadUrlResponseStatus.Failed:
        onExportFailure && onExportFailure("Failed to get download URL.");
        break;
    }
  }, [tableDataDownloadUrl, downloadFile, onExportSuccess, onExportFailure]);
};
