import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  Maybe,
  SensorStatus,
  SensorStatusUppercase,
} from "../../../../../graphql/operations";

// Note: think about combining it with PsiWheelEndAxle
export enum TPMS_Axle {
  One = "Axle One",
  Two = "Axle Two",
  Three = "Axle Three",
}

export enum TPMS_Tire {
  InnerLeft = "Left Inner Tire",
  InnerRight = "Right Inner Tire",
  OuterLeft = "Left Outer Tire",
  OuterRight = "Right Outer Tire",
}

export enum TPMS_Tire_Type {
  InnerCurbside = "innerCurbside",
  PrimaryCurbside = "primaryCurbside",
  InnerRoadside = "innerRoadside",
  PrimaryRoadside = "primaryRoadside",
}

export enum AxleSide {
  LEFT = "left",
  RIGHT = "right",
}

export enum TPMS_Tire_Name {
  InnerLeft = "Inner Roadside Tire",
  InnerRight = "Inner Curbside Tire",
  OuterLeft = "Primary Roadside Tire",
  OuterRight = "Primary Curbside Tire",
}

/**
 * All possible dual imbalance keys we can have per axle
 */
export enum ImbalanceVariantsByAxle {
  AXLE1LEFT = "left1",
  AXLE1RIGHT = "right1",
  AXLE2LEFT = "left2",
  AXLE2RIGHT = "right2",
  AXLE3LEFT = "left3",
  AXLE3RIGHT = "right3",
}

// Note: think about combining it with TPMS_Axle
export enum PsiWheelEndAxle {
  One = "Axle One",
  Two = "Axle Two",
  Three = "Axle Three",
}

export enum PsiWheelEnd {
  Left = "Left Wheel End",
  Right = "Right Wheel End",
}

export const MAP_TPMS_TIRE_TYPES: Record<TPMS_Tire_Type, TPMS_Tire> = {
  [TPMS_Tire_Type.PrimaryRoadside]: TPMS_Tire.OuterLeft,
  [TPMS_Tire_Type.InnerRoadside]: TPMS_Tire.InnerLeft,
  [TPMS_Tire_Type.InnerCurbside]: TPMS_Tire.InnerRight,
  [TPMS_Tire_Type.PrimaryCurbside]: TPMS_Tire.OuterRight,
};

export const MAP_AXLE_TO_NUMBER: Record<TPMS_Axle, number> = {
  [TPMS_Axle.One]: 1,
  [TPMS_Axle.Two]: 2,
  [TPMS_Axle.Three]: 3,
};

export interface TireSummaryData {
  axle: TPMS_Axle;
  tire: TPMS_Tire;
  status: SensorStatusUppercase;
  gap: Maybe<number>;
  dualImbalanceStatus: Maybe<SensorStatusUppercase>;
  pressure: number | null;
  temperature: number | null;
  lastReported: Date;
}

// DualImbalanceLeftSideData
export interface DILeftSideTireData {
  outerLeft: TireSummaryData;
  innerLeft?: TireSummaryData;
}

export type RangeReferenceAreaValueType = {
  state: string;
  min1: number;
  max1: number;
  min2: number;
  max2: number;
};

// DualImbalanceRightSideData
export interface DIRightSideTireData {
  outerRight: TireSummaryData;
  innerRight?: TireSummaryData;
}

export interface DualImbalanceAxleTireData {
  left: DILeftSideTireData;
  right: DIRightSideTireData;
}

export interface IMappedDualImbalanceChartData {
  date: string;
  tooltipDate: string;
  left1?: number;
  "left1-min"?: number;
  "left1-max"?: number;
  right1?: number;
  "right1-min"?: number;
  "right1-max"?: number;
  left2?: number;
  "left2-min"?: number;
  "left2-max"?: number;
  right2?: number;
  "right2-min"?: number;
  "right2-max"?: number;
  left3?: number;
  "left3-min"?: number;
  "left3-max"?: number;
  right3?: number;
  "right3-min"?: number;
  "right3-max"?: number;
}

export interface IReferenceAreaValueType {
  state: string;
  min: number;
  max: number;
}

export interface PsiWheelEndSummaryData {
  axle: PsiWheelEndAxle;
  wheelEnd: PsiWheelEnd;
  status: SensorStatus;
  temperature: string | null;
  lastReported: Date;
}

export enum SensorState {
  Critical = "Critical",
  Warning = "Warning",
  Alert = "Alert",
  Healthy = "Healthy",
}

// TODO: Think about exporting it as global
export const STATE_TO_COLOR = {
  [SensorState.Critical]: ColorsPalette.ErrorOpacity20,
  [SensorState.Warning]: ColorsPalette.CautionOpacity10,
  [SensorState.Alert]: ColorsPalette.AlertOpacity20,
  [SensorState.Healthy]: ColorsPalette.SuccessOpacity20,
};
