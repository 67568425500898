import { FC, memo, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { ReactComponent as LiftgateSensorDark } from "../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../assets/svgs/liftgateSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  ProfileConfigProperty,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  batteryLiftgateDefaultValues,
  batteryStep,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, LiftgateDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type LiftgateDrawerProps = {
  sensorType: SensorProfileConfigType | ProfileConfigProperty;
  sensorProfileData?: SensorProfileResult;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
};

const LiftgateDrawer: FC<LiftgateDrawerProps> = ({
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
}) => {
  const liftgateBatteryRules = sensorProfileData?.configuration?.liftgate;
  const parsedLiftgateBatteryValues =
    extractReversedRules(liftgateBatteryRules);
  const initialLiftgateBattery =
    parsedLiftgateBatteryValues || batteryLiftgateDefaultValues;

  const [liftgateBattery, setLiftgateBattery] = useState(
    initialLiftgateBattery
  );
  const [deleteLiftgateProfilePopup, setDeleteLiftgateProfilePopup] =
    useState(false);
  const {
    dispatch,
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };
  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();

  const isEdit = type === LiftgateDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);

  // Main Form is always static & will constist of same fields for every sensor
  const { form: liftgateMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const handleLiftgateProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Liftgate Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleLiftgateProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleLiftgateProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Liftgate Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleLiftgateProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleLiftgateProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Liftgate Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleLiftgateProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleLiftgateProfileCreateSuccess,
    createSensorProfileOnError: handleLiftgateProfileCreateError,
    updateSensorProfileOnSuccess: handleLiftgateProfileUpdateSuccess,
    updateSensorProfileOnError: handleLiftgateProfileUpdateError,
    deleteSensorProfileOnSuccess: handleLiftgateProfileDeleteSuccess,
    deleteSensorProfileOnError: handleLiftgateProfileDeleteError,
  });

  const handleSubmitClick = async () => {
    const mainFormData: FieldValues = liftgateMainForm.getValues();
    const { orgName, name, default: isDefault } = mainFormData;

    const liftgateBatteryConfig = {
      liftgate: {
        match: prepareReversedPayload(
          undefined,
          liftgateBattery,
          MinValuesBySensorType.liftgate,
          MaxValuesBySensorType.liftgate,
          batteryStep
        ),
      },
    };

    if (type === LiftgateDrawers.Create) {
      createSensorProfile({
        name,
        configuration: liftgateBatteryConfig,
        default: isDefault ?? false,
        orgId: orgName ?? "",
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: orgName,
        name,
        default: isDefault,
        configuration: liftgateBatteryConfig,
      };

      updateSensorProfile({
        ...updateSensorProfilePayload,
      } as UpdateOrganizationSensorProfileInput);
    }
  };

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const toggleDeleteProfilePopup = () => {
    setDeleteLiftgateProfilePopup(!deleteLiftgateProfilePopup);
  };

  const handleClose = () => onClose(false);

  const liftgateMarks = sliderMarks(
    MinValuesBySensorType.liftgate,
    MaxValuesBySensorType.liftgate,
    "V"
  );

  const headerText = `${isEdit ? "Edit" : "Create"} Profile`;

  const isLoadingOrUpdating =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;
  const isInvalid = isLoadingOrUpdating || !liftgateMainForm.formState.isValid;
  const isEditAndPristine =
    isEdit &&
    !liftgateMainForm.formState.isDirty &&
    liftgateBattery.toString() === initialLiftgateBattery.toString();

  return (
    <Drawer
      isOpen={isOpen}
      onRequestClose={handleClose}
      testId="liftgate-profile-drawer"
    >
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            <ProfilesDrawerMainForm
              form={liftgateMainForm}
              disabled={isLoadingCreateSensorProfile}
              isEdit={isEdit}
            />

            <Box className="px-6" data-testid="profiles-drawer-battery-slider">
              <Box className="mb-16">
                <Typography sx={{ fontWeight: "bold" }}>
                  Liftgate Battery Settings
                </Typography>
                {isLightTheme ? (
                  <LiftgateSensorDark style={svgIconSettings} />
                ) : (
                  <LiftgateSensorLight style={svgIconSettings} />
                )}
              </Box>
              <SensorSlider
                values={liftgateBattery}
                min={MinValuesBySensorType.liftgate}
                max={MaxValuesBySensorType.liftgate}
                marks={liftgateMarks}
                disabled={isLoadingCreateSensorProfile}
                step={batteryStep}
                reversed
                onChange={setLiftgateBattery}
              />
            </Box>
          </Box>

          <DrawerActions
            cancelBtnTestId="btn-cancel-liftgate-profile"
            deleteBtnTestId="btn-delete-liftgate-profile"
            showDeleteBtn={isEdit}
            disabled={isLoadingOrUpdating}
            onCancel={handleClose}
            onDelete={toggleDeleteProfilePopup}
          />

          {isEdit && deleteLiftgateProfilePopup && (
            <DeleteProfileDialog
              isOpen={deleteLiftgateProfilePopup}
              onClose={toggleDeleteProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteProfile}
              testId="liftgate"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={isInvalid || isEditAndPristine}
        testId="liftgate-profiles-drawer-submit-btn"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default memo(LiftgateDrawer);
