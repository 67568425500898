import React, { createContext, useContext, useState } from "react";
import { DEFAULT_CHART_DATE_RANGE_OPTION } from "../../../../../../constants/map";
import { CargoCameraSpaceData } from "./types";

interface InternalCameraSpaceGraphContextValue {
  // Setup the date range filters for the graph
  startDate: Date | undefined;
  setStartDate: (date: Date) => void;

  endDate: Date | undefined;
  setEndDate: (date: Date) => void;

  selectedDateRangeOption: number;
  setSelectedDateRangeOption: (value: number) => void;

  // Contain the data fetched from the server
  aggregatedCargoCameraSpaceData: CargoCameraSpaceData[];
  setAggregatedCargoCameraSpaceData: (data: CargoCameraSpaceData[]) => void;
}

const InternalCameraSpaceGraphContext = createContext<
  InternalCameraSpaceGraphContextValue | undefined
>(undefined);

interface InternalCameraSpaceGraphContextProps {
  children: React.ReactNode;
}

export const InternalCameraSpaceGraphProvider: React.FC<
  InternalCameraSpaceGraphContextProps
> = ({ children }: InternalCameraSpaceGraphContextProps) => {
  // Setup the date range filters for the graph
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [selectedDateRangeOption, setSelectedDateRangeOption] =
    useState<number>(DEFAULT_CHART_DATE_RANGE_OPTION);

  // Contain the data fetched from the server
  const [aggregatedCargoCameraSpaceData, setAggregatedCargoCameraSpaceData] =
    useState<CargoCameraSpaceData[]>([]);

  const contextValue: InternalCameraSpaceGraphContextValue = {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    selectedDateRangeOption,
    setSelectedDateRangeOption,

    aggregatedCargoCameraSpaceData,
    setAggregatedCargoCameraSpaceData,
  };

  return (
    <InternalCameraSpaceGraphContext.Provider value={contextValue}>
      {children}
    </InternalCameraSpaceGraphContext.Provider>
  );
};

export const useInternalCameraSpaceGraphContext = () => {
  const context = useContext(InternalCameraSpaceGraphContext);
  if (!context) {
    throw new Error(
      "useInternalCameraSpaceGraphContext must be used within an InternalCameraSpaceGraphProvider"
    );
  }
  return context;
};
