import React, { PropsWithChildren } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from "@mui/material";
import DateRangePicker from "../../DateRangePicker";
import HeaderOptions from "./components/HeaderOptions";

export type BaseDashboardWidgetProps = PropsWithChildren & {
  title: string;

  widgetId: number;
  onWidgetDelete?: () => void;

  // Control the visibility of the date filter
  isDateFilterEnabled?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  isLoading?: boolean;
  onDateFilterChange?: (startDate: Date | null, endDate: Date | null) => void;
  noPermission?: boolean;
};

const BaseDashboardWidget: React.FC<BaseDashboardWidgetProps> = ({
  title,
  children,
  widgetId,
  onWidgetDelete,
  isLoading = false,
  isDateFilterEnabled = false,
  startDate,
  endDate,
  onDateFilterChange,
  noPermission = false,
}) => {
  return (
    <Card
      data-testid="dashboard-widget--card"
      className={`background-white items-center flex flex-col`}
      sx={{
        boxShadow: "none",
        width: "100%",
        borderRadius: "8px",
        borderColor: "var(--asset-card-border)",
      }}
    >
      <CardMedia className="w-full">
        <div
          className="flex flex-row justify-between items-center p-4 w-full"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <div>
            {isLoading ? (
              <Skeleton
                variant="text"
                data-testid={"base-dashboard-widget--header-loader"}
                sx={{ height: "26px", width: "236px" }}
              />
            ) : (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "var(--brand)",
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
            )}
          </div>

          <div className="flex flex-row items-center gap-4">
            {isDateFilterEnabled && onDateFilterChange ? (
              <>
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ height: "26px", width: "200px" }}
                  />
                ) : (
                  <DateRangePicker
                    defaultStartDate={startDate}
                    defaultEndDate={endDate}
                    onChange={onDateFilterChange}
                  />
                )}
              </>
            ) : null}

            <HeaderOptions
              widgetId={widgetId}
              onWidgetDelete={onWidgetDelete}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent className="w-full">
        {noPermission ? (
          <div className="h-[120px] flex items-center justify-center">
            <Typography className="!font-medium">No data available</Typography>
          </div>
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
};

export default BaseDashboardWidget;
