import {
  createContext,
  MutableRefObject,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import type { JsonLogicResult } from "@react-awesome-query-builder/ui";
import { QueryObserverResult } from "@tanstack/react-query";
import { Feature as GeoJsonFeature, Point } from "geojson";
import { isEqual, cloneDeep, omit } from "lodash";
import { useDebounce } from "use-debounce";
import { PAGE_SNACKBAR } from "../../../../constants";
import {
  DateFilterOption,
  FILTER_TYPE,
  LastReportedDateOptions,
  SORT_OPTIONS,
  SORT_OPTIONS_GEOFENCES,
} from "../../../../constants/map";
import { useAppContext } from "../../../../context/AppContext";
import {
  Asset,
  AssetFilter,
  useFindAssetFiltersQuery,
  useFindGeofencesQuery,
  AssetInput,
  AssetSortOrder,
  AssetSort,
  AssetFiltersInput,
  GeofenceData,
  Geofence,
  useGetGeofenceTypeQuery,
  GeofenceTypeData,
  GeofenceShape,
  GeofenceMetadata,
  AssetType,
  SensorStatus,
  useFindGeofenceByIdQuery,
  Scalars,
  FindAssetFiltersQuery,
  FindGeofenceByIdQuery,
  useFindGeofencesByViewPortQuery,
  TableFiltersInput,
} from "../../../../graphql/operations";
import type { CustomFormatResponseType } from "../../../../shared/components/QueryBuilder/QueryBuilder";
import { useOrgsDownwardHierarchy } from "../../../../shared/hooks/openSearchMongoPolyfillHooks/useOrgsDownwardHierarchy";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { useUserData } from "../../../../shared/hooks/useUserData";
import {
  generateAssetFiltersPayload,
  getCustomGeofenceFilterPayload,
  removeInvalidDateRangeFilters,
  removeInvalidValueRangeFilters,
  removeInvalidValueRangesFilters,
  useFeatureFlag,
} from "../../../../utils";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { ZoneData } from "../../../AdminPanel/tabs/Zones/interfaces";
import {
  defaultAssetsFilter,
  splitViewportAtMeridian,
} from "../../helpers/helpers";
import { AssetPagesUrlTypes } from "../../utils";
import { AssetDetentionState } from "../AssetsFilterControls/Filters/AssetFilters/types";

export type FeatureProperties = Partial<Omit<Asset, "_id">> & {
  id?: string;
  cluster_id?: string;
  cluster?: boolean;
  status?: string;
  signal?: boolean;
  load?: string | null;
  dwellingDays?: number;
  point_count?: number;
  point_count_abbreviated?: string;
  assetsCountPerHealthStatus?: AssetsCountPerHealthStatus; // for clusters
};

export type AssetsCountPerHealthStatus = {
  healthy?: number;
  warning?: number;
  alert?: number;
  critical?: number;
  unknown?: number;
};

export interface Feature extends GeoJsonFeature {
  type: "Feature";
  geometry: Point;
  properties: FeatureProperties;
}

export interface FilterDaysRange {
  minDays: number;
  maxDays: number;
}

interface BaseVoltageRange {
  minVoltage: number;
  maxVoltage: number;
}

export interface BatteryPrimaryVoltageRange extends BaseVoltageRange {}
export interface BatterySecondaryVoltageRange extends BaseVoltageRange {}
export interface BackupBatteryVoltageRange extends BaseVoltageRange {}

export interface SolarAmperageRange {
  minAmperage: number;
  maxAmperage: number;
}

export interface InternalCameraUsagePercentageRange {
  minPercentage: number;
  maxPercentage: number;
}

export type SignalFilter = "all" | boolean;
export type InternalCameraStatusFilter = null | string;
export type InternalCameraUsagePercentageFilter =
  InternalCameraUsagePercentageRange | null;
export type CargoUltrasonicStateFilter = string | null;
export type DoorStateFilter = string | null;

export type InstalledDateFilter = {
  startDate: Date | null;
  endDate: Date | null;
} | null;
export type GeofenceCategoryFilter = { id: string; label: string };
export type ZoneFilterOption = {
  id: string;
  label: string;
  country: string;
  ranges?: ZoneData["ranges"];
  matches?: ZoneData["matches"];
};

export type AssetRegionsFilter = {
  value: string[];
  label: string;
  country?: string;
};

export interface AssetFilters {
  startDate: Date | null;
  endDate: Date | null;
  dateRangeOption: DateFilterOption | null;
  status: string[] | FilterDaysRange;
  subOrganizations: boolean;
  assets: null | string;
  more: null | string;
  assetTypes: string[];
  assetTags: string[];
  signals: SignalFilter;
  assetId: string;
  imeiId: string;
  search?: string;
  productNames: { label: string; value: string }[];
  installers: string[];
  installer?: string;
  installedDate: InstalledDateFilter;
  installStatus: boolean;
  complexFilters?: JsonLogicResult;
  complexFiltersMongodb?: CustomFormatResponseType;
  batteryPrimaryVoltage: BatteryPrimaryVoltageRange | null;
  batterySecondaryVoltage: BatterySecondaryVoltageRange | null;
  backupBatteryVoltage: BackupBatteryVoltageRange | null;
  solarAmperage: SolarAmperageRange | null;
  internalCameraStatus?: InternalCameraStatusFilter;
  internalCameraFloorUsagePercentage?: InternalCameraUsagePercentageRange | null;
  internalCameraCubeUsagePercentage?: InternalCameraUsagePercentageRange | null;
  cargoUltrasonicState?: CargoUltrasonicStateFilter;
  doorState?: DoorStateFilter;
  liteSentryGamma?: SensorStatus[] | null;
  atisAlpha?: SensorStatus[] | null;
  temperatureInternal?: SensorStatus[] | null;
  wheelEndTemperature?: SensorStatus[] | null;
  airbag?: SensorStatus[] | null;
  dualImbalance?: SensorStatus[] | null;
  liftgate?: SensorStatus[] | null;
  battery?: SensorStatus[] | null;
  tpmsBeta?: SensorStatus[] | null;
  supplyPressure?: SensorStatus[] | null; // regulator
  tankPressure?: SensorStatus[] | null; // airtank
  assetHealth?: SensorStatus[] | null;
  numberOfAxles?: { label: string; value: number }[];
  numberOfTires?: { label: string; value: string }[];
  weightStatus?: FilterPayloadValueRange;
  totalMileage?: FilterPayloadValueRange;
  assetYear?: FilterPayloadValueRange;
  manufacturers?: string[];
  assetDetentionState?: {
    label: AssetDetentionState;
    value: AssetDetentionState;
  }[];
  regions?: AssetRegionsFilter[] | null; // done as array so we take use of the prepareRegionTerritoriesFilter functionality
  lastReportedDateRange?: LastReportedDateOptions | null;
  geofenceCategories?: string[];
  geofenceNames?: string[];
  zones?: ZoneFilterOption | null;
  sharedGroupName?: { label: string; value: string; type: string } | null;
  selectedShareType?: string;
}

export type GeofenceShapesFilter = "all" | GeofenceShape;
export type GeofenceAssetsFilter = "all" | boolean;

export interface GeofenceDateRange {
  startDate: Scalars["AWSDateTime"];
  endDate: Scalars["AWSDateTime"];
}

export interface GeofenceTimeRange {
  timeFrom: string;
  timeTo: string;
}

export interface IGeofenceFilters {
  names: { id: string; label: string }[];
  orgIds: { id: string; label: string }[];
  shapes: GeofenceShapesFilter;
  typeIds: { id: string; label: string }[];
  tags: { id: string; label: string }[];
  underMin: { id: string; label: string }[];
  overMax: { id: string; label: string }[];
  hasAssets: GeofenceAssetsFilter;
  filters: string | null;
  regions: AssetRegionsFilter | null;
  timezones: { id: string; label: string }[];
  locationCodes: string[];
  createdRange: GeofenceDateRange | null;
  updatedRange: GeofenceDateRange | null;
  operatingHoursRange: GeofenceTimeRange;
  zones?: ZoneFilterOption | null;
}

export enum PageTypes {
  AssetMap,
  AssetTable,
  Geofences,
  Geofencing,
  AssetGallery,
  ShareAssetsInvitation,
}

export type AssetFilterConfig = {
  filters: AssetFiltersInput[];
  linkOperator: string;
};

export type AssetsDataContextState = {
  shouldShowFiltersStripAndViewToggle: boolean;
  setShouldShowFiltersStripAndViewToggle: (shouldShow: boolean) => void;
  isLoadingAllAssetsCoordinates: boolean;
  setIsLoadingAllAssetsCoordinates: (loading: boolean) => void;
  allAssetsCoordinates: number[][];
  setAllAssetsCoordinates: (coordinates: number[][]) => void;
  shouldRecenterMap: boolean;
  setShouldRecenterMap: (shouldRecenter: boolean) => void;
  lastFetchedAllCoordinatesForOrg: string | null;
  setLastFetchedAllCoordinatesForOrg: (orgId: string | null) => void;
  isFetchingUserFilters: boolean;
  savedFilters: AssetFilter[];
  currentFilter: AssetFilters;
  currentGeofenceFilter: IGeofenceFilters;
  selectedFeature: Feature | null;
  focusedFeature: Feature | null;
  onChangeFilters: (newFilters: Partial<AssetFilters>) => void;
  clearAllAssetFilters: (filters?: AssetFilters) => void;
  onChangeGeofenceFilters: (newFilters: Partial<IGeofenceFilters>) => void;
  removeAssetFilterCache?: () => void;
  refetchAssetFilters?: () => Promise<
    QueryObserverResult<FindAssetFiltersQuery, unknown>
  >;
  setSelectedFeature: (feature: Feature | null) => void;
  setFocusedFeature: (feature: Feature | null) => void;
  detailId: string | null;
  setDetailId: Dispatch<SetStateAction<string | null>>;
  geofenceDetailError: unknown;
  isGeofenceDetailError: boolean;
  isGeofenceDetailLoading: boolean;
  isGeofenceDetailFetching: boolean;
  isGeofenceDetailFetchingSuccess: boolean;
  geofenceDetail: GeofenceData | null;
  geofenceParentDetail: GeofenceData | null;
  setGeofenceDetail: Dispatch<SetStateAction<GeofenceData | null>>;
  geofences: GeofenceData[];
  geofencesByViewport: GeofenceData[];
  isGeofenceFormDirty: boolean;
  setIsGeofenceFormDirty: (isFormDirty: boolean) => void;
  allGeofences: GeofenceData[];
  sortedGeofences: GeofenceData[];
  selectedGeofence: GeofenceData | null;
  isGeofencesFetching: boolean;
  isGeofencesFetchingSuccess: boolean;
  isGeofencesLoading: boolean;
  setGeofences: (geofences: GeofenceData[]) => void;
  setAllGeofences: (geofences: GeofenceData[]) => void;
  setSelectedGeofence: (x: GeofenceData | null) => void;
  userMapViewportCoordinates: number[][] | null;
  setUserMapViewportCoordinates: (coordinates: number[][] | null) => void;
  mapViewportCoordinates: number[][] | null;
  setMapViewportCoordinates: (coordinates: number[][] | null) => void;
  geofenceTypes: GeofenceTypeData[];
  shouldFitBounds: boolean;
  isDrawingGeofence: boolean;
  isFiltersDrawerVisible: boolean;
  isFiltersDrawerOpen: boolean;
  isAssetsDrawerVisible: boolean;
  isAssetsDrawerOpen: boolean;
  addGeofenceDrawerOpen: boolean;
  setShouldFitBounds: (x: boolean) => void;
  setIsDrawingGeofence: (x: boolean) => void;
  setIsFiltersDrawerVisible: (x: boolean) => void;
  setIsFiltersDrawerOpen: (x: boolean) => void;
  setIsAssetsDrawerVisible: (x: boolean) => void;
  setIsAssetsDrawerOpen: (x: boolean) => void;
  setAddGeofenceDrawerOpen: (x: boolean) => void;
  isGeofenceDashboardVisible: boolean;
  setIsGeofenceDashboardVisible: (x: boolean) => void;
  geofenceForUpdate: GeofenceData | null;
  setGeofenceForUpdate: (x: GeofenceData | null) => void;
  drawnGeofence: GeoJsonFeature | null;
  setDrawnGeofence: (x: GeoJsonFeature | null) => void;
  drawnGeofenceType: GeofenceShape | null;
  setDrawnGeofenceType: (x: GeofenceShape | null) => void;
  drawerFocused: boolean;
  setDrawerFocused: (x: boolean) => void;
  drawnGeofenceArea: string | null;
  setDrawnGeofenceArea: (x: string | null) => void;
  drawnGeofenceAreaInSqKm: number | null;
  setDrawnGeofenceAreaInSqKm: (x: number | null) => void;
  geofenceCreateFormRef: MutableRefObject<any> | null;
  pageType: PageTypes;
  showDashboard: boolean;
  setShowDashboard: (showDashboard: boolean) => void;
  gridApiRef: MutableRefObject<GridApiPremium> | null;
  assetsInput: AssetInput;
  setAssetsInput: (x: AssetInput) => void;
  assetsPagination: number;
  currentPageNo: number;
  onPageChange: (page: number) => void;
  onLastAssetDateAtPageChange: (id: string) => void;
  lastAssetDateAtPage: string;
  onViewToggle: (
    pageUrl: string,
    searchValue: string | null | undefined
  ) => void;
  currentSort: AssetSort | null;
  setCurrentSort: (asset: AssetSort) => void;
  selectedAssetId: string | null;
  setSelectedAssetId: (assetId: string | null) => void;
  filters: AssetFilterConfig;
  setIsInitialLoad: (isInitial: boolean) => void;
  isInitialLoad: boolean;
  initialMapCoordinates: any[];
  setInitialMapCoordinates: (coordinates: any[]) => void;
  initialMapZoom: number;
  setInitialMapZoom: (initZoom: number) => void;
  debouncedMapViewCoordinatesFromUrl: number[][];
  setViewportFromUrl: (arr: []) => void;
  userInitialSideMapFeaturesLength: number;
  setUserInitialSideMapFeaturesLength: (initZoom: number) => void;
  assetsSearchInput: string;
  setAssetsSearchInput: (inputValue: string) => void;
  geofenceNameInput: string;
  setGeofenceNameInput: (inputValue: string) => void;
  showAddAsset: boolean;
  setShowAddAsset: (showAddAsset: boolean) => void;
  isFileUploadDialogOpen: boolean;
  setIsFileUploadDialogOpen: (showAddAsset: boolean) => void;
  isGeofencesFileUploadDialogOpen: boolean;
  setIsGeofencesFileUploadDialogOpen: (showAddGeofences: boolean) => void;
  isAssetsVisibleOnGeoFence: boolean;
  setIsAssetsVisibleOnGeoFence: (flag: boolean) => void;
  isGeofencesListVisible: boolean;
  setIsGeofencesListVisible: (flag: boolean) => void;
  shouldUpdateQueryBuilder: boolean;
  setShouldUpdateQueryBuilder: (flag: boolean) => void;
  refetchGeofenceDetail: () => Promise<
    QueryObserverResult<FindGeofenceByIdQuery, unknown>
  >;
  restoreGeofencePreviousFilters: () => void;
  resetGeofenceFiltersToDefault: () => void;
  resetFilterToDefault: (key: keyof AssetFilters) => void;
  resetGeofenceFilterToDefault: (key: keyof IGeofenceFilters) => void;
  isGeofencesView?: boolean;
};
export const initialFilterState: AssetFilters = {
  assetHealth: [],
  airbag: [],
  battery: [],
  dualImbalance: [],
  liftgate: [],
  supplyPressure: [],
  tankPressure: [],
  tpmsBeta: [],
  startDate: null,
  endDate: null,
  status: [],
  subOrganizations: true,
  assets: null,
  more: null,
  dateRangeOption: null,
  assetId: "",
  imeiId: "",
  search: "",
  assetTypes: [],
  assetTags: [],
  signals: "all",
  productNames: [],
  installers: [],
  installer: "",
  installStatus: true,
  installedDate: {
    startDate: null,
    endDate: null,
  },
  complexFilters: {},
  complexFiltersMongodb: {},
  batteryPrimaryVoltage: null,
  batterySecondaryVoltage: null,
  backupBatteryVoltage: null,
  solarAmperage: null,
  internalCameraStatus: null,
  internalCameraFloorUsagePercentage: null,
  internalCameraCubeUsagePercentage: null,
  doorState: null,
  cargoUltrasonicState: null,
  liteSentryGamma: [],
  atisAlpha: [],
  temperatureInternal: [],
  wheelEndTemperature: [],
  numberOfAxles: [],
  numberOfTires: [],
  weightStatus: { startValue: null, endValue: null },
  totalMileage: { startValue: null, endValue: null },
  assetYear: { startValue: null, endValue: null },
  manufacturers: [],
  assetDetentionState: [],
  regions: [],
  zones: null,
  lastReportedDateRange: null,
  geofenceCategories: [],
  geofenceNames: [],
  sharedGroupName: null,
  selectedShareType: undefined,
};

export const initialGeofenceFilterState: IGeofenceFilters = {
  names: [],
  orgIds: [],
  shapes: "all",
  typeIds: [],
  tags: [],
  underMin: [],
  overMax: [],
  hasAssets: "all",
  filters: null,
  regions: null,
  timezones: [],
  locationCodes: [],
  createdRange: null,
  updatedRange: null,
  operatingHoursRange: {
    timeFrom: "",
    timeTo: "",
  },
  zones: null,
};

const defaultSort: AssetSort = {
  field: SORT_OPTIONS[0].key,
  direction: SORT_OPTIONS[0].order as AssetSortOrder,
};

const defaultSortGeofence: AssetSort = {
  field: SORT_OPTIONS_GEOFENCES[0].key,
  direction: SORT_OPTIONS_GEOFENCES[0].order as AssetSortOrder,
};

export interface FilterPayload {
  columnField: string;
  elemMatchField?: string;
  operatorValue?: string;
  value?:
    | string
    | string[]
    | string[][]
    | number[]
    | boolean
    | boolean[]
    | number
    | Record<string, unknown>;
  valueRange?: FilterPayloadValueRange;
  valueRanges?: FilterPayloadValueRange[];
  valueRangeForZones?: FilterPayloadValueRangeForZones;
  dateRange?: FilterPayloadDateRange;
}

export interface FilterPayloadValueRange {
  startValue?: number | null;
  endValue?: number | null;
}
export interface FilterPayloadValueRangeForZones {
  startValue?: number | null;
  endValue?: number | null;
  country?: string;
}
export interface FilterPayloadDateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export interface GeofenceFiltersPayload {
  names?: string[];
  shapes?: GeofenceShape[];
  orgIds?: string[];
  typeIds?: string[];
  tags?: string[];
  underMin?: AssetType[];
  overMax?: AssetType[];
  hasAssets?: boolean;
  filters?: string | null;
  timezones?: string[];
  locationCodes?: string[];
  regions?: string[];
  createdRange?: GeofenceDateRange;
  updatedRange?: GeofenceDateRange;
  operatingHoursRange?: GeofenceTimeRange;
  zones?: TableFiltersInput;
}
export interface FiltersPayload {
  filters: FilterPayload[];
  linkOperator: string;
}

export type FilterPayloadList = {
  filterList: FiltersPayload[];
};

const AssetsDataContext = createContext<AssetsDataContextState>({
  shouldShowFiltersStripAndViewToggle: false,
  setShouldShowFiltersStripAndViewToggle: () => {},
  isLoadingAllAssetsCoordinates: false,
  setIsLoadingAllAssetsCoordinates: () => {},
  allAssetsCoordinates: [],
  setAllAssetsCoordinates: () => {},
  shouldRecenterMap: false,
  setShouldRecenterMap: () => {},
  lastFetchedAllCoordinatesForOrg: null,
  setLastFetchedAllCoordinatesForOrg: () => {},
  isFetchingUserFilters: false,
  currentFilter: initialFilterState,
  currentGeofenceFilter: initialGeofenceFilterState,
  savedFilters: [],
  onChangeFilters: () => {},
  clearAllAssetFilters: (filters?: AssetFilters) => {},
  onChangeGeofenceFilters: () => {},
  selectedFeature: null,
  focusedFeature: null,
  setSelectedFeature: () => {},
  setFocusedFeature: () => {},
  geofenceDetailError: null,
  isGeofenceDetailError: false,
  isGeofenceDetailLoading: false,
  isGeofenceDetailFetching: false,
  isGeofenceDetailFetchingSuccess: false,
  detailId: null,
  setDetailId: () => null,
  geofenceDetail: null,
  geofenceParentDetail: null,
  setGeofenceDetail: () => null,
  geofences: [],
  geofencesByViewport: [],
  allGeofences: [],
  sortedGeofences: [],
  selectedGeofence: null,
  isGeofencesFetching: false,
  isGeofencesFetchingSuccess: false,
  isGeofencesLoading: false,
  setGeofences: () => {},
  isGeofenceFormDirty: false,
  setIsGeofenceFormDirty: () => {},
  setAllGeofences: () => {},
  setSelectedGeofence: () => {},
  drawnGeofence: null,
  geofenceCreateFormRef: null,
  setDrawnGeofence: () => {},
  drawnGeofenceType: null,
  setDrawnGeofenceType: () => {},
  drawerFocused: false,
  setDrawerFocused: () => {},
  drawnGeofenceArea: null,
  setDrawnGeofenceArea: () => {},
  drawnGeofenceAreaInSqKm: null,
  setDrawnGeofenceAreaInSqKm: () => {},
  shouldFitBounds: false,
  isDrawingGeofence: false,
  isFiltersDrawerOpen: false,
  isFiltersDrawerVisible: true,
  isAssetsDrawerOpen: false,
  addGeofenceDrawerOpen: false,
  setShouldFitBounds: () => {},
  setIsDrawingGeofence: () => {},
  setIsFiltersDrawerOpen: () => {},
  setIsFiltersDrawerVisible: () => {},
  setIsAssetsDrawerVisible: () => {},
  setIsAssetsDrawerOpen: () => {},
  setAddGeofenceDrawerOpen: () => {},
  isGeofenceDashboardVisible: false,
  setIsGeofenceDashboardVisible: () => {},
  geofenceForUpdate: null,
  setGeofenceForUpdate: () => {},
  geofenceTypes: [],
  userMapViewportCoordinates: null,
  setUserMapViewportCoordinates: () => {},
  mapViewportCoordinates: null,
  setMapViewportCoordinates: () => {},
  pageType: PageTypes.AssetMap,
  showDashboard: false,
  setShowDashboard: () => {},
  gridApiRef: null,
  assetsInput: {},
  setAssetsInput: () => {},
  assetsPagination: 0,
  currentPageNo: 1,
  onPageChange: () => {},
  lastAssetDateAtPage: "",
  onLastAssetDateAtPageChange: () => {},
  onViewToggle: () => {},
  currentSort: defaultSort,
  setCurrentSort: () => {},
  selectedAssetId: null,
  setSelectedAssetId: () => {},
  filters: {} as AssetFilterConfig,
  setIsInitialLoad: () => {},
  isInitialLoad: false,
  initialMapCoordinates: [],
  setInitialMapCoordinates: () => {},
  initialMapZoom: 1,
  setInitialMapZoom: () => {},
  debouncedMapViewCoordinatesFromUrl: [],
  setViewportFromUrl: () => {},
  userInitialSideMapFeaturesLength: 0,
  setUserInitialSideMapFeaturesLength: () => {},
  assetsSearchInput: "",
  setAssetsSearchInput: () => {},
  geofenceNameInput: "",
  setGeofenceNameInput: () => {},
  showAddAsset: false,
  setShowAddAsset: () => {},
  isFileUploadDialogOpen: false,
  setIsFileUploadDialogOpen: () => {},
  isGeofencesFileUploadDialogOpen: false,
  setIsGeofencesFileUploadDialogOpen: () => {},
  isAssetsVisibleOnGeoFence: false,
  setIsAssetsVisibleOnGeoFence: () => {},
  isGeofencesListVisible: false,
  setIsGeofencesListVisible: () => {},
  shouldUpdateQueryBuilder: false,
  setShouldUpdateQueryBuilder: () => {},
  refetchGeofenceDetail: async () => {
    // Implementation here
    return {} as QueryObserverResult<FindGeofenceByIdQuery, unknown>;
  },
  restoreGeofencePreviousFilters: () => {},
  resetGeofenceFiltersToDefault: () => {},
  isAssetsDrawerVisible: false,
  resetFilterToDefault: () => {},
  resetGeofenceFilterToDefault: () => {},
  isGeofencesView: false,
});

export const AssetsDataContextProvider = ({
  children,
  isGeofencesView,
}: {
  children: ReactElement | ReactElement[];
  isGeofencesView?: boolean;
}) => {
  const location = useLocation();

  const gridApiRef = useGridApiRef();

  const pageType = useMemo<PageTypes>(() => {
    if (location.pathname.includes(AssetPagesUrlTypes.Map)) {
      return PageTypes.AssetMap;
    } else if (location.pathname.includes(AssetPagesUrlTypes.Table)) {
      return PageTypes.AssetTable;
    } else if (location.pathname.includes(AssetPagesUrlTypes.Gallery)) {
      return PageTypes.AssetGallery;
    } else if (location.pathname.includes(AssetPagesUrlTypes.Invitations)) {
      return PageTypes.ShareAssetsInvitation;
    } else {
      return PageTypes.Geofences;
    }
  }, [location]);
  const lastPageType = useRef(pageType);

  const userData = useUserData();

  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const assetsPerPage = appConfig.table.defaultRowsPerPage;
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [
    shouldShowFiltersStripAndViewToggle,
    setShouldShowFiltersStripAndViewToggle,
  ] = useState(false);
  const [searchOnLoad, setSearchOnLoad] = useState(false);
  const [currentFilter, setCurrentFilter] =
    useState<AssetFilters>(initialFilterState);
  const [currentGeofenceFilter, setCurrentGeofenceFilter] =
    useState<IGeofenceFilters>(initialGeofenceFilterState);
  const [userMapViewportCoordinates, setUserMapViewportCoordinates] = useState<
    number[][] | null
  >(null);
  const [mapViewportCoordinates, setMapViewportCoordinates] = useState<
    number[][] | null
  >(null);
  const [isLoadingAllAssetsCoordinates, setIsLoadingAllAssetsCoordinates] =
    useState<boolean>(false);
  const [allAssetsCoordinates, setAllAssetsCoordinates] = useState<number[][]>(
    []
  );
  const [lastFetchedAllCoordinatesForOrg, setLastFetchedAllCoordinatesForOrg] =
    useState<string | null>(null);
  const [shouldRecenterMap, setShouldRecenterMap] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const [focusedFeature, setFocusedFeature] = useState<Feature | null>(null);
  const [geofences, setGeofences] = useState<GeofenceData[] | any[]>([]); // used by Assets List for now
  const [geofencesByViewport, setGeofencesByViewport] = useState<
    GeofenceData[]
  >([]);
  const [geofenceDetail, setGeofenceDetail] = useState<GeofenceData | null>(
    null
  );
  const [geofenceParentDetail, setGeofenceParentDetail] =
    useState<GeofenceData | null>(null);
  const [detailId, setDetailId] = useState<string | null>(null);
  const [isGeofenceFormDirty, setIsGeofenceFormDirty] =
    useState<boolean>(false);
  const [allGeofences, setAllGeofences] = useState<GeofenceData[] | any[]>([]);
  const [selectedGeofence, setSelectedGeofence] = useState<GeofenceData | null>(
    null
  );
  const [shouldFitBounds, setShouldFitBounds] = useState<boolean>(false);
  const [isDrawingGeofence, setIsDrawingGeofence] = useState<boolean>(false);
  const [addGeofenceDrawerOpen, setAddGeofenceDrawerOpen] = useState(false);

  const isMobile = useBreakpoint("down", "sm");

  const [isFiltersDrawerVisible, setIsFiltersDrawerVisible] = useState(
    !isMobile
  );
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(!isMobile);
  const [isAssetsDrawerVisible, setIsAssetsDrawerVisible] = useState(!isMobile);
  const [isAssetsDrawerOpen, setIsAssetsDrawerOpen] = useState(!isMobile);

  const [isGeofenceDashboardVisible, setIsGeofenceDashboardVisible] =
    useState<boolean>(false);
  const [geofenceForUpdate, setGeofenceForUpdate] =
    useState<GeofenceData | null>(null);
  const [drawnGeofence, setDrawnGeofence] = useState<GeoJsonFeature | null>(
    null
  );
  const [drawnGeofenceType, setDrawnGeofenceType] =
    useState<GeofenceShape | null>(null);
  const [drawnGeofenceArea, setDrawnGeofenceArea] = useState<string | null>(
    null
  );
  const [drawnGeofenceAreaInSqKm, setDrawnGeofenceAreaInSqKm] = useState<
    number | null
  >(null);
  const [drawerFocused, setDrawerFocused] = useState<boolean>(false);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [currentSort, setCurrentSort] = useState<AssetSort | null>(
    pageType === PageTypes.AssetMap ? defaultSort : defaultSortGeofence
  );
  const [isAssetsVisibleOnGeoFence, setIsAssetsVisibleOnGeoFence] =
    useState<boolean>(false);
  //Used to set the "skip" property
  const [assetsPagination, setAssetsPagination] = useState(0);
  const [lastAssetDateAtPage, setLastAssetDateAtPage] = useState<string>("");
  const [assetsInput, setAssetsInput] = useState<AssetInput>({
    limit: assetsPerPage,
    skip: 0,
    zoom: 3,
    gridCells: 10000,
    cluster: Boolean(pageType === PageTypes.AssetMap),
    sort: defaultSort,
    search: "",
  });
  const defaultCustomFilters = defaultAssetsFilter.filterList[0];
  const [isGeofencesListVisible, setIsGeofencesListVisible] = useState(false);
  const [geofenceNameInput, setGeofenceNameInput] = useState<string>("");
  const [customFilters, setCustomFilters] = useState<FiltersPayload | null>(
    defaultCustomFilters
  );
  const [previousGeofenceFilterState, setPreviousGeofenceFilterState] =
    useState<{ search: string; filters: IGeofenceFilters }>({
      search: "",
      filters: initialGeofenceFilterState,
    });
  const [assetsSearchInput, setAssetsSearchInput] = useState<string>("");

  const [customGeofenceFilters, setCustomGeofenceFilters] =
    useState<GeofenceFiltersPayload>({} as GeofenceFiltersPayload);

  // default user view: save coordinates & zoom value on inital load
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [initialMapCoordinates, setInitialMapCoordinates] = useState<any[]>([]);
  const [initialMapZoom, setInitialMapZoom] = useState<number>(1);
  const [
    userInitialSideMapFeaturesLength,
    setUserInitialSideMapFeaturesLength,
  ] = useState<number>(0);

  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  const [viewportFromUrl, setViewportFromUrl] = useState<number[][]>([]);
  const geofenceCreateFormRef = useRef();
  const { data: geofenceTypeData } = useGetGeofenceTypeQuery();
  const geofenceTypes = useMemo(
    () => geofenceTypeData?.getGeofenceType ?? [],
    [geofenceTypeData]
  );

  const [showAddAsset, setShowAddAsset] = useState<boolean>(false);
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] =
    useState<boolean>(false);

  const [isGeofencesFileUploadDialogOpen, setIsGeofencesFileUploadDialogOpen] =
    useState<boolean>(false);

  // the debounced map view port coordinates should be shared between the map, assets list and assets table
  const [debouncedMapViewCoordinatesFromUrl] = useDebounce(
    viewportFromUrl,
    appConfig.debounceTimeLong
  );
  const [shouldUpdateQueryBuilder, setShouldUpdateQueryBuilder] =
    useState<boolean>(false);

  useEffect(() => {
    const viewportParamFromUrl = searchParams.get("viewport");
    setViewportFromUrl(JSON.parse(viewportParamFromUrl ?? "[]") ?? []);
  }, [searchParams]);

  const filters = useMemo(() => {
    if (
      customFilters === null ||
      (customFilters && !customFilters.filters.length)
    ) {
      return {};
    }
    let results: FilterPayloadList = {
      filterList: [] as FiltersPayload[],
    } as FilterPayloadList;

    if (customFilters?.filters.length) {
      const filterCopy = cloneDeep(customFilters);

      filterCopy.filters.forEach((f, index) => {
        // stringify array value for api
        if (
          !(
            f.valueRange ||
            f.columnField === FILTER_TYPE.signal ||
            f.columnField === FILTER_TYPE.imei ||
            f.columnField === FILTER_TYPE.regions
          )
        ) {
          f.value = JSON.stringify(f?.value || []);
        }
      });

      // remove invalid date range filters from queries
      filterCopy.filters = removeInvalidDateRangeFilters(filterCopy.filters);

      // remove invalid value range filters from queries
      filterCopy.filters = removeInvalidValueRangeFilters(filterCopy.filters);
      filterCopy.filters = removeInvalidValueRangesFilters(filterCopy.filters);

      results.filterList.push(filterCopy);
    }
    if (results?.filterList?.length > 0) {
      return results;
    } else {
      return {};
    }
  }, [customFilters]) as AssetFilterConfig;

  // fetch user filters
  const {
    data: assetFiltersData,
    isFetching: isFetchingUserFilters,
    remove: removeAssetFilterCache,
    refetch: refetchAssetFilters,
  } = useFindAssetFiltersQuery(undefined, {
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Failed to load saved filters!",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    },
  });

  const splitViewPort =
    mapViewportCoordinates && splitViewportAtMeridian(mapViewportCoordinates);

  const orgsFromHierarchy = useOrgsDownwardHierarchy({
    selectedOrganization,
  });

  // Extend the viewport by adding the first coordinate to the end of the array.
  // This is necessary for OpenSearch, which checks the first and last coordinates.
  // When there are 10 coordinates, the first 5 form the first square, and the next 5 form the second square.
  // Adding the first coordinate to the end closes the second square.
  const extendedViewport =
    splitViewPort?.length === 10
      ? [...splitViewPort, splitViewPort[0]]
      : splitViewPort;

  const {
    data: geoFencesGQLData,
    isLoading: isGeofencesLoading,
    isFetching: isGeofencesFetching,
    isSuccess: isGeofencesFetchingSuccess,
    error: geofenceError,
  } = useFindGeofencesQuery(
    {
      input: {
        viewport: extendedViewport,
        ...customGeofenceFilters,
        name: geofenceNameInput,
        orgIds: orgsFromHierarchy,
      },
    },
    { enabled: pageType === PageTypes.Geofences }
  );

  const { shapes, ...geoFilters } = customGeofenceFilters;
  const { data: geoFencesByViewportGQLData, error: geofenceByViewportError } =
    useFindGeofencesByViewPortQuery(
      {
        input: {
          viewport: extendedViewport,
          ...geoFilters,
          name: geofenceNameInput,
          orgIds: orgsFromHierarchy,
          limit: 5000,
          skip: 0,
        },
      },
      { enabled: pageType === PageTypes.Geofences }
    );

  const {
    data: geofenceGQLDetail,
    isLoading: isGeofenceDetailLoading,
    isFetching: isGeofenceDetailFetching,
    isSuccess: isGeofenceDetailFetchingSuccess,
    error: geofenceDetailError,
    isError: isGeofenceDetailError,
    refetch: refetchGeofenceDetail,
  } = useFindGeofenceByIdQuery(
    {
      input: detailId as string,
    },
    {
      enabled: pageType === PageTypes.Geofences && !!detailId,
      cacheTime: 0,
    }
  );

  const { data: geofenceParentGQLDetail, error: geofenceParentDetailError } =
    useFindGeofenceByIdQuery(
      {
        input: selectedGeofence?.configuration?.parentId as string,
      },
      {
        enabled:
          pageType === PageTypes.Geofences &&
          !!selectedGeofence?.configuration?.parentId,
        cacheTime: 0,
      }
    );

  const { data: allGeofencesData } = useFindGeofencesQuery(
    { input: {} },
    { enabled: true }
  );

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPageNo(page);
      setAssetsPagination(assetsPerPage * (page - 1));
    },
    [assetsPerPage]
  );

  const onLastAssetDateAtPageChange = (id: string) =>
    setLastAssetDateAtPage(id);

  const onViewToggle = useCallback(
    (pageUrl: string, searchValue: string | null | undefined) => {
      if (searchValue) {
        setAssetsInput((prev) => ({ ...prev, search: searchValue }));
      } else {
        setAssetsInput((prev) => omit({ ...prev }, ["search"]));
      }

      if (pageUrl.includes(AssetPagesUrlTypes.Map)) {
        const availableSortOptions = SORT_OPTIONS.find(
          (sortOpt) => sortOpt.key === (currentSort?.field as string)
        );
        const newAssetInputs = !availableSortOptions
          ? { sort: defaultSort, cluster: true }
          : { cluster: true };
        if (!availableSortOptions) {
          setCurrentSort(defaultSort);
        }
        setAssetsInput((prev) => ({
          ...prev,
          ...newAssetInputs,
        }));
      }
    },
    [currentSort]
  );

  //---------------------------------------------
  // Effect to capture useFindGeofencesQuery Results
  //---------------------------------------------
  useEffect(() => {
    const data = geoFencesGQLData?.findGeofences?.data || [];
    setGeofences(data);
    if (geofenceError) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofences Failed!",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    }
  }, [geoFencesGQLData, geofenceError, dispatch, searchParams, pageType]);
  //---------------------------------------------
  // Effect to capture useFindGeofencesByViewPortQuery Results
  //---------------------------------------------
  useEffect(() => {
    const data = geoFencesByViewportGQLData?.findGeofences?.data || [];
    setGeofencesByViewport(data as GeofenceData[]);
    if (geofenceByViewportError) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofences Failed!",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    }
  }, [
    geoFencesByViewportGQLData,
    geofenceByViewportError,
    dispatch,
    searchParams,
    pageType,
  ]);

  useEffect(() => {
    const data = geofenceGQLDetail?.findGeofenceById || null;
    setGeofenceDetail(data);
    setSelectedGeofence(data);
    if (!data?.configuration?.parentId) {
      setGeofenceParentDetail(null);
    }
    if (geofenceDetailError) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofence Detail Failed!",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    }
  }, [geofenceGQLDetail, geofenceDetailError, pageType, dispatch]);

  useEffect(() => {
    const data = geofenceParentGQLDetail?.findGeofenceById || null;
    setGeofenceParentDetail(data);
    if (geofenceParentDetailError) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofence Parent Detail Failed!",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    }
  }, [geofenceParentGQLDetail, geofenceParentDetailError, pageType, dispatch]);

  useEffect(() => {
    if (isGeofenceDetailError) {
      const errorData = geofenceDetailError as Error;
      if (errorData.message === "ERROR_MISSING_GEOFENCE_WITH_GIVEN_ID") {
        navigate(NavigationRoutes.Geofences);

        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Not Found!",
            text: "The geofence you are looking for does not exist.",
            severity: "error",
          },
        });
      }
    }
  }, [isGeofenceDetailError, geofenceDetailError, dispatch, navigate]);

  useEffect(() => {
    const data = allGeofencesData?.findGeofences?.data || [];
    setAllGeofences(data);
  }, [allGeofencesData, pageType]);

  const onChangeFilters = useCallback(
    (newFilters: Partial<AssetFilters>) => {
      const shouldChangeFilters = [
        NavigationRoutes.AssetMap,
        NavigationRoutes.AssetGallery,
        NavigationRoutes.AssetTable,
        NavigationRoutes.Geofences,
      ].some((route) => location.pathname.includes(route));
      //if we enter below check we are on asset id page and don`t want to change search params
      if (!shouldChangeFilters) return;
      // Reset the page number when filters change
      onPageChange(1);
      setSelectedFeature(null);
      setSearchOnLoad(true);
      setCurrentFilter((prev) => ({
        ...prev,
        ...newFilters,
      }));

      if (isEqual(initialFilterState, newFilters) || newFilters?.search) {
        searchParams.delete("filters");
        // Reset the url, but keep the viewport and zoom level info
        setSearchParams(searchParams);
        setCustomFilters(defaultCustomFilters);
      } else {
        setAssetsInput((prev) => ({ ...prev, search: "" }));
        const customerOrgId = !newFilters.subOrganizations
          ? userData?.customerOrg._id
          : null;
        const payload = generateAssetFiltersPayload(
          newFilters,
          customFilters?.filters || null,
          customerOrgId
        );
        if (payload) {
          setCustomFilters((prev) => ({
            ...prev,
            filters: [...payload],
            linkOperator: "and",
          }));
        }
        // get the viewport and zoomlevel from the parameters
        let viewport = searchParams.get("viewport");
        let zoomLevel = searchParams.get("zoomLevel");
        setSearchParams((prevState) => {
          return {
            filters: JSON.stringify({
              ...JSON.parse(prevState.get("filters") ?? "{}"),
              ...newFilters,
            }),
            ...(viewport && viewport?.length > 0 ? { viewport } : {}),
            ...(zoomLevel ? { zoomLevel: zoomLevel } : {}),
          };
        });
      }
    },
    [
      searchParams,
      setSearchParams,
      customFilters?.filters,
      userData?.customerOrg._id,
      onPageChange,
      defaultCustomFilters,
      location.pathname,
    ]
  );

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  // TODO: Cleanup with PRJIND-9218
  const clearAllAssetFilters = useCallback(
    (filters?: AssetFilters) => {
      // Certain filters need to have a default value of "undefined" when using OS and "all" when using Mongo
      const valueToUseForEdgeCaseFilters = fetchAssetsFromOpenSearchFeatureFlag
        ? undefined
        : "all";

      onChangeFilters(
        filters || {
          ...initialFilterState,
          cargoUltrasonicState: valueToUseForEdgeCaseFilters,
          doorState: valueToUseForEdgeCaseFilters,
          internalCameraStatus: valueToUseForEdgeCaseFilters,
          signals: valueToUseForEdgeCaseFilters,
        }
      );
    },
    [fetchAssetsFromOpenSearchFeatureFlag, onChangeFilters]
  );

  const onChangeGeofenceFilters = useCallback(
    (newFilters: Partial<IGeofenceFilters>) => {
      setCurrentGeofenceFilter((prev) => ({
        ...prev,
        ...newFilters,
      }));

      if (isEqual(initialGeofenceFilterState, newFilters)) {
        setCustomGeofenceFilters({} as GeofenceFiltersPayload);
      } else {
        setCustomGeofenceFilters((prev) => {
          const newPayload = getCustomGeofenceFilterPayload(newFilters, prev);

          return newPayload ?? prev;
        });
      }
    },
    [setCustomGeofenceFilters]
  );

  const resetGeofenceFiltersToDefault = useCallback(() => {
    if (
      isEqual(previousGeofenceFilterState.filters, currentGeofenceFilter) &&
      isEqual(geofenceNameInput, previousGeofenceFilterState.search)
    ) {
      return;
    }
    const prevSearch = geofenceNameInput;
    const prevFilters = { ...currentGeofenceFilter };
    setPreviousGeofenceFilterState({
      search: prevSearch,
      filters: prevFilters,
    });

    setCustomGeofenceFilters({});
    setCurrentGeofenceFilter(initialGeofenceFilterState);
    setGeofenceNameInput("");
  }, [
    previousGeofenceFilterState.filters,
    previousGeofenceFilterState.search,
    currentGeofenceFilter,
    geofenceNameInput,
  ]);
  const restoreGeofencePreviousFilters = useCallback(() => {
    const payload = getCustomGeofenceFilterPayload(
      ((
        initial: IGeofenceFilters,
        received: IGeofenceFilters
      ): Partial<IGeofenceFilters> =>
        Object.keys(received).reduce((acc: Partial<IGeofenceFilters>, key) => {
          if (key in received) {
            if (
              JSON.stringify(initial[key as keyof IGeofenceFilters]) !==
              JSON.stringify(received[key as keyof IGeofenceFilters])
            ) {
              return {
                ...acc,
                [key]: received[key as keyof IGeofenceFilters],
              };
            }
            return acc;
          }
          return acc;
        }, {}))(
        initialGeofenceFilterState,
        previousGeofenceFilterState.filters
      ),
      null
    );

    setCustomGeofenceFilters(payload);
    setCurrentGeofenceFilter(previousGeofenceFilterState.filters);
    setGeofenceNameInput(previousGeofenceFilterState.search);
    setPreviousGeofenceFilterState({
      search: "",
      filters: initialGeofenceFilterState,
    });
  }, [previousGeofenceFilterState.filters, previousGeofenceFilterState.search]);

  const savedFilters = useMemo(
    () =>
      assetFiltersData?.findAssetFilters
        ?.map((filter) => (filter ? [filter] : []))
        .flat() ?? [],
    [assetFiltersData?.findAssetFilters]
  );

  useEffect(() => {
    if (
      searchParams.get("filters") !== null &&
      searchParams.get("filters") !== JSON.stringify(initialFilterState) &&
      !searchOnLoad &&
      (pageType !== PageTypes.Geofences || !isGeofencesLoading)
    ) {
      onChangeFilters(JSON.parse(searchParams.get("filters") ?? ""));
    }
  }, [
    onChangeFilters,
    searchParams,
    searchOnLoad,
    isGeofencesLoading,
    pageType,
  ]);

  const sortedGeofences = useMemo(() => {
    const sortGeofences = () => {
      const sortOption =
        currentSort &&
        SORT_OPTIONS_GEOFENCES.find(
          (item) =>
            item.key === currentSort?.field &&
            item.order === currentSort?.direction
        );

      const compareGeofences =
        (key: keyof Geofence | keyof GeofenceMetadata, order: "asc" | "desc") =>
        (
          { geofence: geofenceA, metadata: metadataA }: GeofenceData,
          { geofence: geofenceB, metadata: metadataB }: GeofenceData
        ) => {
          const isDateField = key === "createdAt";

          const aValue = isDateField
            ? new Date(metadataA?.[key] as string).getTime()
            : (geofenceA as Record<string, any>)[key];
          const bValue = isDateField
            ? new Date(metadataB?.[key] as string).getTime()
            : (geofenceB as Record<string, any>)[key];

          if (aValue === undefined || bValue === undefined) {
            return 0; // If either value is undefined, treat them as equal
          }

          if (aValue === bValue) {
            return 0; // No need to compare further if values are equal
          }

          const comparison = aValue > bValue ? 1 : -1;

          return order === "asc" ? comparison : -comparison;
        };

      if (sortOption) {
        const { key, order } = sortOption;
        return [...geofences].sort(
          compareGeofences(key as keyof Geofence, order)
        );
      }
      return geofences;
    };
    return currentSort ? sortGeofences() : geofences;
  }, [geofences, currentSort]);

  const resetFilterToDefault = useCallback(
    (filterKey: keyof AssetFilters) => {
      const isEdgeCaseFilter = [
        "cargoUltrasonicState",
        "doorState",
        "internalCameraStatus",
        "signals",
      ].includes(filterKey);

      // Certain filters need to have a default value of "undefined" when using OS and "all" when using Mongo
      if (fetchAssetsFromOpenSearchFeatureFlag && isEdgeCaseFilter) {
        onChangeFilters({ [filterKey]: undefined });
      } else {
        onChangeFilters({ [filterKey]: initialFilterState[filterKey] });
      }
    },
    [fetchAssetsFromOpenSearchFeatureFlag, onChangeFilters]
  );

  const resetGeofenceFilterToDefault = useCallback(
    (filterKey: keyof IGeofenceFilters) => {
      onChangeGeofenceFilters({
        [filterKey]: initialGeofenceFilterState[filterKey],
      });
    },
    [onChangeGeofenceFilters]
  );

  // Reset filters to default when switching between assets/geofences views
  useEffect(() => {
    if (
      lastPageType.current === PageTypes.Geofences &&
      pageType !== PageTypes.Geofences
    ) {
      resetGeofenceFiltersToDefault();
    } else if (
      lastPageType.current !== PageTypes.Geofences &&
      pageType === PageTypes.Geofences
    ) {
      clearAllAssetFilters();
    }

    lastPageType.current = pageType;
  }, [pageType, resetGeofenceFiltersToDefault, clearAllAssetFilters]);

  return (
    <AssetsDataContext.Provider
      value={{
        shouldShowFiltersStripAndViewToggle,
        setShouldShowFiltersStripAndViewToggle,
        isLoadingAllAssetsCoordinates,
        setIsLoadingAllAssetsCoordinates,
        allAssetsCoordinates,
        setAllAssetsCoordinates,
        shouldRecenterMap,
        setShouldRecenterMap,
        lastFetchedAllCoordinatesForOrg,
        setLastFetchedAllCoordinatesForOrg,
        isFetchingUserFilters,
        currentFilter,
        currentGeofenceFilter,
        savedFilters,
        selectedFeature,
        focusedFeature,
        setSelectedFeature,
        setFocusedFeature,
        onChangeFilters,
        clearAllAssetFilters,
        onChangeGeofenceFilters,
        removeAssetFilterCache,
        refetchAssetFilters,
        detailId,
        setDetailId,
        isGeofenceDetailFetching,
        isGeofenceDetailFetchingSuccess,
        isGeofenceDetailLoading,
        geofenceDetailError,
        isGeofenceDetailError,
        geofenceDetail,
        geofenceParentDetail,
        setGeofenceDetail,
        geofences,
        geofencesByViewport,
        isGeofenceFormDirty,
        setIsGeofenceFormDirty,
        allGeofences,
        selectedGeofence,
        isGeofencesFetching,
        isGeofencesFetchingSuccess,
        isGeofencesLoading,
        sortedGeofences,
        setGeofences,
        setAllGeofences,
        setSelectedGeofence,
        geofenceTypes,
        shouldFitBounds,
        isDrawingGeofence,
        isFiltersDrawerVisible,
        isFiltersDrawerOpen,
        isAssetsDrawerVisible,
        isAssetsDrawerOpen,
        addGeofenceDrawerOpen,
        setShouldFitBounds,
        setIsDrawingGeofence,
        setIsFiltersDrawerVisible,
        setIsFiltersDrawerOpen,
        setIsAssetsDrawerVisible,
        setIsAssetsDrawerOpen,
        setAddGeofenceDrawerOpen,
        setIsGeofenceDashboardVisible,
        isGeofenceDashboardVisible,
        geofenceForUpdate,
        setGeofenceForUpdate,
        drawnGeofence,
        setDrawnGeofence,
        drawnGeofenceType,
        setDrawnGeofenceType,
        drawnGeofenceArea,
        setDrawnGeofenceArea,
        drawnGeofenceAreaInSqKm,
        setDrawnGeofenceAreaInSqKm,
        drawerFocused,
        setDrawerFocused,
        geofenceCreateFormRef,
        userMapViewportCoordinates,
        setUserMapViewportCoordinates,
        mapViewportCoordinates,
        setMapViewportCoordinates,
        pageType,
        showDashboard,
        setShowDashboard,
        gridApiRef,
        assetsInput,
        setAssetsInput,
        assetsPagination,
        lastAssetDateAtPage,
        currentPageNo,
        onPageChange,
        onLastAssetDateAtPageChange,
        onViewToggle,
        currentSort,
        setCurrentSort,
        selectedAssetId,
        setSelectedAssetId,
        filters,
        setIsInitialLoad,
        isInitialLoad,
        initialMapCoordinates,
        setInitialMapCoordinates,
        initialMapZoom,
        setInitialMapZoom,
        debouncedMapViewCoordinatesFromUrl,
        setViewportFromUrl,
        userInitialSideMapFeaturesLength,
        setUserInitialSideMapFeaturesLength,
        assetsSearchInput,
        setAssetsSearchInput,
        showAddAsset,
        setShowAddAsset,
        isFileUploadDialogOpen,
        setIsFileUploadDialogOpen,
        isGeofencesFileUploadDialogOpen,
        setIsGeofencesFileUploadDialogOpen,
        isAssetsVisibleOnGeoFence,
        setIsAssetsVisibleOnGeoFence,
        isGeofencesListVisible,
        setIsGeofencesListVisible,
        shouldUpdateQueryBuilder,
        setShouldUpdateQueryBuilder,
        geofenceNameInput,
        setGeofenceNameInput,
        refetchGeofenceDetail,
        restoreGeofencePreviousFilters,
        resetGeofenceFiltersToDefault,
        resetFilterToDefault,
        resetGeofenceFilterToDefault,
        isGeofencesView,
      }}
    >
      {children}
    </AssetsDataContext.Provider>
  );
};
export const useAssetsDataContext = () => useContext(AssetsDataContext);
