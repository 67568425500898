import { FC } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { nanoid } from "nanoid";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import { CargoCameraStats } from "../../../../../../../graphql/operations";
import { InternalCameraGraphType, InternalCameraSpaceStatus } from "../types";
import { getGraphChipBoxColor } from "../utils";
import Tooltip from "./Tooltip";

export interface GraphChipProps {
  graphType: InternalCameraGraphType;
  item: CargoCameraStats;
  isPhotoTaken: boolean;
}

const GraphChip: FC<GraphChipProps> = ({ graphType, item, isPhotoTaken }) => {
  const status =
    graphType === InternalCameraGraphType.Floor
      ? item.floorUsageStatus
      : item.volumetricUsageStatus;
  const percentage =
    graphType === InternalCameraGraphType.Floor
      ? item.floorUsagePercentage
      : item.volumetricUsagePercentage;

  return (
    <Tooltip
      arrow
      placement="top"
      sx={{
        display: status === InternalCameraSpaceStatus.Loaded ? "block" : "none",
      }}
      title={
        <p data-testid="graph-chip--tooltip">
          {capitalize(status!)}
          <span className="text-brand font-bold ml-2">{percentage}</span>
        </p>
      }
    >
      <Box
        key={nanoid()}
        className={`h-[36px] flex flex-1 items-center justify-center m-1 mb-2 rounded ${getGraphChipBoxColor(
          item
        )}`}
        sx={{
          borderColor: ColorsPalette.RoyalBlue,
        }}
        data-testid="graph-chip"
      >
        {isPhotoTaken && <CameraAltIcon data-testid="graph-chip--photo-icon" />}
      </Box>
    </Tooltip>
  );
};

export default GraphChip;
