import { FC } from "react";
import { Card, CardHeader, CardContent, Typography, Box } from "@mui/material";
import { ReactComponent as DualImbalanceSensorIconDark } from "../../../../../../../../assets/svgs/dualImbalanceSensorDark.svg";
import { ReactComponent as DualImbalanceSensorIconLight } from "../../../../../../../../assets/svgs/dualImbalanceSensorLight.svg";
import { useAppContext } from "../../../../../../../../context/AppContext";
import { SensorStatusUppercase } from "../../../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { usePreferredTimezone } from "../../../../../../../../shared/hooks/usePreferredTimezone";
import { getConvertedPressureValueWithUnit } from "../../../../../../../../utils/convertPressure";
import {
  getConvertedTemperatureValue,
  getTemperatureUnitLabel,
} from "../../../../../../../../utils/convertTemperature";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../../../../utils/date";
import { AssetSensorState } from "../../../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { tireBoxDesign } from "../../../constants";
import { useTiresTabContext } from "../../../context";
import {
  iconSettings,
  isCurrentDualImbalanceSelected,
  isCurrentTireSelected,
} from "../../../helpers";
import {
  AxleSide,
  DualImbalanceAxleTireData,
  ImbalanceVariantsByAxle,
  MAP_AXLE_TO_NUMBER,
  TPMS_Axle,
  TPMS_Tire,
  TPMS_Tire_Name,
} from "../../../interfaces";

export interface TireDualImbalanceSummaryCardProps {
  axleData: DualImbalanceAxleTireData;
}

const TireDualImbalanceSummaryCard: FC<TireDualImbalanceSummaryCardProps> = ({
  axleData,
}) => {
  const isTablet = useBreakpoint("down", "lg");
  const pressureUnit = useGetPressureUnitPreference();
  const tempUnitPreference = useGetTemperatureUnitPreference();
  const timezone = usePreferredTimezone();

  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "3.125rem",
    height: "3.125rem",
    display: "block",
  };

  const { getTireSensorChip, getSensorStateWidget } = useGetSensorStateWidget();
  const leftOuterTire = getTireSensorChip(
    axleData?.left?.outerLeft?.status,
    iconSettings
  );
  const leftInnerTire = getTireSensorChip(
    axleData?.left?.innerLeft?.status as AssetSensorState,
    iconSettings
  );
  const rightOuterTire = getTireSensorChip(
    axleData?.right?.outerRight?.status,
    iconSettings
  );
  const rightInnerTire = getTireSensorChip(
    axleData?.right?.innerRight?.status as AssetSensorState,
    iconSettings
  );

  const leftCardStateWidget = getSensorStateWidget({
    type: undefined,
    label: leftOuterTire.label,
    state: axleData.left.outerLeft.dualImbalanceStatus as SensorStatusUppercase,
  } as any);

  const rightCardStateWidget = getSensorStateWidget({
    type: undefined,
    label: rightOuterTire.label,
    state: axleData.right.outerRight
      .dualImbalanceStatus as SensorStatusUppercase,
  } as any);

  const {
    selectedTpmsAxle: selectedAxle,
    setSelectedTpmsAxle: setSelectedAxle,
    selectedTpmsTire: selectedTire,
    setSelectedTpmsTire: setSelectedTire,
    selectedDualImbalanceRecord,
    setSelectedDualImbalanceRecord,
  } = useTiresTabContext();

  const handleElementClick = (
    axle: TPMS_Axle,
    clickedTire?: TPMS_Tire,
    selectedDualImbalanceSide?: AxleSide
  ) => {
    if (clickedTire && selectedTire === clickedTire && axle === selectedAxle) {
      setSelectedAxle(undefined);
      setSelectedTire(undefined);
    } else if (clickedTire) {
      setSelectedAxle(axle);
      setSelectedTire(clickedTire);
    }

    if (selectedDualImbalanceSide) {
      // set the tire so it's matching the ImbalanceVariantsByAxle type
      const currentDualImbalanceRecord =
        `${selectedDualImbalanceSide}${MAP_AXLE_TO_NUMBER[axle]}` as ImbalanceVariantsByAxle;
      if (selectedDualImbalanceRecord === currentDualImbalanceRecord) {
        setSelectedDualImbalanceRecord(undefined);
      } else {
        setSelectedDualImbalanceRecord(currentDualImbalanceRecord);
      }
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        width: "100%",
        flexDirection: isTablet ? "column" : "row",
      }}
    >
      {/* ------- Left Dual Imbalance Card Container ------- */}
      <Card
        className={`background-white border min-w-[280px] cursor-pointer DualImbalanceCard`}
        onClick={(event) =>
          handleElementClick(
            axleData.left.outerLeft.axle,
            undefined,
            AxleSide.LEFT
          )
        }
        sx={{
          backgroundColor: isCurrentDualImbalanceSelected(
            axleData.left.outerLeft.axle,
            AxleSide.LEFT,
            selectedDualImbalanceRecord as ImbalanceVariantsByAxle
          )
            ? "var(--primary-blue-transparent)"
            : "",
        }}
        data-testid="asset-dashboard--tires-tab--left-card-container-summary"
      >
        <CardHeader
          className="DualImbalanceCardHeader"
          data-testid="header-left-card"
          title={
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                {/* Icon with Axle text */}
                {isLightTheme ? (
                  <DualImbalanceSensorIconDark style={svgIconSettings} />
                ) : (
                  <DualImbalanceSensorIconLight style={svgIconSettings} />
                )}

                <div className="ml-4">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "var(--light-charcoal)",
                      lineHeight: "24px",
                      fontWeight: 600,
                    }}
                  >
                    {/* Every tire has mapped axle data inside it's object */}
                    {axleData.left.outerLeft.axle}
                  </Typography>
                  {axleData.left.innerLeft && (
                    <Typography
                      className="text-brand"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "26px",
                        fontWeight: 600,
                      }}
                    >
                      Dual Left
                    </Typography>
                  )}
                </div>
              </Box>

              {/* Right Side of header with gap & status texts */}
              {/* Right side of Header is not shown when we don't have data for it */}

              {axleData.left.outerLeft.dualImbalanceStatus !==
                SensorStatusUppercase.Unknown &&
                axleData.left.innerLeft && (
                  <Box display="flex" flexDirection="column">
                    {leftCardStateWidget}
                    <Typography className="text-base text-card__typography">
                      Gap:
                      {
                        <span className="ml-1 font-bold">
                          {`${getConvertedPressureValueWithUnit(
                            axleData.left.outerLeft.gap ?? 0,
                            pressureUnit
                          )}`}
                        </span>
                      }
                    </Typography>
                  </Box>
                )}
            </Box>
          }
        ></CardHeader>
        <CardContent
          className={`tirecardContent ${isTablet && "mobileTireCardContent"}`}
        >
          {/* Left part of Axle */}
          {/* Outer Left tire */}
          <Box
            component="div"
            onClick={(event) => {
              handleElementClick(
                axleData.left.outerLeft.axle,
                axleData.left.outerLeft.tire
              );
              event.stopPropagation(); // protection from parent fn being fired
            }}
            sx={{
              ...tireBoxDesign,
              borderColor: isCurrentTireSelected(
                axleData.left.outerLeft.axle,
                axleData.left.outerLeft.tire,
                selectedTire as TPMS_Tire,
                selectedAxle as TPMS_Axle
              )
                ? "var(--primary-blue)"
                : "var(--gray)",
            }}
          >
            {/* Tire name + status Icon */}
            <Box
              marginBottom="12px"
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-card__typography"
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                {TPMS_Tire_Name.OuterLeft}
              </Typography>
              <span className="ml-2">{leftOuterTire.icon}</span>
            </Box>

            {/* Summary of it's data */}
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Pressure:
              </Typography>
              {axleData.left.outerLeft.pressure && (
                <span className="ml-1 font-bold">
                  {`${getConvertedPressureValueWithUnit(
                    axleData.left.outerLeft.pressure,
                    pressureUnit
                  )}`}
                </span>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Temperature:
              </Typography>
              {axleData.left.outerLeft.temperature && (
                <span className="ml-1 font-bold">
                  {`${getConvertedTemperatureValue(
                    Number(axleData.left.outerLeft.temperature.toFixed(2)),
                    tempUnitPreference
                  )} ${getTemperatureUnitLabel(tempUnitPreference, true)}`}
                </span>
              )}
            </Box>
            <Typography
              className="text-base text-card__typography"
              sx={{ marginBottom: "10px" }}
            >
              Last Reported:
              <span className="ml-1">
                {axleData?.left?.outerLeft?.lastReported
                  ? formatDateInTimezone(
                      axleData.left.outerLeft.lastReported,
                      DATE_TIME_FORMAT_SHORT,
                      mapTimezoneToIANAValue(timezone)
                    )
                  : "N/A"}
              </span>
            </Typography>
          </Box>

          {/* Inner Left tire */}
          {axleData?.left?.innerLeft && (
            <Box
              component="div"
              sx={{
                ...tireBoxDesign,
                marginLeft: isTablet ? "0px" : "16px",
                marginTop: isTablet ? "16px" : "0px",
                borderColor: isCurrentTireSelected(
                  axleData?.left?.outerLeft?.axle,
                  axleData?.left?.innerLeft?.tire,
                  selectedTire as TPMS_Tire,
                  selectedAxle as TPMS_Axle
                )
                  ? "var(--primary-blue)"
                  : "var(--gray)",
              }}
              onClick={(event) => {
                handleElementClick(
                  axleData?.left?.outerLeft?.axle,
                  axleData?.left?.innerLeft?.tire
                );
                event.stopPropagation(); // protection from parent fn being fired
              }}
            >
              {/* Tire name + status Icon */}
              <Box
                marginBottom="12px"
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-card__typography"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 600,
                  }}
                >
                  {TPMS_Tire_Name.InnerLeft}
                </Typography>
                <span className="ml-2">{leftInnerTire.icon}</span>
              </Box>

              {/* Summary of it's data */}
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-base text-card__typography"
                  sx={{ marginBottom: "10px" }}
                >
                  Pressure:
                </Typography>
                {axleData.left.innerLeft.pressure && (
                  <span className="ml-1 font-bold">
                    {`${getConvertedPressureValueWithUnit(
                      axleData.left.innerLeft.pressure,
                      pressureUnit
                    )}`}
                  </span>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-base text-card__typography"
                  sx={{ marginBottom: "10px" }}
                >
                  Temperature:
                </Typography>
                {axleData.left.innerLeft.temperature && (
                  <span className="ml-1 font-bold">
                    {`${getConvertedTemperatureValue(
                      Number(axleData.left.innerLeft.temperature.toFixed(2)),
                      tempUnitPreference
                    )} ${getTemperatureUnitLabel(tempUnitPreference, true)}`}
                  </span>
                )}
              </Box>
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Last Reported:
                <span className="ml-1">
                  {axleData?.left?.innerLeft?.lastReported
                    ? formatDateInTimezone(
                        axleData.left.innerLeft.lastReported,
                        DATE_TIME_FORMAT_SHORT,
                        mapTimezoneToIANAValue(timezone)
                      )
                    : "N/A"}
                </span>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* ------- Right Dual Imbalance Card Container ------- */}
      <Card
        className={`background-white border min-w-[280px] cursor-pointer DualImbalanceCard secondCard`}
        sx={{
          marginLeft: isTablet ? "0px" : "16px",
          marginTop: isTablet ? "16px" : "0px",
          backgroundColor: isCurrentDualImbalanceSelected(
            axleData.left.outerLeft.axle,
            AxleSide.RIGHT,
            selectedDualImbalanceRecord as ImbalanceVariantsByAxle
          )
            ? "var(--primary-blue-transparent)"
            : "",
        }}
        onClick={(event) =>
          handleElementClick(
            axleData.left.outerLeft.axle,
            undefined,
            AxleSide.RIGHT
          )
        }
        data-testid="asset-dashboard--tires-tab--right-card-container-summary"
      >
        <CardHeader
          className="DualImbalanceCardHeader"
          data-testid="header-right-card"
          title={
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                {isLightTheme ? (
                  <DualImbalanceSensorIconDark style={svgIconSettings} />
                ) : (
                  <DualImbalanceSensorIconLight style={svgIconSettings} />
                )}

                <div className="ml-4">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "var(--light-charcoal)",
                      lineHeight: "24px",
                      fontWeight: 600,
                    }}
                  >
                    {/* Every tire has mapped axle data inside it's object */}
                    {axleData.right.outerRight.axle}
                  </Typography>
                  {axleData?.right?.innerRight && (
                    <Typography
                      className="text-brand"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "26px",
                        fontWeight: 600,
                      }}
                    >
                      Dual Right
                    </Typography>
                  )}
                </div>
              </Box>

              {/* Right Side of header with gap & status texts */}
              {/* Right side of Header is not shown when we don't have data for it */}
              {axleData.right.outerRight.dualImbalanceStatus !==
                SensorStatusUppercase.Unknown &&
                axleData.right.innerRight && (
                  <Box display="flex" flexDirection="column">
                    {rightCardStateWidget}
                    <Typography className="text-base text-card__typography">
                      Gap:
                      {
                        <span className="ml-1 font-bold">
                          {`${getConvertedPressureValueWithUnit(
                            axleData.right.outerRight.gap ?? 0,
                            pressureUnit
                          )}`}
                        </span>
                      }
                    </Typography>
                  </Box>
                )}
            </Box>
          }
        ></CardHeader>

        <CardContent
          className={`tirecardContent ${isTablet && "mobileTireCardContent"}`}
        >
          {/* Right part of Axle */}
          {/* Inner Right tire */}
          {axleData?.right?.innerRight && (
            <Box
              component="div"
              sx={{
                ...tireBoxDesign,
                borderColor: isCurrentTireSelected(
                  axleData?.right?.outerRight?.axle,
                  axleData?.right?.innerRight?.tire,
                  selectedTire as TPMS_Tire,
                  selectedAxle as TPMS_Axle
                )
                  ? "var(--primary-blue)"
                  : "var(--gray)",
              }}
              onClick={(event) => {
                handleElementClick(
                  axleData?.right?.outerRight?.axle,
                  axleData?.right?.innerRight?.tire,
                  undefined
                );
                event.stopPropagation(); // protection from parent fn being fired
              }}
            >
              {/* Tire name + status Icon */}
              <Box
                marginBottom="12px"
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-card__typography"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 600,
                  }}
                >
                  {TPMS_Tire_Name.InnerRight}
                </Typography>
                <span className="ml-2">{rightInnerTire.icon}</span>
              </Box>

              {/* Summary of it's data */}
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-base text-card__typography"
                  sx={{ marginBottom: "10px" }}
                >
                  Pressure:
                </Typography>
                {axleData.right.innerRight.pressure && (
                  <span className="ml-1 font-bold">
                    {`${getConvertedPressureValueWithUnit(
                      axleData.right.innerRight.pressure,
                      pressureUnit
                    )}`}
                  </span>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography
                  className="text-base text-card__typography"
                  sx={{ marginBottom: "10px" }}
                >
                  Temperature:
                </Typography>
                {axleData.right.innerRight.temperature && (
                  <span className="ml-1 font-bold">
                    {`${getConvertedTemperatureValue(
                      Number(axleData.right.innerRight.temperature.toFixed(2)),
                      tempUnitPreference
                    )} ${getTemperatureUnitLabel(tempUnitPreference, true)}`}
                  </span>
                )}
              </Box>
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Last Reported:
                <span className="ml-1">
                  {axleData.right.innerRight.lastReported
                    ? formatDateInTimezone(
                        axleData.right.innerRight.lastReported,
                        DATE_TIME_FORMAT_SHORT,
                        mapTimezoneToIANAValue(timezone)
                      )
                    : "N/A"}
                </span>
              </Typography>
            </Box>
          )}

          {/* Outer Right tire */}
          <Box
            component="div"
            sx={{
              ...tireBoxDesign,
              marginLeft: isTablet ? "0px" : "16px",
              marginTop: isTablet ? "16px" : "0px",
              borderColor: isCurrentTireSelected(
                axleData.right.outerRight.axle,
                axleData.right.outerRight.tire,
                selectedTire as TPMS_Tire,
                selectedAxle as TPMS_Axle
              )
                ? "var(--primary-blue)"
                : "var(--gray)",
            }}
            onClick={(event) => {
              handleElementClick(
                axleData.right.outerRight.axle,
                axleData.right.outerRight.tire,
                undefined
              );
              event.stopPropagation(); // protection from parent fn being fired
            }}
          >
            {/* Tire name + status Icon */}
            <Box
              marginBottom="12px"
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-card__typography"
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                {TPMS_Tire_Name.OuterRight}
              </Typography>
              <span className="ml-2">{rightOuterTire.icon}</span>
            </Box>

            {/* Summary of it's data */}
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Pressure:
              </Typography>
              {axleData.right.outerRight.pressure && (
                <span className="ml-1 font-bold">
                  {`${getConvertedPressureValueWithUnit(
                    axleData.right.outerRight.pressure,
                    pressureUnit
                  )}`}
                </span>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                className="text-base text-card__typography"
                sx={{ marginBottom: "10px" }}
              >
                Temperature:
              </Typography>
              {axleData.right.outerRight.temperature && (
                <span className="ml-1 font-bold">
                  {`${getConvertedTemperatureValue(
                    Number(axleData.right.outerRight.temperature.toFixed(2)),
                    tempUnitPreference
                  )} ${getTemperatureUnitLabel(tempUnitPreference, true)}`}
                </span>
              )}
            </Box>
            <Typography
              className="text-base text-card__typography"
              sx={{ marginBottom: "10px" }}
            >
              Last Reported:
              <span className="ml-1">
                {axleData?.right?.outerRight?.lastReported
                  ? formatDateInTimezone(
                      axleData.right.outerRight.lastReported,
                      DATE_TIME_FORMAT_SHORT,
                      mapTimezoneToIANAValue(timezone)
                    )
                  : "N/A"}
              </span>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TireDualImbalanceSummaryCard;
