import { FC, MutableRefObject, memo } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  SensorProfileConfigType,
  TableViewType,
  GetTableDataInput,
  TableDomain,
} from "../../../../../../graphql/operations";
import type { QueryInput } from "../../../../../../shared/components/Table/BackEndProcessingTable";
import { BackEndProcessingTable } from "../../../../../../shared/components/Table/BackEndProcessingTable";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { columnVisibilityModel, SensorsData } from "../../sensorsUtils";
import useSensorsTableLogic from "../../shared/hooks/useSensorsTableLogic";
import { sensorsPsiWheelEndTableColumns } from "./constants";

export interface SensorsPsiWheelEndTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  onRowSelect: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  sensorsData: SensorsData;
  rowSelectionModel?: Array<number>;
  updateQueryInput: (data: Partial<QueryInput>) => void;
  queryInput: GetTableDataInput;
}

const SensorsPsiWheelEndTable: FC<SensorsPsiWheelEndTableProps> = ({
  tableType,
  onRowClick,
  onRowSelect,
  apiRef,
  sensorsData,
  rowSelectionModel,
  updateQueryInput,
  queryInput,
}) => {
  const { tableRows, canCheckTheRows, fileName, appConfig } =
    useSensorsTableLogic({
      apiRef,
      rowSelectionModel,
      sensorsData: sensorsData.data,
      sensorPropertyType: SensorProfileConfigType.PsiWheelEnd,
      sensorColumns: sensorsPsiWheelEndTableColumns,
    });

  return (
    <Box className="flex-1 basis-[300px]">
      <BackEndProcessingTable
        apiRef={apiRef}
        queryInput={queryInput}
        updateQueryInput={updateQueryInput}
        domain={TableDomain.SensorsWheelEnd}
        tableName="psi-wheel-end-sensors"
        baseFilters={{}}
        tableType={tableType}
        columns={sensorsPsiWheelEndTableColumns}
        allowFilters
        columnVisibilityModel={columnVisibilityModel}
        sorting={queryInput.sorting ?? undefined}
        data={{
          pagination: sensorsData?.pagination,
          rows: tableRows,
        }}
        checkboxSelection={canCheckTheRows}
        isLoading={sensorsData.isFetchingSensor}
        isSuccess={!sensorsData.isErrorSensor}
        onExport={() => {}}
        rowsPerPage={appConfig.table.defaultRowsPerPage}
        updateSelectedRowsHandler={onRowSelect}
        searchMinLength={appConfig.searchMinLength}
        onRowClick={onRowClick}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(SensorsPsiWheelEndTable);
