import { FC, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";
import { mapChartColors } from "./AssetsPerSuborgWidget";

export type DevicesPerSubOrgRecord = {
  _id: string;
  color: ColorsPalette;
  totalCount: number;
  percentage: number;
};

export type DashboardWidgetDevicesPerSubOrgData = {
  total: number;
  data: Array<DevicesPerSubOrgRecord>;
};

export interface DashboardWidgetDevicesPerSubOrgType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetDevicesPerSubOrgData;
}

export interface DevicesPerSubOrganizationsWidgetProps {
  widget: DashboardWidgetDevicesPerSubOrgType;
}

const mapWidgetData = (devicesPerOrg: DashboardWidgetDevicesPerSubOrgData) => {
  return devicesPerOrg?.data?.reduce(
    (acc: HorizontalBarChartData[], deviceStat, index) => {
      const isOther = deviceStat._id === "Other";
      acc.push({
        name: capitalize(deviceStat._id),
        color: mapChartColors(isOther, index),
        value: deviceStat.totalCount ?? 0,
      });

      return acc;
    },
    []
  );
};

export const DevicesPerSubOrganizationsWidget: FC<
  DevicesPerSubOrganizationsWidgetProps
> = ({ widget }) => {
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg.value,
      },
    },
    {
      enabled: Boolean(currentOrg.value),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetDevicesPerSubOrgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--devices-per-suborg"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the devices per
          suborganization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={widget.name ?? "Organization"}
          showXAxis={false}
          subtitle={"Devices"}
          isLoading={isLoading}
          showTotal={true}
          showPercentages={true}
          totalRecords={total}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          widgetId={widget.id}
        />
      )}
    </Card>
  );
};
